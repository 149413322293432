import React, { useEffect, useState } from 'react';
import { Row, Button, Container, Card, Form, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { CheckCircle, ExclamationTriangle } from 'react-bootstrap-icons';
import dateFnsFormat from 'date-fns/format';
import uploadPhotoAsync from '@/utilities/uploadPhotoAsync';
import { UPDATE_CARRIER_DOCUMENT } from '../mutations';
import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { useClientUser } from '@/hooks';

function Documents(props) {
    const [uploadedFiles, setUploadedFiles] = useState({});
    let carrierAgreementFileRef = React.useRef(null);
    let w9FileRef = React.useRef(null);
    let insuranceCertFileRef = React.useRef(null);
    let workersCompFileRef = React.useRef(null);
    const { user_id } = useClientUser();

    const [updateCarrierDoc] = useMutation(UPDATE_CARRIER_DOCUMENT, {
        onError: (error) => {
            console.log(error);
            Sentry.captureException(error);
        },
    });

    const theme = createTheme({
        palette: {
            primary: {
                main: '#71b77a',
            },
        },
    });

    useEffect(() => {
        setUploadedFiles((prevState) => ({
            ...prevState,
            onwardform: props.currentCarrier?.onward_form,
            insuranceform: props.currentCarrier?.insurance_form,
            wform: props.currentCarrier?.w_form,
            workersCompIns: props.currentCarrier?.workers_comp_form,
        }));
    }, [props.currentCarrier]);

    const uploadDoc = async (event) => {
        let fileName = event.target.name;
        let expiration = '';

        if (fileName === 'wform') {
            if (!uploadedFiles.wform) {
                let lastDayOfYear = new Date(new Date().getFullYear(), 11, 31);
                expiration = dateFnsFormat(lastDayOfYear, 'MM/dd/yyyy');
            } else {
                expiration = props.currentCarrier.w_form_expiration || '';
            }
        }

        if (['wform', 'insuranceform', 'workersCompIns', 'onwardform'].includes(event.target.name)) {
            try {
                let file = await URL.createObjectURL(event.target.files[0]);
                let fbFile = await uploadPhotoAsync(file, 'carrier', 'pdf');

                let variables = {};

                switch (fileName) {
                    case 'onwardform':
                        variables = {
                            onward_form: fbFile,
                            onward_form_uploaded_at: new Date().toISOString(),
                            onward_form_expiration: expiration,
                        };

                        break;
                    case 'insuranceform':
                        variables = {
                            insurance_form: fbFile,
                            insurance_form_uploaded_at: new Date().toISOString(),
                            insurance_form_expiration: expiration,
                        };

                        break;
                    case 'workersCompIns':
                        variables = {
                            workers_comp_form: fbFile,
                            workers_comp_form_uploaded_at: new Date().toISOString(),
                            workers_comp_form_expiration: expiration,
                        };

                        break;
                    case 'wform':
                        variables = {
                            w_form: fbFile,
                            w_form_uploaded_at: new Date().toISOString(),
                            w_form_expiration: expiration,
                        };

                        break;
                    default:
                        console.log('improper file name');
                        break;
                }

                await updateCarrierDoc({
                    variables: {
                        client_id: user_id,
                        _set: variables,
                    },
                });

                setUploadedFiles({ ...uploadedFiles, [fileName]: fbFile });
            } catch (e) {
                console.error(`There was an error: ${e.message}`);
            }
        }
    };

    return (
        <Card className="tabCard">
            <ThemeProvider theme={theme}>
                <Card.Body className="">
                    <Container fluid>
                        <Form>
                            <Container className="d-flex justify-content-center">
                                
                            </Container>
                            <Row className="mt-2 mb-1">
                                <Col>
                                    <h5>Upload Legal Documents</h5>
                                </Col>
                            </Row>
                            <Row className="mt-2 mb-1">
                                <Col md="4" sm="12">
                                    Onward Carrier Agreement: (
                                    <Link
                                        to="/files/Onward Delivery, LLC - Carrier Agreement.pdf"
                                        target="_blank"
                                        download
                                    >
                                        Download
                                    </Link>
                                    )
                                </Col>
                                <Col md="8" sm="12">
                                    <Row className="d-flex justify-content-center">
                                        <Col xs="3">
                                            <Row className="align-items-center">
                                                <Button
                                                    size="sm"
                                                    color="primary"
                                                    onClick={() => carrierAgreementFileRef.current.click()}
                                                >
                                                    Upload File
                                                </Button>
                                                <div
                                                    style={{
                                                        marginLeft: '5px',
                                                    }}
                                                >
                                                    {/* {this.state.fileNameDisplays.onwardform} */}
                                                </div>
                                                <input
                                                    type="file"
                                                    ref={carrierAgreementFileRef}
                                                    name="onwardform"
                                                    onChange={uploadDoc}
                                                    style={{ display: 'none' }}
                                                />
                                                {uploadedFiles.onwardform && (
                                                    <Button
                                                        size="sm"
                                                        style={{ marginTop: '5px' }}
                                                        variant="primary"
                                                        target="_blank"
                                                        href={uploadedFiles.onwardform}
                                                    >
                                                        Show Preview
                                                    </Button>
                                                )}
                                            </Row>
                                        </Col>
                                        <Col className="d-flex justify-content-center">
                                            {uploadedFiles.onwardform ? (
                                                <>
                                                    <CheckCircle className="text-success mx-1" size={25} />
                                                    <p className="text-success">Uploaded</p>
                                                </>
                                            ) : (
                                                <>
                                                    <ExclamationTriangle className="text-warning mx-1" size={25} />
                                                    <p className="text-warning">Still needed</p>
                                                </>
                                            )}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="mt-2 mb-1">
                                <Col md="4" sm="12">
                                    General Liability, Auto, Cargo, Umbrella, and Warehouse Insurance: (
                                    <Link to="/files/Example of COI Form.PNG" target="_blank" download>
                                        Example
                                    </Link>
                                    )
                                </Col>
                                <Col md="8" sm="12">
                                    <Row className="d-flex justify-content-center">
                                        <Col xs="3">
                                            <Row className="align-items-center">
                                                <Button
                                                    size="sm"
                                                    color="primary"
                                                    onClick={() => insuranceCertFileRef.current.click()}
                                                >
                                                    Upload File
                                                </Button>
                                                <div
                                                    style={{
                                                        marginLeft: '5px',
                                                    }}
                                                ></div>
                                                <input
                                                    type="file"
                                                    ref={insuranceCertFileRef}
                                                    name="insuranceform"
                                                    onChange={uploadDoc}
                                                    style={{ display: 'none' }}
                                                />
                                            
                                            {uploadedFiles.insuranceform && (
                                                <Button
                                                    size="sm"
                                                    style={{ marginTop: '5px' }}
                                                    variant="primary"
                                                    target="_blank"
                                                    href={uploadedFiles.insuranceform}
                                                >
                                                    Show Preview
                                                </Button>
                                            )}
                                            </Row>
                                        </Col>
                                        <Col className="d-flex justify-content-center">
                                            {uploadedFiles.insuranceform ? (
                                                <>
                                                    <CheckCircle className="text-success mx-1" size={25} />
                                                    <p className="text-success">Uploaded</p>
                                                </>
                                            ) : (
                                                <>
                                                    <ExclamationTriangle className="text-warning mx-1" size={25} />
                                                    <p className="text-warning">Still needed</p>
                                                </>
                                            )}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="mt-2 mb-1">
                                <Col md="4" sm="12">
                                    Workers' Compensation Insurance:
                                </Col>
                                <Col md="8" sm="12">
                                    <Row className="d-flex justify-content-center">
                                        <Col xs="3">
                                            <Row className="align-items-center">
                                                <Button
                                                    size="sm"
                                                    color="primary"
                                                    onClick={() => workersCompFileRef.current.click()}
                                                    fontSize="10px"
                                                >
                                                    Upload File
                                                </Button>
                                                <div
                                                    style={{
                                                        marginLeft: '5px',
                                                    }}
                                                ></div>
                                                <input
                                                    type="file"
                                                    ref={workersCompFileRef}
                                                    name="workersCompIns"
                                                    onChange={uploadDoc}
                                                    style={{ display: 'none' }}
                                                />
                                                {uploadedFiles.workersCompIns && (
                                                    <Button
                                                        size="sm"
                                                        style={{ marginTop: '5px' }}
                                                        variant="primary"
                                                        target="_blank"
                                                        href={uploadedFiles.workersCompIns}
                                                    >
                                                        Show Preview
                                                    </Button>
                                                )}
                                            </Row>
                                        </Col>
                                        <Col className="d-flex justify-content-center">
                                            {uploadedFiles.workersCompIns ? (
                                                <>
                                                    <CheckCircle className="text-success mx-1" size={25} />
                                                    <p className="text-success">Uploaded</p>
                                                </>
                                            ) : (
                                                <>
                                                    <ExclamationTriangle className="text-warning mx-1" size={25} />
                                                    <p className="text-warning">Still needed</p>
                                                </>
                                            )}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="mt-2 mb-1">
                                <Col md="4" sm="12">
                                    W9 Form:
                                </Col>
                                <Col md="8" sm="12">
                                    <Row className="d-flex justify-content-center">
                                        <Col xs="3">
                                            <Row className="align-items-center">
                                                <Button size="sm" color="primary" onClick={() => w9FileRef.current.click()}>
                                                    Upload File
                                                </Button>
                                                <div
                                                    style={{
                                                        marginLeft: '5px',
                                                    }}
                                                >
                                                    {/* {this.state.fileNameDisplays.wform} */}
                                                </div>
                                                <input
                                                    type="file"
                                                    name="wform"
                                                    ref={w9FileRef}
                                                    onChange={uploadDoc}
                                                    style={{ display: 'none' }}
                                                />
                                                {uploadedFiles.wform && (
                                                    <Button
                                                        size="sm"
                                                        style={{ marginTop: '5px' }}
                                                        variant="primary"
                                                        target="_blank"
                                                        href={uploadedFiles.wform}
                                                    >
                                                        Show Preview
                                                    </Button>
                                                )}
                                            </Row>
                                        </Col>
                                        <Col className="d-flex justify-content-center">
                                            {uploadedFiles.wform ? (
                                                <>
                                                    <CheckCircle className="text-success mx-1" size={25} />
                                                    <p className="text-success">Uploaded</p>
                                                </>
                                            ) : (
                                                <>
                                                    <ExclamationTriangle className="text-warning mx-1" size={25} />
                                                    <p className="text-warning">Still needed</p>
                                                </>
                                            )}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="mt-5 mb-1">
                                <Col>
                                    <h6>If you have any questions, please contact compliance@onwarddelivery.com</h6>
                                </Col>
                            </Row>
                        </Form>
                    </Container>
                </Card.Body>
            </ThemeProvider>
        </Card>
    );
}

export default Documents;
