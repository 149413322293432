import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, TextField, InputAdornment } from '@material-ui/core';
import { css } from '@emotion/react';
import { PrimaryButton } from '@/styles/blocks';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';

const FuelModal = ({ open, onClose, onSubmit }) => {
    const [startRate, setStartRate] = useState(0);
    const [endRate, setEndRate] = useState(0);
    const [percentInterval, setPercentInterval] = useState(0);
    const [rateInterval, setRateInterval] = useState(0);
    const [startingPercent, setStartingPercent] = useState(0);

    const isValid =
        startRate >= 0 &&
        endRate >= 0 &&
        percentInterval >= 0 &&
        rateInterval >= 0 &&
        startRate < endRate &&
        startingPercent >= 0;

    const handleSubmit = () => {
        if (!isValid) return;

        onSubmit({
            startRate,
            endRate,
            percentInterval,
            rateInterval,
            startingPercent,
        });
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Add Calculated FSC</DialogTitle>
            <DialogContent>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <CurrencyTextField
                            label="Starting Rate"
                            variant="outlined"
                            fullWidth
                            value={startRate}
                            onChange={(_, value) => setStartRate(value)}
                            error={startRate < 0 || (startRate >= endRate && (startRate !== 0 || endRate !== 0))}
                            helperText={
                                startRate < 0
                                    ? 'Must be non-negative'
                                    : startRate >= endRate && endRate !== 0
                                    ? 'Must be less than End Rate'
                                    : ''
                            }
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <CurrencyTextField
                            label="Ending Rate"
                            variant="outlined"
                            fullWidth
                            value={endRate}
                            onChange={(_, value) => setEndRate(value)}
                            error={endRate < 0 || (endRate <= startRate && (startRate !== 0 || endRate !== 0))}
                            helperText={
                                endRate < 0
                                    ? 'Must be non-negative'
                                    : endRate <= startRate && startRate !== 0
                                    ? 'Must be greater than Start Rate'
                                    : ''
                            }
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            label="Starting Surcharge"
                            variant="outlined"
                            fullWidth
                            type="number"
                            value={startingPercent}
                            onChange={(e) => setStartingPercent(parseFloat(e.target.value))}
                            error={startingPercent < 0}
                            helperText={startingPercent < 0 ? 'Must be non-negative' : ''}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">%</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            label="Percentage Interval"
                            variant="outlined"
                            fullWidth
                            type="number"
                            value={percentInterval}
                            onChange={(e) => setPercentInterval(parseFloat(e.target.value))}
                            error={percentInterval < 0}
                            helperText={percentInterval < 0 ? 'Must be non-negative' : ''}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">%</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <CurrencyTextField
                            label="Rate Interval"
                            variant="outlined"
                            fullWidth
                            value={rateInterval}
                            onChange={(_, value) => setRateInterval(value)}
                            error={rateInterval < 0}
                            helperText={rateInterval < 0 ? 'Must be non-negative' : ''}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions
                css={css`
                    padding: 16px 24px;
                `}
            >
                <PrimaryButton onClick={onClose}>Cancel</PrimaryButton>
                <PrimaryButton onClick={handleSubmit} disabled={!isValid || (startRate === 0 && endRate === 0)}>
                    Add
                </PrimaryButton>
            </DialogActions>
        </Dialog>
    );
};

export default FuelModal;
