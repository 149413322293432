import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { css } from '@emotion/react';
import { SecondaryButton, PrimaryButton } from '@/styles/blocks';
import { useItemDynamicFields } from '@/components/ShipmentForm/hooks/items';
import {
    ItemType,
    ItemTypeDetails,
    BedSize,
    AssemblyType,
    ItemIsPackagedSelect,
    ItemPieceCountSelect,
    OnwardTextField,
} from '../../InputFields';
import { TabSection, TabRow } from '../../../blocks';
import { ITEM_LABELS_BY_FREIGHT } from '@/components/ShipmentForm/constants/freightTypes';

const ItemDetails = ({ order, item, index, callbacks, hasError, isDirty, startDirty }) => {
    useItemDynamicFields(item, (changes) => {
        callbacks.editItem(index, changes);
    });

    const isPallet = order.freight_type === 'pallet';
    const isContainer = order.freight_type === 'containers';
    const isBedItem = useMemo(() => {
        if (item?.item_type_details) {
            return ['mattress', 'boxSpring', 'bedFrame'].includes(item.item_type_details);
        }

        return false;
    }, [item]);

    const isWillCall = useMemo(() => {
        return ['WILL_CALL', 'PICKUP_AND_WILL_CALL'].includes(order.job_type);
    }, [order]);

    let config = (
        <>
            <TabRow>
                <Grid item xs={3}>
                    <ItemType
                        disabled={isWillCall}
                        isWillCall={isWillCall}
                        value={item.is_return ? 'return' : 'delivery'}
                        onChange={(e) => {
                            callbacks.editItem(index, {
                                is_return: e.target.value === 'return',
                            });
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <OnwardTextField
                        variant="outlined"
                        fullWidth
                        label="Item Class"
                        value={item.class}
                        onChange={(e) => {
                            callbacks.editItem(index, { class: e.target.value });
                            callbacks.makeItemDirty(index, ['class']);
                        }}
                        error={hasError.class && (startDirty || isDirty.class)}
                    />
                </Grid>
                <Grid item xs={3}>
                    <OnwardTextField
                        variant="outlined"
                        fullWidth
                        label="NFMC #"
                        value={item.nfmc_no}
                        onChange={(e) => {
                            callbacks.editItem(index, { nfmc_no: e.target.value });
                            callbacks.makeItemDirty(index, ['nfmc_no']);
                        }}
                        error={hasError.nfmc_no && (startDirty || isDirty.nfmc_no)}
                    />
                </Grid>
                <Grid item xs={3}>
                    <OnwardTextField
                        variant="outlined"
                        fullWidth
                        label="Barcode ID"
                        value={item.barcode}
                        onChange={(e) => {
                            callbacks.editItem(index, { barcode: e.target.value });
                            callbacks.makeItemDirty(index, ['barcode']);
                        }}
                        error={hasError.barcode && (startDirty || isDirty.barcode)}
                    />
                </Grid>
            </TabRow>
        </>
    );
    if (!isPallet && !isContainer) {
        config = (
            <>
                <TabRow>
                    <Grid item xs={4}>
                        <ItemType
                            value={item.is_return ? 'return' : 'delivery'}
                            onChange={(e) => {
                                callbacks.editItem(index, {
                                    is_return: e.target.value === 'return',
                                });
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <ItemTypeDetails
                            required={!order.oms}
                            value={item.item_type_details}
                            freightType={order.freight_type}
                            onChange={(e) => {
                                callbacks.editItem(index, { item_type_details: e.target.value });
                                const toDirty = ['item_type_details'];
                                if (e.target.value !== 'mattress') {
                                    toDirty.concat(['length', 'width', 'height', 'weight', 'cubes_per_unit']);
                                }
                                callbacks.makeItemDirty(index, toDirty);
                            }}
                            error={hasError.item_type_details && (startDirty || isDirty.item_type_details)}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <BedSize
                            disabled={!isBedItem}
                            value={{ height: item.height, width: item.width, length: item.length }}
                            itemType={item.item_type_details}
                            onChange={({ height, length, weight, width }) => {
                                callbacks.editItem(index, {
                                    height,
                                    length,
                                    weight,
                                    width,
                                });
                                callbacks.makeItemDirty(index, [
                                    'length',
                                    'width',
                                    'height',
                                    'weight',
                                    'cubes_per_unit',
                                ]);
                            }}
                            error={
                                isBedItem &&
                                ['length', 'width', 'height'].some(
                                    (attr) => hasError[attr] && (startDirty || isDirty[attr])
                                )
                            }
                        />
                    </Grid>
                </TabRow>

                <TabRow>
                    <Grid item xs={3}>
                        <OnwardTextField
                            variant="outlined"
                            color="primary"
                            label="Pallet ID"
                            value={item.pallet?.data?.pallet_name || item.pallet?.pallet_name}
                            onChange={(e) =>
                                callbacks.editPallet(index, item.pallet_id, { pallet_name: e.target.value || null })
                            }
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <OnwardTextField
                            variant="outlined"
                            fullWidth
                            label="Item Class"
                            value={item.class}
                            onChange={(e) => {
                                callbacks.editItem(index, { class: e.target.value });
                                callbacks.makeItemDirty(index, ['class']);
                            }}
                            error={hasError.class && (startDirty || isDirty.class)}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <OnwardTextField
                            variant="outlined"
                            fullWidth
                            label="NFMC #"
                            value={item.nfmc_no}
                            onChange={(e) => {
                                callbacks.editItem(index, { nfmc_no: e.target.value });
                                callbacks.makeItemDirty(index, ['nfmc_no']);
                            }}
                            error={hasError.nfmc_no && (startDirty || isDirty.nfmc_no)}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <OnwardTextField
                            variant="outlined"
                            fullWidth
                            label="Barcode ID"
                            value={item.barcode}
                            onChange={(e) => {
                                callbacks.editItem(index, { barcode: e.target.value });
                                callbacks.makeItemDirty(index, ['barcode']);
                            }}
                            error={hasError.barcode && (startDirty || isDirty.barcode)}
                        />
                    </Grid>
                </TabRow>

                <TabRow>
                    <Grid item xs={3}>
                        <AssemblyType
                            required={order.assembly_required || !order.oms}
                            value={item.assembly_type}
                            onChange={(e) => {
                                callbacks.editItem(index, { assembly_type: e.target.value });
                                callbacks.makeItemDirty(index, ['assembly_type']);
                            }}
                            error={hasError.assembly_type && (startDirty || isDirty.assembly_type)}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <OnwardTextField
                            label="Service Time (min)"
                            variant="outlined"
                            type="number"
                            fullWidth
                            value={Number.isFinite(item.service_time) ? item.service_time : undefined}
                            onChange={(e) => {
                                callbacks.editItem(index, {
                                    service_time: Number.isFinite(parseInt(e.target.value))
                                        ? parseInt(e.target.value, 10)
                                        : undefined,
                                });
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <ItemIsPackagedSelect
                            required={!order.oms}
                            value={item.is_packaged}
                            onChange={(e) => {
                                callbacks.editItem(index, { is_packaged: e.target.value });
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <ItemPieceCountSelect
                            required={!order.oms}
                            value={item.item_piece_count}
                            onChange={(e) => {
                                callbacks.editItem(index, { item_piece_count: e.target.value });
                                callbacks.makeItemDirty(index, ['item_piece_count']);
                            }}
                            error={hasError.item_piece_count && (startDirty || isDirty.item_piece_count)}
                        />
                    </Grid>
                </TabRow>
            </>
        );
    }

    return (
        <>
            <TabSection>{`${ITEM_LABELS_BY_FREIGHT[order.freight_type] || 'Item'} ${index + 1}`}</TabSection>
            <TabRow css={css`align-items; center; margin-bottom: 12px; justify-content: space-between;`}>
                <Grid item>
                    <SecondaryButton onClick={() => callbacks.copyItem({ ...item })}>Duplicate Item</SecondaryButton>
                </Grid>
                <Grid item>
                    <PrimaryButton red onClick={() => callbacks.deleteItem(index)}>
                        Delete Item
                    </PrimaryButton>
                </Grid>
            </TabRow>

            <TabRow>
                <Grid item xs={2}>
                    <OnwardTextField
                        type="number"
                        variant="outlined"
                        label="Quantity"
                        value={item.quantity}
                        fullWidth
                        InputProps={{ inputProps: { min: 1 } }}
                        onChange={(e) => {
                            callbacks.editItem(index, {
                                quantity: Number(e.target.value),
                            });
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <OnwardTextField
                        label="Weight (lb)"
                        variant="outlined"
                        fullWidth
                        value={item.weight}
                        error={hasError.weight && (startDirty || isDirty.weight)}
                        onBlur={() => callbacks.makeItemDirty(index, ['weight'])}
                        onChange={(e) => {
                            const str = `${e.target.value}`.replaceAll(/,|\s/g, '');
                            callbacks.editItem(index, { weight: parseInt(str, 10) || 0 });
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <OnwardTextField
                        label="Length (in)"
                        variant="outlined"
                        fullWidth
                        value={item.length}
                        error={hasError.length && (startDirty || isDirty.length)}
                        onBlur={() => callbacks.makeItemDirty(index, ['length'])}
                        onChange={(e) => callbacks.editItem(index, { length: parseInt(e.target.value, 10) || 0 })}
                    />
                </Grid>
                <Grid item xs={2}>
                    <OnwardTextField
                        label="Width (in)"
                        variant="outlined"
                        fullWidth
                        value={item.width}
                        error={hasError.width && (startDirty || isDirty.width)}
                        onBlur={() => callbacks.makeItemDirty(index, ['width'])}
                        onChange={(e) => callbacks.editItem(index, { width: parseInt(e.target.value, 10) || 0 })}
                    />
                </Grid>
                <Grid item xs={2}>
                    <OnwardTextField
                        label="Height (in)"
                        variant="outlined"
                        fullWidth
                        value={item.height}
                        error={hasError.height && (startDirty || isDirty.height)}
                        onBlur={() => callbacks.makeItemDirty(index, ['height'])}
                        onChange={(e) => callbacks.editItem(index, { height: parseInt(e.target.value, 10) || 0 })}
                    />
                </Grid>
                <Grid item xs={2}>
                    <OnwardTextField
                        label="Cubic Feet"
                        variant="outlined"
                        fullWidth
                        value={item.cubes_per_unit}
                        error={hasError.cubes_per_unit && (startDirty || isDirty.cubes_per_unit)}
                        onBlur={() => callbacks.makeItemDirty(index, ['cubes_per_unit'])}
                        onChange={(e) =>
                            callbacks.editItem(index, { cubes_per_unit: parseInt(e.target.value, 10) || 0 })
                        }
                    />
                </Grid>
            </TabRow>
            <TabRow>
                <Grid item xs={2}>
                    <OnwardTextField
                        variant="outlined"
                        color="primary"
                        label="SKU"
                        value={item.sku}
                        onChange={(e) => callbacks.editItem(index, { sku: e.target.value })}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={10}>
                    <OnwardTextField
                        label={isPallet ? 'Description of palletized item' : 'Description'}
                        variant="outlined"
                        name="desc"
                        fullWidth
                        value={item.description}
                        onChange={(e) => callbacks.editItem(index, { description: e.target.value })}
                    />
                </Grid>
            </TabRow>

            {config}
        </>
    );
};
export default ItemDetails;
