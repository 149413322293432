import { PrimaryButton, Row, SecondaryButton } from '@/styles/blocks';
import { Grid, TextField, MenuItem } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { OnwardPhoneInput, OnwardTextField, SecondaryContactBtn } from '../../InputFields';
import { css } from '@emotion/react';
import { useClientUser } from '@/hooks';
import CancelIcon from '@material-ui/icons/Cancel';
import { ModalActions, ModalHeader, ModalContent } from '@/components/Navigation/NavResponsiveModal';
import NavResponsiveModal from '@/components/Navigation/NavResponsiveModal';
import { TabRow, TabSection } from '@/components/ShipmentForm/blocks';

const ContactFields = ({ isDirty, order, attrs, hasError, callbacks, opt }) => {
    const [name, phone, email] = attrs;

    return (
        <TabRow>
            <Grid item xs={4}>
                <OnwardTextField
                    fullWidth
                    label="Name"
                    variant="outlined"
                    value={order[name]}
                    onBlur={() => callbacks.makeDirty([name])}
                    onChange={(e) => callbacks.modifyOrder({ [name]: e.target.value })}
                    error={hasError[name] && (opt.startDirty || isDirty[name])}
                />
            </Grid>
            <Grid item xs={4}>
                <OnwardPhoneInput
                    fullWidth
                    label="Phone"
                    variant="outlined"
                    value={order[phone]}
                    onBlur={() => callbacks.makeDirty([phone])}
                    onChange={(e, value) => callbacks.modifyOrder({ [phone]: value })}
                    error={hasError[phone] && (opt.startDirty || isDirty[phone])}
                />
            </Grid>
            <Grid item xs={4}>
                <OnwardTextField
                    fullWidth
                    label="Email"
                    variant="outlined"
                    value={order[email]}
                    onBlur={() => callbacks.makeDirty([phone])}
                    onChange={(e) => callbacks.modifyOrder({ [email]: e.target.value })}
                />
            </Grid>
        </TabRow>
    );
};

const Customer = ({ isDirty, order, hasError, callbacks, opt, modalState }) => {
    const [hasSecondaryContactOverride, setHasSecondaryContact] = useState(null);
    const [confirmDeleteCustomerModalOpen, setConfirmDeleteCustomerModalOpen] = useState(false);
    const [deletingCustomer, setDeletingCustomer] = useState(null);
    const { customer_locations, client_id } = useClientUser();

    const onModalClose = () => {
        setConfirmDeleteCustomerModalOpen(false);
        setDeletingCustomer(null);
    };

    const handleDeleteCustomer = () => {
        callbacks.deleteSavedCustomer({
            variables: {
                location_id: deletingCustomer.location_id,
            },
            onCompleted: () => {
                onModalClose();
            },
        });
    };

    const hasSecondaryContact = useMemo(() => {
        return hasSecondaryContactOverride !== null
            ? hasSecondaryContactOverride
            : order?.secondary_dropoff_contact_name?.length > 0;
    }, [order, hasSecondaryContactOverride]);

    return (
        <>
            <TabSection>Contact Info</TabSection>
            {customer_locations?.length > 0 ? (
                <Row>
                    <Grid item xs={12}>
                        <TextField
                            select
                            variant="outlined"
                            label="Saved Customers"
                            value={modalState.savedCustomer || ''}
                            onChange={(e) => callbacks.onSavedCustomerSelect(e.target.value)}
                            fullWidth
                            renderValue={(e) => {
                                console.log(e);
                                return e;
                            }}
                        >
                            {customer_locations.map((customer, idx) => (
                                <MenuItem key={`${customer.location_id}-${idx}`} value={customer}>
                                    <div
                                        css={css`
                                            display: flex;
                                            justify-content: space-between;
                                            width: 100%;
                                        `}
                                    >
                                        <span>{customer.contact_name}</span>
                                        <span
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setDeletingCustomer(customer);
                                                setConfirmDeleteCustomerModalOpen(true);
                                            }}
                                        >
                                            <CancelIcon />
                                        </span>
                                    </div>
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Row>
            ) : null}
            <ContactFields
                isDirty={isDirty}
                order={order}
                attrs={['dropoff_name', 'dropoff_phone', 'dropoff_email']}
                hasError={hasError}
                callbacks={callbacks}
                opt={opt}
            />
            <TabRow>
                <Grid item>
                    <SecondaryContactBtn
                        hasSecondaryContact={hasSecondaryContact}
                        onClick={() => {
                            setHasSecondaryContact(!hasSecondaryContact);
                            callbacks.modifyOrder({
                                secondary_dropoff_contact_name: null,
                                secondary_dropoff_contact_phone: null,
                                secondary_dropoff_contact_email: null,
                            });
                        }}
                    />
                </Grid>
            </TabRow>
            {hasSecondaryContact && (
                <ContactFields
                    isDirty={isDirty}
                    order={order}
                    attrs={[
                        'secondary_dropoff_contact_name',
                        'secondary_dropoff_contact_phone',
                        'secondary_dropoff_contact_email',
                    ]}
                    hasError={hasError}
                    callbacks={callbacks}
                    opt={opt}
                />
            )}
            {confirmDeleteCustomerModalOpen && (
                <NavResponsiveModal open={confirmDeleteCustomerModalOpen} onClose={onModalClose}>
                    <ModalHeader onClose={onModalClose} title="Delete Customer?" />
                    <ModalContent>
                        Are you sure you would like to delete customer {deletingCustomer?.contact_name}?
                    </ModalContent>
                    <ModalActions>
                        <SecondaryButton onClick={onModalClose}>Cancel</SecondaryButton>
                        <PrimaryButton onClick={handleDeleteCustomer}>Confirm</PrimaryButton>
                    </ModalActions>
                </NavResponsiveModal>
            )}
        </>
    );
};

export default Customer;
