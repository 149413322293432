import { Grid } from '@material-ui/core';
import { ModalContext } from '..';
import { PrimaryButton } from '@/styles/blocks';
import { TabRow, TabTitle } from '@/components/ShipmentForm/blocks';
import HaulAwayItem from './HaulAwayItem';
import React, { useState, useContext, forwardRef } from 'react';

const ItemsEditModal = forwardRef(({ initCollapsed }, ref) => {
    const { state: modalState, callbacks } = useContext(ModalContext);
    const { order } = modalState;
    const [collapsed, setCollapsed] = useState(!!initCollapsed);

    return (
        <>
            <TabTitle ref={ref} collapsed={collapsed} callbacks={{ setCollapsed }}>
                Haulaways
            </TabTitle>
            {collapsed ? null : (
                <>
                    {(order.haulaway_items || []).map((item, idx) => (
                        <HaulAwayItem key={idx} order={order} item={item} index={idx} callbacks={callbacks} />
                    ))}

                    <TabRow>
                        <Grid item>
                            <PrimaryButton onClick={() => callbacks.addItem({ type: 'haulaway' })}>
                                Add Item
                            </PrimaryButton>
                        </Grid>
                    </TabRow>
                </>
            )}
        </>
    );
});

export default ItemsEditModal;
