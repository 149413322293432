import { useClientUser } from '@/hooks';
import React, { useMemo, useState } from 'react';
import { LocationPermissionsTitle, LocationPermissionsTitleSpan, TabCard } from '../blocks';
import { css } from '@emotion/react';
import { ModalContent, PrimaryButton, SecondaryButton } from '@/styles/blocks';
import { Grid, LinearProgress, MenuItem, Snackbar, TextField, Typography } from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/client';
import { ADD_CUSTOM_DOCUMENT, DELETE_CUSTOM_DOCUMENT, DOCUMENTS_BY_CLIENT_ID } from './graphql';
import { captureException } from '@sentry/react';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import NavResponsiveModal, { ModalHeader } from '@/components/Navigation/NavResponsiveModal';
import FileDropZone from '@/components/OrderDetailsPage/FileDropZone';
import uploadPhotoAsync from '@/utilities/uploadPhotoAsync';
import * as Sentry from '@sentry/react';
import { CheckCircleOutline } from '@material-ui/icons';

const documentTypes = ['Assembly', 'Waybill', 'Pickup Confirmation'];

const CustomDocuments = () => {
    const { user_id } = useClientUser();
    const [notification, setNotification] = useState({});
    const [newDocModalInfo, setNewDocModalInfo] = useState(null);
    const [uploading, setUploading] = useState(false);

    const { data, loading: documentsLoading } = useQuery(DOCUMENTS_BY_CLIENT_ID, {
        variables: { client_id: user_id },
    });

    const documents = useMemo(() => {
        return data?.documents || [];
    }, [data]);

    const [addDocument, { loading: documentAddLoading }] = useMutation(ADD_CUSTOM_DOCUMENT, {
        onError: (e) => {
            console.error(e);
            captureException(e);
            setNotification({
                severity: 'error',
                message: 'Error uploading document',
            });
        },
    });

    const [deleteDocument, { loading: documentDeleteLoading }] = useMutation(DELETE_CUSTOM_DOCUMENT, {
        onError: (e) => {
            console.error(e);
            captureException(e);
            setNotification({
                severity: 'error',
                message: 'Error deleting document',
            });
        },
    });

    const handleModalClose = () => {
        setNewDocModalInfo(null);
    };

    const handleFileAdd = async (file, fileType) => {
        setUploading(true);
        if (file) {
            try {
                const fileUrl = URL.createObjectURL(file);
                const fbFileUrl = await uploadPhotoAsync(fileUrl, 'custom_documents', fileType);
                const fbFile = fbFileUrl;

                setNewDocModalInfo((prev) => ({
                    ...prev,
                    link: fbFile,
                    ...(!prev.name
                        ? {
                              name: file.name,
                          }
                        : {}),
                }));
            } catch (error) {
                Sentry.captureException(error);
                console.error(error);
            }
        }
        setUploading(false);
    };

    const handleDocumentSave = async () => {
        await addDocument({
            variables: {
                object: {
                    client_id: user_id,
                    link: newDocModalInfo.link,
                    name: newDocModalInfo.name,
                    type: newDocModalInfo.type,
                },
            },
            onCompleted: () => {
                handleModalClose();
            },
            update: (cache, { data: { insert_documents_one } }) => {
                const existingData = cache.readQuery({
                    query: DOCUMENTS_BY_CLIENT_ID,
                    variables: { client_id: user_id }
                });
                cache.writeQuery({
                    query: DOCUMENTS_BY_CLIENT_ID,
                    variables: { client_id: user_id },
                    data: {
                        documents: [...(existingData?.documents || []), insert_documents_one]
                    }
                });
            },
        });
    };

    const handleDocumentDelete = async (document_id) => {
        await deleteDocument({
            variables: {
                document_id: document_id,
            },
            onCompleted: () => {
                handleModalClose();
            },
            update: (cache, { data: { delete_documents_by_pk } }) => {
                const existingData = cache.readQuery({
                    query: DOCUMENTS_BY_CLIENT_ID,
                    variables: { client_id: user_id }
                });
                
                cache.writeQuery({
                    query: DOCUMENTS_BY_CLIENT_ID,
                    variables: { client_id: user_id },
                    data: {
                        documents: existingData.documents.filter(
                            doc => doc.document_id !== delete_documents_by_pk.document_id
                        )
                    }
                });
            },
        });
    };

    const loading = documentAddLoading || documentDeleteLoading;
    const valid = newDocModalInfo?.name && newDocModalInfo?.link && newDocModalInfo?.type;

    return (
        <>
            <TabCard
                css={css`
                    padding: 2rem;
                `}
            >
                <Snackbar open={!!notification.message} handleClose={() => setNotification({})} {...notification} />
                <Grid
                    direction="column"
                    css={css`
                        flex-wrap: nowrap;
                    `}
                >
                    <Grid
                        container
                        css={css`
                            justify-content: space-between;
                        `}
                    >
                        <LocationPermissionsTitle>
                            <LocationPermissionsTitleSpan>Documents</LocationPermissionsTitleSpan>
                        </LocationPermissionsTitle>
                        <Grid item>
                            <PrimaryButton disabled={loading} onClick={() => setNewDocModalInfo({})}>
                                Upload
                            </PrimaryButton>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        css={css`
                            padding-bottom: 16px;
                            padding-left: 16px;
                            padding-right: 16px;
                        `}
                    >
                        <Grid item xs={5}>
                            <Typography
                                css={css`
                                    font-weight: 700;
                                `}
                            >
                                Name
                            </Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography
                                css={css`
                                    font-weight: 700;
                                `}
                            >
                                Document Type
                            </Typography>
                        </Grid>
                    </Grid>

                    {documents.map((doc) => (
                        <>
                            <hr
                                css={css`
                                    margin: 0;
                                `}
                            />

                            <Grid
                                container
                                css={css`
                                    padding: 16px;
                                `}
                            >
                                <Grid item xs={5}>
                                    <Typography>{doc.name}</Typography>
                                </Grid>
                                <Grid
                                    container
                                    xs={7}
                                    css={css`
                                        justify-content: space-between;
                                    `}
                                >
                                    <Grid item>
                                        <Typography>{doc.type}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <DeleteForeverIcon
                                            css={css`
                                                color: red;
                                                cursor: pointer;
                                            `}
                                            onClick={() => {
                                                !loading && handleDocumentDelete(doc.document_id);
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    ))}
                </Grid>
            </TabCard>
            <NavResponsiveModal open={!!newDocModalInfo} onClose={handleModalClose}>
                <ModalHeader
                    title="Upload Document"
                    onClose={handleModalClose}
                    css={css`
                        border-bottom: 1px solid lightgray;
                    `}
                />
                {uploading && (
                    <LinearProgress
                        color="primary"
                        css={css`
                            width: 100%;
                        `}
                    />
                )}
                <ModalContent
                    css={css`
                        width: 100%;
                    `}
                >
                    <Grid container>
                        {newDocModalInfo?.link ? (
                            <Grid
                                css={css`
                                    height: 100px;
                                    width: 100%;
                                    align-content: center;
                                    text-align: center;
                                `}
                            >
                                File Selected&nbsp;
                                <CheckCircleOutline />
                            </Grid>
                        ) : (
                            <FileDropZone
                                customLabel={' '}
                                customStyle={{
                                    height: '100px',
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: 'white',
                                    borderRadius: '5px',
                                    outline: '1px solid #c4c4c4',
                                    fontFamily: 'Montserrat',
                                    cursor: 'pointer',
                                }}
                                handleFileAdd={handleFileAdd}
                                disabled={uploading}
                            />
                        )}

                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            spacing={4}
                            css={css`
                                margin-top: 8px;
                            `}
                        >
                            <Grid item xs={6}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    label="Name"
                                    value={newDocModalInfo?.name || ''}
                                    onChange={(e) =>
                                        setNewDocModalInfo((prev) => ({
                                            ...prev,
                                            name: e.target.value,
                                        }))
                                    }
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    select
                                    variant="outlined"
                                    label="Document Type"
                                    value={newDocModalInfo?.type || ''}
                                    onChange={(e) => {
                                        setNewDocModalInfo((prev) => ({
                                            ...prev,
                                            type: e.target.value,
                                        }));
                                    }}
                                >
                                    {documentTypes.map((docType) => (
                                        <MenuItem key={docType} value={docType}>
                                            {docType}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            css={css`
                                margin-top: 24px;
                                width: 100%;
                            `}
                        >
                            <SecondaryButton onClick={handleModalClose}>Cancel</SecondaryButton>
                            <PrimaryButton disabled={!valid || loading} onClick={handleDocumentSave}>
                                Save
                            </PrimaryButton>
                        </Grid>
                    </Grid>
                </ModalContent>
            </NavResponsiveModal>
        </>
    );
};

export default CustomDocuments;
