import React, { createContext, useEffect, useMemo, useState } from 'react';
import { addDays, format } from 'date-fns';
import { captureException } from '@sentry/react';
import { useClientUser } from '@/hooks';
import { asUTCDate } from '@/utilities/convertToISO';
import { useQuery } from '@apollo/client';
import { LOADBOARD_QUERY, QUERY_PLANNING_DATA } from './graphql/queries';
import { v4 as uuid } from 'uuid';
import { formatInTimeZone } from 'date-fns-tz';
import { ROUTE_COLORS } from './constants';
import { TEMP_ROUTE } from './constants';
import { asDateInTZ } from '@/utilities/convertToISO';

export const PlanningContext = createContext();

const PlanningContextProvider = ({ children }) => {
    const { user_id, circles, test_acc, trucks, preferences_next_day_returns } = useClientUser();
    const [deliveryDate, setDeliveryDate] = useState(
        window.sessionStorage.getItem('plan_page_del_date') || format(new Date(), 'yyyy-MM-dd')
    );
    const [loadDeliveryDate, setLoadDeliveryDate] = useState(
        window.sessionStorage.getItem('plan_page_del_date') || format(new Date(), 'yyyy-MM-dd')
    );
    const updateDeliveryDate = (date) => {
        setSelectedRoute(null);
        setSelectedOrders({});
        setSelectedLoads({});
        setDeliveryDate(date);
        setLoadDeliveryDate(date);
        window?.sessionStorage?.setItem('plan_page_del_date', date);
    };
    // Misc state
    const [showAllDirections, setShowAllDirections] = useState(false);
    const [showUnscheduledOrders, setShowUnscheduledOrders] = useState(false);
    const [showReceivedOrders, setShowReceivedOrders] = useState(false);
    const [showLoadBoardLoads, setShowLoadBoardLoads] = useState(false);
    const [showNetworkRoutes, setShowNetworkRoutes] = useState(false);
    const toggleShowAllDirections = () => setShowAllDirections((prev) => !prev);
    const toggleShowUnscheduledOrders = () => setShowUnscheduledOrders((prev) => !prev);
    const toggleShowLoadBoardLoads = () => setShowLoadBoardLoads((prev) => !prev);
    const toggleShowNetworkRoutes = () => setShowNetworkRoutes((prev) => !prev);
    const toggleShowReceivedOrders = () => setShowReceivedOrders((prev) => !prev);
    const [directionLoading, setDirectionLoading] = useState({ total: 0, complete: 0 });
    const [clashingRoutes, setClashingRoutes] = useState(null);
    const [clashingDriver, setClashingDriver] = useState(null);
    const [networkDirectionLoading, setNetworkDirectionLoading] = useState({ total: 0, complete: 0 });
    const [bid, setBid] = useState(null);

    const [selectedDrivers, setSelectedDrivers] = useState({});
    const [selectedRoute, setSelectedRoute] = useState(null);
    const [selectedOrders, setSelectedOrders] = useState({});
    const [expandedOrders, setExpandedOrders] = useState({});
    const [selectedLoads, setSelectedLoads] = useState({});
    const toggleSelectOrder = (order_id) => {
        setSelectedOrders({
            ...selectedOrders,
            [order_id]: !selectedOrders[order_id],
        });
    };
    const toggleSelectLoad = (order_id) => {
        setSelectedLoads({
            ...selectedLoads,
            [order_id]: !selectedLoads[order_id],
        });
    };
    const selectMultiOrders = (orderIds, selected = true) => {
        if (!orderIds) return;
        setSelectedOrders({
            ...selectedOrders,
            ...Object.fromEntries(orderIds.map((orderId) => [orderId, selected])),
        });
        setSelectedLoads({});
    };
    const selectMultiLoads = (orderIds, selected = true) => {
        if (!orderIds) return;
        setSelectedLoads({
            // ...selectedLoads,
            ...Object.fromEntries(orderIds.map((orderId) => [orderId, selected])),
        });
        setSelectedOrders({});
    };

    const [notification, setNotification] = useState(null);
    const setError = (error, userMsg) => {
        console.error(error);
        captureException(error);
        setNotification({
            severity: 'error',
            message: userMsg || 'Error processing request',
        });
    };

    const [variables, loadBoardVariables] = useMemo(() => {
        const startCutoff = asUTCDate(deliveryDate);
        const endCutoff = addDays(startCutoff, 1);

        return [
            {
                dropoffCondition: {
                    _or: [
                        { _and: [{ delivery_date: { _gte: startCutoff } }, { delivery_date: { _lt: endCutoff } }] },
                        ...(circles?.['plan-page-unscheduled'] ? [{ delivery_date: { _is_null: true } }] : []),
                        ...(circles?.['plan-page-received']
                            ? [
                                  {
                                      _and: [
                                          { delivery_date: { _is_null: true } },
                                          { wh_events: { status: { _eq: 'RECEIVED' } } },
                                      ],
                                  },
                              ]
                            : []),
                    ],
                },
                pickupCondition: {
                    _or: [
                        { _and: [{ pickup_date: { _gte: startCutoff } }, { pickup_date: { _lt: endCutoff } }] },
                        ...(circles?.['plan-page-unscheduled'] ? [{ pickup_date: { _is_null: true } }] : []),
                        ...(circles?.['plan-page-received']
                            ? [
                                  {
                                      _and: [
                                          { pickup_date: { _is_null: true } },
                                          { wh_events: { status: { _eq: 'RECEIVED' } } },
                                      ],
                                  },
                              ]
                            : []),
                    ],
                },
                from_date: startCutoff,
                to_date: endCutoff,
                user_id,
            },
            {
                user_id,
                end: endCutoff,
                test_acc,
                currentTime: new Date().toISOString(),
            },
        ];
    }, [deliveryDate, user_id]);
    const { loading, error, data, refetch, subscribeToMore, networkStatus } = useQuery(QUERY_PLANNING_DATA, {
        variables,
        fetchPolicy: 'cache-and-network',
        onError: (error) => {
            setError(error, `Error getting orders and routes for delivery date ${deliveryDate}`);
        },
    });
    const [orders, routes] = useMemo(() => {
        const ordersByKey = {
            ...(data?.routes || []).reduce((acc, route) => {
                return {
                    ...acc,
                    ...Object.fromEntries(
                        (route?.orders || []).map((mapping) => {
                            const row = {
                                ...mapping.order,
                                ...(mapping.type === 'PICKUP'
                                    ? {
                                          pickup_route_id: route.route_id,
                                          crossdock_leg: 'pickup',
                                      }
                                    : {}),
                                ...(mapping.type === 'DROPOFF'
                                    ? {
                                          dropoff_route_id: route.route_id,
                                          crossdock_leg: 'dropoff',
                                      }
                                    : {}),
                                ...(mapping.type === 'FULL'
                                    ? {
                                          dropoff_route_id: route.route_id,
                                      }
                                    : {}),
                            };
                            const rowId = `${row.order_id}_${row.crossdock_leg}`;
                            return [rowId, row];
                        })
                    ),
                };
            }, {}),
            ...Object.fromEntries(
                (data?.pickups || []).map((o) => {
                    const row = { ...o, crossdock_leg: 'pickup' };
                    const rowId = `${row.order_id}_${row.crossdock_leg}`;
                    return [rowId, row];
                })
            ),
            ...Object.fromEntries(
                (data?.dropoffs || []).map((o) => {
                    const row = {
                        ...o,
                        ...(['CD_PENDING_PO', 'ROUTED_PU', 'CD_RECEIVED_SKIP_DO'].includes(o.event_state)
                            ? { crossdock_leg: 'dropoff' }
                            : {}),
                    };
                    const rowId = `${row.order_id}_${row.crossdock_leg}`;
                    return [rowId, row];
                })
            ),
        };

        return [Object.values(ordersByKey), data?.routes || []];
    }, [data]);

    const {
        loading: loadBoardLoading,
        data: loadBoardData,
        refetch: loadBoardRefetch,
    } = useQuery(LOADBOARD_QUERY, {
        skip: !circles?.['plan-page-load-board'] || !showLoadBoardLoads,
        variables: loadBoardVariables,
        fetchPolicy: 'network-only',
        onError: (error) => {
            setError(error, `Error getting marketplace loads for ${deliveryDate}`);
        },
    });
    const loadBoardLoads = useMemo(() => {
        if (loadBoardData?.orders) {
            return loadBoardData.orders;
        }
        return [];
    }, [loadBoardData]);
    const selectedLoadBoardLoads = useMemo(() => {
        return (loadBoardLoads || []).filter((order) => selectedLoads[order.order_id]);
    }, [loadBoardLoads, selectedLoads]);

    const [modalOpen, setModalOpen] = useState(null);
    const [actionableRoute, setActionableRoute] = useState(null);
    const [actionableStop, setActionableStop] = useState(null);
    const [actionableOrder, setActionableOrder] = useState(null);
    const [routesToSubmitInternal, setRoutesToSubmitInternal] = useState([]);
    const [lockAllRoutesClicked, setLockAllRoutesClicked] = useState(false);

    useEffect(() => {
        setSelectedOrders({});
        setSelectedLoads({});
        setSelectedRoute(null);
    }, [deliveryDate]);

    const setters = {
        setSelectedDrivers,
        setLockAllRoutesClicked,
        setRoutesToSubmitInternal,
    };

    const dayOfTheWeek = useMemo(() => {
        if (deliveryDate) {
            const _date = new Date(deliveryDate);

            return formatInTimeZone(_date, 'utc', 'iiii').toLowerCase();
        }
    }, [deliveryDate]);

    const availableTrucks = useMemo(() => {
        if (!dayOfTheWeek || !trucks || !routes || loading) return [];
        let todaysTrucks = trucks.filter((truck) => truck?.days_available?.[dayOfTheWeek]);

        return todaysTrucks.sort((a, b) => {
            if (a.truck_name < b.truck_name) return -1;
            return 1;
        });
    }, [dayOfTheWeek, routes, trucks]);

    const generateRoutesForAvailableTrucks = (truckData) => {
        const displayedRouteColors = new Set(routes.map((route) => route.route_color));
        const allowedRouteColors = new Set(ROUTE_COLORS);

        // pick colors not already assigned
        const availableColors = [
            ...new Set([...allowedRouteColors].filter((color) => !displayedRouteColors.has(color))),
        ];

        const { route_id, route_number, ...rest } = TEMP_ROUTE;

        const newRoutes = Array.from({ length: truckData.length }, (_, i) => {
            return {
                ...rest,
                shipper_id: user_id,
                scheduled_delivery: asDateInTZ(deliveryDate).toISOString(),
                route_color: availableColors[i % availableColors.length],
                finish_returns_next_day: preferences_next_day_returns,
                status: 'planning',
                source_form: 'PLANNING',
                need_to_optimize: true,
                planning: true,
                stopsByRouteId: [],
                orders: [],
                truck_id: truckData[i].truck_id,
                truck: {
                    ...truckData[i],
                },
                __typename: 'routes',
                route_id: uuid(),
                route_alias: truckData[i].truck_name,
                shell: true,
            };
        });
        return newRoutes;
    };

    const withGeneratedRoutes = useMemo(() => {
        const filteredTrucks = availableTrucks?.filter((t) => {
            return !routes?.some((r) => r.truck_id === t.truck_id);
        });
        const generatedRoutes = generateRoutesForAvailableTrucks(filteredTrucks);
        return [...routes, ...generatedRoutes].filter((r) => r.status !== 'deleted');
    }, [routes, trucks]);

    return (
        <PlanningContext.Provider
            value={{
                state: {
                    orders,
                    routes: withGeneratedRoutes,
                    loading: loading || networkStatus === 4,
                    variables,
                    loadBoardLoads,
                    selectedLoadBoardLoads,
                    loadBoardLoading,
                    selectedDrivers,
                    routesToSubmitInternal,
                    lockAllRoutesClicked,
                },
                callbacks: {
                    refetch,
                    subscribeToMore,
                    loadBoardRefetch,
                    ...setters,
                },
                deliveryDate,
                updateDeliveryDate,
                loadDeliveryDate,
                setLoadDeliveryDate,
                showAllDirections,
                toggleShowAllDirections,
                showUnscheduledOrders,
                toggleShowUnscheduledOrders,
                showLoadBoardLoads,
                toggleShowLoadBoardLoads,
                showNetworkRoutes,
                toggleShowNetworkRoutes,
                directionLoading,
                setDirectionLoading,
                networkDirectionLoading,
                setNetworkDirectionLoading,
                modalOpen,
                setModalOpen,
                selectedRoute,
                setSelectedRoute,
                selectedOrders,
                setSelectedOrders,
                expandedOrders,
                setExpandedOrders,
                selectedLoads,
                setSelectedLoads,
                toggleSelectLoad,
                selectMultiLoads,
                toggleSelectOrder,
                selectMultiOrders,
                notification,
                setNotification,
                setError,
                actionableRoute,
                setActionableRoute,
                actionableStop,
                setActionableStop,
                actionableOrder,
                setActionableOrder,
                clashingRoutes,
                setClashingRoutes,
                clashingDriver,
                setClashingDriver,
                circles,
                bid,
                setBid,
                showReceivedOrders,
                toggleShowReceivedOrders,
            }}
        >
            {children}
        </PlanningContext.Provider>
    );
};

export const withPlanningContext = (Component) => (props) =>
    (
        <PlanningContextProvider>
            <Component {...props} />
        </PlanningContextProvider>
    );
