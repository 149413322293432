import { useSubscription } from '@apollo/client';
import { useMemo } from 'react';
import { GET_DRIVER_COORDINATES } from '@/graphql/queries/drivers';

/**
 * Calculate the direction that the truck is facing in cartesian coordinates
 * @param {} pointA Previous Truck Location
 * @param {*} pointB Current Truck Location
 * @returns Direction in degrees
 */
const getDirection = (pointA, pointB) => {
    const x = pointB.lng - pointA.lng;
    const y = pointB.lat - pointA.lat;
    if (isNaN(x) || isNaN(y)) return 0;
    // Math.atan2(y2-y1 / x2-x1) returns radians from the x axis
    // Converting to degrees gives a number in the range (-180, 180]
    return (Math.atan2(y, x) * 180) / Math.PI;
};

export const useDriverLocation = (entity_id, { disabled } = {}) => {
    const { data: req } = useSubscription(GET_DRIVER_COORDINATES, {
        variables: {
            entity_id,
        },
    });

    const driver = req?.results?.[0] && !disabled ? req.results[0] : {};
    const { coordinates = [], ...rest } = driver;
    const [curr, prev] = coordinates;

    return useMemo(() => {
        const direction = getDirection(prev || {}, curr || {});

        return {
            location: {
                ...(curr || {}),
                direction,
                lastUpdated: location.time,
            },
            driver: {
                ...rest,
            },
        };
    }, [curr, prev]);
};

export default useDriverLocation;
