import { capitalize } from 'lodash';
import { asDateInTZ } from '@/utilities/convertToISO';
import { genAttributes } from '@onward-delivery/core';
import { formatInTimeZone } from 'date-fns-tz';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { integerFormatter } from '../MyRoutes/columns';
import getDeliveryWindow from '@/utilities/getDeliveryWindow';

export const ORDER_EXPORT_COLUMNS = [
    {
        header: 'Order Number',
        value: ({ order, item }) => {
            return order.order_number;
        },
    },
    {
        header: 'PO Number',
        value: ({ order, item }) => {
            return order.po_number;
        },
    },
    {
        header: 'Shipper',
        value: ({ order, item, revenue, owner }) => {
            return order?.order_shipper?.business_name || '-';
        },
    },
    {
        header: 'Scheduled Date',
        value: ({ order, item }) => {
            const { zip } = genAttributes(order);
            const tz = zipcode_to_timezone.lookup(zip) || 'America/New_York';
            return order.delivery_date
                ? formatInTimeZone(asDateInTZ(order.delivery_date, tz), tz, 'EEE, MMM d, yyyy')
                : '';
        },
    },
    {
        header: 'Target Date',
        value: ({ order, item }) => {
            const { zip } = genAttributes(order);
            const tz = zipcode_to_timezone.lookup(zip) || 'America/New_York';
            return order.target_delivery_date
                ? formatInTimeZone(asDateInTZ(order.target_delivery_date, tz), tz, 'EEE, MMM d, yyyy')
                : '';
        },
    },
    {
        header: 'Preferred Date',
        value: ({ order, item }) => {
            const { zip } = genAttributes(order);
            const tz = zipcode_to_timezone.lookup(zip) || 'America/New_York';
            return order.preferred_delivery_date
                ? formatInTimeZone(asDateInTZ(order.preferred_delivery_date, tz), tz, 'EEE, MMM d, yyyy')
                : '';
        },
    },
    {
        header: 'Alt Preferred Dates',
        value: ({ order, item }) => {
            const { zip } = genAttributes(order);
            const tz = zipcode_to_timezone.lookup(zip) || 'America/New_York';

            return (order.alternative_delivery_dates || [])
                .map((date) => {
                    return date ? formatInTimeZone(asDateInTZ(date, tz), tz, 'EEE, MMM d, yyyy') : '';
                })
                .filter((val) => val)
                .join(', ');
        },
    },
    {
        header: 'Order Type',
        value: ({ order, item }) => {
            return item.is_return ? 'Return' : 'Delivery';
        },
    },
    {
        header: 'Origin Name',
        value: ({ order, item }) => {
            return order.order_type === 'return' ? order.dropoff_name : order.pickup_name;
        },
    },
    {
        header: 'Origin Email',
        value: ({ order, item }) => {
            return order.order_type === 'return' ? order.dropoff_email : order.pickup_email;
        },
    },
    {
        header: 'Origin Phone',
        value: ({ order, item }) => {
            return order.order_type === 'return' ? order.dropoff_phone : order.pickup_phone;
        },
    },
    {
        header: 'Origin Address',
        value: ({ order, item }) => {
            return ['pickup_street_address', 'pickup_unit']
                .map((attr) => order[attr])
                .filter((val) => val)
                .join(', ');
        },
    },
    {
        header: 'Origin City',
        value: ({ order, item }) => {
            return order.pickup_city;
        },
    },
    {
        header: 'Origin State',
        value: ({ order, item }) => {
            return order.pickup_state;
        },
    },
    {
        header: 'Origin Zip',
        value: ({ order, item }) => {
            return order.pickup_zip;
        },
    },
    {
        header: 'Destination Name',
        value: ({ order, item }) => {
            return order.order_type !== 'return' ? order.dropoff_name : order.pickup_name;
        },
    },
    {
        header: 'Destination Email',
        value: ({ order, item }) => {
            return order.order_type !== 'return' ? order.dropoff_email : order.pickup_email;
        },
    },
    {
        header: 'Destination Phone',
        value: ({ order, item }) => {
            return order.order_type !== 'return' ? order.dropoff_phone : order.pickup_phone;
        },
    },
    {
        header: 'Destination Address',
        value: ({ order, item }) => {
            return ['dropoff_street', 'dropoff_unit']
                .map((attr) => order[attr])
                .filter((val) => val)
                .join(', ');
        },
    },
    {
        header: 'Destination City',
        value: ({ order, item }) => {
            return order.dropoff_city;
        },
    },
    {
        header: 'Destination State',
        value: ({ order, item }) => {
            return order.dropoff_state;
        },
    },
    {
        header: 'Destination Zip',
        value: ({ order, item }) => {
            return order.dropoff_zip;
        },
    },
    {
        header: 'Owner',
        value: ({ order, item, revenue, owner }) => {
            if (owner === 'Shipper') {
                return 'Marketplace Shipper';
            } else if (owner === 'Carrier') {
                return 'Marketplace Carrier';
            } else if (owner === 'Internal') {
                return 'Internal';
            }
            return '-';
        },
    },
    {
        header: 'Route(s)',
        value: ({ routeLabel }) => {
            if (routeLabel) {
                return routeLabel;
            }
            return '-';
        },
    },
    {
        header: 'Consignee Signature Name',
        value: ({ order, item }) => {
            return order.customer_signature_meta?.name || '';
        },
    },
    {
        header: 'Consignee Signature Date',
        value: ({ order, item }) => {
            const { zip } = genAttributes(order);
            const tz = zipcode_to_timezone.lookup(zip) || 'America/New_York';
            return order.customer_signature_meta?.date
                ? formatInTimeZone(asDateInTZ(order.customer_signature_meta?.date, tz), tz, 'MM/dd/yyyy hh:mm a')
                : '';
        },
    },
    {
        header: 'Item',
        value: ({ order, item }) => {
            const itemInfo = ['item_type_details', 'description', 'sku'];
            return itemInfo
                .map((attr) => item[attr])
                .filter((val) => val)
                .join(' - ');
        },
    },
    {
        header: 'SKU',
        value: ({ order, item }) => {
            return item.sku;
        },
    },
    {
        header: 'Quantity',
        value: ({ order, item }) => {
            return item.quantity;
        },
    },
    {
        header: 'Weight (lb)',
        value: ({ order, item }) => {
            return item.weight;
        },
    },
    {
        header: 'L (in)',
        value: ({ order, item }) => {
            return item.length;
        },
    },
    {
        header: 'W (in)',
        value: ({ order, item }) => {
            return item.width;
        },
    },
    {
        header: 'H (in)',
        value: ({ order, item }) => {
            return item.height;
        },
    },
    {
        header: 'Order Price',
        value: ({ order, item, revenue, owner }) => {
            if (owner === 'Shipper') {
                return revenue || 0;
            }
            return '-';
        },
    },
    {
        header: 'Order Revenue',
        value: ({ order, item, revenue, owner }) => {
            if (owner === 'Carrier' || owner === 'Internal') {
                return revenue || 0;
            }
            return '-';
        },
    },
    {
        header: 'Pickup Notes',
        value: ({ order, accountType }) => {
            const pickupNotes = [];
            (order?.notes || []).forEach((note) => {
                if (note?.private_to && note.private_to !== capitalize(accountType)) {
                    return;
                }
                if (note?.type === 'Pickup') {
                    pickupNotes.push(note.note);
                }
            });
            
            return pickupNotes?.length ? pickupNotes.join(' / ') : '';
        },
    },
    {
        header: 'Dropoff Notes',
        value: ({ order, accountType }) => {
            const dropoffNotes = [];
            (order?.notes || []).forEach((note) => {
                if (note?.private_to && note.private_to !== capitalize(accountType)) {
                    return;
                }
                if (note?.type === 'Delivery') {
                    dropoffNotes.push(note.note);
                }
            });

            return dropoffNotes?.length ? dropoffNotes.join(' / ') : '';
        },
    },
    {
        header: 'Generic Notes',
        value: ({ order, accountType }) => {
            const genericNotes = [];
            (order?.notes || []).forEach((note) => {
                if (note?.private_to && note.private_to !== capitalize(accountType)) {
                    return;
                }
                if (!(note?.type === 'Pickup' || note?.type === 'Delivery')) {
                    genericNotes.push(note.note);
                }
            });

            return genericNotes?.length ? genericNotes.join(' / ') : '';
        },
    },
];

export const ROUTE_EXPORT_COLUMNS = [
    {
        header: 'Route Number',
        value: (route) => {
            return route.route_number;
        },
    },
    {
        header: 'Route Alias',
        value: (route) => {
            return route.route_alias;
        },
    },
    {
        header: 'Stops',
        value: (route) => {
            return route.stopsByRouteId.length;
        },
    },
    {
        header: 'Cubic Ft.',
        value: (route) => {
            return integerFormatter.format(route.totalCubes);
        },
    },
    {
        header: 'Weight lbs.',
        value: (route) => {
            return integerFormatter.format(route.totalWeight);
        },
    },
    {
        header: 'Price',
        value: (route) => {
            if (route.owner === 'Claimed') {
                return route?.total_carrier_rate || '-';
            }
            return '-';
        },
    },
    {
        header: 'Scheduled Delivery',
        value: (route) => {
            return route.scheduled_delivery_formatted;
        },
    },
    {
        header: 'Orders',
        value: (route) => {
            return route.orders.map((orderMapping) => orderMapping.order.order_number).join(', ');
        },
    },
    {
        header: 'Driver',
        value: (route) => {
            //works for now, need to revisit after driver_mappings update
            return route.driver_name || '-';
        },
    },
    {
        header: 'Truck',
        value: (route) => {
            return route.truck_name || '-';
        },
    },
    {
        header: 'Owner',
        value: (route) => {
            return route.owner || '-';
        },
    },
    {
        header: 'Completed At',
        value: (route) => {
            return route.completed_formatted || '-';
        },
    },
];

export const STOP_EXPORT_COLUMNS = [
    {
        header: 'Route',
        value: (stop) => {
            return stop.routeLabel;
        },
    },
    {
        header: 'Stop Type',
        value: (stop) => {
            return stop.type;
        },
    },
    {
        header: 'Address',
        value: (stop) => {
            return stop.address;
        },
    },
    {
        header: 'Service Time',
        value: (stop) => {
            const hours = Math.floor(stop.service_time / 3600000);
            const minutes = Math.floor((stop.service_time % 3600000) / 60000);
            const durationString = `${hours > 0 ? `${hours}h ` : ''}${minutes}min`;
            return durationString.trim() || '-';
        },
    },
    {
        header: 'Cubic Ft.',
        value: (stop) => {
            return integerFormatter.format(stop.totalCubes);
        },
    },
    {
        header: 'Weight lbs.',
        value: (stop) => {
            return integerFormatter.format(stop.totalWeight);
        },
    },
    {
        header: 'Timeframe',
        value: (stop) => {
            return getDeliveryWindow(stop);
        },
    },
    {
        header: 'Arrival Time',
        value: (stop) => {
            const { zip } = genAttributes(stop.stopOrders[0]);
            const tz = zipcode_to_timezone.lookup(zip) || 'America/New_York';
            return stop.arrival_time
                ? formatInTimeZone(asDateInTZ(stop.arrival_time, tz), tz, 'MM/dd/yyyy hh:mm a')
                : '-';
        },
    },
    {
        header: 'Completion Time',
        value: (stop) => {
            const { zip } = genAttributes(stop.stopOrders[0]);
            const tz = zipcode_to_timezone.lookup(zip) || 'America/New_York';
            return stop.stop_completion_time
                ? formatInTimeZone(asDateInTZ(stop.stop_completion_time, tz), tz, 'MM/dd/yyyy hh:mm a')
                : '-';
        },
    },
    {
        header: 'Delivery Duration',
        value: (stop) => {
            if (!stop.arrival_time || !stop.stop_completion_time) {
                return '-';
            }

            const arrivalTime = new Date(stop.arrival_time);
            const completionTime = new Date(stop.stop_completion_time);

            const durationMs = completionTime - arrivalTime;
            const hours = Math.floor(durationMs / 3600000);
            const minutes = Math.floor((durationMs % 3600000) / 60000);
            const seconds = Math.floor((durationMs % 60000) / 1000);

            let durationString = '';
            if (hours > 0) {
                durationString += `${hours}h `;
            }
            if (minutes > 0 || hours > 0) {
                durationString += `${minutes}min `;
            }
            durationString += `${seconds}sec`;

            return durationString.trim();
        },
    },
    {
        header: 'Customer',
        value: (stop) => {
            return stop.stopOrders[0].dropoff_name ? stop.stopOrders[0].dropoff_name : '-';
        },
    },
];
