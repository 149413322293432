import { useClientUser } from '@/hooks';
import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { QUERY_ACTIVITY_LOG } from './graphql';
import Table, { TableLoader } from '../Table';
import { useColumns } from './constants';

const ActivityLog = ({ order_id, compact }) => {
    const { user_id, isOnwardAdmin, isImposter } = useClientUser();

    const condition = useMemo(() => {
        let filters = [{ order_id: { _eq: order_id } }];

        if (!isOnwardAdmin && !isImposter) {
            filters.push(
                ...[
                    { private_admin: { _eq: false } },
                    { _or: [{ private_client_id: { _eq: user_id } }, { private_client_id: { _is_null: true } }] },
                ]
            );
        }

        return { _and: filters };
    }, [order_id, user_id, isOnwardAdmin, isImposter]);

    const { data, loading } = useQuery(QUERY_ACTIVITY_LOG, {
        variables: {
            order_id,
            condition,
        },
        skip: !order_id,
    });

    const logs = useMemo(() => {
        return data?.order_activity_logs || [];
    }, [data]);

    const ACTIVITY_LOG_COLUMNS = useColumns(compact);

    if (loading) {
        return <TableLoader />;
    }

    return (
        <Table
            data={logs}
            columns={ACTIVITY_LOG_COLUMNS}
            callbacks={{
                getRowId: (log) => log.log_id,
            }}
            options={{
                select: false,
                sortAsync: false,
            }}
        />
    );
};

export default ActivityLog;
