const devBase = 'http://localhost:5001/onward-new/us-central1';
const prodBase = 'https://us-central1-onward-new.cloudfunctions.net';
const stagingBase = 'https://us-central1-onward-staging.cloudfunctions.net';

const routes = {
    createPaymentIntent: {
        development: `${devBase}/createPaymentIntent`,
        production: `${prodBase}/createPaymentIntent`,
        staging: `${stagingBase}/createPaymentIntent`,
    },
    generateTeamMemberJwt: {
        development: `${devBase}/v2-generateTeammateJwt`,
        production: `${prodBase}/v2-generateTeammateJwt`,
        staging: `${stagingBase}/v2-generateTeammateJwt`,
        description: 'Generates a JWT token to use for sending invite links to future teammate users',
    },
    teammateInviteLink: {
        development: `${devBase}/v2-teammateInviteLink`,
        production: `${prodBase}/v2-teammateInviteLink`,
        staging: `${stagingBase}/v2-teammateInviteLink`,
        description: 'Sends an email with a sign-up link to future teammate users',
    },
    verifyJwt: {
        development: `${devBase}/v2-lib-verifyToken`,
        production: `${prodBase}/v2-lib-verifyToken`,
        staging: `${stagingBase}/v2-lib-verifyToken`,
        description: 'Verifies a jwt token generated by hitting the verifyToken route',
    },
    getCardDetails: {
        development: `${devBase}/v2-lib-stripe/getCardDetails`,
        production: `${prodBase}/v2-lib-stripe/getCardDetails`,
        staging: `${stagingBase}/v2-lib-stripe/getCardDetails`,
        description: `Retrieves stripe user's payment method`,
    },
    createCustomer: {
        development: `${devBase}/v2-lib-stripe/createCustomer`,
        production: `${prodBase}/v2-lib-stripe/createCustomer`,
        staging: `${stagingBase}/v2-lib-stripe/createCustomer`,
        description: 'Creates and returns a stripe customer',
    },
    createSetupIntent: {
        development: `${devBase}/v2-lib-stripe/createSetupIntent`,
        production: `${prodBase}/v2-lib-stripe/createSetupIntent`,
        staging: `${stagingBase}/v2-lib-stripe/createSetupIntent`,
        description: 'Creates a stripe setup intent and returns client secret',
    },
    createPayment: {
        development: `${devBase}/v2-lib-stripe/createPayment`,
        production: `${prodBase}/v2-lib-stripe/createPayment`,
        staging: `${stagingBase}/v2-lib-stripe/createPayment`,
        description: 'Charges shippers for payable orders',
    },
    calculatePrice: {
        development: `${devBase}/libOrdersCalculatePrice`,
        production: `${prodBase}/libOrdersCalculatePrice`,
        staging: `${stagingBase}/libOrdersCalculatePrice`,
        description: "Calculates price from an order's information.",
    },
    calculatePriceV2: {
        development: `${devBase}/libOrdersCalculatePriceV2`,
        production: `${prodBase}/libOrdersCalculatePriceV2`,
        staging: `${stagingBase}/libOrdersCalculatePriceV2`,
        description: "Calculates price from an order's information.",
    },
    createRating: {
        development: `${devBase}/libRatingsCreate`,
        production: `${prodBase}/libRatingsCreate`,
        staging: `${stagingBase}/libRatingsCreate`,
        description: 'Creates a new rating entry.',
    },
    fetchOverallRating: {
        development: `${devBase}/libRatingsFetchOverall`,
        production: `${prodBase}/libRatingsFetchOverall`,
        staging: `${stagingBase}/libRatingsFetchOverall`,
        description: "Get a user's overall rating",
    },
    fetchFtlRouteRunId: {
        development: `${devBase}/libFtlRoutesGetRunId`,
        production: `${prodBase}/libFtlRoutesGetRunId`,
        staging: `${stagingBase}/libFtlRoutesGetRunId`,
        description: 'Makes a nextMv api call to start calculating an optimal FTL stops route',
    },
    fetchFtlRouteStatus: {
        development: `${devBase}/libFtlRoutesGetRunStatus`,
        production: `${prodBase}/libFtlRoutesGetRunStatus`,
        staging: `${stagingBase}/libFtlRoutesGetRunStatus`,
        description: 'Fetches the status of the run initiated by {fetchFtlRouteRunId} route',
    },
    fetchFtlRouteResult: {
        development: `${devBase}/libFtlRoutesGetRunResult`,
        production: `${prodBase}/libFtlRoutesGetRunResult`,
        staging: `${stagingBase}/libFtlRoutesGetRunResult`,
        description: 'Fetches the result of the run initiated by {fetchFtlRouteRunId} route',
    },
    getShipperInvoices: {
        development: `${devBase}/libInvoicesGetShipperInvoices`,
        production: `${prodBase}/libInvoicesGetShipperInvoices`,
        staging: `${stagingBase}/libInvoicesGetShipperInvoices`,
        description:
            "Retrieves shipper invoices. Takes {shipperUid} and {pageNum}. Optionally takes in {filter} (defaults to 'ALL')",
    },
    fetchCarrierPayPeriods: {
        development: `${devBase}/libInvoicesGetPayPeriods`,
        production: `${prodBase}/libInvoicesGetPayPeriods`,
        staging: `${stagingBase}/libInvoicesGetPayPeriods`,
        description: 'Fetches all pay periods with invoices for a carrier',
    },
    fetchPayPeriodOrdersAndRoutes: {
        development: `${devBase}/libInvoicesGetPayPeriodOrdersAndRoutes`,
        production: `${prodBase}/libInvoicesGetPayPeriodOrdersAndRoutes`,
        staging: `${stagingBase}/libInvoicesGetPayPeriodOrdersAndRoutes`,
        description: 'Fetches all orders and routes associated to a pay period',
    },
    getAllStatementsPaginated: {
        development: `${devBase}/libInvoicesGetAllStatementsPaginated`,
        production: `${prodBase}/libInvoicesGetAllStatementsPaginated`,
        staging: `${stagingBase}/libInvoicesGetAllStatementsPaginated`,
        description:
            'Retrieves all statements for admin, grouped by carrier and shipper. Takes {pageNum}. Optionally takes in {filter} (defaults to "ALL")',
    },
    setPayPeriodStatus: {
        development: `${devBase}/libInvoicesSetPayPeriodStatus`,
        production: `${prodBase}/libInvoicesSetPayPeriodStatus`,
        staging: `${stagingBase}/libInvoicesSetPayPeriodStatus`,
        description: 'Sets the status of every invoice associated to a particular pay period/statement',
    },
    sendCustomerTrackingSMSs: {
        development: `${devBase}/scheduledFunctionsCustomerTrackingStagingTrigger`,
        production: `${prodBase}/scheduledFunctionsCustomerTrackingStagingTrigger`,
        staging: `${stagingBase}/scheduledFunctionsCustomerTrackingStagingTrigger`,
        description:
            'A manual trigger of the scheduled customer tracking SMS generator function meant for testing purposes',
    },
    setStopDeliveryDelayTimeframe: {
        development: `${devBase}/libFtlRoutesDelayStop`,
        production: `${prodBase}/libFtlRoutesDelayStop`,
        staging: `${stagingBase}/libFtlRoutesDelayStop`,
        description:
            'Responsible for setting a delay on a stop delivery timeframe, as well as sending an SMS alerting the customer of said delay',
    },
    messageGenerator: {
        development: `${devBase}/libTwilioMessageGenerator`,
        production: `${prodBase}/libTwilioMessageGenerator`,
        staging: `${stagingBase}/libTwilioMessageGenerator`,
        description: 'Creates a message object and stores it in firebase.',
    },
    retryFTLFlow: {
        development: `${devBase}/libTwilioRetryFtlFlow`,
        production: `${prodBase}/libTwilioRetryFtlFlow`,
        staging: `${stagingBase}/libTwilioRetryFtlFlow`,
        description: 'Re-initiates the Twilio FTL Flow for a stop/orders.',
    },
    submitSurvey: {
        development: `${devBase}/v2-lib-tms/survey/respond`,
        production: `${prodBase}/v2-lib-tms/survey/respond`,
        staging: `${stagingBase}/v2-lib-tms/survey/respond`,
        description: 'Submits predelivery survery results.',
    },
    sendSlackMessage: {
        development: `${devBase}/v2-lib-sendSlackMessage`,
        production: `${prodBase}/v2-lib-sendSlackMessage`,
        staging: `${stagingBase}/v2-lib-sendSlackMessage`,
        description: 'Sends a slack message',
    },
    instantQuote: {
        development: `${devBase}/v2-pricing/instant-quote`,
        production: `${prodBase}/v2-pricing/instant-quote`,
        staging: `${stagingBase}/v2-pricing/instant-quote`,
        description: 'Fetches an instant quote price estimate',
    },
    ltlPriceOrder: {
        development: `${devBase}/v2-pricing/ltl-order-pricing`,
        production: `${prodBase}/v2-pricing/ltl-order-pricing`,
        staging: `${stagingBase}/v2-pricing/ltl-order-pricing`,
        description: 'Price an ltl order',
    },
    trackOrder: {
        development: `${devBase}/v2-lib-customer/track-order`,
        production: `${prodBase}/v2-lib-customer/track-order`,
        staging: `${stagingBase}/v2-lib-customer/track-order`,
        description: 'Generate a tracking link for an order',
    },
    guestToken: {
        development: `${devBase}/v2-lib-customer/get-token/guest`,
        production: `${prodBase}/v2-lib-customer/get-token/guest`,
        staging: `${stagingBase}/v2-lib-customer/get-token/guest`,
        description: 'Get a guest token',
    },
    createOrderJob: {
        development: `${devBase}/v2-lib-jobs/init`,
        production: `${prodBase}/v2-lib-jobs/init`,
        staging: `${stagingBase}/v2-lib-jobs/init`,
        description: 'Process a batch of orders',
    },
    sendToBillcom: {
        development: `${devBase}/v2-lib-billPay/sendToBillcom`,
        production: `${prodBase}/v2-lib-billPay/sendToBillcom`,
        staging: `${stagingBase}/v2-lib-billPay/sendToBillcom`,
        description: 'Create payment in bill.com for carrier invoice',
    },
};

class ApiService {
    constructor() {
        this.env = 'production';

        if (process.env.REACT_APP_ENVIRONMENT) {
            this.env = process.env.REACT_APP_ENVIRONMENT;
        }
    }

    setToken(token) {
        this.token = token;
    }

    getToken() {
        return this.token;
    }

    post(payload, headers = {}) {
        const { data, routeName } = payload;
        const route = routes[routeName][this.env];
        const header = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...headers,
        };

        let req;
        if (this.token) {
            req = this.token.getIdToken().then((token) => {
                return fetch(route, {
                    method: 'POST',
                    headers: {
                        ...header,
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(data),
                });
            });
        } else {
            req = fetch(route, {
                method: 'POST',
                headers: header,
                body: JSON.stringify(data),
            });
        }

        return req.then((res) => res.json()).catch((error) => error);
    }
}

export default new ApiService();
