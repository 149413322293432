import { VERIFY_TOKEN } from '@/constants/apiRoutes';
import { postUnauth } from '@/utilities/onwardClient';

const verifyToken = (token) => {
    return postUnauth(`${VERIFY_TOKEN}/customer`, { token }).then((result) => {
        return result.data;
    });
};

export default verifyToken;
