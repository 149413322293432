import React, { useContext } from 'react';
import ShipperAccount from './ShipperAccount';
import CarrierAccount from './CarrierAccount';
import TeammateAccount from './TeammateAccount';
import CorporateAccount from './CorporateAccount';
import { useClientUser } from '@/hooks';
import { UserContext } from '../App';

const Account = () => {
    const { user, clientId } = useContext(UserContext);
    const { accountType } = useClientUser();

    if (user?.roles?.['ADMIN'] || user?.roles?.['ONWARD_ADMIN']) {
        if (clientId) {
            if (accountType === 'shipper') {
                return <ShipperAccount />;
            }
            return <CarrierAccount />;
        }
        return <CorporateAccount />;
    }
    if (user?.roles?.['TEAMMATE']) {
        return <TeammateAccount />;
    }
    if (user?.roles?.['SHIPPER']) {
        return <ShipperAccount />;
    }
    if (user?.roles?.['CARRIER']) {
        return <CarrierAccount />;
    }
};

export default Account;
