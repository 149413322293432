import { ITEM_FIELDS } from '@/graphql/queries/items';
import { ORDER_FIELDS, ORDER_ROUTE_FIELDS } from '@/graphql/queries/orders';
import { ROUTE_FIELDS, ROUTE_ORDER_FIELDS } from '@/graphql/queries/routes';
import { STOP_FIELDS } from '@/graphql/queries/stops';
import { CLIENT_FIELDS } from '@/graphql/queries/clients';
import { MESSAGE_FIELDS } from '@/graphql/queries/messages';
import { USER_FIELDS } from '@/graphql/queries/users';
import { gql } from '@apollo/client';
import { LISTING_FIELDS } from '@/graphql/queries/listings';
import { SURVEY_QUESTION_FIELDS, SURVEY_RESPONSE_FIELDS } from '@/graphql/fragments/surveys';

export const QUERY_STOP_MESSAGES = gql`
    ${MESSAGE_FIELDS}

    query GetStopMessages($order_ids: [uuid!] = []) {
        messages(where: { order_id: { _in: $order_ids } }) {
            ...MessageFields
        }
    }
`;

export const QUERY_PLANNING_DATA = gql`
    ${ROUTE_FIELDS}
    ${ORDER_FIELDS}
    ${ORDER_ROUTE_FIELDS}
    ${ROUTE_ORDER_FIELDS}
    ${STOP_FIELDS}
    ${ITEM_FIELDS}
    ${SURVEY_RESPONSE_FIELDS}
    ${SURVEY_QUESTION_FIELDS}

    query QueryPlanningData(
        $from_date: timestamptz!
        $to_date: timestamptz!
        $user_id: uuid!
        $dropoffCondition: orders_bool_exp!
        $pickupCondition: orders_bool_exp!
    ) {
        routes(
            where: {
                _and: [
                    { shipper_id: { _eq: $user_id } }
                    { scheduled_delivery: { _gte: $from_date } }
                    { scheduled_delivery: { _lt: $to_date } }
                ]
            }
            order_by: { route_number: asc }
        ) {
            ...RouteFields
            ...RouteOrderFields
            stopsByRouteId(order_by: { ordering: asc }) {
                ...StopFields
            }
        }
        pickups: orders(
            where: {
                _and: [
                    {
                        _or: [
                            { carrier_id: { _eq: $user_id } }
                            { _and: [{ shipper_id: { _eq: $user_id } }, { oms: { _eq: true } }] }
                        ]
                    }
                    $pickupCondition
                    { event_state: { _in: ["CD_PENDING_PO", "ROUTED_DO"] } }
                    { order_status: { _in: ["pending", "claimed", "routed", "routedActive"] } }
                    { job_type: { _neq: "WILL_CALL" } }
                ]
            }
        ) {
            ...OrderFields
            ...OrderRouteFields
            itemsByOrderId {
                ...ItemFields
            }
            items @client {
                ...ItemFields
            }
            returns @client {
                ...ItemFields
            }
            survey_responses {
                ...SurveyResponseFields
                survey_question {
                    ...SurveyQuestionFields
                }
            }
        }
        dropoffs: orders(
            where: {
                _and: [
                    {
                        _or: [
                            { carrier_id: { _eq: $user_id } }
                            { _and: [{ shipper_id: { _eq: $user_id } }, { oms: { _eq: true } }] }
                        ]
                    }
                    $dropoffCondition
                    {
                        event_state: {
                            _in: [
                                "START"
                                "RECEIVING"
                                "PENDING"
                                "WAREHOUSE_RETURN"
                                "CD_PENDING_PO"
                                "ROUTED_PU"
                                "CD_RECEIVED_SKIP_DO"
                            ]
                        }
                    }
                    { order_status: { _in: ["pending", "claimed", "routed", "routedActive"] } }
                    { job_type: { _neq: "WILL_CALL" } }
                ]
            }
        ) {
            ...OrderFields
            ...OrderRouteFields
            itemsByOrderId {
                ...ItemFields
            }
            items @client {
                ...ItemFields
            }
            returns @client {
                ...ItemFields
            }
            survey_responses {
                ...SurveyResponseFields
                survey_question {
                    ...SurveyQuestionFields
                }
            }
        }
    }
`;

export const LOADBOARD_QUERY = gql`
    ${ORDER_FIELDS}
    ${ITEM_FIELDS}
    ${CLIENT_FIELDS}
    ${USER_FIELDS}
    ${LISTING_FIELDS}
    query FetchLoadboardOrders($end: timestamptz, $user_id: uuid!, $test_acc: Boolean!, $currentTime: timestamptz) {
        orders(
            where: {
                _and: [
                    { order_status: { _in: ["open", "pending"] } }
                    { _not: { routes: {} } }
                    { oms: { _eq: false } }
                    { first_available_date: { _lt: $end } }
                    { order_shipper: { test_acc: { _eq: $test_acc } } }
                    { shipper_id: { _neq: $user_id } }
                    { carrier_id: { _is_null: true } }
                    { listing: { expiration_date: { _gte: $currentTime } } }
                ]
            }
        ) {
            ...OrderFields
            order_carrier {
                ...ClientFields
            }
            order_shipper {
                ...ClientFields
                user {
                    ...UserFields
                }
            }
            listing {
                ...ListingFields
            }
            itemsByOrderId {
                ...ItemFields
            }
            items @client {
                ...ItemFields
            }
            returns @client {
                ...ItemFields
            }
        }
    }
`;
export const NETWORK_ROUTE_QUERY = gql`
    ${ROUTE_FIELDS}
    ${ORDER_FIELDS}
    ${ROUTE_ORDER_FIELDS}
    ${STOP_FIELDS}
    ${ITEM_FIELDS}
    query FetchNetworkRoutes($from_date: timestamptz, $to_date: timestamptz, $user_id: uuid!, $test_acc: Boolean!) {
        routes(
            where: {
                _and: [
                    { status: { _in: ["active", "pending", "planning"] } }
                    { scheduled_delivery: { _gte: $from_date } }
                    { scheduled_delivery: { _lt: $to_date } }
                    { route_shipper: { test_acc: { _eq: $test_acc } } }
                    { route_shipper: { client_id: { _neq: $user_id } } }
                    { route_shipper: { client_id: { _is_null: false } } }
                    {
                        _or: [
                            { route_carrier: { data_share: { _eq: true } } }
                            { route_shipper: { data_share: { _eq: true } } }
                        ]
                    }
                ]
            }
        ) {
            ...RouteFields
            ...RouteOrderFields
            stopsByRouteId(order_by: { ordering: asc }) {
                ...StopFields
            }
        }
    }
`;

export const TIMEFRAME_CONFIRMATION_SUBSCRIPTION = gql`
    subscription TimeframeConfirmationSubscription($order_ids: [uuid!] = []) {
        orders(where: { order_id: { _in: $order_ids } }) {
            order_number
            order_id
            delivery_time_confirmed
        }
    }
`;

export const ROUTE_STATUS_SUBSCRIPTION = gql`
    subscription RouteStatusSubscription($route_id: uuid!) {
        routes_by_pk(route_id: $route_id) {
            status
            route_id
            route_number
        }
    }
`;

export const GET_ROUTE_ALIAS = gql`
    query GetRouteAlias($route_id: uuid!) {
        routes_by_pk(route_id: $route_id) {
            route_id
            route_alias
        }
    }
`;
