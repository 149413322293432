import { gql } from '@apollo/client';
import { DRIVER_FIELDS } from '../fragments/drivers';
import { EXCEPTION_FIELDS } from './db_exceptions';
import { LOCATION_FIELDS } from './locations';

export const WAREHOUSE_EVENT_FIELDS = gql`
    ${EXCEPTION_FIELDS}
    ${LOCATION_FIELDS}
    fragment WarehouseEvents on order_wh_events {
        action
        created_at
        delivery_date
        driver_email
        driver_id
        driver_name
        driver_phone
        est_delivery_date
        est_pickup_date
        est_received_date
        est_ship_date
        event_id
        last_modified_at
        location_id
        notes
        order_id
        pickup_date
        received_date
        route_id
        ship_date
        status
        trailer_id
        wh_driver_id
        transition {
            current
            next
        }
        location {
            ...LocationFields
        }
    }
`;

export const QUERY_BY_ORDER = gql`
    ${WAREHOUSE_EVENT_FIELDS}
    ${LOCATION_FIELDS}
    ${DRIVER_FIELDS}
    query GetEventsByOrder($order_id: uuid) {
        results: order_wh_events(
            where: { order_id: { _eq: $order_id } }
            order_by: [{ created_at: asc }, { transition: { current: asc } }]
        ) {
            ...WarehouseEvents
            location {
                ...LocationFields
            }
            driver {
                ...DriverFields
            }
            wh_driver {
                ...DriverFields
            }
        }
    }
`;
