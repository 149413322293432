import { gql } from '@apollo/client';

export const LOCATION_FIELDS = gql`
    fragment LocationFields on locations {
        address
        business_address
        business_city
        business_state
        business_unit
        business_zip
        client_id
        contact_email
        contact_name
        contact_phone
        days_closed
        default_end_location
        enable_for_wms
        hours_open
        july_fourth
        labor_day
        lat
        lng
        geo_point
        location_code
        location_info
        location_name
        location_type
        memorial_day
        new_years_day
        new_years_eve
        preferred_inbound
        qbo_class
        shipper_pu_comments
        thanksgiving
        warehouse_racks
        xmas
        xmas_eve
        location_id
        is_customer_location
    }
`;
