import { InputAdornment, TextField } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';

const SearchInput = ({ onChange, placeholder = 'Search Orders (OD#)', size = 'small' , ...rest }) => {
    const [debouncer, setDebouncer] = useState(null);
    const [searchInput, setSearchInput] = useState('');

    useEffect(() => {
        return () => {
            if (debouncer) {
                clearTimeout(debouncer);
            }
        };
    }, []);

    const handleSearch = (input) => {
        setSearchInput(input);
        if (debouncer) {
            clearTimeout(debouncer);
        }
        setDebouncer(
            setTimeout(() => {
                onChange(input);
            }, 300)
        );
    };

    return (
        <TextField
            variant="outlined"
            value={searchInput}
            onChange={(e) => handleSearch(e.target.value)}
            placeholder={placeholder}
            size={size}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Search />
                    </InputAdornment>
                ),
            }}
            css={css`
                .MuiInputBase-root {
                    background-color: white;
                }
            `}
            {...rest}
        />
    );
};

export default SearchInput;
