import NavResponsiveModal from '@/components/Navigation/NavResponsiveModal';
import { SUBMIT_ROUTE } from '@/constants/apiRoutes';
import { post } from '@/utilities/onwardClient';
import { FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useContext, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import {
    Body1,
    Body2,
    GridItemRow,
    ModalActions,
    ModalContent,
    ModalTitle,
    PrimaryButton,
    SecondaryButton,
} from '../blocks';
import { MODALS } from '../constants';
import { PlanningContext } from '../context';
import { useAfterHours } from '../hooks';
import { captureException } from '@sentry/react';
import { useClientUser } from '@/hooks';

const RouteSubmitInternalModal = () => {
    const {
        modalOpen,
        setModalOpen,
        actionableRoute,
        setActionableRoute,
        setError,
        setNotification,
        state: { routesToSubmitInternal, lockAllRoutesClicked },
        callbacks: { refetch, setRoutesToSubmitInternal, setLockAllRoutesClicked },
    } = useContext(PlanningContext);
    const [sendSMS, setSendSMS] = useState('yes');
    const [loading, setLoading] = useState(false);
    const { partner_carriers, circles } = useClientUser();

    const routes = useMemo(() => {
        if (routesToSubmitInternal?.length > 0) {
            return routesToSubmitInternal;
        }
        return actionableRoute ? [actionableRoute] : [];
    }, [routesToSubmitInternal, actionableRoute]);

    const afterHours = useAfterHours(routes[0]);

    const hasCommingledOrders = useMemo(() => {
        return routes?.some((r) =>
            r?.orders?.some(({ order }) => order.shipper_id !== order.carrier_id && order.shipper_id !== r.shipper_id)
        );
    }, [routes]);

    const hasAutoconfirmAllStops = useMemo(() => {
        const autoConfirm = circles?.['autoconfirm-all-stops'] === true;
        if (autoConfirm) {
            setSendSMS('autoConfirm');
        }
        return autoConfirm;
    }, [circles]);

    const hasLockAllRoutes = useMemo(() => {
        return circles?.['lock-all-routes'] === true;
    }, [circles]);

    const handleModalClose = () => {
        setLoading(false);
        setModalOpen(null);
        setActionableRoute(null);
        setRoutesToSubmitInternal([]);
        setSendSMS('yes');
        setLockAllRoutesClicked(false);
    };

    const missingPhoneNumbers = useMemo(() => {
        let ordersWithoutPhone = [];
        routes.forEach((r) => {
            r?.orders.forEach(({ type, order }) => {
                if (type !== 'PICKUP' && !order.dropoff_phone) {
                    ordersWithoutPhone.push(order.order_number);
                }
            });
        });
        if (ordersWithoutPhone.length > 0) {
            setSendSMS('no');
        }
        return ordersWithoutPhone;
    }, [routes]);

    const handleSubmitInternal = async () => {
        try {
            setLoading(true);

            const payloads = routes.map((route) => {
                const partnerCarrier = partner_carriers?.find((c) => c.client_id === route.carrier_id);
                return {
                    routeId: route.route_id,
                    sendingTo: partnerCarrier ? 'partner' : 'internal',
                    sendSMS: sendSMS === 'yes' || sendSMS === 'commingledOnly',
                    commingledOnlySMS: sendSMS === 'commingledOnly',
                    autoConfirm: sendSMS === 'autoConfirm',
                    lockAllRoutes: lockAllRoutesClicked && hasLockAllRoutes,
                };
            });

            const responses = await Promise.all(payloads.map((payload) => post(SUBMIT_ROUTE, payload)));

            if (responses.every((response) => response.status === 200)) {
                setNotification({
                    severity: 'success',
                    message: `Successfully submitted Route${routes.length > 1 ? 's' : ''} ${routes
                        .map((r) => r.route_alias || r.route_number)
                        .join(', ')} !`,
                });
                refetch();
                handleModalClose();
            } else {
                const error = responses.find((r) => r?.data?.errorMessage);
                captureException(new Error(error));
                setNotification({
                    severity: 'error',
                    message: error || 'Error submitting route, please verify all stops have timeframes and try again',
                });
                setLoading(false);
            }
        } catch (error) {
            setError(error, 'Error submitting route, please try again');
            setLoading(false);
        }
    };

    if (!routes?.length) return null;

    return (
        <NavResponsiveModal open={modalOpen === MODALS.ROUTE_INTERNAL} onClose={handleModalClose}>
            <ModalTitle>{`Confirm Submission`}</ModalTitle>
            <ModalContent>
                {lockAllRoutesClicked && hasLockAllRoutes && !hasAutoconfirmAllStops && (
                    <GridItemRow>
                        <Alert severity="info">
                            Lock all routes is selected. Routes will be locked and sent to drivers regardless of
                            customer confirmation status.
                        </Alert>
                    </GridItemRow>
                )}
                {afterHours && sendSMS === 'yes' && (
                    <GridItemRow>
                        <Alert severity="error">
                            {`You are about to send SMS messages to customers outside of regular business hours (8 AM - 5 PM)`}
                        </Alert>
                    </GridItemRow>
                )}
                <GridItemRow>
                    <Alert severity="warning">
                        Verify all service times are correct. Customers only receive reschedule texts if timeframes
                        change by more than 30 minutes.
                    </Alert>
                </GridItemRow>
                <GridItemRow
                    css={css`
                        margin-top: 1.5rem !important;
                    `}
                >
                    <Body2>Customer communication options:</Body2>
                </GridItemRow>
                <RadioGroup
                    value={sendSMS}
                    onChange={(e) => setSendSMS(e.target.value)}
                    css={css`
                        display: flex;
                        flex-direction: column;
                        gap: 1rem;
                    `}
                >
                    <GridItemRow>
                        <FormControlLabel
                            control={<Radio color="primary" />}
                            value={'yes'}
                            disabled={hasAutoconfirmAllStops}
                        />
                        <Grid container>
                            <Body1>Text and email customers to confirm timeframes</Body1>
                            <Body2>
                                Onward will use automated messages to relay timeframes and receive confirmations.
                            </Body2>
                        </Grid>
                    </GridItemRow>
                    <GridItemRow>
                        <FormControlLabel
                            control={<Radio color="primary" />}
                            value={'no'}
                            disabled={hasAutoconfirmAllStops}
                        />
                        <Grid container>
                            <Body1>Do not send automated communications</Body1>
                            <Body2>
                                No texts or emails will be sent. You'll need to contact customers and confirm timeframes
                                manually.
                            </Body2>
                        </Grid>
                    </GridItemRow>
                    {hasCommingledOrders && (
                        <GridItemRow>
                            <FormControlLabel
                                control={<Radio color="primary" />}
                                value={'commingledOnly'}
                                disabled={hasAutoconfirmAllStops}
                            />
                            <Grid container>
                                <Body1>Only contact Marketplace customers to confirm timeframes</Body1>
                                <Body2>
                                    Only customers with Onward Marketplace orders will receive texts and emails for
                                    confirmation.
                                </Body2>
                            </Grid>
                        </GridItemRow>
                    )}
                    <GridItemRow>
                        <FormControlLabel control={<Radio color="primary" />} value={'autoConfirm'} />
                        <Grid container>
                            <Body1>Autoconfirm all timeframes</Body1>
                            <Body2>No texts or emails will be sent. All stops will be automatically confirmed.</Body2>
                        </Grid>
                    </GridItemRow>
                </RadioGroup>
                {missingPhoneNumbers.length > 0 && (
                    <Body2
                        style={{ color: 'red' }}
                        css={css`
                            margin-top: 1rem;
                        `}
                    >
                        Warning: The following orders are missing a customer phone number:{' '}
                        {missingPhoneNumbers.join(', ')}
                    </Body2>
                )}
            </ModalContent>
            <ModalActions>
                <SecondaryButton disabled={loading} onClick={handleModalClose}>
                    Cancel
                </SecondaryButton>
                <PrimaryButton disabled={loading} onClick={handleSubmitInternal}>
                    Submit Routes
                </PrimaryButton>
            </ModalActions>
        </NavResponsiveModal>
    );
};

export default RouteSubmitInternalModal;
