import React, { useContext, useMemo, useState } from 'react';
import { Box, FormControl, Select, MenuItem } from '@material-ui/core';
import { css } from '@emotion/react';
import { MODALS, ROUTE_COLORS } from '../constants';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import { PlanningContext } from '../context';
import { useMutation } from '@apollo/client';
import { UPDATE_ROUTE_STOPS, ASSIGN_ORDERS_UPSERT } from '../graphql/mutations';
import FTLStopHelpers from '@/utilities/FTLStopHelpers';
import routeStatusOptions from '@/constants/routeStatusOptions';
import { useClientUser } from '@/hooks/useClientUser';
import { colors } from '@/styles';
import { Body1, GridItemRow, SecondaryButtonFullW } from '../blocks';
import SendToOnward from './SendToOnward';
import { cloneDeep, omit, uniq } from 'lodash';
import { LTL_MARKETPLACE, PLAN_PAGE_LOADBOARD } from '@/constants/featureFlags';
import { toArrayLiteral } from '@/utilities/toArrayLiteral';
import { PrimaryButton, SecondaryButton } from '@/styles/blocks';
import { removeRefs } from '@/graphql/util';
import { ROUTE_READONLY_FIELDS } from '@/constants/readonlyFields';

const AssignToRoute = () => {
    const {
        selectedOrders,
        setSelectedOrders,
        setNotification,
        setError,
        setModalOpen,
        state: { orders, routes, loading },
        callbacks: { refetch },
    } = useContext(PlanningContext);
    const [assignedRoute, setAssignedRoute] = useState('');
    const { circles, default_end_location, user_id } = useClientUser();

    const routesByKey = useMemo(() => {
        return Object.fromEntries(routes.map((route) => [route.route_id, route]));
    }, [routes]);

    const ordersByKey = useMemo(() => {
        return Object.fromEntries(orders.map((order) => [`${order.order_id}_${order.crossdock_leg}`, order]));
    }, [orders]);

    const ordersToAdd = useMemo(() => {
        return Object.entries(selectedOrders)
            .filter(([_, selected]) => selected)
            .map(([key]) => ordersByKey[key])
            .filter(
                (order) =>
                    order && !(order.pickup_route_id === assignedRoute || order.dropoff_route_id === assignedRoute)
            );
    }, [selectedOrders, ordersByKey, assignedRoute]);

    const [assignOrdersUpsert, { loading: assignLoading }] = useMutation(ASSIGN_ORDERS_UPSERT);

    const assign = async () => {
        const assignedRouteObj = routesByKey[assignedRoute];

        // Both legs assigned to same route
        const dupeOrders = ordersToAdd.reduce((acc, order) => {
            return {
                ...acc,
                [order.order_id]: [...(acc[order.order_id] || []), order],
            };
        }, {});
        const alreadyAssigned = ordersToAdd.filter((order) =>
            assignedRouteObj?.orders?.some((mapping) => mapping.order_id === order.order_id)
        );
        if (alreadyAssigned.length > 0 || Object.values(dupeOrders).some((x) => x.length > 1)) {
            setNotification({
                severity: 'warning',
                message:
                    'Cannot assign both (pickup -> cross-dock) and (cross-dock -> delivery) legs of an order to the same route.',
            });
            return;
        }

        // Invariant to ensure a locked route is not assigned to.
        if (assignedRouteObj?.status !== routeStatusOptions.PLANNING.value) {
            setNotification({
                severity: 'warning',
                message:
                    'Cannot assign to a locked route. If you would like to make edits, unlock the route if necessary, make the desired addition, and reoptimize the route.',
            });
            return;
        }

        const LEG_ADD_ACTIONS = {
            pickup: 'ADD_PU',
            dropoff: 'ADD_DO',
            default: 'PICKING_UP',
        };
        const LEG_REMOVE_ACTIONS = {
            pickup: 'REMOVE_PU',
            dropoff: 'REMOVE_DO',
            default: 'REMOVE',
        };
        const LEG_ROUTE_TYPES = {
            pickup: 'PICKUP',
            dropoff: 'DROPOFF',
            default: 'FULL',
        };
        const LEG_REMOVE_TRANSITIONS = {
            pickup: {
                CD_PENDING: 'ROUTED_DO',
                ROUTED_PU: 'CD_PENDING_PO',
            },
            dropoff: {
                CD_PENDING: 'ROUTED_PU',
                ROUTED_DO: 'CD_PENDING_PO',
                CD_RECEIVED: 'CD_RECEIVED_SKIP_DO',
            },
            default: {
                PICKED_UP: 'PENDING',
            },
        };

        try {
            const newStops = FTLStopHelpers.addOrders(assignedRouteObj, ordersToAdd).map((stop) => ({
                ...stop,
                route_id: assignedRoute,
            }));

            let otherRouteStops = {};

            const [removeEvents, addEvents] = ordersToAdd.reduce(
                ([removeAcc, addAcc], order) => {
                    let prevState = order.event_state;
                    const prevRouteKey =
                        order.crossdock_leg === 'pickup' ? order.pickup_route_id : order.dropoff_route_id;
                    if (prevRouteKey) {
                        const prevRoute = routesByKey[prevRouteKey];
                        const prevStops = otherRouteStops[prevRouteKey] || prevRoute.stopsByRouteId || [];
                        const updatedStops = FTLStopHelpers.removeStop(order, {
                            ...prevRoute,
                            stopsByRouteId: prevStops,
                        });
                        otherRouteStops[prevRouteKey] = updatedStops;

                        removeAcc.push({
                            order_id: order.order_id,
                            action: `${prevState}:${
                                LEG_REMOVE_ACTIONS[order.crossdock_leg] || LEG_REMOVE_ACTIONS.default
                            }`,
                            notes: `Removed from route ${prevRoute.route_id} (${
                                prevRoute.route_alias || prevRoute.route_number
                            })`,
                        });
                        prevState =
                            LEG_REMOVE_TRANSITIONS[order.crossdock_leg]?.[prevState] ||
                            LEG_REMOVE_TRANSITIONS.default[prevState];
                        if (!prevState) {
                            throw new Error(
                                `Error attempting to remove order ${order.po_number} from route ${
                                    prevRoute.route_alias || prevRoute.route_number
                                }`
                            );
                        }
                    }

                    addAcc.push({
                        order_id: order.order_id,
                        action: `${prevState}:${LEG_ADD_ACTIONS[order.crossdock_leg] || LEG_ADD_ACTIONS.default}`,
                        route_id: assignedRoute,
                        notes: `Added to route ${assignedRouteObj.route_id} (${
                            assignedRouteObj.route_alias || assignedRouteObj.route_number
                        })`,
                        ...(order.crossdock_leg !== 'pickup'
                            ? {
                                  est_delivery_date: assignedRouteObj.scheduled_delivery,
                              }
                            : {}),
                        ...(order.crossdock_leg !== 'dropoff'
                            ? {
                                  est_pickup_date: assignedRouteObj.scheduled_delivery,
                              }
                            : {}),
                    });

                    return [removeAcc, addAcc];
                },
                [[], []]
            );

            const optimisticRoute = {
                ...assignedRouteObj,
                orders: [
                    ...(assignedRouteObj.orders || []),
                    ...ordersToAdd.map((o) => ({
                        order_id: o.order_id,
                        type: LEG_ROUTE_TYPES[o.crossdock_leg] || LEG_ROUTE_TYPES.default,
                        order: o,
                    })),
                ],
            };

            const order_updates = ordersToAdd.map((order) => {
                let customerOrderUpdate = {
                    del_window_start: null,
                    del_window_end: null,
                    original_del_window_start: null,
                    original_del_window_end: null,
                    delivery_time_confirmed: null,
                };
                const matchingStop = newStops.find(
                    (s) =>
                        (s.orders || []).includes(order.order_id) && FTLStopHelpers.isCustomerStop(s, optimisticRoute)
                );
                if (matchingStop && matchingStop.del_window_start) {
                    customerOrderUpdate = {
                        del_window_start: matchingStop.del_window_start,
                        del_window_end: matchingStop.del_window_end,
                        original_del_window_start: matchingStop.del_window_start,
                        original_del_window_end: matchingStop.del_window_end,
                    };
                }

                const isCustomerLeg =
                    (order.order_type === 'return' && order.crossdock_leg !== 'dropoff') ||
                    (order.order_type !== 'return' && order.crossdock_leg !== 'pickup');

                return {
                    where: { order_id: { _eq: order.order_id } },
                    _set: {
                        ...(order.crossdock_leg === 'pickup'
                            ? {
                                  pickup_date: assignedRouteObj.scheduled_delivery,
                              }
                            : {
                                  delivery_date: assignedRouteObj.scheduled_delivery,
                              }),
                        ...(isCustomerLeg ? customerOrderUpdate : {}),
                    },
                };
            });

            console.debug(otherRouteStops, newStops, removeEvents, addEvents, order_updates);

            // Remove
            assignOrdersUpsert({
                variables: {
                    route_ids: Object.keys(otherRouteStops),
                    routes: Object.keys(otherRouteStops).map((routeId) => {
                        const route = routesByKey[routeId];
                        return omit(route, ROUTE_READONLY_FIELDS);
                    }),
                    order_updates: [],
                    stops: Object.values(otherRouteStops)
                        .reduce((acc, stops) => {
                            return [...acc, ...stops];
                        }, [])
                        .map(({ __typename, ...stop }) => {
                            return {
                                ...stop,
                                ...Object.fromEntries(
                                    ['orders', 'returns', 'exchanges', 'actions_completed'].map((attr) => [
                                        attr,
                                        toArrayLiteral(uniq(stop[attr] || [])),
                                    ])
                                ),
                            };
                        }),
                    events: removeEvents,
                },
                onError: (error) => {
                    setError(error, 'Error removing already assigned orders from previous route');
                },
                onCompleted: () => {
                    // Add after Remove is complete
                    assignOrdersUpsert({
                        variables: {
                            route_ids: [assignedRoute],
                            routes: [assignedRoute].map((routeId) => {
                                const route = routesByKey[routeId];
                                return omit(route, ROUTE_READONLY_FIELDS);
                            }),
                            order_updates,
                            stops: newStops.map(({ __typename, ...stop }) => {
                                return {
                                    ...stop,
                                    ...Object.fromEntries(
                                        ['orders', 'returns', 'exchanges', 'actions_completed'].map((attr) => [
                                            attr,
                                            toArrayLiteral(uniq(stop[attr] || [])),
                                        ])
                                    ),
                                };
                            }),
                            events: addEvents,
                        },
                        onError: (error) => {
                            setError(error, 'Error assigning orders to route');
                        },
                        onCompleted: () => {
                            refetch();
                            setSelectedOrders({});
                            setAssignedRoute('');
                        },
                    });
                },
            });
        } catch (error) {
            setError(error, error.message || 'Error assigning orders to route');
        }
    };

    const assignedRouteObject = routesByKey[assignedRoute];

    const isLoading = loading || assignLoading;

    return (
        <Box
            css={css`
                background-color: white;
                width: 300px;
                position: fixed;
                right: 16px;
                bottom: 16px;
                border: 1px solid rgba(0, 0, 0, 0.32);
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                border-radius: 5px 5px 0px 0px;
            `}
        >
            <GridItemRow
                css={css`
                    border-bottom: 1px solid #e2e2e2;
                    padding: 0.875rem 1rem 0.875rem 1rem;
                `}
            >
                <Body1
                    css={css`
                        color: ${colors.greys.primary};
                    `}
                >
                    Assign to
                </Body1>
                <Body1>{Object.entries(selectedOrders).filter(([, toggle]) => toggle)?.length} orders selected</Body1>
            </GridItemRow>
            <GridItemRow
                css={css`
                    padding: 0.875rem 1rem 0.375rem 1rem;
                    fieldset {
                        top: 0;
                    }
                `}
            >
                <FormControl fullWidth variant="outlined">
                    <Select
                        value={assignedRoute || 'NONE'}
                        onChange={(e) => setAssignedRoute(e.target.value)}
                        disabled={!routes.length}
                    >
                        <MenuItem value="NONE" key="REMOVE">
                            Select a Route
                        </MenuItem>
                        {routes.map((route, i) => (
                            <MenuItem value={route.route_id} key={`route-menu-item-${route.route_id}`}>
                                <LocalShippingOutlinedIcon
                                    fontSize="small"
                                    css={css`
                                        color: ${route.route_color || ROUTE_COLORS[i] || 'black'};
                                        margin-right: 5px;
                                    `}
                                />
                                {route.route_alias || `Route ${route.route_number}`}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </GridItemRow>
            <GridItemRow
                css={css`
                    padding: 0.375rem 1rem 0.875rem 1rem;
                `}
            >
                <PrimaryButton fullWidth disabled={isLoading || !assignedRouteObject} onClick={assign}>
                    {assignedRouteObject && assignedRouteObject.status !== 'planning'
                        ? 'Route is Locked'
                        : `Assign to Route`}
                </PrimaryButton>
            </GridItemRow>
            <GridItemRow
                css={css`
                    padding: 0.375rem 1rem 0.875rem 1rem;
                `}
            >
                <PrimaryButton fullWidth disabled={isLoading} onClick={() => setModalOpen(MODALS.ORDER_RESCHEDULE)}>
                    Reschedule Orders
                </PrimaryButton>
            </GridItemRow>
            {circles?.[LTL_MARKETPLACE] ? <SendToOnward orders={ordersToAdd} /> : null}
        </Box>
    );
};

export default AssignToRoute;
