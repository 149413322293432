import React, { useContext, useMemo } from 'react';
import HistoryIcon from '@mui/icons-material/History';
import WarningIcon from '@mui/icons-material/Warning';
import { OnwardTooltip, PrimaryButton } from '@/styles/blocks';
import { PALLET_STATUSES, RECEIVED_STATUSES, STAGED_STATUSES, STORED_STATUSES } from '@/constants/manifestStatuses';
import ExceptionCard from '@/components/OrderDetailsPage/ExceptionCard';
import { css } from '@emotion/react';
import { colors } from '@/styles';
import { Grid } from '@material-ui/core';
import { Body1, Body2 } from '../blocks';
import { Context } from './../store';
import { OnwardLink } from '@/styles/blocks';

import IconButton from '@material-ui/core/IconButton';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const ItemSection = ({ manifest, order, item, loading, callbacks, options, editable }) => {
    let status;
    switch (manifest.type) {
        case 'INBOUND': {
            if (RECEIVED_STATUSES.includes(item.pallet?.warehouse_status)) {
                status = (
                    <Grid item>
                        <Body2
                            css={css`
                                color: ${colors.greens.primary};
                            `}
                        >
                            Received
                        </Body2>
                    </Grid>
                );
            }
            break;
        }
        default: {
            status = null;
        }
    }

    const shouldShowActions = useMemo(() => {
        if (item?.isLastOnPallet) return true;
        else return false;
    }, [item]);

    let actions;
    switch (manifest.type) {
        case 'CROSS_DOCK':
        case 'INBOUND': {
            if (!item.pallet?.warehouse_status || item.pallet?.warehouse_status === PALLET_STATUSES.NOT_RECEIVED) {
                actions = shouldShowActions ? (
                    <>
                        <Grid item>
                            <PrimaryButton
                                disabled={loading?.insertPalletLoading}
                                onClick={() =>
                                    item?.pallet?.warehouse_status === PALLET_STATUSES.NOT_RECEIVED
                                        ? callbacks.receivePallet(item, item.pallet, manifest)
                                        : callbacks.receiveItem(item, manifest)
                                }
                            >
                                Mark Received
                            </PrimaryButton>
                        </Grid>
                        <Grid
                            item
                            css={css`
                                margin-left: 1rem;
                            `}
                        >
                            {!options?.editOrderModal && (
                                <PrimaryButton
                                    disabled={loading?.insertPalletLoading}
                                    onClick={() =>
                                        callbacks.editException({
                                            type: 'PICKUP',
                                            items: [item],
                                            order,
                                        })
                                    }
                                    orange
                                >
                                    Add Exception
                                </PrimaryButton>
                            )}
                        </Grid>
                    </>
                ) : null;
                break;
            }

            switch (item.pallet.warehouse_status) {
                case PALLET_STATUSES.RECEIVED: {
                    actions = shouldShowActions ? (
                        <>
                            <Grid item>
                                <PrimaryButton onClick={() => callbacks.storePalletsInit([item.pallet])}>
                                    Store
                                </PrimaryButton>
                            </Grid>
                            <Grid
                                item
                                css={css`
                                    margin-left: 1rem;
                                `}
                            >
                                {!options?.editOrderModal && (
                                    <PrimaryButton
                                        disabled={loading?.insertPalletLoading}
                                        onClick={() =>
                                            callbacks.editException({
                                                type: 'PICKUP',
                                                items: [item],
                                                order,
                                            })
                                        }
                                        orange
                                    >
                                        Add Exception
                                    </PrimaryButton>
                                )}
                            </Grid>
                        </>
                    ) : null;
                    break;
                }
            }

            break;
        }
        case 'WILL_CALL':
        case 'RETURN_TO_SENDER':
        case 'OUTBOUND': {
            switch (item.pallet?.warehouse_status) {
                case PALLET_STATUSES.STAGED:
                    actions = null;
                    break;
                default:
                    const valid = [PALLET_STATUSES.STORED, PALLET_STATUSES.PICKED].includes(
                        item.pallet?.warehouse_status
                    );
                    actions =
                        !item.pallet || shouldShowActions ? (
                            <>
                                <Grid item>
                                    <OnwardTooltip
                                        title={
                                            valid
                                                ? ''
                                                : 'All items must be received and stored before being picked or staged'
                                        }
                                        placement="top"
                                    >
                                        <span>
                                            <PrimaryButton
                                                disabled={!valid}
                                                onClick={() => callbacks.stagePalletsInit([item.pallet])}
                                            >
                                                Pick & Stage
                                            </PrimaryButton>
                                        </span>
                                    </OnwardTooltip>
                                </Grid>
                            </>
                        ) : null;
                    break;
            }

            break;
        }
        default: {
            status = null;
        }
    }

    return (
        <Grid
            container
            direction="row"
            css={css`
                padding: 1rem;
                flex-wrap: nowrap;
                justify-content: space-between;
                ${item.isFirstOnPallet ? `border-top: 1px solid ${colors.greys.border};` : ''}
            `}
        >
            <Grid
                container
                direction="column"
                css={css`
                    flex: 1;
                    flex-basis: 0;
                `}
            >
                <Grid item>
                    <Body2>SKU: {item.sku || '--'}</Body2>
                </Grid>
                <Grid item>
                    <Body1>{item.description || item.item_type_details}</Body1>
                </Grid>
                <Grid item>
                    <Body1>Quantity: {item.quantity || 1}</Body1>
                </Grid>
                <Grid item>
                    <Body1>
                        {item.length}L {item.width}W {item.height}H / {item.weight} lbs
                    </Body1>
                </Grid>
                {item.pallet && (
                    <Grid item>
                        <Body1>
                            Pallet: {item.pallet.pallet_name ? item.pallet.pallet_name : item.pallet.pallet_number}
                        </Body1>
                        {!options?.editOrderModal && editable && (
                            <Body2
                                css={css`
                                    color: ${colors.greens.primary};
                                    text-decoration: underline;
                                    cursor: pointer;
                                    margin-left: 5px;
                                `}
                                onClick={() => callbacks.renamePalletInit(item.pallet)}
                            >
                                Rename
                            </Body2>
                        )}
                    </Grid>
                )}
                <Grid item>
                    {manifest.type === 'OUTBOUND' && (
                        <Body1>
                            Status:
                            {!item.pallet && (
                                <span
                                    css={css`
                                        font-weight: bold;
                                        color: ${colors.oranges.primary};
                                    `}
                                >
                                    {' Not Received'}
                                </span>
                            )}
                            {item.pallet?.warehouse_status === null && (
                                <span
                                    css={css`
                                        font-weight: bold;
                                        color: ${colors.oranges.primary};
                                    `}
                                >
                                    {' Not Received'}
                                </span>
                            )}
                            {item.pallet?.warehouse_status === 'RECEIVED' && (
                                <span
                                    css={css`
                                        font-weight: bold;
                                        color: ${colors.oranges.primary};
                                    `}
                                >
                                    {' Received (Need to Store)'}
                                </span>
                            )}
                            {item.pallet?.warehouse_status && item.pallet.warehouse_status !== 'RECEIVED' && (
                                <span
                                    css={css`
                                        color: ${STAGED_STATUSES.includes(item.pallet.warehouse_status)
                                            ? colors.greens.primary
                                            : colors.oranges.primary};
                                        font-weight: bold;
                                    `}
                                >
                                    {` ${item.pallet.warehouse_status}`}
                                </span>
                            )}
                            <IconButton
                                onClick={() => window.open(`/order/${order.order_id}`, '_blank')}
                                css={css`
                                    color: inherit;
                                    padding: 2px;
                                    margin-left: 5px;
                                    margin-bottom: 2px;
                                    width: 20px;
                                    height: 20px;
                                    &:hover {
                                        background-color: rgba(0, 0, 0, 0.04);
                                    }
                                `}
                            >
                                <OpenInNewIcon sx={{ fontSize: '16px' }} />
                            </IconButton>
                        </Body1>
                    )}
                </Grid>
                {editable && item?.exceptions?.length
                    ? item?.exceptions
                          .filter((mapping) => mapping.exception.status !== 'RESOLVED')
                          .map((mapping) => {
                              return (
                                  <Grid
                                      item
                                      css={css`
                                          margin-top: 20px;
                                          margin-bottom: 20px;
                                      `}
                                  >
                                      <ExceptionCard
                                          exception={mapping.exception}
                                          order={order}
                                          exceptionItems={[item]}
                                          callbacks={{
                                              ...callbacks,
                                              setExceptionParams: (args) => {
                                                  callbacks.editException({
                                                      type: 'PICKUP',
                                                      prev: mapping.exception,
                                                      items: [item],
                                                      order,
                                                  });
                                              },
                                          }}
                                      />
                                  </Grid>
                              );
                          })
                    : null}

                {status}

                {actions && editable ? (
                    <Grid
                        container
                        direction="row"
                        css={css`
                            align-items: center;
                            justify-content: flex-start;
                            margin-top: 0.5rem;
                            flex-wrap: nowrap;
                        `}
                    >
                        {actions}
                    </Grid>
                ) : null}
            </Grid>
            <Grid
                container
                direction="column"
                css={css`
                    align-items: flex-end;
                    flex: 0;
                    flex-basis: 0;
                `}
            >
                <Grid
                    item
                    css={css`
                        cursor: pointer;
                    `}
                    onClick={() => callbacks.openPalletLog(item.pallet || {})}
                >
                    {item.pallet?.warehouse_location ? <Body1>{item.pallet.warehouse_location}</Body1> : null}
                    <HistoryIcon
                        css={css`
                            margin-left: 0.25rem;
                            color: ${colors.greens.primary};
                        `}
                    />
                </Grid>
                {!!item.item_exception ? (
                    <Grid item>
                        <WarningIcon
                            css={css`
                                color: ${colors.oranges.primary};
                            `}
                        />
                    </Grid>
                ) : null}
                {manifest.type === 'INBOUND' &&
                shouldShowActions &&
                item.pallet?.warehouse_status === PALLET_STATUSES.STORED &&
                editable ? (
                    <Grid
                        container
                        css={css`
                            flex-grow: 1;
                            justify-content: flex-end;
                            align-items: flex-end;
                            flex-direction: row;
                            flex-wrap: nowrap;
                        `}
                    >
                        <Grid item>
                            <PrimaryButton onClick={() => callbacks.storePalletsInit([item.pallet])}>
                                Relocate
                            </PrimaryButton>
                        </Grid>
                        <Grid
                            item
                            css={css`
                                margin-left: 1rem;
                            `}
                        >
                            {!options?.editOrderModal && (
                                <PrimaryButton
                                    disabled={loading?.insertPalletLoading}
                                    onClick={() =>
                                        callbacks.editException({
                                            type: 'PICKUP',
                                            items: [item],
                                            order,
                                        })
                                    }
                                    orange
                                >
                                    Add Exception
                                </PrimaryButton>
                            )}
                        </Grid>
                    </Grid>
                ) : null}
            </Grid>
        </Grid>
    );
};

export default ItemSection;
