import React, { useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { useClientUser } from '@/hooks';
import { Grid, TextField } from '@material-ui/core';
import { TabRow, TabSection } from '@/components/ShipmentForm/blocks';
import dateFns from '@/utilities/dateFns';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { asDateInTZ, asBrowserDate } from '@/utilities/convertToISO';
import { useSwappedAttributes } from '@/components/ShipmentForm/hooks';
import DatePickerModal from '../../DatePickerModal';
import { Alert } from '@material-ui/lab';
import { DELIVERY_SLA } from '@/constants/featureFlags';

const DeliveryDate = ({ isDirty, order, hasError, callbacks, opt }) => {
    const { circles } = useClientUser();

    const [openPrefDate, setOpenPrefDate] = useState(false);
    const [openAltDate, setOpenAltDate] = useState(false);
    const [openDelDate, setOpenDelDate] = useState(false);
    const [openDelRangeStart, setOpenDelRangeStart] = useState(false);
    const [openDelRangeEnd, setOpenDelRangeEnd] = useState(false);

    const { zip } = useSwappedAttributes(order);

    const orderTZ = useMemo(() => {
        return order[zip] ? zipcode_to_timezone.lookup(order[zip]) : Intl.DateTimeFormat().resolvedOptions().timeZone;
    }, [order, zip]);

    const deliverySLA = circles?.[DELIVERY_SLA] || order.order_shipper?.user?.circles?.[DELIVERY_SLA];

    return (
        <>
            <TabSection>Delivery Date</TabSection>
            {deliverySLA ? (
                <TabRow>
                    <Grid item xs={6}>
                        <TextField
                            type="text"
                            variant="outlined"
                            fullWidth
                            label="Estimated Delivery Start Date (optional)"
                            multiline={false}
                            InputLabelProps={{ shrink: !!order.estimated_delivery_range_start }}
                            value={
                                order.estimated_delivery_range_start
                                    ? dateFns.formatDate(new Date(order.estimated_delivery_range_start), 'MM/dd/yyyy')
                                    : null
                            }
                            onClick={() => {
                                setOpenDelRangeStart(true);
                            }}
                            error={
                                hasError.estimated_delivery_range_start &&
                                (opt.startDirty || isDirty.estimated_delivery_range_start)
                            }
                        />
                        <DatePickerModal
                            open={openDelRangeStart}
                            handleClose={() => setOpenDelRangeStart(false)}
                            orderTZ={orderTZ}
                            onChange={(date) => {
                                callbacks.modifyOrder({
                                    estimated_delivery_range_start: date,
                                });
                                callbacks.makeDirty(['estimated_delivery_range_start']);
                                setOpenDelRangeStart(false);
                            }}
                            selectedDates={order.estimated_delivery_range_start}
                            maxSelections={1}
                            minDate={order.first_available_date ? new Date(order.first_available_date) : null}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            type="text"
                            variant="outlined"
                            fullWidth
                            label="Estimated Delivery End Date (optional)"
                            multiline={false}
                            InputLabelProps={{ shrink: !!order.estimated_delivery_range_end }}
                            value={
                                order.estimated_delivery_range_end
                                    ? dateFns.formatDate(new Date(order.estimated_delivery_range_end), 'MM/dd/yyyy')
                                    : null
                            }
                            onClick={() => {
                                setOpenDelRangeEnd(true);
                            }}
                            error={
                                hasError.estimated_delivery_range_end &&
                                (opt.startDirty || isDirty.estimated_delivery_range_end)
                            }
                        />
                        <DatePickerModal
                            open={openDelRangeEnd}
                            handleClose={() => setOpenDelRangeEnd(false)}
                            orderTZ={orderTZ}
                            onChange={(date) => {
                                callbacks.modifyOrder({
                                    estimated_delivery_range_end: date,
                                });
                                callbacks.makeDirty(['estimated_delivery_range_end']);
                                setOpenDelRangeEnd(false);
                            }}
                            selectedDates={order.estimated_delivery_range_end}
                            maxSelections={1}
                            minDate={order.first_available_date ? new Date(order.first_available_date) : null}
                        />
                    </Grid>
                </TabRow>
            ) : null}
            <TabRow>
                <Grid item xs={6}>
                    <TextField
                        type="text"
                        variant="outlined"
                        fullWidth
                        label="Scheduled Delivery Date (optional)"
                        multiline={false}
                        disabled={order.routes?.length}
                        InputLabelProps={{ shrink: !!order.delivery_date }}
                        value={
                            order.delivery_date
                                ? dateFns.formatDate(asBrowserDate(order.delivery_date), 'MM/dd/yyyy')
                                : ''
                        }
                        onClick={() => {
                            setOpenDelDate(true);
                        }}
                        error={hasError.delivery_date && (opt.startDirty || isDirty.delivery_date)}
                    />
                    <DatePickerModal
                        open={openDelDate}
                        handleClose={() => setOpenDelDate(false)}
                        orderTZ={orderTZ}
                        onChange={(date) => {
                            if (!date) {
                                callbacks.modifyOrder({
                                    delivery_date: null,
                                });
                                return;
                            }
                            const utc = new Date(new Date(date).setUTCHours(0, 0, 0, 0)).toISOString();
                            const dateStr = asDateInTZ(utc, orderTZ).toISOString();

                            callbacks.modifyOrder({
                                delivery_date: dateStr,
                            });
                            callbacks.makeDirty(['delivery_date']);
                        }}
                        selectedDates={order.delivery_date ? asBrowserDate(order.delivery_date).toISOString() : null}
                        maxSelections={1}
                        minDate={order.first_available_date ? new Date(order.first_available_date) : null}
                        canClear={true}
                    />
                    {opt.customerAvailabilityToggle &&
                        !circles?.['disable-customer-availability'] &&
                        order.delivery_date &&
                        !order.known_availability && (
                            <Alert
                                severity="warning"
                                css={css`
                                    margin-top: 8px;
                                `}
                            >
                                You have opted to collect the customer's availability via text/email. Customer will not
                                be contacted if a scheduled delivery date is already set here.
                            </Alert>
                        )}
                    {order.routes?.length > 0 && (
                        <Alert
                            severity="warning"
                            css={css`
                                margin-top: 8px;
                            `}
                        >
                            Cannot change delivery date of an order that is already assigned to a route. Remove the
                            order from the route and send it back to unassigned to edit.
                        </Alert>
                    )}
                </Grid>
            </TabRow>
        </>
    );
};

export default DeliveryDate;
