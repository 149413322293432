import _, { pick } from 'lodash';
import hash from 'object-hash';
import handleGeocode from '@/utilities/handleGeocode';
import states from '../../../constants/states.json';
import statesInverted from '../../../constants/statesInverted.json';
import { genAttributes } from '@onward-delivery/core';
import { genAttributesPerTab } from '../hooks/modal';
import { asDateInTZ } from '@/utilities/convertToISO';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { toE164 } from '@/utilities/formatPhoneNumber';
import { LOCATION_TYPES } from '@/constants/locationTypes';
import { ITEM_TYPES, ASSEMBLY_TYPES } from '@/components/ShipmentForm/constants/freightTypes';
import { DEFAULT_ITEM } from '../constants';
import { LOCATION_TYPES as DROPOFF_TYPES } from '@/components/ShipmentForm/constants/dropoffOptions';
import api from '@/utilities/api';

const dmService = new window.google.maps.DistanceMatrixService();
const MMDDYYYY_FORMAT = /^[0-9]{1,2}\/[0-9]{1,2}\/((\d{2})|(\d{4}))$/;
const YYYYMMDD_FORMAT = /^((\d{2})|(\d{4}))\/[0-9]{1,2}\/[0-9]{1,2}$/;

export const VALID_DROPOFF_TYPES = Object.fromEntries(DROPOFF_TYPES.map((type) => [type.value, [type.value]]));
export const VALID_LOCATION_TYPES = Object.fromEntries(
    Object.entries(LOCATION_TYPES).map(([value, display]) => [value, [value, display]])
);
export const VALID_ITEM_TYPE_DETAILS = Object.fromEntries(
    Object.entries(ITEM_TYPES).reduce((acc, [, types]) => {
        return [...acc, ...types.map(({ label, value }) => [value, [label, value]])];
    }, [])
);

export const VALID_ASSEMBLY_TYPES = Object.fromEntries(
    ASSEMBLY_TYPES.reduce((acc, type) => {
        return [...acc, [type.value, [type.display, type.value]]];
    }, [])
);

const wait = (duration) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({});
        }, duration);
    });
};

export const addressSplit = (street) => {
    if (!street) {
        return [null, ''];
    }

    // Often, we see apartment number included in the street address field. Examples: 5822 RUSSETT #3,
    // 2322 INDEPENDENCE LN APT 202, 9213 CROSSWINDS LANE #404.
    // This attempts to parse out the apartment number from street address entries such as these.

    // Leading spaces, in case of a road named 'Aptitude Street APT 101' for example
    const dividers = [' APT', '#', ' SUITE'];
    // Case insensitive search
    const capsStreet = street.toUpperCase();

    let street1;
    let street2;
    dividers.forEach((divider) => {
        const indexOf = capsStreet.indexOf(divider);
        if (indexOf > -1) {
            street1 = street.substring(0, indexOf).trim();
            street2 = street.substring(indexOf + divider.length).trim();
        }
    });

    if (street2) {
        return [street1, street2];
    }

    // If nothing can be parsed from the streetAddress, default back to an empty string.
    return [street, ''];
};

export const sanitizeEnum = (user, valid) => {
    if (user && user.length > 0) {
        for (const [value, matches] of Object.entries(valid)) {
            if (
                matches
                    .map((val) => val.toLowerCase().replace(/\s/g, ''))
                    .includes(user.toLowerCase().replace(/\s/g, ''))
            ) {
                return value;
            }
        }
    }

    return undefined;
};

// Future todo: update these mappings to prefill out as much as these item fields as possible
const convertItemFields = (orders, freightType) => {
    const allItems = orders.map((order) => {
        let qty = parseInt(order.qty) || 1;
        let isReturn = order.order_type === 'return';
        const weight = parseInt(order.weight) || 1;
        if (qty < 0) {
            qty = Math.abs(qty);
            isReturn = !isReturn;
        }

        // If total cubes is for the sub-order is provided, use that. Otherwise calculate it via L,W,H. If those are not provided either, then simply use a default (12 inches for each dimension).
        let totalCubes, cubesPerUnit, length, width, height;
        if (order.itemCubes) {
            totalCubes = order.itemCubes;
            cubesPerUnit = freightType === 'household' ? totalCubes / qty : totalCubes;
            length = parseInt(order.length || Math.cbrt(cubesPerUnit) * 12);
            width = parseInt(order.width || Math.cbrt(cubesPerUnit) * 12);
            height = parseInt(order.height || Math.cbrt(cubesPerUnit) * 12);
        } else {
            length = parseInt(order.length) || 12;
            width = parseInt(order.width) || 12;
            height = parseInt(order.height) || 12;
            cubesPerUnit = (length / 12.0) * (width / 12.0) * (height / 12.0);
            totalCubes = freightType === 'household' ? cubesPerUnit * qty : cubesPerUnit;
        }

        const item_type_details = sanitizeEnum(order.item_type_deets, VALID_ITEM_TYPE_DETAILS);
        const assembly_type = sanitizeEnum(order.assembly_type, VALID_ASSEMBLY_TYPES);

        return _.omitBy(
            {
                assembly_type,
                barcode: order.barcode,
                class: order.class,
                cubes_per_unit: cubesPerUnit,
                description: order.desc || (freightType === 'pallet' ? 'Pallet' : null),
                height,
                is_return: isReturn,
                item_type_details,
                item_type: freightType,
                length,
                nfmc_no: order.nfmc_no,
                quantity: qty,
                service_time: order.item_service_time,
                sku: order.sku,
                total_cubes: totalCubes,
                total_weight: weight * qty,
                weight,
                width,
            },
            (val) => val === null || val === undefined
        );
    });

    return allItems;
};

const convertOrderFields = (orders, freightType, sort, { service_levels, pickupInfo }) => {
    // Sort items by package sequence id
    orders.sort((a, b) => a.package_seq_id - b.package_seq_id);

    const {
        po_number,
        reference_id,
        manufacturer,
        customer_phone,
        customer_email,
        customer_name,
        customer_street = '',
        customer_unit,
        customer_state,
        customer_city,
        customer_zip,
        dropofflocation,
        service_type,
        del_stairs,
        del_elev,
        delivery_date,
        dropoffcomments,
        estimated_delivery_range_start,
        estimated_delivery_range_end,
        preferred_delivery_date,
        alternative_delivery_dates,
        warehouse_estimated_delivery_date,
        warehouse_estimated_ship_date,
        warehouse_trailer_id,
        warehouse_delivery_notes,
        warehouse_delivery_status,
        order_revenue_override,
    } = orders[0];

    // Generate an id from a hash of the order's PO Number and Address
    const id = `${String(sort).padStart(4, '0')}-${hash({ po_number, address: customer_street })}`;

    const [street, apartment] = customer_unit ? [customer_street, customer_unit] : addressSplit(customer_street);
    const state = states[customer_state] || customer_state || pickupInfo.pickupstate;
    const tz = customer_zip ? zipcode_to_timezone.lookup(customer_zip) : 'America/New_York';

    let failedDelDateProcessing = false;
    let delivery_date_info = {};
    if (delivery_date) {
        if (MMDDYYYY_FORMAT.test(delivery_date) || YYYYMMDD_FORMAT.test(delivery_date)) {
            delivery_date_info = {
                delivery_date: asDateInTZ(delivery_date, tz).toISOString(),
                manual_scheduled_delivery: true,
            };
        } else {
            failedDelDateProcessing = true;
        }
    }

    const dropoff_location = sanitizeEnum(dropofflocation, VALID_DROPOFF_TYPES);
    const serviceLevel = service_levels.find((sl) => sl.service_level === service_type);
    const dropoff_location_type = sanitizeEnum(service_type, VALID_LOCATION_TYPES);
    const initWarehouse =
        warehouse_estimated_delivery_date ||
        warehouse_estimated_ship_date ||
        warehouse_trailer_id ||
        warehouse_delivery_notes
            ? 'NOT_DELIVERED'
            : null;

    return {
        failedDelDateProcessing,
        convertedOrder: _.omitBy(
            {
                key: id,
                ...delivery_date_info,
                po_number,
                reference_id,
                manufacturer,
                dropoff_phone: toE164(customer_phone),
                dropoff_email: customer_email,
                dropoff_name: customer_name,
                del_stairs: del_stairs > 0,
                delivery_stair_quantity: del_stairs,
                del_elev,
                dropoff_full_address: [street, apartment, customer_city, state, customer_zip].join(', '),
                dropoff_address: [street, customer_city, state, customer_zip].join(', '),
                dropoff_street: street,
                dropoff_unit: apartment,
                dropoff_city: customer_city,
                dropoff_state: state,
                dropoff_zip: customer_zip,
                dropoff_location,
                ...(serviceLevel
                    ? {
                          service_level_id: serviceLevel.service_level_id,
                          dropoff_location_type: serviceLevel.location_type,
                      }
                    : {
                          dropoff_location_type,
                      }),
                dropoff_comments: dropoffcomments,
                estimated_delivery_range_start: estimated_delivery_range_start
                    ? asDateInTZ(estimated_delivery_range_start, tz).toISOString()
                    : null,
                estimated_delivery_range_end: estimated_delivery_range_end
                    ? asDateInTZ(estimated_delivery_range_end, tz).toISOString()
                    : null,
                order_status: 'pending',
                dropoff_location_info: apartment && dropoff_location === 'Residence' ? 'Apartment' : null,
                known_availability: false,
                warehouse_estimated_delivery_date: warehouse_estimated_delivery_date
                    ? asDateInTZ(warehouse_estimated_delivery_date, tz).toISOString()
                    : null,
                warehouse_estimated_ship_date: warehouse_estimated_ship_date
                    ? asDateInTZ(warehouse_estimated_ship_date, tz).toISOString()
                    : null,
                preferred_delivery_date: preferred_delivery_date
                    ? asDateInTZ(preferred_delivery_date, tz).toISOString()
                    : null,
                alternative_delivery_dates: alternative_delivery_dates
                    ? alternative_delivery_dates.map((date) => asDateInTZ(date, tz).toISOString())
                    : null,
                warehouse_trailer_id,
                warehouse_delivery_notes,
                warehouse_delivery_status: warehouse_delivery_status || initWarehouse,
                order_revenue_override,
            },
            (val) => val === null || val === undefined
        ),
    };
};

export const mapMultiPickupInfo = (order, business, locations) => {
    const defaults = {
        pickup_name: business.name,
        pickup_email: business.email,
        pickup_phone: business.phone,
        pickup_address_is_custom: false,
    };

    const overrides = {
        ...(order.pickup_name ? { pickup_name: order.pickup_name } : {}),
        ...(order.pickup_email ? { pickup_email: order.pickup_email } : {}),
        ...(order.pickup_phone ? { pickup_phone: order.pickup_phone } : {}),
    };

    if (order.pickup_store) {
        const store = locations.find((location) => location.location_name === order.pickup_store);
        if (store) {
            return {
                ...defaults,
                pickup_address:
                    store.address ||
                    [store.business_address, store.business_city, store.business_state, store.business_zip]
                        .filter((x) => x)
                        .join(', '),
                pickup_full_address: [
                    store.business_address,
                    store.business_unit,
                    store.business_city,
                    store.business_state,
                    store.business_zip,
                ]
                    .filter((x) => x)
                    .join(', '),

                pickup_street_address: store.business_address,
                pickup_unit: store.business_unit,
                pickup_state: states[store.business_state] || store.business_state,
                pickup_city: store.business_city,
                pickup_zip: store.business_zip,
                pickup_lat: store.lat,
                pickup_lng: store.lng,
                pickup_location: store.location_type,
                pickup_location_type: store.location_info,
                ...overrides,
            };
        }
    }

    return {
        ...defaults,
        pickup_address: [
            order.pickup_street,
            order.pickup_city,
            statesInverted[order.pickup_state] || order.pickup_state,
            order.pickup_zip,
        ]
            .filter((x) => x)
            .join(', '),
        pickup_full_address: [
            order.pickup_street,
            order.pickup_unit,
            order.pickup_city,
            statesInverted[order.pickup_state] || order.pickup_state,
            order.pickup_zip,
        ]
            .filter((x) => x)
            .join(', '),
        pickup_street_address: order.pickup_street,
        pickup_unit: order.pickup_unit,
        pickup_state: states[order.pickup_state] || order.pickup_state,
        pickup_city: order.pickup_city,
        pickup_zip: order.pickup_zip,
        pickup_location: sanitizeEnum(order.pickuplocation, VALID_DROPOFF_TYPES),
        pickup_location_type: sanitizeEnum(order.pickuplocationtype, VALID_LOCATION_TYPES),
        ...overrides,
    };
};

export const mapPickupInfo = (pickupInfo, userId, attrs) => {
    const defaultAttrs = genAttributes({}, true);
    const {
        is_custom,
        full_address,
        address,
        city,
        state,
        street,
        zip,
        lat,
        long,
        comments,
        unit,
        location,
        location_type,
    } = attrs || defaultAttrs;

    return {
        job_type: pickupInfo.job_type,
        ...(pickupInfo.delivery_date ? { delivery_date: pickupInfo.delivery_date } : {}),
        pickup_window_end: pickupInfo.pickup_window_end,
        is_same_day: pickupInfo.is_same_day,
        shipper_id: pickupInfo.shipper_id || userId,
        carrier_id: pickupInfo.carrier_id || null,
        first_available_date: pickupInfo.firstAvailableDate,
        ...(pickupInfo.is_middle_mile_origin
            ? {
                  is_middle_mile: true,
                  has_middle_mile_origin: true,
                  middle_mile_origin_name: pickupInfo.pickupname,
                  middle_mile_origin_email: pickupInfo.pickupemail,
                  middle_mile_origin_phone: pickupInfo.pickupphone,
                  middle_mile_origin_address: pickupInfo.pickupstreetaddress,
                  middle_mile_origin_unit: pickupInfo.pickupunit,
                  middle_mile_origin_city: pickupInfo.pickupcity,
                  middle_mile_origin_state: states[pickupInfo.pickupstate] || pickupInfo.pickupstate,
                  middle_mile_origin_zip: pickupInfo.pickupzip,
              }
            : pickupInfo.is_middle_mile
            ? {
                  is_middle_mile: true,
              }
            : {
                  pickup_name: pickupInfo.pickupname,
                  pickup_email: pickupInfo.pickupemail,
                  pickup_phone: pickupInfo.pickupphone,
                  [street]: pickupInfo.pickupstreetaddress,
                  [unit]: pickupInfo.pickupunit,
                  [state]: states[pickupInfo.pickupstate] || pickupInfo.pickupstate,
                  [city]: pickupInfo.pickupcity,
                  [zip]: pickupInfo.pickupzip,
                  [lat]: pickupInfo.pulat,
                  [long]: pickupInfo.pulng,
                  [is_custom]: pickupInfo.isCustom,
                  [address]: pickupInfo.pickupaddress
                      ? pickupInfo.pickupaddress
                      : `${pickupInfo.pickupstreetaddress}, ${pickupInfo.pickupcity}, ${
                            states[pickupInfo.pickupstate] || pickupInfo.pickupstate
                        }, ${pickupInfo.pickupzip}`,
                  [full_address]: pickupInfo.pickupfulladdress,
                  [location]: pickupInfo.pickuplocation,
                  [location_type]: pickupInfo.pickuplocationtype,
                  secondary_pickup_contact_name: pickupInfo.secondaryPickupContactName,
                  secondary_pickup_contact_phone: pickupInfo.secondaryPickupContactPhone,
                  secondary_pickup_contact_email: pickupInfo.secondaryPickupContactEmail,
              }),
    };
};

const returnSwap = {
    dropoff_location: 'pickup_location',
    dropoff_location_info: 'pickup_location_info',
    dropoff_location_type: 'pickup_location_type',
    del_stairs: 'pickup_stairs',
    del_elev: 'pickup_elevator',
    delivery_stair_quantity: 'pickup_stair_quantity',
    dropoff_address: 'pickup_address',
    dropoff_address_is_custom: 'pickup_address_is_custom',
    dropoff_full_address: 'pickup_full_address',
    dropoff_state: 'pickup_state',
    dropoff_zip: 'pickup_zip',
    dropoff_city: 'pickup_city',
    dropoff_street: 'pickup_street_address',
    dropoff_lat: 'pickup_lat',
    dropoff_lng: 'pickup_lng',
    dropoff_unit: 'pickup_unit',
    dropoff_comments: 'pickup_comments',
};

export function swapReturnAttr(order) {
    const clone = { ...order };
    for (const [dropoffKey, pickupKey] of Object.entries(returnSwap)) {
        const tmp = clone[dropoffKey];
        clone[dropoffKey] = clone[pickupKey];
        clone[pickupKey] = tmp;
    }

    return clone;
}

export async function enrich(order, geocodeCache = {}, opt = { swap: false, pickup: false }) {
    let enrichPickups = true;
    let geocodePartialMatch = false;
    let geocodeFailed = false;
    let addressGeocodeString;

    if (order.order_type === 'return') {
        enrichPickups = !enrichPickups;
    }
    if (!opt.pickup) {
        enrichPickups = !enrichPickups;
    }

    if (enrichPickups) {
        addressGeocodeString =
            order.pickup_address ||
            ['pickup_street_address', 'pickup_city', 'pickup_state', 'pickup_zip']
                .filter((attr) => !!order[attr])
                .map((attr) => order[attr])
                .join(', ');
    } else {
        addressGeocodeString =
            order.dropoff_address ||
            ['dropoff_street', 'dropoff_city', 'dropoff_state', 'dropoff_zip']
                .filter((attr) => !!order[attr])
                .map((attr) => order[attr])
                .join(', ');
    }

    let geocodeResults = geocodeCache[addressGeocodeString];
    try {
        if (addressGeocodeString && !geocodeResults) {
            geocodeResults = await handleGeocode({ address: addressGeocodeString });

            if (geocodeResults.partial_match) {
                geocodePartialMatch = true;
            }
        }
    } catch (err) {
        console.error(`Could not geocode address ${addressGeocodeString}`, err);
        geocodeFailed = true;
    }

    let fields = {};
    if (enrichPickups) {
        fields = Object.keys(geocodeResults || {}).length
            ? {
                  pickup_full_address: [
                      geocodeResults.street,
                      order.pickup_unit,
                      geocodeResults.city,
                      geocodeResults.state,
                      geocodeResults.zip,
                  ]
                      .filter((val) => val && val.length > 0)
                      .join(', '),
                  pickup_address: geocodeResults.address || '',
                  pickup_city: geocodeResults.city || '',
                  pickup_state: geocodeResults.state || '',
                  pickup_street_address: geocodeResults.street || '',
                  pickup_zip: geocodeResults.zip || '',
                  pickup_lat: geocodeResults.lat,
                  pickup_lng: geocodeResults.lng,
                  pickup_address_geocode_failed: geocodeFailed,

                  ...(opt.swap
                      ? {
                            dropoff_full_address: order.pickup_full_address,
                            dropoff_address: order.pickup_address,
                            dropoff_city: order.pickup_city,
                            dropoff_state: order.pickup_state,
                            dropoff_street: order.pickup_street_address,
                            dropoff_zip: order.pickup_zip,
                            dropoff_lat: order.pickup_lat,
                            dropoff_lng: order.pickup_lng,
                        }
                      : {}),
              }
            : {};
    } else {
        fields = Object.keys(geocodeResults || {}).length
            ? {
                  dropoff_full_address: [
                      geocodeResults.street,
                      order.dropoff_unit,
                      geocodeResults.city,
                      geocodeResults.state,
                      geocodeResults.zip,
                  ]
                      .filter((val) => val && val.length > 0)
                      .join(', '),
                  dropoff_address: geocodeResults.address || '',
                  dropoff_city: geocodeResults.city || '',
                  dropoff_state: geocodeResults.state || '',
                  dropoff_street: geocodeResults.street || '',
                  dropoff_zip: geocodeResults.zip || '',
                  dropoff_lat: geocodeResults.lat,
                  dropoff_lng: geocodeResults.lng,
                  dropoff_address_geocode_failed: geocodeFailed,
              }
            : {};
    }

    return [
        {
            geocodePartialMatch,
            geocodeFailed,
            ...order,
            ...fields,
        },
        { ...geocodeCache, [addressGeocodeString]: geocodeResults },
    ];
}

export const distance = async (orders) => {
    const MAX_DESTINATIONS_PER_QUERY = 25;

    if (orders.length > 0) {
        const { lat, long } = genAttributes(orders[0], true);
        const origins = [{ lat: orders[0][lat], lng: orders[0][long] }];
        const batches = [];

        for (let batch = 0; batch * MAX_DESTINATIONS_PER_QUERY < orders.length; batch++) {
            batches.push(orders.slice(batch * MAX_DESTINATIONS_PER_QUERY, (batch + 1) * MAX_DESTINATIONS_PER_QUERY));
        }

        return await batches.reduce(
            async (acc, batch) => {
                let [failed, successful] = await acc;

                const [filtered, destinations] = batch.reduce(
                    ([filtered, destinations], order) => {
                        const { lat, long } = genAttributes(order);

                        if (order[lat] && order[long]) {
                            return [
                                [...filtered, order],
                                [...destinations, { lat: order[lat], lng: order[long] }],
                            ];
                        }

                        failed[order.key || order.order_id] = true;
                        successful.push(order);

                        return [filtered, destinations];
                    },
                    [[], []]
                );

                try {
                    const [results] = await Promise.allSettled([
                        dmService.getDistanceMatrix({
                            origins,
                            destinations,
                            travelMode: 'DRIVING',
                            unitSystem: window.google.maps.UnitSystem.IMPERIAL,
                        }),
                        wait(500),
                    ]);

                    filtered.forEach((order, idx) => {
                        const result = results.value.rows[0].elements[idx];
                        if (result.status === 'OK') {
                            successful.push({
                                ...order,
                                distance: result.distance.text,
                                miles: result.distance.value / 1609.344,
                                duration_seconds: result.duration.value,
                            });
                        } else {
                            failed = {
                                ...failed,
                                [order.key || order.order_id]: true,
                            };
                            successful.push(order);
                        }
                    });
                } catch (e) {
                    console.error(e);

                    successful = [...successful, ...batch];
                    failed = {
                        ...failed,
                        ...Object.fromEntries(batch.map((order) => [order.key || order.order_id, true])),
                    };
                }

                return [failed, successful];
            },
            [{}, []]
        );
    }

    return [{}, []];
};

export const price = async (orders) => {
    if (orders.length > 0) {
        const pricingPayload = Object.fromEntries(orders.map((order) => [order.key || order.order_id, order]));

        const data = await api.post({
            routeName: 'ltlPriceOrder',
            data: pricingPayload,
        });

        const success = [];
        const failed = {};

        orders.forEach((order) => {
            const key = order.key || order.order_id;
            const pricingResult = data[key];
            if (pricingResult?.price_breakdown) {
                success.push({
                    ...order,
                    ...pick(pricingResult, ['order_revenue', 'shipper_rate', 'carrier_rate', 'price_breakdown']),
                });
            } else {
                failed[key] = pricingResult?.pricing_errors || ['unknown'];
                success.push({
                    ...order,
                    shipper_rate: null,
                    carrier_rate: null,
                    price_breakdown: null,
                    order_revenue: null,
                });
            }
        });

        return [failed, success];
    }

    return [{}, []];
};

export function createOrder(pickupInfo, freightType, type, userId, circles) {
    const key = `${new Date().getTime()}-${hash({ type: type, freightType: freightType })}`;

    let oms = true;
    if (circles?.['ltl-marketplace'] && !circles?.['saas-v1']) {
        oms = false;
    }

    const order = {
        key,
        order_type: type,
        freight_type: freightType,
        oms,
        itemsByOrderId: [
            {
                ...DEFAULT_ITEM,
                item_type: freightType,
                is_return: pickupInfo.job_type === 'PICKUP_AND_WILL_CALL',
                ...(freightType === 'pallet'
                    ? {
                          description: 'Pallet',
                          length: 48,
                          width: 40,
                      }
                    : {}),
            },
        ],
        haulaway_items: [],
        known_availability: false,
    };

    const { pickupcomments, ...rest } = pickupInfo;

    Object.assign(order, mapPickupInfo(rest, userId));

    return {
        ...order,
        geocodePartialMatch: false,
        geocodeFailed: true,
        hasMissingField: ['itemsByOrderId', 'dropoff_name'],
        ...(pickupcomments
            ? {
                  notes: [
                      {
                          note: pickupcomments,
                          source_user_type: 'Shipper',
                          source_user_id: userId,
                          is_acknowledgement: false,
                          requires_acknowledgement: false,
                          acknowledged_by_shipper: false,
                          acknowledged_by_admin: false,
                          private_to: null,
                          type: type === 'return' ? 'Delivery' : 'Pickup',
                      },
                  ],
              }
            : {}),
    };
}

const BACKFILL_CSV_KEYS = {
    del_date: 'delivery_date',
    cubesPerUnit: 'itemCubes',
    order_revenue: 'order_revenue_override',
    dropofflocationtype: 'service_type',
};

export async function processOrdersFromCSV({
    csvOptions,
    csvFile,
    csvMapping,
    pickupInfo,
    freightType,
    multiPickup,
    locations,
    business,
    userId,
    service_levels,
    circles,
}) {
    // Split CSV headers from CSV data
    const [csvHeaders, ...csvData] = csvFile;

    // Hydrate custom template with required boolean, default value, and parser func
    const hydratedMapping = Object.fromEntries(
        Object.keys(csvMapping).map((csvKey) => {
            const key = BACKFILL_CSV_KEYS[csvKey] || csvKey;
            return [
                key,
                {
                    key,
                    val: csvMapping[key],
                    required: csvOptions[key].required,
                    default: csvOptions[key].default,
                    parser: csvOptions[key].parser,
                },
            ];
        })
    );

    let ordersToCreate = {};
    const sorting = {};
    for (const [i, row] of (csvData || []).entries()) {
        // Parse the raw csv row into a JSON object by the zipTarget mapping
        const parsedRow = {
            ..._.zipObject(csvHeaders, row),
        };

        // If an entire row is empty (sometimes an extra row without data is accidentally appended at the bottom of the CSV) then skip it.
        if (Object.values(parsedRow).every((rowContent) => !rowContent)) continue;

        // Parse raw data with template
        const templateParsedRow = {};
        Object.values(hydratedMapping).map((templateColumn) => {
            if (templateColumn.required && !parsedRow[templateColumn.val]) {
                console.error(`Missing required value for CSV Column ${templateColumn.val} at row ${i + 1}`);
            }
            const value = parsedRow[templateColumn.val];
            templateParsedRow[templateColumn.key] = value ? templateColumn.parser(value) : templateColumn.default;
        });

        const po = templateParsedRow.po_number;
        sorting[po] = sorting[po] ? sorting[po] : i;

        ordersToCreate[po] = [...(ordersToCreate[po] || []), templateParsedRow];
    }

    let deliveryDateProcessingErrors = false;
    const orders = Object.entries(ordersToCreate)
        .map(([po, items]) => {
            let { failedDelDateProcessing, convertedOrder } = convertOrderFields(items, freightType, sorting[po], {
                service_levels,
                pickupInfo,
            });
            const itemsByOrderId = convertItemFields(items, freightType);
            if (failedDelDateProcessing) deliveryDateProcessingErrors = true;

            let type = 'delivery';
            const [containsDelivery, containsReturn] = itemsByOrderId.reduce(
                ([containsDelivery, containsReturn], item) => {
                    return [containsDelivery || !item.is_return, containsReturn || item.is_return];
                },
                [false, false]
            );

            if (containsDelivery && containsReturn) {
                type = 'exchange';
            } else if (containsReturn) {
                type = 'return';
            }

            let oms = true;
            if (circles?.['ltl-marketplace'] && !circles?.['saas-v1']) {
                oms = false;
            }

            const { dropoff_comments, warehouse_delivery_notes, ...rest } = convertedOrder;

            const notes = [];
            if (dropoff_comments) {
                notes.push({
                    note: dropoff_comments,
                    source_user_type: 'Shipper',
                    source_user_id: userId,
                    is_acknowledgement: false,
                    requires_acknowledgement: false,
                    acknowledged_by_shipper: false,
                    acknowledged_by_admin: false,
                    private_to: null,
                    type: 'Delivery',
                });
            }
            if (warehouse_delivery_notes) {
                notes.push({
                    note: warehouse_delivery_notes,
                    source_user_type: 'Shipper',
                    source_user_id: userId,
                    is_acknowledgement: false,
                    requires_acknowledgement: false,
                    acknowledged_by_shipper: false,
                    acknowledged_by_admin: false,
                    private_to: null,
                    type: 'Warehouse',
                });
            }

            let mappedPickupInfo;
            if (multiPickup) {
                mappedPickupInfo = mapMultiPickupInfo(items[0], business, locations);
                if (items?.[0]?.pickupcomments) {
                    notes.push({
                        note: items?.[0]?.pickupcomments,
                        source_user_type: 'Shipper',
                        source_user_id: userId,
                        is_acknowledgement: false,
                        requires_acknowledgement: false,
                        acknowledged_by_shipper: false,
                        acknowledged_by_admin: false,
                        private_to: null,
                        type: 'Pickup',
                    });
                }
            } else {
                mappedPickupInfo = mapPickupInfo(pickupInfo, userId);
                if (pickupInfo?.pickupcomments) {
                    notes.push({
                        note: pickupInfo?.pickupcomments,
                        source_user_type: 'Shipper',
                        source_user_id: userId,
                        is_acknowledgement: false,
                        requires_acknowledgement: false,
                        acknowledged_by_shipper: false,
                        acknowledged_by_admin: false,
                        private_to: null,
                        type: 'Pickup',
                    });
                }
            }

            return {
                ...rest,
                ...(notes?.length
                    ? {
                          notes: notes,
                      }
                    : {}),
                order_type: type,
                freight_type: freightType,
                oms,
                itemsByOrderId,

                ...mappedPickupInfo,
                ...(pickupInfo?.is_middle_mile
                    ? {
                          pickup_city: convertedOrder.dropoff_city,
                          pickup_state: convertedOrder.dropoff_state,
                      }
                    : {}),
            };
        })
        .sort((l, r) => sorting[l.po_number] - sorting[r.po_number]);

    const processed = orders.map((order) => (order.order_type === 'return' ? swapReturnAttr(order) : order));

    return {
        orders: {
            ...Object.fromEntries(
                processed.map((order) => [order.key, _.omitBy(order, (val) => val === null || val === undefined)])
            ),
        },
        deliveryDateProcessingErrors,
    };
}

function swapPdf({ order, prev: { address, city, state, zip }, type, pickup }) {
    const swapped = genAttributes({ type }, pickup);
    const [street, apartment] = addressSplit(order[address]);

    return {
        [swapped.street]: street,
        [swapped.address]: order[address],
        [swapped.unit]: apartment,
        [swapped.city]: order[city],
        [swapped.state]: order[state],
        [swapped.zip]: order[zip],
    };
}

export async function processOrdersFromPDF({ order, freightType }) {
    const { items, ...remaining } = order;
    const enrichedItems = (items || []).map((item) => ({
        ...DEFAULT_ITEM,
        item_type: freightType,
        ...Object.fromEntries(
            Object.entries({
                height: 'height',
                length: 'length',
                name: 'desc',
                quantity: 'qty',
                weight: 'weight',
                width: 'width',
            }).map(([prev, next]) => {
                return [next, item[prev]];
            })
        ),
    }));
    let type = 'delivery';
    const [containsDelivery, containsReturn] = enrichedItems.reduce(
        ([containsDelivery, containsReturn], item) => {
            return [containsDelivery || !item.is_return, containsReturn || item.is_return];
        },
        [false, false]
    );

    if (containsDelivery && containsReturn) {
        type = 'exchange';
    } else if (containsReturn) {
        type = 'return';
    }

    const swapped = {
        pickup_location: 'Business',
        pickup_location_type: 'rollUpDoor',
        dropoff_email: remaining.dropoff_contact_email,
        dropoff_phone: remaining.dropoff_contact_phone ? toE164(remaining.dropoff_contact_phone) : null,
        dropoff_name: remaining.dropoff_name,
        pickup_email: remaining.pickup_contact_email,
        pickup_phone: remaining.pickup_contact_phone ? toE164(remaining.pickup_contact_phone) : null,
        pickup_name: remaining.pickup_name,
        po_number: remaining.po_number,
        ...swapPdf({
            order: remaining,
            prev: {
                address: 'dropoff_address',
                city: 'dropoff_city',
                zip: 'dropoff_zip',
                state: 'dropoff_state',
            },
            pickup: false,
            type,
        }),
        ...swapPdf({
            order: remaining,
            prev: {
                address: 'pickup_address',
                city: 'pickup_city',
                zip: 'pickup_zip',
                state: 'pickup_state',
            },
            pickup: true,
            type,
        }),
    };

    const { zip } = genAttributes({ type }, false);
    const tz = swapped[zip] ? zipcode_to_timezone.lookup(swapped[zip]) : 'America/New_York';

    let delivery_date_info = {};
    if (remaining.scheduled_delivery_date) {
        const parsed = asDateInTZ(remaining.scheduled_delivery_date, tz);
        if (!isNaN(parsed.getTime())) {
            delivery_date_info = {
                delivery_date: parsed.toISOString(),
                manual_scheduled_delivery: true,
            };
        }
    }

    const key = `${new Date().getTime()}-${hash({ type, freightType })}`;
    return {
        orders: {
            [key]: {
                ...swapped,
                ...delivery_date_info,
                key,
                order_type: type,
                freight_type: freightType,
                oms: true,
                haulaway_items: [],
                known_availability: false,
                itemsByOrderId: convertItemFields(enrichedItems, freightType),
            },
        },
    };
}
