import React, { useContext, useState, useEffect, useMemo } from 'react';
import { css } from '@emotion/react';
import { Grid } from '@material-ui/core';
import { Info as InfoIcon, LocalShipping } from '@material-ui/icons';
import { format } from 'date-fns';
import { Context } from './context';
import FTLStopHelpers from '@/utilities/FTLStopHelpers';
import { useStopsAway } from './hooks';
import { delWindowToFormattedDateRange } from '@/utilities/delWindowToFormattedDate';
import { Body1, Body2, DeliveryWindow, ModalLinkText, Subtitle1 } from './blocks';
import { colors } from '@/styles';
import { getStopCompletionTimes } from './utilities';

const OrderSummary = ({ order, route }) => {
    const { openPODModal, openExceptionsModal } = useContext(Context);

    const stopsAway = useStopsAway(route, order);

    const orderType = order?.order_type === 'return' ? 'return' : 'delivery';

    const getLtlEstimatedDelivery = (formattedDelDate) => {
        const { del_window_start, del_window_end } = order;

        if (!del_window_start || !del_window_end) return { date: formattedDelDate };

        return {
            date: formattedDelDate,
            time: delWindowToFormattedDateRange(del_window_start, del_window_end, order.dropoff_zip),
        };
    };

    const getFtlEstimatedDelivery = (formattedDelDate) => {
        if (!route) return getLtlEstimatedDelivery(formattedDelDate);

        const stopSequence = route.stopsByRouteId.filter((stop) => FTLStopHelpers.isCustomerStop(stop, route));

        const orderStop = stopSequence.find((stop) => {
            return (
                (stop.type === 'PICKUP' && stop.returns?.includes(order.order_id)) ||
                (stop.type === 'DROPOFF' && stop.orders?.includes(order.order_id) && !stop?.returns?.length)
            );
        });

        if (!orderStop) return getLtlEstimatedDelivery(formattedDelDate);

        let _delWindow;
        if (orderStop.del_window_start) {
            _delWindow = delWindowToFormattedDateRange(
                orderStop.del_window_start,
                orderStop.del_window_end,
                order.dropoff_zip
            );
        }

        if (!_delWindow) return getLtlEstimatedDelivery(formattedDelDate);

        const [time, zonedTime] = _delWindow.split('(');
        if (_delWindow.indexOf('(') > -1) {
            return { date: formattedDelDate, time, zonedTime: `(${zonedTime}` };
        } else {
            return { date: formattedDelDate, time, zonedTime: null };
        }
    };

    const estimatedDelivery = useMemo(() => {
        const formattedDelDate = order.delivery_date_formatted;
        if (!!route) {
            return getFtlEstimatedDelivery(formattedDelDate);
        } else {
            return getLtlEstimatedDelivery(formattedDelDate);
        }
    }, [order, route]);

    const delayedTimeFrame = useMemo(() => {
        if (!route) return;

        let associatedStop = route.stopsByRouteId.find(
            (_stop) => FTLStopHelpers.isCustomerStop(_stop, route) && _stop.orders?.includes(order.order_id)
        );

        if (!associatedStop?.del_window_delay) return;

        const delayedStart = new Date(
            new Date(associatedStop.del_window_start).getTime() + associatedStop.del_window_delay
        );
        const delayedEnd = new Date(
            new Date(associatedStop.del_window_end).getTime() + associatedStop.del_window_delay
        );

        return delWindowToFormattedDateRange(delayedStart, delayedEnd);
    }, [order, route]);

    const stopDeliveryTime = useMemo(() => {
        if (route) {
            const { stopDeliveryTime } = getStopCompletionTimes(order.order_id, route);
            return stopDeliveryTime;
        }
    }, [order, route]);

    const exceptionExists =
        !!order.exception &&
        (!!order.exception.images ||
            !!order.exception.reason ||
            !!order.exception.notes ||
            !!order.exception.occuredDuring);

    const orderIsComplete =
        order.order_status === 'complete' || order.order_status === 'routedComplete' || stopDeliveryTime;

    const orderIsCancelled = ['cancelled'].includes(order.order_status);

    if (!order) return null;

    return (
        <Grid container spacing={2}>
            {/* Order Cancelled or Order Delayed */}
            {(orderIsCancelled || order.del_window_delay) && (
                <Grid item xs={12}>
                    <Body1
                        css={css`
                            color: ${orderIsCancelled
                                ? colors.reds.cancelled
                                : order.del_window_delay
                                ? colors.golds.primary
                                : 'black'} !important;
                        `}
                    >
                        <LocalShipping />
                        <span className="ml-2">
                            {orderIsCancelled && 'Your order was cancelled.'}
                            {order.del_window_delay && 'Your order was delayed.'}
                        </span>
                    </Body1>
                </Grid>
            )}

            {/* ETA */}
            <Grid item xs={12}>
                {!orderIsComplete && !order.delivery_time_confirmed === 'confirmed' && (
                    <>
                        {!!order?.first_available ? (
                            <>
                                <Body1>
                                    {order.del_window_delay ? 'Delayed estimated timeframe:' : 'Estimated timeframe:'}
                                </Body1>
                                <br />
                            </>
                        ) : (
                            <>
                                <Body2>Awaiting Pickup Availability</Body2>
                                <br />
                                <Subtitle1>
                                    We're waiting for confirmation that your order is ready to ship, then we will get
                                    you scheduled.
                                </Subtitle1>
                                <br />
                            </>
                        )}
                    </>
                )}

                {orderIsComplete ? (
                    <DeliveryWindow status={'success'}>{order?.order_type === 'return' ? 'Complete' : 'Delivered'}</DeliveryWindow>
                ) : (
                    <DeliveryWindow
                        status={
                            orderIsCancelled || order?.exception?.reason
                                ? 'error'
                                : order?.del_window_delay
                                ? 'delay'
                                : 'success'
                        }
                    >
                        {estimatedDelivery?.date}
                        <br />
                        {order.del_window_delay ? delayedTimeFrame : estimatedDelivery?.time}
                        <br />
                        {!order.del_window_delay && estimatedDelivery?.zonedTime ? estimatedDelivery?.zonedTime : null}
                    </DeliveryWindow>
                )}
            </Grid>

            {/* Prior ETA */}
            {!orderIsComplete && order.del_window_delay && (
                <Grid item xs={12}>
                    <Body1>Prior estimated timeframe:</Body1>
                    <br />
                    <Body1>{delayedTimeFrame}</Body1>
                </Grid>
            )}

            {/* N Stops Away */}
            {!!stopsAway && !orderIsComplete && !orderIsCancelled && (
                <Grid item xs={12}>
                    <Body2>
                        {stopsAway === 0 ? 'Next stop' : `Driver is ${stopsAway} stop${stopsAway > 1 ? 's' : ''} away.`}
                    </Body2>
                </Grid>
            )}

            {/* Live Tracking helper text */}
            {!orderIsComplete && route && (
                <Grid item xs={12}>
                    <Body1>Live tracking will begin within 2 hours of your {orderType}.</Body1>
                </Grid>
            )}

            {/* Final Delivery Photos */}
            {!!order.delivery_photo?.length && (
                <Grid item xs={12}>
                    <ModalLinkText onClick={openPODModal}>Proof of Delivery</ModalLinkText>
                </Grid>
            )}

            {/* Cancelled or Exception Text */}
            {(orderIsCancelled || exceptionExists) && (
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item>
                            <InfoIcon
                                color="error"
                                fontSize="small"
                                className="mr-2 mt-1"
                                css={css`
                                    align-self: baseline;
                                `}
                            />
                        </Grid>
                        <Grid item>
                            {orderIsCancelled && (
                                <>
                                    <Body1>Order cancelled because of last minute cancellation.</Body1>
                                    <br />
                                    <Body1>
                                        We apologize for the inconvenience. The store will reach out to reschedule.
                                    </Body1>
                                </>
                            )}
                            {exceptionExists && (
                                <Body1>
                                    One or more of your items may be delayed. View exception below for details.
                                </Body1>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            )}

            {/* Exception Reported Modal Link */}
            {exceptionExists && (
                <Grid item xs={12}>
                    <ModalLinkText onClick={openExceptionsModal}>Exception(s) Reported</ModalLinkText>
                </Grid>
            )}
        </Grid>
    );
};

export default OrderSummary;
