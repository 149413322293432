import { ACTIVITY_LOG_FIELDS } from '@/graphql/fragments/order_activity_logs';
import { gql } from '@apollo/client';

export const QUERY_ACTIVITY_LOG = gql`
    ${ACTIVITY_LOG_FIELDS}
    query ActivityLog($condition: order_activity_logs_bool_exp!) {
        order_activity_logs(where: $condition, order_by: { timestamp: asc }) {
            ...ActivityLogFields
        }
    }
`;
