import { useClientUser } from '@/hooks';
import { asUTCDate } from '@/utilities/convertToISO';
import { useQuery } from '@apollo/client';
import { captureException } from '@sentry/react';
import { addDays } from 'date-fns';
import { createContext, useMemo, useState } from 'react';
import { UNASSIGNED_TABS } from './constants';
import { UNASSIGNED_ORDERS } from './graphql/queries';
import { useCallbacks } from './hooks';

export const Context = createContext();

export const ContextProvider = ({ children }) => {
    const { user_id } = useClientUser();

    const [tab, setTab] = useState(UNASSIGNED_TABS[0]);
    const [modal, setModal] = useState(null);
    const [notification, setNotification] = useState({});
    const [selectedOrderIds, setSelectedOrderIds] = useState({});
    const [listings, setListings] = useState({});
    const [filters, setFilters] = useState({
        scheduledFrom: null,
        scheduledTo: null,
        createdFrom: null,
        createdTo: null,
        selectedZips: [],
        ownerType: 'All',
        warehouseStatus: 'Any',
        hasPrefDate: false,
        prefDateFrom: null,
        prefDateTo: null,
        search: '',
        warehouseFrom: null,
        warehouseTo: null,
        serviceType: null,
        locationType: null,
    });
    const [editingOrder, editOrder] = useState(null);

    const SEARCHABLE = [
        'order_number',
        'po_number',
        'dropoff_name',
        'dropoff_state',
        'dropoff_city',
        'dropoff_zip',
        'pickup_state',
        'pickup_city',
        'pickup_zip',
        'reference_id',
    ];

    const where = useMemo(() => {
        const query = [...tab.filters];

        if (filters.serviceType) {
            query.push({ service_level_id: { _eq: filters.serviceType } });
        }

        if (filters.locationType) {
            query.push({
                _or: [
                    {
                        _and: [
                            { order_type: { _neq: 'return' } },
                            { dropoff_location_type: { _eq: filters.locationType } },
                        ],
                    },
                    {
                        _and: [
                            { order_type: { _eq: 'return' } },
                            { pickup_location_type: { _eq: filters.locationType } },
                        ],
                    },
                ],
            });
        }

        if (filters.scheduledFrom) {
            const cutoff = asUTCDate(filters.scheduledFrom);
            query.push({ delivery_date: { _gte: cutoff.toISOString() } });
        }
        if (filters.scheduledTo) {
            const cutoff = addDays(asUTCDate(filters.scheduledTo), 1);
            query.push({ delivery_date: { _lt: cutoff.toISOString() } });
        }

        if (filters.createdFrom) {
            const cutoff = asUTCDate(filters.createdFrom);
            query.push({ created_at: { _gte: cutoff.toISOString() } });
        }
        if (filters.createdTo) {
            const cutoff = addDays(asUTCDate(filters.createdTo), 1);
            query.push({ created_at: { _lt: cutoff.toISOString() } });
        }

        if (filters.prefDateFrom) {
            const cutoff = asUTCDate(filters.prefDateFrom);
            query.push({ preferred_delivery_date: { _gte: cutoff.toISOString() } });
        }
        if (filters.prefDateTo) {
            const cutoff = addDays(asUTCDate(filters.prefDateTo), 1);
            query.push({ preferred_delivery_date: { _lt: cutoff.toISOString() } });
        }

        if (filters.selectedZips?.length > 0) {
            query.push({
                _or: [{ dropoff_zip: { _in: filters.selectedZips } }, { pickup_zip: { _in: filters.selectedZips } }],
            });
        }

        if (filters.ownerType === 'Internal') {
            query.push({ shipper_id: { _eq: user_id } });
        }

        if (filters.ownerType === 'Onward') {
            query.push({ shipper_id: { _neq: user_id } });
        }

        if (filters.warehouseStatus === 'Received') {
            query.push({ wh_events: { status: { _eq: 'RECEIVED' } } });
        }

        if (filters.warehouseStatus === 'Not Received') {
            query.push({ wh_events: { status: { _eq: 'NOT_DELIVERED' } } });
        }

        if (filters.warehouseFrom) {
            const cutoff = asUTCDate(filters.warehouseFrom);
            query.push({ wh_events: { received_date: { _gte: cutoff.toISOString() } } });
        }
        if (filters.warehouseTo) {
            const cutoff = addDays(asUTCDate(filters.warehouseTo), 1);
            query.push({ wh_events: { received_date: { _lt: cutoff.toISOString() } } });
        }

        if (filters.hasPrefDate) {
            query.push({ preferred_delivery_date: { _is_null: false } });
        }

        if (filters.search) {
            query.push({
                _or: SEARCHABLE.map((field) => ({ [field]: { _ilike: `%${filters.search}%` } })),
            });
        }

        return { _and: query };
    }, [tab, filters]);

    const { data, loading, refetch } = useQuery(UNASSIGNED_ORDERS, {
        variables: {
            user_id,
            filters: where,
        },
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            setSelectedOrderIds({});
        },
        onError: (err) => {
            captureException(err);
        },
    });

    const orders = useMemo(() => {
        return [
            ...(data?.pickups || []).map((order) => ({ ...order, crossdock_leg: 'pickup' })),
            ...(data?.dropoffs || []).map((order) => ({
                ...order,
                ...(['CD_PENDING_PO', 'ROUTED_PU', 'CD_RECEIVED_SKIP_DO'].includes(order.event_state)
                    ? { crossdock_leg: 'dropoff' }
                    : {}),
            })),
        ];
    }, [data]);

    const selectedOrders = useMemo(() => {
        return orders.filter((order) => {
            const rowId = `${order.order_id}_${order.crossdock_leg}`;
            return selectedOrderIds[rowId];
        });
    }, [orders, selectedOrderIds]);

    const state = {
        tab,
        loading,
        orders,
        selectedOrders,
        selectedOrderIds,
        filters,
        editingOrder,
        notification,
        modal,
        listings,
    };

    const setters = {
        setTab,
        setSelectedOrderIds,
        setFilters,
        editOrder,
        setNotification,
        setModal,
        setListings,
    };

    const callbacks = useCallbacks(state, setters);

    return (
        <Context.Provider
            value={{
                state,
                callbacks: {
                    ...callbacks,
                    ...setters,
                    refetch,
                },
            }}
        >
            {children}
        </Context.Provider>
    );
};
