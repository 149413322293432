import useShippersAndCarriers from '@/hooks/useShippersAndCarriers';
import { OnwardSwitch, PrimaryButton, Row, SecondaryButton, SectionSubtitle } from '@/styles/blocks';
import { Body1, Body2, OnwardToggle, RadioLabelInfo, TabRow, TabSection, TabTitle, TextField } from '../../../blocks';
import { useClientUser } from '@/hooks';
import { css } from '@emotion/react';
import { Grid, Tooltip } from '@material-ui/core';
import React, { useContext, useMemo, useState, useEffect, forwardRef } from 'react';
import { ModalContext } from '..';
import { COMPLETE_STATUSES } from '../../../constants/statusOptions';
import {
    ClientSelect,
    DateInput,
    InfoInput,
    StatusSelect,
    TimeSelect,
    SelectCarrierTable,
    OnwardPriceInput,
} from '../../InputFields';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { useSwappedAttributes } from '@/components/ShipmentForm/hooks';
import { Alert } from '@material-ui/lab';
import { post } from '@/utilities/onwardClient';
import { CUSTOMER_REQUEST_PAYMENT, NOTIFY_CARRIERS_OF_LTL_ORDER } from '@/constants/apiRoutes';
import { withStyles } from '@material-ui/core/styles';
import useTrackedComms from '@/hooks/useTrackedComms';
import Snackbar from '@/components/Snackbar';
import { SOURCE_MAPPINGS } from '../../../constants/admin';
import { isFinite } from 'lodash';
import { AIRPORT_CODES_BY_ZIP } from '@/constants/airportCodes';
import AdminNotes from './AdminNotes';
import OrderDetailsPricing from '../OrderDetailsTab/OrderDetailsPricing';

const CustomTooltip = withStyles(() => ({
    tooltip: {
        fontSize: 14,
    },
}))(Tooltip);

const numberValOrDefault = (numberVal, defaultVal) => {
    if (numberVal || numberVal === 0) {
        return numberVal;
    }
    return defaultVal;
};

const black = css`
    color: black;
`;

const AdminTab = forwardRef(({ hasError, opt }, ref) => {
    const { enableAdminTestAccounts } = opt;
    const { state: modalState, callbacks } = useContext(ModalContext);
    const { order } = modalState;
    const [lockedClients, setLockedClients] = useState([]);
    const [saving, setSaving] = useState(false);
    const [notifyCarriersMessage, setNotifyCarriersMessage] = useState('');
    const [customerLoading, setCustomerLoading] = useState(false);
    const [selectedCarriers, setSelectedCarriers] = useState([]);
    const [successMsg, setSuccessMsg] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [collapsed, setCollapsed] = useState(false);

    const { zip } = useSwappedAttributes(order);

    const [shippers, _carriers] = useShippersAndCarriers(enableAdminTestAccounts);

    const orderAirportCodes = useMemo(() => {
        return {
            originCode: AIRPORT_CODES_BY_ZIP[order?.pickup_zip] || null,
            destinationCode: AIRPORT_CODES_BY_ZIP[order?.dropoff_zip] || null,
        };
    }, [order]);

    const carriers = useMemo(() => {
        return _carriers
            .map((carrier) => {
                const airportCodes = [];
                carrier.locations?.forEach((location) => {
                    const airportCode = AIRPORT_CODES_BY_ZIP[location.business_zip];
                    if (airportCode && !airportCodes.includes(airportCode)) {
                        if (airportCode === orderAirportCodes.destinationCode) {
                            airportCodes.unshift(airportCode);
                        } else {
                            airportCodes.push(airportCode);
                        }
                    }
                });
                return {
                    ...carrier,
                    airportCodes,
                };
            })
            .sort((a, b) => {
                const aHasDestCode = a.airportCodes.includes(orderAirportCodes.destinationCode);
                const bHasDestCode = b.airportCodes.includes(orderAirportCodes.destinationCode);
                if (aHasDestCode && !bHasDestCode) return -1;
                if (!aHasDestCode && bHasDestCode) return 1;
                return 0;
            });
    }, [_carriers, orderAirportCodes.destinationCode]);

    const margin = useMemo(() => {
        return (100 * (order.shipper_rate - order.carrier_rate)) / order.shipper_rate;
    }, [order]);

    const orderTZ = useMemo(() => {
        return order[zip] ? zipcode_to_timezone.lookup(order[zip]) : 'America/New_York';
    }, [order, zip]);

    const handleNotifyCarriers = async () => {
        setSaving(true);
        setNotifyCarriersMessage('');
        try {
            const carriersToNotify = selectedCarriers.filter(
                (carrierId) => !lockedClients.some((locked) => locked.client_id === carrierId)
            );

            const response = await post(NOTIFY_CARRIERS_OF_LTL_ORDER, {
                carriers: carriersToNotify.map((carrierId) => {
                    const carrier = carriers.find((c) => c.client_id === carrierId);
                    return {
                        business_name: carrier.business_name,
                        email: carrier.email,
                        contract_rates: !!carrier?.user?.circles?.['hide-market-rates'],
                        client_id: carrier.client_id,
                    };
                }),
                order,
            });

            setLockedClients([...lockedClients, ...(response?.data?.successes || [])]);

            if (response?.data?.failures.length) {
                setNotifyCarriersMessage(
                    `Partial success: There was an error attempting to notify the following carriers: ${response?.data?.failures
                        .map((carrier) => carrier.business_name)
                        .join(', ')}`
                );
            } else {
                setNotifyCarriersMessage('All carriers were successfully notified.');
            }
        } catch (e) {
            setNotifyCarriersMessage('There was an error attempting to notify carriers.');
            console.error(e);
        } finally {
            setSelectedCarriers([]);
            setSaving(false);
        }
    };

    const isMarketplaceOrder = useMemo(() => {
        if (!order) return false;
        return (
            ['pending', 'open'].includes(order?.order_status) &&
            !order?.oms &&
            !order.routes?.length &&
            !order.carrier_id
        );
    }, [order]);

    const { loading: trackedCommsLoading, tracked_communications } = useTrackedComms(
        order.order_id,
        !isMarketplaceOrder
    );

    const disableNotifyCarriersButton = useMemo(() => {
        if (saving) return { disabled: true, reason: 'Notifying carriers already currently in progress.' };
        if (order.order_status !== 'open')
            return { disabled: true, reason: `Cannot notify carriers unless order is in 'Open' order status` };
        if (!selectedCarriers.length)
            return { disabled: true, reason: 'No new carriers have been selected for notifying.' };
        if (!isMarketplaceOrder) return { disabled: true, reason: 'Not a marketplace order.' };
        if (trackedCommsLoading) return { disabled: true, reason: 'Loading...' };
        return false;
    }, [saving, order, selectedCarriers, isMarketplaceOrder]);

    const pricingOverride = useMemo(() => {
        const override = order.oms ? order.onward_rate_override : order.shipper_rate_override;

        return override || {};
    }, [order]);

    const syncDateAndTimeframes = (newDate, oldWindowStart, oldWindowEnd) => {
        const newDateObj = new Date(newDate);
        const newWindowStart = new Date(oldWindowStart);
        newWindowStart.setDate(newDateObj.getUTCDate());
        newWindowStart.setMonth(newDateObj.getUTCMonth());
        newWindowStart.setFullYear(newDateObj.getFullYear());

        const newWindowEnd = new Date(oldWindowEnd);
        newWindowEnd.setDate(newDateObj.getUTCDate());
        newWindowEnd.setMonth(newDateObj.getUTCMonth());
        newWindowEnd.setFullYear(newDateObj.getUTCFullYear());

        return { newStartIso: newWindowStart.toISOString(), newEndIso: newWindowEnd.toISOString() };
    };
    let cantCopy = order.order_status !== 'open' || !!order?.carrier_id || !!order?.routes?.length || !!order?.oms;

    const [shipperOverrideBase, carrierOverrideBase] = useMemo(() => {
        return [
            order.admin_shipper_rate_override ?? order.listing?.final_accepted_shipper_rate,
            order.admin_carrier_rate_override ?? order.listing?.final_accepted_carrier_rate,
        ];
    }, [order]);

    return (
        <>
            <TabTitle
                ref={ref}
                collapsed={collapsed}
                callbacks={{ setCollapsed }}
                css={css`
                    align-items: center;
                    justify-content: space-between;
                    ${collapsed ? '' : 'margin-bottom: 20px;'}
                `}
            >
                Admin
            </TabTitle>

            <TabRow>
                <Grid item xs={6}>
                    <StatusSelect
                        routed={Boolean(order.routes?.length)}
                        value={order.order_status}
                        disabled={['on_hold_awaiting_payment'].includes(order.order_status)}
                        onChange={(status) =>
                            callbacks.modifyOrder({
                                order_status: status,
                                ...(COMPLETE_STATUSES.includes(status) &&
                                !COMPLETE_STATUSES.includes(order.order_status)
                                    ? {
                                          completion_time: new Date().toISOString(),
                                          completion_source: 'WEB_ONWARD_ADMIN',
                                      }
                                    : {}),
                            })
                        }
                    />
                </Grid>
                <Grid item xs={6}>
                    <OnwardSwitch
                        label={'Marketplace Order'}
                        checked={!order.oms}
                        onChange={() => callbacks.modifyOrder({ oms: !order.oms })}
                        disabled={order.routes?.length}
                    />
                </Grid>
            </TabRow>

            <TabRow>
                <Grid item xs={6}>
                    <ClientSelect
                        value={order.shipper_id}
                        options={shippers}
                        onChange={(shipperId) => callbacks.modifyOrder({ shipper_id: shipperId })}
                        allowEmpty={false}
                        type="shipper"
                        css={css`
                            margin-right: 0.5rem;
                        `}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        label={'Carrier'}
                        value={
                            carriers?.find((carrier) => carrier.client_id === order?.carrier_id)?.business_name ||
                            'None'
                        }
                        disabled
                    />
                </Grid>
            </TabRow>
            <TabRow>
                <Grid item xs={6}>
                    <TextField
                        variant="outlined"
                        label={'Origin Airport Code'}
                        value={orderAirportCodes.originCode || 'N/A'}
                        disabled
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        variant="outlined"
                        label={'Destination Airport Code'}
                        value={orderAirportCodes.destinationCode || 'N/A'}
                        disabled
                        fullWidth
                    />
                </Grid>
            </TabRow>

            <SelectCarrierTable
                carriers={carriers}
                selectedCarriers={selectedCarriers}
                setSelectedCarriers={setSelectedCarriers}
            />

            <TabRow>
                <Grid item xs={3}>
                    <CustomTooltip title='Changes not final until you click "Done".'>
                        <span>
                            <PrimaryButton
                                disabled={selectedCarriers.length !== 1 || !!order.oms || !!pricingOverride.assign}
                                onClick={() =>
                                    callbacks.modifyOrder({
                                        carrier_id: selectedCarriers[0],
                                        is_middle_mile: false,
                                        ...(order.order_status === 'open' ? { order_status: 'claimed' } : {}),
                                        last_modified_source: 'ADMIN',
                                    })
                                }
                            >
                                Assign Selected Carrier
                            </PrimaryButton>
                        </span>
                    </CustomTooltip>
                </Grid>
                <Grid item xs={6}>
                    <CustomTooltip title="Email will be sent to new carrier once you click Done.">
                        <span>
                            <OnwardToggle
                                css={css`
                                    margin: 0;
                                `}
                                value={modalState.sendCarrierAssignedEmail}
                                disabled={selectedCarriers.length !== 1}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        modalState.setSendCarrierAssignedEmail(true);
                                    } else {
                                        modalState.setSendCarrierAssignedEmail(false);
                                    }
                                }}
                            />
                            <RadioLabelInfo
                                css={css`
                                    color: #000;
                                    padding-top: 3px;
                                    margin-left: 3px;
                                `}
                            >
                                Email/Notify Carrier of Assignment
                            </RadioLabelInfo>
                        </span>
                    </CustomTooltip>
                </Grid>
                <Grid item xs={3}>
                    {disableNotifyCarriersButton ? (
                        <CustomTooltip title={disableNotifyCarriersButton.reason}>
                            <span>
                                <PrimaryButton onClick={handleNotifyCarriers} disabled={true}>
                                    Notify Carriers
                                </PrimaryButton>
                            </span>
                        </CustomTooltip>
                    ) : (
                        <PrimaryButton onClick={handleNotifyCarriers}>Notify Carriers</PrimaryButton>
                    )}
                </Grid>
            </TabRow>

            {notifyCarriersMessage && (
                <TabRow>
                    <Grid item>
                        <span>{notifyCarriersMessage}</span>
                    </Grid>
                </TabRow>
            )}

            <TabSection>Admin - Rates</TabSection>

            <TabRow>
                <Grid item xs={4}>
                    <OnwardPriceInput
                        fullWidth
                        variant="outlined"
                        value={shipperOverrideBase}
                        label="Shipper Base Override"
                        onChange={(_, shipperRate) =>
                            callbacks.modifyOrder({
                                admin_shipper_rate_override: shipperRate,
                                order_revenue_adjustments: {
                                    ...(order.order_revenue_adjustments || {}),
                                    shipper: order.order_revenue_adjustments?.shipper || [],
                                },
                            })
                        }
                    />
                </Grid>
                <Grid item xs={4}>
                    <OnwardPriceInput
                        fullWidth
                        variant="outlined"
                        value={carrierOverrideBase}
                        label="Carrier Base Override"
                        onChange={(_, carrierRate) =>
                            callbacks.modifyOrder({
                                admin_carrier_rate_override: carrierRate,
                                order_revenue_adjustments: {
                                    ...(order.order_revenue_adjustments || {}),
                                    carrier: order.order_revenue_adjustments?.carrier || [],
                                },
                            })
                        }
                    />
                </Grid>
                <Grid item xs={4}>
                    <SecondaryButton
                        onClick={() =>
                            callbacks.modifyOrder({
                                admin_carrier_rate_override: null,
                                admin_shipper_rate_override: null,
                                order_revenue_adjustments: {
                                    ...(order.order_revenue_adjustments || {}),
                                    shipper: null,
                                    carrier: null,
                                },
                            })
                        }
                        fullWidth
                    >
                        Reset Overrides
                    </SecondaryButton>
                </Grid>
            </TabRow>

            <TabRow>
                <Grid item xs={4}>
                    <InfoInput
                        value={order.shipper_rate ? order.shipper_rate.toFixed(2) : 'N/A'}
                        label="Shipper Total"
                    />
                </Grid>
                <Grid item xs={4}>
                    <InfoInput
                        value={order.carrier_rate ? order.carrier_rate.toFixed(2) : 'N/A'}
                        label="Carrier Total"
                    />
                </Grid>
                <Grid item xs={4}>
                    <InfoInput value={isFinite(margin) ? `${margin} %` : 'N/A'} label="Margin" />
                </Grid>
            </TabRow>

            <OrderDetailsPricing perspective="shipper" />
            <OrderDetailsPricing perspective="carrier" />

            {order.source_form === 'CUSTOMER' && (
                <TabRow>
                    <Grid item xs={6}>
                        <InfoInput
                            value={order.customer_rate ? order.customer_rate.toFixed(2) : 'N/A'}
                            label="Customer Authorized Amount"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <PrimaryButton
                            disabled={customerLoading}
                            onClick={() => {
                                setCustomerLoading(true);
                                post(CUSTOMER_REQUEST_PAYMENT, { order_id: order.order_id })
                                    .then((result) => {
                                        // todo: close edit modal / refetch ?
                                        setSuccessMsg(`${result?.data?.message || ''} Order placed on hold.`);
                                    })
                                    .catch((error) => {
                                        setErrorMsg(
                                            error?.response?.data?.error || 'Error notifying customer of new price.'
                                        );
                                    })
                                    .finally(() => {
                                        setCustomerLoading(false);
                                    });
                            }}
                        >
                            Request Customer Updated Payment
                        </PrimaryButton>
                    </Grid>
                </TabRow>
            )}

            <TabRow>
                <Grid item xs={6}>
                    <DateInput
                        value={order.pickup_date}
                        onChange={(newPickupDate) => {
                            if (!newPickupDate) {
                                callbacks.modifyOrder({
                                    pickup_date: null,
                                    pickup_window_start: null,
                                    pickup_window_end: null,
                                });
                            } else if (order.pickup_window_start && order.pickup_window_end) {
                                const { newStartIso, newEndIso } = syncDateAndTimeframes(
                                    newPickupDate,
                                    order.pickup_window_start,
                                    order.pickup_window_end
                                );

                                callbacks.modifyOrder({
                                    pickup_date: newPickupDate,
                                    pickup_window_start: newStartIso,
                                    pickup_window_end: newEndIso,
                                });
                            } else {
                                callbacks.modifyOrder({
                                    pickup_date: newPickupDate,
                                });
                            }
                        }}
                        label="Pickup Date"
                        tz={orderTZ}
                        firstAvailable={order.first_available_date}
                        disabled={order.routes?.length}
                        error={hasError.pickup_date}
                    />
                </Grid>
                <Grid item xs={6}>
                    <DateInput
                        value={order.delivery_date}
                        onChange={(newDeliveryDate) => {
                            if (!newDeliveryDate) {
                                callbacks.modifyOrder({
                                    delivery_date: null,
                                    del_window_start: null,
                                    del_window_end: null,
                                });
                            } else if (order.del_window_start && order.del_window_end) {
                                const { newStartIso, newEndIso } = syncDateAndTimeframes(
                                    newDeliveryDate,
                                    order.del_window_start,
                                    order.del_window_end
                                );

                                callbacks.modifyOrder({
                                    delivery_date: newDeliveryDate,
                                    del_window_start: newStartIso,
                                    del_window_end: newEndIso,
                                });
                            } else {
                                callbacks.modifyOrder({
                                    delivery_date: newDeliveryDate,
                                });
                            }
                        }}
                        label="Delivery Date"
                        tz={orderTZ}
                        firstAvailable={order.first_available_date}
                        disabled={order.routes?.length}
                        error={hasError.delivery_date}
                    />
                </Grid>
            </TabRow>

            <TabRow>
                <Grid item xs={3}>
                    <TimeSelect
                        value={order.pickup_window_start}
                        label="Pickup Start"
                        tz={orderTZ}
                        date={order.pickup_date}
                        onChange={(val) => callbacks.modifyOrder({ pickup_window_start: val })}
                        disabled={order.routes?.length || !order.pickup_date}
                        error={hasError.pickup_window}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TimeSelect
                        value={order.pickup_window_end}
                        label="Pickup End"
                        tz={orderTZ}
                        date={order.pickup_date}
                        onChange={(val) => callbacks.modifyOrder({ pickup_window_end: val })}
                        disabled={order.routes?.length || !order.pickup_date}
                        error={hasError.pickup_window}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TimeSelect
                        value={order.del_window_start}
                        label="Delivery Start"
                        tz={orderTZ}
                        date={order.delivery_date}
                        onChange={(val) => callbacks.modifyOrder({ del_window_start: val })}
                        disabled={order.routes?.length || !order.delivery_date}
                        error={hasError.delivery_window}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TimeSelect
                        value={order.del_window_end}
                        label="Delivery End"
                        tz={orderTZ}
                        date={order.delivery_date}
                        onChange={(val) => callbacks.modifyOrder({ del_window_end: val })}
                        disabled={order.routes?.length || !order.delivery_date}
                        error={hasError.delivery_window}
                    />
                </Grid>
            </TabRow>

            <TabRow>
                <AdminNotes />
            </TabRow>

            <TabRow>
                <Grid item>
                    <Body2 css={black}>Source: {SOURCE_MAPPINGS[order.source_form] || 'Unknown'}</Body2>
                </Grid>
            </TabRow>
            {order.source_form === 'CUSTOMER' && order.payment_id ? (
                <TabRow>
                    <Grid item>
                        <Body2 css={black}>Stripe PI: {order.payment_id}</Body2>
                    </Grid>
                </TabRow>
            ) : null}

            <Snackbar
                severity="success"
                open={!!successMsg}
                message={successMsg}
                autohide={2000}
                handleClose={() => setSuccessMsg('')}
                disableClose={true}
            />
            <Snackbar
                severity="error"
                open={!!errorMsg}
                message={errorMsg}
                autohide={2000}
                handleClose={() => setErrorMsg('')}
                disableClose={true}
            />
        </>
    );
});

export default AdminTab;
