import { gql } from '@apollo/client';

export const ACTIVITY_LOG_FIELDS = gql`
    fragment ActivityLogFields on order_activity_logs {
        entity_id
        entity_type
        log_detail
        log_id
        log_message
        order_id
        private_admin
        private_client_id
        timestamp
        source_user_id
        user {
            user_id
            username
        }
    }
`;
