import React, { useContext, useMemo, useState } from 'react';
import { Grid } from '@material-ui/core';
import { css } from '@emotion/react';
import { OnwardCard } from '../../OrderDetailsPage/Card';
import { CardTitle, CardItem, CardSubtitle, CardItemBold } from '../../OrderDetailsPage/blocks';
import { Context } from '../store';
import SurveyCard from '@/components/OrderDetailsPage/SurveyCard';

export default function SurveyInfo() {
    const { state, callbacks } = useContext(Context);
    const order = state?.updated;

    const surveyResponses = useMemo(() => {
        const responsesByType = (order?.survey_responses || []).reduce((acc, response) => {
            const type = response.survey_question.survey_type;
            return {
                ...acc,
                [type]: [...(acc[type] || []), response],
            };
        }, {});
        return {
            PREDELIVERY: [...(responsesByType.PREDELIVERY || []), ...(responsesByType.PREDELIVERY_DEFAULTS || [])],
            DRIVER: [...(responsesByType.DRIVER || []), ...(responsesByType.DRIVER_DEFAULTS || [])],
            POSTDELIVERY: [...(responsesByType.POSTDELIVERY || []), ...(responsesByType.POSTDELIVERY_DEFAULTS || [])],
        };
    }, [order]);

    const CardRow = ({ children, ...rest }) => {
        return (
            <Grid
                container
                item
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                style={{ marginTop: '0.25rem', marginBottom: '0.25rem' }}
                {...rest}
            >
                {children}
            </Grid>
        );
    };

    if (Object.values(surveyResponses)?.length === 0 || Object.values(surveyResponses).every(i => i?.length === 0)) {
        return (<>
            <OnwardCard>
                <CardRow>
                    <Grid item>
                        <CardItem>
                            No survey questions.
                        </CardItem>
                    </Grid>
                </CardRow>
            </OnwardCard>
        </>)
    }

    return (
        <>
            {Object.entries(surveyResponses).map(([surveyType, responses]) => (
                <Grid
                    key={surveyType}
                    item
                    css={css`
                        margin-top: 16px;
                    `}
                >
                    <SurveyCard surveyType={surveyType} responses={responses} />
                </Grid>
            ))}
        </>
    );
}
