import { gql } from '@apollo/client';
import { EXCEPTION_MAPPINGS_FIELD } from '@/graphql/fragments/exception_mappings';
import { WAREHOUSE_EVENT_FIELDS } from '@/graphql/queries/order_wh_events';
import { EXCEPTION_FIELDS } from '@/graphql/queries/db_exceptions';
import { ITEM_FIELDS } from '@/graphql/queries/items';
import { ORDER_FIELDS } from '../../../graphql/queries/orders';
import { LOCATION_FIELDS } from '@/graphql/queries/locations';
import { DRIVER_FIELDS } from '@/graphql/fragments/drivers';

export const UPSERT_EVENT = gql`
    ${WAREHOUSE_EVENT_FIELDS}
    ${ORDER_FIELDS}
    ${LOCATION_FIELDS}
    ${EXCEPTION_FIELDS}
    ${EXCEPTION_MAPPINGS_FIELD}
    ${ITEM_FIELDS}
    ${DRIVER_FIELDS}

    mutation UpsertEvent(
        $event: order_wh_events_insert_input!
        $order: orders_insert_input!
        $update_cols: [order_wh_events_update_column!]! = []
        $order_update_cols: [orders_update_column!]! = []
    ) {
        event: insert_order_wh_events_one(
            object: $event
            on_conflict: { constraint: order_wh_events_pkey, update_columns: $update_cols }
        ) {
            ...WarehouseEvents
            location {
                ...LocationFields
            }
            driver {
                ...DriverFields
            }
            wh_driver {
                ...DriverFields
            }
            exceptions {
                ...ExceptionMappingsFields

                exception {
                    ...ExceptionFields
                }
            }
        }

        order: insert_orders_one(
            object: $order
            on_conflict: { constraint: orders_pkey, update_columns: $order_update_cols }
        ) {
            ...OrderFields

            itemsByOrderId {
                ...ItemFields
            }
        }
    }
`;

export const INSERT_EXCEPTION_EVENT = gql`
    ${WAREHOUSE_EVENT_FIELDS}
    ${ORDER_FIELDS}
    ${LOCATION_FIELDS}
    ${EXCEPTION_FIELDS}
    ${EXCEPTION_MAPPINGS_FIELD}
    ${ITEM_FIELDS}
    ${DRIVER_FIELDS}

    mutation InsertExceptionEvent(
        $exception_event: insert_exception_event_args!
        $order: orders_insert_input! = {}
        $order_update_cols: [orders_update_column!]! = []
    ) {
        mappings: insert_exception_event(args: $exception_event) {
            ...ExceptionMappingsFields
        }

        order: insert_orders_one(
            object: $order
            on_conflict: { constraint: orders_pkey, update_columns: $order_update_cols }
        ) {
            ...OrderFields

            itemsByOrderId {
                ...ItemFields
            }

            wh_events(order_by: [{ created_at: asc }, { transition: { current: asc } }]) {
                ...WarehouseEvents

                location {
                    ...LocationFields
                }

                driver {
                    ...DriverFields
                }

                wh_driver {
                    ...DriverFields
                }
            }
        }
    }
`;

export const INSERT_EXCEPTION = gql`
    ${EXCEPTION_MAPPINGS_FIELD}
    ${EXCEPTION_FIELDS}
    mutation InsertItemExceptions($exceptions: [exception_mappings_insert_input!]!) {
        created: insert_exception_mappings(objects: $exceptions) {
            returning {
                ...ExceptionMappingsFields
                exception {
                    ...ExceptionFields
                }
            }
        }
    }
`;

export const UPDATE_EXCEPTION = gql`
    ${EXCEPTION_MAPPINGS_FIELD}
    ${EXCEPTION_FIELDS}
    mutation UpdateItemExceptions($exception_id: uuid!, $exception: exceptions_set_input) {
        updated: update_exceptions(where: { exception_id: { _eq: $exception_id } }, _set: $exception) {
            returning {
                ...ExceptionFields
            }
        }
    }
`;
