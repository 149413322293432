import React, { useContext, useMemo } from 'react';
import styled from '@emotion/styled';
import { Grid, Checkbox, FormControlLabel } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { ROUTE_COLORS } from '../constants';
import { useTotalWeight, useTotalCubes, useItemQuantity, useClientUser } from '@/hooks';
import { poundFormatter, integerFormatter } from '@/constants/formats';
import { PlanningContext } from '../context';
import { Body1, Body2, GridItemRow as BaseRow, PlanningListItem } from '../blocks';
import { css } from '@emotion/react';
import { LOCATION_TYPES } from '@/constants/locationTypes';
import { colors } from '@/styles';
import { genAttributes } from '@onward-delivery/core';
import { startCase } from 'lodash';
import { toNational } from '@/utilities/formatPhoneNumber';

const GridItemRow = styled(BaseRow)`
    white-space: nowrap;
    flex-direction: row;
    flex: 0;
    flex-basis: 0;
    margin: 0;
`;

const OrderListItem = ({ order, routes, style }) => {
    const { selectedOrders, toggleSelectOrder, setActionableOrder, setModalOpen } = useContext(PlanningContext);
    const { user_id, userType } = useClientUser();

    const itemQuantity = useItemQuantity([order]);
    const totalWeight = useTotalWeight([order]);
    const totalCubes = useTotalCubes([order]);
    const isClaimed = !order.oms && order?.carrier_id === user_id;

    const display = useMemo(() => {
        if (!order) {
            return {};
        }

        const { street, unit, city, state, zip, location_type, location_info } = genAttributes(order);
        const {
            street: pu_street,
            unit: pu_unit,
            city: pu_city,
            state: pu_state,
            zip: pu_zip,
            location_type: pu_location_type,
            location_info: pu_location_info,
        } = genAttributes(order, true);

        const store = {
            address1: [order[pu_street], order[pu_unit]].filter((x) => x).join(', '),
            address2: `${order[pu_city]}, ${order[pu_state]}  ${order[pu_zip]}`,
            type: order[pu_location_type] || order[pu_location_info],
        };
        const customer = {
            address1: [order[street], order[unit]].filter((x) => x).join(', '),
            address2: `${order[city]}, ${order[state]}  ${order[zip]}`,
            type: order[location_type] || order[location_info],
        };

        return {
            ...((order.crossdock_leg === 'pickup' && order.order_type !== 'return') ||
            (order.crossdock_leg === 'dropoff' && order.order_type === 'return')
                ? store
                : customer),
            customer: order.dropoff_name,
            orderType: startCase(order.order_type),
        };
    }, [order]);

    const formatPhone = (phone) => {
        try {
            return phone ? toNational(phone) : 'N/A';
        } catch (e) {
            return 'N/A';
        }
    };

    const handleOrderClick = (event) => {
        event.preventDefault(); // Prevent default behavior
        event.stopPropagation(); // Stop event propagation
        setModalOpen('ORDER_VIEW');
        setActionableOrder(order);
    };

    const handleCheckboxChange = (event) => {
        event.stopPropagation();
        toggleSelectOrder(`${order.order_id}_${order.crossdock_leg}`);
    };

    const handleItemClick = (event) => {
        // Check if the click is on the checkbox or the order number
        if (!event.target.closest('.MuiCheckbox-root') && !event.target.closest('.order-number-span')) {
            toggleSelectOrder(`${order.order_id}_${order.crossdock_leg}`);
        }
    };

    const orderRoute = useMemo(() => {
        return routes.find(
            (r) => r.route_id === (order.crossdock_leg === 'pickup' ? order.pickup_route_id : order.dropoff_route_id)
        );
    }, [order, routes]);

    const orderRouteColor = useMemo(() => {
        if (!orderRoute || !routes?.length) return 'black';
        return orderRoute.route_color || ROUTE_COLORS[routes.findIndex((r) => r.route_id === orderRoute.route_id)];
    }, [orderRoute, routes]);

    const isUnscheduled = useMemo(() => {
        return order.crossdock_leg === 'pickup' ? !order.pickup_date : !order.delivery_date;
    }, [order]);

    const Badge = ({ color, ...props }) => (
        <Body2
            css={css`
                color: white;
                background-color: ${color || colors.greens.primary};
                font-size: 14px;
                font-weight: 700;
                padding: 0 1rem;
                border-radius: 10px;
                margin-right: 1rem;
            `}
            {...props}
        />
    );

    return (
        <PlanningListItem style={style} hover="true" onClick={handleItemClick}>
            <Grid container>
                <FormControlLabel
                    css={css`
                        width: 100%;
                        margin-right: 0;
                        .MuiFormControlLabel-label {
                            flex-grow: 1;
                        }
                    `}
                    control={
                        <Checkbox
                            color="primary"
                            checked={!!selectedOrders[`${order.order_id}_${order.crossdock_leg}`]}
                            onChange={handleCheckboxChange}
                            onClick={(e) => e.stopPropagation()}
                        />
                    }
                    label={
                        <Grid
                            container
                            css={css`
                                flex-direction: column;
                            `}
                            onClick={(e) => e.stopPropagation()} // Prevent clicks on the label from triggering item selection
                        >
                            <GridItemRow
                                css={css`
                                    justify-content: flex-start;
                                `}
                            >
                                {isUnscheduled ? <Badge color={colors.reds.primary}>Unscheduled</Badge> : null}
                                {isClaimed ? <Badge>Marketplace</Badge> : null}
                            </GridItemRow>
                            <GridItemRow
                                css={css`
                                    flex: 1;
                                `}
                            >
                                <Body1>
                                    Order{' '}
                                    <span
                                        className="order-number-span"
                                        css={css`
                                            color: ${colors.greens.primary};
                                            text-decoration: underline;
                                            cursor: pointer;
                                        `}
                                        onClick={handleOrderClick}
                                    >
                                        {order.order_number}
                                    </span>
                                    {!!order.crossdock_leg && (
                                        <span
                                            css={css`
                                                font-style: italic;
                                                color: ${colors.greys.primary};
                                            `}
                                        >
                                            {order.crossdock_leg === 'pickup'
                                                ? ' (pickup -> cross-dock)'
                                                : ' (cross-dock -> delivery)'}
                                        </span>
                                    )}
                                </Body1>
                                {!!orderRoute && (
                                    <CheckCircleIcon
                                        fontSize="small"
                                        css={css`
                                            color: ${orderRouteColor};
                                        `}
                                    />
                                )}
                            </GridItemRow>
                            <GridItemRow>
                                <Body1>PO: {order.po_number}</Body1>
                            </GridItemRow>
                            {order.reference_id && (
                                <GridItemRow>
                                    <Body2> Reference: {order.reference_id}</Body2>
                                </GridItemRow>
                            )}
                            <GridItemRow>
                                <Body1
                                    css={css`
                                        white-space: pre-line;
                                        max-height: 42px;
                                    `}
                                >
                                    Consignee: {display.customer}
                                </Body1>
                            </GridItemRow>
                            <GridItemRow>
                                <Body2>{display.address1}</Body2>
                            </GridItemRow>
                            <GridItemRow>
                                <Body2
                                    css={css`
                                        white-space: pre-line;
                                        max-height: 42px;
                                    `}
                                >
                                    {display.address2}
                                </Body2>
                            </GridItemRow>
                            <GridItemRow>
                                <Body2>Phone: {formatPhone(order.dropoff_phone)}</Body2>
                            </GridItemRow>
                            <GridItemRow>
                                <Body2>
                                    {integerFormatter.format(itemQuantity)} Items /{' '}
                                    {integerFormatter.format(totalCubes)} cu ft / {poundFormatter.format(totalWeight)}
                                </Body2>
                            </GridItemRow>
                            <GridItemRow>
                                <Body2>Order Type: {display.orderType}</Body2>
                            </GridItemRow>
                            <GridItemRow>
                                <Body2>{`Service Type: ${
                                    display.type ? LOCATION_TYPES[display.type] || display.type : '--'
                                }`}</Body2>
                            </GridItemRow>
                            {order.notes &&
                                order.notes.length > 0 &&
                                order.notes.some((note) => note.type === 'Delivery') && (
                                    <GridItemRow
                                        css={css`
                                            max-width: 310px;
                                        `}
                                    >
                                        {order.notes.map(
                                            (note, index) =>
                                                note.type === 'Delivery' && (
                                                    <Body2
                                                        key={index}
                                                        css={css`
                                                            max-width: 310px;
                                                            white-space: normal;
                                                            overflow-wrap: break-word;
                                                            max-height: 42px;
                                                            overflow-y: hidden;
                                                        `}
                                                    >
                                                        Comments: {note.note}
                                                    </Body2>
                                                )
                                        )}
                                    </GridItemRow>
                                )}
                        </Grid>
                    }
                />
            </Grid>
        </PlanningListItem>
    );
};

export default OrderListItem;
