import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Grid } from '@material-ui/core';
import {
    useClientUser,
    useOrderNotes,
} from '@/hooks';
import { css } from '@emotion/react';
import { OnwardCard } from '../../OrderDetailsPage/Card';
import { CardTitle, CardItem, CardSubtitle, CardItemBold } from '../../OrderDetailsPage/blocks';
import { Context } from '../store';
import { startCase } from 'lodash';
import { Note } from '@/components/OrderDetailsPage/Notes/Notes';

export default function Notes() {
    const { state, callbacks } = useContext(Context);
    const order = state?.updated;

    const { accountType } = useClientUser();

    const orderNotes = useOrderNotes([order], accountType);

    const CardRow = ({ children, ...rest }) => {
        return (
            <Grid
                container
                item
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                style={{ marginTop: '0.25rem', marginBottom: '0.25rem' }}
                {...rest}
            >
                {children}
            </Grid>
        );
    };

    const accountTypeCapital = startCase(accountType);

    return (
        <>
            <OnwardCard>
                <CardRow>
                    <Grid item css={css`
                        margin-bottom: 1.75rem;
                    `}>
                        <CardTitle>
                            Notes
                        </CardTitle>
                    </Grid>
                </CardRow>

                {(orderNotes?.all || []).map((n, idx) => (
                    Note(n, orderNotes.all.length - 1 === idx, accountTypeCapital, true)
                ))}

            </OnwardCard>
        </>
    );
}
