import React, { useState, useEffect, useMemo, useRef } from 'react';
import GoogleMap, { DirectionsRenderer } from '@/components/GoogleMap';
import { LastUpdatedControl } from '../GoogleMap/controls/LastUpdatedControl';
import { useDriverLocation } from '@/hooks/useDriverLocation';
import FTLStopHelpers from '@/utilities/FTLStopHelpers';
import { DropOff, Truck, Home } from '@/components/DispatchPlan/map/MapMarkers';
import { colors } from '@/styles';
import { useStopsAway } from './hooks';

const DEFAULT_CENTER = { lat: 39.74, lng: -104.99 };
const DEFAULT_ZOOM = 17;
const TWO_HOURS = 7200000;

const TrackingMap = ({ order, route }) => {
    const mapRef = useRef();

    const stopsAway = useStopsAway(route, order);
    const [bounds, setBounds] = useState();
    const [directions, setDirections] = useState();
    const isIndividualLTL = !route;

    const customerStop = useMemo(() => {
        if (!route?.stopsByRouteId || !order) return null;

        return route?.stopsByRouteId.find((stop) => {
            return (
                (stop.type === 'PICKUP' && stop.returns?.includes(order.order_id)) ||
                (stop.type === 'DROPOFF' && stop.orders?.includes(order.order_id) && !stop?.returns?.length)
            );
        });
    }, [route, order]);

    const liveTracking = useMemo(() => {
        return (
            order.delivery_date &&
            customerStop &&
            !customerStop?.stop_completion_time &&
            order.del_window_start &&
            order.del_window_end &&
            Date.now() >= new Date(customerStop.del_window_start).getTime() - TWO_HOURS &&
            Date.now() <= new Date(customerStop.del_window_end).getTime()
        );
    }, [order, customerStop]);

    const { location } = useDriverLocation(route ? route?.route_id : order.order_id, {
        disabled: !liveTracking,
    });

    /*
     * Set map bounds to show both Truck and Customer
     * This is only done within 2 hrs of the delivery window
     */
    useEffect(() => {
        if (customerStop && liveTracking && location?.lat && location?.lng && !bounds) {
            setBounds([customerStop, location].map((x) => ({ lat: x.lat, lng: x.lng })));
        } else if (isIndividualLTL) {
            // Bounds for individual LTL orders
            setBounds([
                { lat: order.pickup_lat, lng: order.pickup_lng },
                { lat: order.dropoff_lat, lng: order.dropoff_lng },
            ]);
        }
    }, [customerStop, location, order]);

    /*
     * Get Directions from Truck to Customer
     * This is only shown when the customer is the next stop on the route
     */
    useEffect(() => {
        if (stopsAway === 0 && customerStop && liveTracking && location?.lat && location?.lng && !directions) {
            FTLStopHelpers.getCustomerDirections(location, customerStop).then((result) => {
                console.debug(result);
                if (result.status === 'OK') setDirections(result);
            });
        }
    }, [stopsAway, customerStop, location]);

    return (
        <>
            <GoogleMap
                ref={mapRef}
                bounds={bounds}
                center={
                    !bounds?.length
                        ? customerStop
                            ? { lat: customerStop.lat, lng: customerStop.lng }
                            : DEFAULT_CENTER
                        : undefined
                }
                zoom={!bounds?.length ? DEFAULT_ZOOM : undefined}
                mapTypeControl={false}
                fullscreenControl={false}
                zoomControl={false}
                streetViewControl={false}
            >
                {customerStop && (
                    <DropOff
                        position={{ lat: customerStop.lat, lng: customerStop.lng }}
                        color={customerStop.stop_completion_time ? colors.greens.primary : 'black'}
                    />
                )}
                {isIndividualLTL && order && (
                    <>
                        <Home position={{ lat: order.dropoff_lat, lng: order.dropoff_lng }} />
                        <DropOff position={{ lat: order.pickup_lat, lng: order.pickup_lng }} color={'black'} />
                    </>
                )}

                {directions && <DirectionsRenderer directions={directions} options={{ suppressMarkers: true }} />}
                {liveTracking && location?.lat && location?.lng && (
                    <Truck
                        key={'truck-marker'}
                        position={{ lat: location.lat, lng: location.lng }}
                        // css rotation direction is flipped, and truck icon without rotation is at 90 degrees
                        rotation={90 - location.direction}
                    />
                )}
            </GoogleMap>
            {liveTracking && (
                <LastUpdatedControl mapRef={mapRef} lastUpdated={location?.lastUpdated} onRefresh={() => {}} />
            )}
        </>
    );
};

export default React.memo(TrackingMap);
