import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { css } from '@emotion/react';
import { useLazyQuery, useMutation, useApolloClient } from '@apollo/client';
import { Grid } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { pdf, PDFViewer } from '@react-pdf/renderer';

import { colors } from '@/styles';
import { QUERY_BY_ROUTE as GET_RATING } from '@/graphql/queries/ratings';
import { SEND_TO_PLANNING_BY_ID as SEND_ROUTE_TO_PLANNING } from './graphql/mutations';
import {
    ROUTES_BY_CLIENT_ID as GET_ROUTES,
    ORDERS_BY_CLIENT_ID as GET_ORDERS,
    GET_LTL_ORDERS,
} from './graphql/queries';
import { QUERY_BY_LEADER as GET_TEAMMATES } from '@/graphql/queries/teammates';
import { OnwardTabContainer, OnwardTab } from '@/components/Tabs';
import generateCSV from '@/utilities/createCSV';
import Skeleton from '@material-ui/lab/Skeleton';
import { useFiltered, useDrivers, useGroupedRoutes, useGroupedOrders, useOrdersByKey } from './hooks';
import { H1, H5, fragments } from './blocks';
import { useColumns } from './constants/csv';
import { OWNERSHIP_TYPES, getOrderOwnership, getRouteOwnership } from './utils';
import OrderCard from './OrderCard';
import RouteCard from './RouteCard';
import RoutePdfExport from './RoutePdf';
import { useClientUser } from '@/hooks';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { capitalize } from 'lodash';

const DAY = 1000 * 60 * 60 * 24;

function Tracking() {
    const [tabIndex, setTabIndex] = useState(0);
    const client = useApolloClient();
    const location = useLocation();
    const [ltlOrders, setLtlOrders] = useState([]);
    const [pageLocation, setPageLocation] = useState(null);

    const TODAY = new Date().setHours(0, 0, 0, 0);
    const CUTOFF = new Date(TODAY - DAY * 7);
    const ROUTE_COLUMNS = useColumns();
    const { isOnwardAdmin, client_id, test_acc, accountType, userType } = useClientUser();

    const [fetchTeammates, { loading: loadingTeammates, error: teammatesError, data: teammates }] =
        useLazyQuery(GET_TEAMMATES);
    const [fetchRoutes, { loading: loadingRoutes, error: routesError, data: routes }] = useLazyQuery(GET_ROUTES, {
        fetchPolicy: 'cache-and-network',
        onCompleted: ({ results }) => {
            if (results.length > 0) {
                results.forEach((route) => {
                    const orders = (route.orders || []).map((mapping) => mapping.order);
                    client.writeQuery({
                        query: GET_RATING,
                        data: {
                            results: orders.reduce((acc, order) => {
                                return [...acc, ...order.ratings];
                            }, []),
                        },
                        variables: {
                            orders: orders.map((order) => order.order_id),
                        },
                    });
                });
            }
        },
    });
    const [fetchOrders, { loading: loadingOrders, error: ordersError, data: orders }] = useLazyQuery(GET_ORDERS, {
        fetchPolicy: 'cache-and-network',
    });
    const [fetchLtlOrders, { loading: loadingLtlOrders, error: ltlOrdersError, data: fetchedLtlOrders }] = useLazyQuery(
        GET_LTL_ORDERS,
        {
            fetchPolicy: 'cache-and-network',
        }
    );
    const [sendToPlanning] = useMutation(SEND_ROUTE_TO_PLANNING);

    useEffect(() => {
        if (isOnwardAdmin) {
            if (location.pathname.split('/')[location.pathname.split('/').length - 1] === 'FTL') {
                setPageLocation('admin-FTL');
                fetchRoutes({
                    variables: {
                        condition: [{}],
                        cutoff_ts: CUTOFF.toISOString(),
                        allow_test: test_acc ? {} : { test_acc: { _eq: false } },
                    },
                });
            } else if (location.pathname.split('/')[location.pathname.split('/').length - 1] === 'LTL') {
                setPageLocation('admin-LTL');
                fetchLtlOrders({
                    variables: {
                        start_ts: CUTOFF.toISOString(),
                        end_ts: new Date(TODAY + DAY * 7).toISOString(),
                    },
                    onCompleted: (data) => {
                        if (data?.results?.length) {
                            setLtlOrders(data.results.filter((o) => o?.shipper_id !== o?.carrier_id));
                        }
                    },
                });
            }
        } else {
            setPageLocation('client');
            fetchTeammates({
                variables: {
                    leader_id: client_id,
                },
            });

            fetchRoutes({
                variables: {
                    condition: [{ shipper_id: { _eq: client_id } }, { carrier_id: { _eq: client_id } }],
                    cutoff_ts: CUTOFF.toISOString(),
                    allow_test: test_acc ? {} : { test_acc: { _eq: false } },
                },
            });

            fetchOrders({
                variables: {
                    condition: [{ shipper_id: { _eq: client_id } }, { carrier_id: { _eq: client_id } }],
                    cutoff_ts: CUTOFF.toISOString(),
                    allow_test: test_acc ? {} : { test_acc: { _eq: false } },
                    user_id: client_id,
                },
            });
        }
    }, [location]);

    const drivers = useDrivers(teammates?.results);
    const ordersByKey = useOrdersByKey(
        routes?.results?.map((route) => (route.orders || []).map((mapping) => mapping.order))
    );
    const isLoading = loadingRoutes || loadingOrders || loadingLtlOrders;
    const orderTabFilter = useCallback(
        (order) => {
            let show = true;
            const type = getOrderOwnership({ order });

            switch (tabIndex) {
                case 1:
                    show = type === OWNERSHIP_TYPES.ONWARD || type === OWNERSHIP_TYPES.COMINGLED;
                    break;
                case 2:
                    show = type === OWNERSHIP_TYPES.INTERNAL;
                    break;
            }

            return show;
        },
        [tabIndex]
    );

    const routeTabFilter = useCallback(
        (route) => {
            let show = true;
            const type = getRouteOwnership({
                route,
                orders: (route.orders || []).map((mapping) => mapping.order),
                client_id,
            });

            switch (tabIndex) {
                case 1:
                    show = type === OWNERSHIP_TYPES.ONWARD || type === OWNERSHIP_TYPES.COMINGLED;
                    break;
                case 2:
                    show = type === OWNERSHIP_TYPES.INTERNAL;
                    break;
            }

            return show;
        },
        [tabIndex, client_id]
    );

    const tabRoutes = useFiltered(routes?.results, routeTabFilter);
    const tabOrders = useFiltered(ltlOrders.length ? ltlOrders : orders?.results, orderTabFilter);

    const [preTransitRoutes, inTransitRoutes, pendingFinalReturnsRoutes, completeRoutes] = useGroupedRoutes(
        tabRoutes,
        pageLocation
    );
    const [preTransitOrders, inTransitOrders, completeOrders] = useGroupedOrders(tabOrders, pageLocation);

    const preTransitCount = useMemo(() => {
        return (
            (preTransitRoutes.today?.length || 0) +
            (preTransitRoutes.tomorrow?.length || 0) +
            (preTransitRoutes.upcoming?.length || 0) +
            (preTransitOrders.today?.length || 0) +
            (preTransitOrders.tomorrow?.length || 0) +
            (preTransitOrders.upcoming?.length || 0)
        );
    }, [preTransitRoutes, preTransitOrders]);

    const handleTabChange = (event, newValue) => setTabIndex(newValue);

    const generateRoutePDF = (route) => {
        const driver =
            drivers.find((driver) => {
                return driver.teammate_id === route.driver_id;
            }) || {};
        const data = route.stopsByRouteId.map((stop) => {
            let orders = (stop?.orders || []).map((key) => {
                let order = { ...(ordersByKey[key] || {}) };
                let comments = [];
                if (stop.type === 'PICKUP') {
                    order.notes?.forEach((n) => {
                        if (n.private_to && n.private_to !== capitalize(userType)) {
                            return;
                        }
                        if (n.type !== 'Pickup') return;
                        comments.push(n.note);
                    });
                } else {
                    order.notes?.forEach((n) => {
                        if (n.private_to && n.private_to !== capitalize(userType)) {
                            return;
                        }
                        if (n.type !== 'Delivery') return;
                        comments.push(n.note);
                    });
                }
                order.comments = comments;
                return order;
            });

            const composite = {
                obj: stop,
                orders: orders,
            };

            const zip = route?.orders?.[0]?.order?.dropoff_zip;
            const tz = zipcode_to_timezone.lookup(zip) || Intl.DateTimeFormat().resolvedOptions().timeZone;

            return {
                route,
                stop: composite,
                driver,
                opt: {
                    tz,
                },
            };
        });

        const filename = `Route${route.route_number} ${new Date().getTime()}`;
        return pdf(<RoutePdfExport data={data} route={route} />)
            .toBlob()
            .then((blob) => {
                const autoclick = document.createElement('a');
                const payload = URL.createObjectURL(blob);
                autoclick.setAttribute('href', payload);
                autoclick.setAttribute('download', filename);
                autoclick.style.visibility = 'hidden';
                document.body.appendChild(autoclick);
                autoclick.click();
                document.body.removeChild(autoclick);
            });
    };

    const generateRouteCSV = (route) => {
        const driver =
            drivers.find((driver) => {
                return driver.teammate_id === route.driver_id;
            }) || {};
        const data = route.stopsByRouteId.map((stop) => {
            const composite = {
                obj: stop,
                orders: (stop?.orders || []).map((key) => ordersByKey[key] || {}),
            };

            const zip = route?.orders?.[0]?.order?.dropoff_zip;
            const tz = zipcode_to_timezone.lookup(zip) || Intl.DateTimeFormat().resolvedOptions().timeZone;

            return {
                route,
                stop: composite,
                driver,
                opt: {
                    tz,
                },
            };
        });

        const blob = generateCSV(ROUTE_COLUMNS, data);
        const filename = `Route${route.route_number} ${new Date().getTime()}`;

        const autoclick = document.createElement('a');
        const payload = URL.createObjectURL(blob);
        autoclick.setAttribute('href', payload);
        autoclick.setAttribute('download', filename);
        autoclick.style.visibility = 'hidden';
        document.body.appendChild(autoclick);
        autoclick.click();
        document.body.removeChild(autoclick);
    };

    return (
        <>
            <Grid container>
                {accountType === 'shipper' && (
                    <Grid
                        container
                        xs={12}
                        justifyContent="center"
                        css={css`
                            background: ${colors.white.primary};
                            border-bottom: 1px solid #dee2e6;
                        `}
                    >
                        <Grid item>
                            <OnwardTabContainer
                                value={tabIndex}
                                onChange={handleTabChange}
                                textColor="primary"
                                indicatorColor="primary"
                                centered
                            >
                                <OnwardTab label="All" value={0} />
                                <OnwardTab label="Onward" value={1} />
                                <OnwardTab label="Internal" value={2} />
                            </OnwardTabContainer>
                        </Grid>
                    </Grid>
                )}
                <Grid
                    css={css`
                        ${fragments.column}
                    `}
                    item
                    xs={4}
                >
                    <Grid
                        container
                        xs={12}
                        css={css`
                            ${fragments.columnHeader}
                        `}
                    >
                        <Grid item sm={9}>
                            <H1>Pre-transit</H1>
                        </Grid>
                        <Grid item sm={3}>
                            <H5>{preTransitCount}</H5>
                        </Grid>
                        {isLoading ? <Skeleton variant="rect" width={'100%'} height={200} /> : null}
                    </Grid>
                    {[
                        ['today', 'Today'],
                        ['tomorrow', 'Tomorrow'],
                        ['upcoming', 'Upcoming'],
                    ].map(([key, label]) => {
                        const routes = preTransitRoutes[key] || [];
                        const orders = preTransitOrders[key] || [];

                        return (
                            <React.Fragment key={key}>
                                {routes.length || orders.length ? <H5>{label}</H5> : null}
                                {routes.map((route, i) => {
                                    return (
                                        <RouteCard
                                            drivers={drivers}
                                            orders={(route.orders || []).map((mapping) => mapping.order)}
                                            key={i}
                                            route={route}
                                            routeType="preTransit"
                                            moment="Today"
                                            callbacks={{
                                                sendBackToPlan: sendToPlanning,
                                                generateRouteCSV,
                                                generateRoutePDF,
                                            }}
                                        />
                                    );
                                })}
                                {orders.map((order, i) => {
                                    return <OrderCard key={i} order={order} orderType="preTransit" />;
                                })}
                            </React.Fragment>
                        );
                    })}
                </Grid>
                <Grid
                    css={css`
                        ${fragments.column}
                    `}
                    item
                    xs={4}
                >
                    <Grid
                        container
                        xs={12}
                        css={css`
                            ${fragments.columnHeader}
                        `}
                    >
                        <Grid item sm={9}>
                            <H1>In Transit</H1>
                        </Grid>
                        <Grid item sm={3}>
                            <H5>{inTransitRoutes.length + inTransitOrders.length}</H5>
                        </Grid>
                    </Grid>
                    {isLoading ? <Skeleton variant="rect" width={'100%'} height={200} /> : null}
                    {inTransitRoutes.map((route, i) => {
                        return (
                            <RouteCard
                                drivers={drivers}
                                key={i}
                                orders={(route.orders || []).map((mapping) => mapping.order)}
                                route={route}
                                routeType="inTransit"
                                callbacks={{ generateRouteCSV, generateRoutePDF }}
                            />
                        );
                    })}
                    {inTransitOrders.map((order, i) => {
                        return <OrderCard key={i} order={order} orderType="inTransit" />;
                    })}
                </Grid>
                <Grid
                    css={css`
                        ${fragments.column}

                        border: 0;
                    `}
                    item
                    xs={4}
                >
                    <Grid
                        container
                        xs={12}
                        css={css`
                            ${fragments.columnHeader}
                        `}
                    >
                        <Grid item sm={9}>
                            <H1>Complete</H1>
                        </Grid>
                        <Grid item sm={3}>
                            <H5>Last 7 Days {`(${completeRoutes?.length + completeOrders?.length})`}</H5>
                        </Grid>
                    </Grid>
                    {isLoading ? <Skeleton variant="rect" width={'100%'} height={200} /> : null}
                    <H5 style={{ display: 'block' }}>Pending Final Returns</H5>

                    {pendingFinalReturnsRoutes?.map((route, i) => {
                        return (
                            <RouteCard
                                drivers={drivers}
                                key={i}
                                orders={(route.orders || []).map((mapping) => mapping.order)}
                                route={route}
                                routeType="completed"
                                callbacks={{ generateRouteCSV, generateRoutePDF }}
                            />
                        );
                    })}
                    <H5 style={{ display: 'block' }}>Complete</H5>
                    {completeRoutes?.map((route, i) => {
                        return (
                            <RouteCard
                                drivers={drivers}
                                key={i}
                                orders={(route.orders || []).map((mapping) => mapping.order)}
                                route={route}
                                routeType="completed"
                                callbacks={{ generateRouteCSV, generateRoutePDF }}
                            />
                        );
                    })}
                    {completeOrders.map((order, i) => {
                        return <OrderCard key={i} order={order} orderType="completed" />;
                    })}
                </Grid>
            </Grid>
        </>
    );
}

export default Tracking;
