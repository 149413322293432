import React from 'react';
import { css } from '@emotion/react';
import { TableRow } from '@material-ui/core';

export default function BodyRow({ row, clickable, children, ...props }) {
    return (
        <TableRow
            {...props}
            css={css`
                ${clickable
                    ? `
                cursor: pointer;
                :hover td {
                    background-color: #f5f5f5;
                }
                `
                    : ''}
            `}
        >
            {children}
        </TableRow>
    );
}
