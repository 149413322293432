import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import cache from '@/graphql';
import { subMonths } from 'date-fns';
import { compose } from 'recompose';
import useAction from '@/utilities/useQuery';
import { toE164, toNational } from '@/utilities/formatPhoneNumber';
import { useSwappedAttributes } from '@/components/ShipmentForm/hooks';
import { OnwardCheckbox } from '@/components/ShipmentForm/blocks';
import { Body, PrimaryButton } from '@/styles/blocks';
import { colors } from '@/styles';
import * as Sentry from '@sentry/react';
import { STAGES } from './constants';
import { Grid, TextField, RadioGroup, Radio, FormControlLabel } from '@material-ui/core';
import { Incrementor } from '../ShipmentForm/blocks';
import { QUERY_BY_ID } from './graphql/queries';
import { submitSurvey } from './queries/submitSurvey';
import { Container, Card, Body1, Header1, Header2, local } from './blocks';
import { TYPES } from './constants';
import Result from './Result';
import Loading from './Loading';
import { UserContext } from '../App';
import withTokenAuth from '../Auth/withTokenAuth';
import { usePredeliveryState } from './hooks';
import Recommendations from './Recommendations';
import Availability from './Availability';
import { PARTNERSHIP_BY_CLIENTS } from '@/graphql/queries/partnerships';
import { Rating } from '@material-ui/lab';

const TODAY = new Date();
const cutoff = subMonths(new Date(TODAY), 1);

const Question = ({ question, response, callbacks }) => {
    let content;
    switch (question.response_type) {
        case 'number':
            content = (
                <Grid
                    item
                    container
                    css={css`
                        margin-bottom: 20px;
                        padding: 0 12px;
                    `}
                >
                    <Rating
                        size="large"
                        precision={0.5}
                        value={response.response_number || 0}
                        onChange={(e, val) => callbacks.setResponse({ ...response, response_number: val })}
                    />
                </Grid>
            );
            break;
        case 'boolean':
            content = (
                <>
                    <Grid
                        item
                        container
                        css={css`
                            padding: 0 12px;
                        `}
                    >
                        <FormControlLabel
                            checked={!!response.response_boolean}
                            control={<Radio color="primary" />}
                            label={<Body1>Yes</Body1>}
                            onChange={() => {
                                callbacks.setResponse({ ...response, response_boolean: true });
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        container
                        css={css`
                            margin-bottom: 20px;
                            padding: 0 12px;
                        `}
                    >
                        <FormControlLabel
                            checked={!response.response_boolean}
                            control={<Radio color="primary" />}
                            label={<Body1>No</Body1>}
                            onChange={() => {
                                callbacks.setResponse({ ...response, response_boolean: false });
                            }}
                        />
                    </Grid>
                </>
            );
            break;
        case 'string':
            content = (
                <Grid
                    item
                    container
                    css={css`
                        margin-bottom: 20px;
                    `}
                >
                    <TextField
                        fullWidth
                        variant="outlined"
                        value={response.response_string || ''}
                        onChange={(e) => callbacks.setResponse({ ...response, response_string: e.target.value })}
                    />
                </Grid>
            );
            break;
    }

    return (
        <>
            <Grid
                item
                container
                css={css`
                    margin-bottom: 8px;
                `}
            >
                <Body1
                    css={css`
                        color: ${local.greys[0]};
                    `}
                >
                    {question.question}
                </Body1>
            </Grid>
            {content}
        </>
    );
};

const CustomerPredeliveryPage = ({ jwt, claims = {} }) => {
    const navigate = useNavigate();
    const { setCustomHeader, setCustomColor } = useContext(UserContext);
    const { orderId } = claims;

    const {
        data,
        loading: isFetchingOrder,
        error: orderError,
    } = useQuery(QUERY_BY_ID, {
        variables: {
            order_id: orderId,
            cutoff,
        },
        onCompleted: ({ order }) => {
            if (!order) {
                navigate('/');
            }
        },
        onError: (err) => {
            console.log(err);
        },
    });

    const [
        {
            availabilityDays,
            confirmed,
            contactPhone,
            deliveryDate,
            disabledDays,
            gateCode,
            order,
            recommendations,
            specialInstructions,
            stage,
            stairs,
            surveyQuestions,
            surveyResponses,
            surveyType,
        },
        {
            setStage,
            setStairs,
            setSpecialInstructions,
            setGateCode,
            setDeliveryDate,
            setAvailabilityDays,
            toDropoffTz,
            setConfirm,
            reset,
            setSurveyResponses,
        },
    ] = usePredeliveryState({ req: data });

    const { address, full_address } = useSwappedAttributes(order);

    const [submit, { loading: isSubmitting, error: submitError }] = useAction(submitSurvey, {
        onComplete: ({ updated }) => {
            cache.updateQuery(
                {
                    query: QUERY_BY_ID,
                    variables: {
                        order_id: orderId,
                        cutoff,
                    },
                },
                (data) => {
                    const updates = Object.fromEntries(
                        [
                            'survey_responses',
                            'alternative_delivery_dates',
                            'customer_instructions',
                            'del_stairs',
                            'delivery_date',
                            'delivery_stair_quantity',
                            'dropoff_address_confirmed',
                            'gate_code',
                            'planning',
                            'preferred_delivery_date',
                        ].map((attr) => [attr, updated[attr]])
                    );

                    return {
                        order: {
                            ...data.order,
                            ...updates,
                        },
                    };
                }
            );

            if (surveyType === TYPES.SCHEDULE && recommendations.length > 0 && !deliveryDate) {
                setStage(STAGES.SCHEDULE_FAILED);
            }

            reset();
        },
        onError: (err) => {
            console.log(err);
        },
    });

    const [findPartnership] = useLazyQuery(PARTNERSHIP_BY_CLIENTS, {
        onError: (error) => {
            console.error(error);
            Sentry.captureException(error);
        },
    });

    useEffect(() => {
        if (order?.order_shipper?.profile_img) {
            setCustomHeader(order?.order_shipper?.profile_img);
            if (order?.order_shipper?.profile_img_color) {
                setCustomColor(order?.order_shipper?.profile_img_color);
            }
        }

        if (order?.oms && order?.carrier_id) {
            findPartnership({
                variables: {
                    shipperId: order.shipper_id,
                    carrierId: order.carrier_id,
                },
                onCompleted: ({ partnerships }) => {
                    if (partnerships[0]?.profile_img) {
                        setCustomHeader(partnerships[0]?.profile_img);
                        if (partnerships[0]?.profile_img_color) {
                            setCustomColor(partnerships[0]?.profile_img_color);
                        }
                    }
                },
            });
        }
    }, [order]);

    const isLoading = isFetchingOrder || !order;

    if (isLoading) {
        return <Loading />;
    }

    if (stage) {
        return (
            <Result
                stage={stage}
                surveyQuestions={surveyQuestions}
                surveyResponses={surveyResponses}
                updated={order}
                contactPhone={contactPhone}
            />
        );
    }

    return (
        <Container>
            <Grid
                container
                direction="column"
                css={css`
                    margin: 0 12px;
                `}
            >
                <Card
                    css={css`
                        margin: 20px 0;
                    `}
                >
                    <Body1
                        css={css`
                            font-weight: 700;
                        `}
                    >{`Your ${order.order_shipper?.business_name} delivery is powered by Onward`}</Body1>
                </Card>
                <Grid
                    item
                    css={css`
                        margin-bottom: 4px;
                    `}
                >
                    <Header1
                        css={css`
                            color: ${local.greys[1]};
                        `}
                    >{`Hey ${order.dropoff_name}, please take a moment to confirm your address and add additional information for your delivery.`}</Header1>
                </Grid>

                {surveyType === TYPES.AVAILABILITY && !order.delivery_date ? (
                    <Availability
                        order={order}
                        availabilityDays={availabilityDays}
                        disabledDays={disabledDays}
                        callbacks={{
                            setAvailabilityDays,
                            toDropoffTz,
                        }}
                    />
                ) : null}

                {surveyType === TYPES.SCHEDULE && recommendations.length > 0 && !order.delivery_date ? (
                    <Recommendations
                        deliveryDate={deliveryDate}
                        recommendations={recommendations}
                        callbacks={{
                            setDeliveryDate,
                            toDropoffTz,
                        }}
                    />
                ) : null}

                <Grid
                    item
                    css={css`
                        margin-bottom: 12px;
                    `}
                >
                    <Body1
                        css={css`
                            color: ${local.greys[0]};
                        `}
                    >
                        If the address is not correct please contact&nbsp;
                        <a
                            href={`tel:${toE164(contactPhone)}`}
                            css={css`
                                &:hover {
                                    text-decoration: none;
                                }
                            `}
                        >
                            <Body1
                                css={css`
                                    color: ${local.greys[0]};
                                `}
                            >
                                {toNational(contactPhone)}
                            </Body1>
                        </a>
                        &nbsp;immediately
                    </Body1>
                </Grid>
                <Card
                    css={css`
                        margin-bottom: 12px;
                    `}
                >
                    <Grid container direction="column">
                        <Header2>Delivery Address</Header2>
                        <Body1>{order[full_address] || order[address]}</Body1>
                    </Grid>
                </Card>

                <Card
                    css={css`
                        margin-bottom: 12px;
                    `}
                >
                    <Grid container direction="column">
                        <Header2>Additional Info</Header2>
                        <Grid
                            item
                            container
                            css={css`
                                flex-wrap: wrap;
                                align-items: center;
                                margin-bottom: 20px;
                            `}
                        >
                            <Body1
                                css={css`
                                    margin-right: 30px;
                                `}
                            >
                                Flights of Stairs:
                            </Body1>
                            <Incrementor
                                body={stairs}
                                incrementCallback={() => setStairs(stairs + 1)}
                                decrementCallback={() => setStairs(Math.max(0, stairs - 1))}
                                css={css`
                                    width: auto;
                                `}
                            />
                        </Grid>

                        <Grid
                            item
                            container
                            css={css`
                                margin-bottom: 20px;
                            `}
                        >
                            <TextField
                                fullWidth
                                type="text"
                                variant="outlined"
                                placeholder="Gate Code"
                                value={gateCode}
                                onChange={(e) => setGateCode(e.target.value)}
                            />
                        </Grid>

                        <Grid
                            item
                            container
                            css={css`
                                margin-bottom: 20px;
                            `}
                        >
                            <TextField
                                fullWidth
                                type="text"
                                variant="outlined"
                                placeholder="Special Delivery Instructions"
                                multiline
                                value={specialInstructions}
                                onChange={(e) => setSpecialInstructions(e.target.value)}
                            />
                        </Grid>

                        {surveyQuestions.map((question) => (
                            <Question
                                question={question}
                                response={surveyResponses[question.survey_id] || {}}
                                callbacks={{
                                    setResponse: (response) =>
                                        setSurveyResponses((prev) => ({ ...prev, [question.survey_id]: response })),
                                }}
                            />
                        ))}
                    </Grid>
                </Card>

                <Grid
                    item
                    css={css`
                        margin-bottom: 12px;
                    `}
                >
                    <OnwardCheckbox
                        label={'Please confirm the area around the items are going to be clear.'}
                        checked={confirmed}
                        onChange={(e) => setConfirm((prev) => !prev)}
                    />
                </Grid>

                <Grid
                    item
                    css={css`
                        width: 100%;
                        margin-bottom: 40px;
                    `}
                >
                    <PrimaryButton
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            submit(
                                {
                                    order_id: order.order_id,
                                    stairs,
                                    ...(deliveryDate ? { delivery_date: deliveryDate } : {}),
                                    ...(availabilityDays?.length > 0 ? { available_dates: availabilityDays } : {}),
                                    special_instructions: specialInstructions,
                                    gate_code: gateCode,
                                    survey_responses: surveyQuestions.map((question) => {
                                        const response = surveyResponses[question.survey_id] || {};
                                        return {
                                            ...response,
                                            survey_id: question.survey_id,
                                            order_id: order.order_id,
                                        };
                                    }),
                                },
                                { Authorization: `Bearer ${jwt}` }
                            );
                        }}
                        disabled={!confirmed}
                        loading={isSubmitting}
                    >
                        <Body1
                            css={css`
                                color: ${colors.white.primary};
                                font-weight: 700;
                                letter-spacing: 1.23 px;
                            `}
                        >
                            Submit
                        </Body1>
                    </PrimaryButton>
                </Grid>
            </Grid>
        </Container>
    );
};

export default compose(withTokenAuth({ role: 'customer' }))(CustomerPredeliveryPage);
