import React, { useState, useMemo, useContext, useEffect } from 'react';
import { compose } from 'recompose';
import { useParams, useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { css } from '@emotion/react';
import {
    TextField,
    Grid,
    CircularProgress,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    IconButton,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { OnwardBreadcrumbActive, OnwardBreadcrumbInactive, OnwardBreadcrumbSpacer } from '@/components/Breadcrumbs';
import Snackbar from '@/components/Snackbar';
import { useClientUser, useOrderCarrierPricing } from '@/hooks';
import { PrimaryButton } from '@/styles/blocks';
import { colors } from '@/styles';
import CreateIcon from '@mui/icons-material/Create';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Container, Card, H1, H2, H3, Body1 } from './blocks';
import { Context, ContextProvider } from './store';

import OrderSummaryCard from './OrderInfoSection/OrderSummaryCard';
import { FIXED_CHARGES } from './constants';
import Header from './Header';
import StaticCharge from './StaticCharge';
import Charge from './Charge';
import { MKPL_CHARGES } from '@/components/Accessorials/constants';
import { calcOrderSubtotal } from '@/utilities/calcOrderSubtotal';
import EditOrderModal from '@/components/ShipmentForm/modals/EditOrderModal';
import OrderInfoSection from './OrderInfoSection';

const dollarFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const Accessorials = () => {
    const { circles, qbo_tags } = useClientUser();
    const { state, loading, callbacks } = useContext(Context);
    const chargeType = !!state?.updated?.oms ? FIXED_CHARGES : MKPL_CHARGES;
    const [openEditOrderModal, setOpenEditOrderModal] = useState(false);
    const navigate = useNavigate();

    const onSaveOrder = ({
        errors,
        order: modified,
        items: itemsModified,
        itemsRemoved,
        tags,
        tagsRemoved,
        documents,
    }) => {
        if (errors?.hasMissingField?.length) {
            callbacks.setNotification({
                severity: 'error',
                message: `Order has missing fields: ${errors.hasMissingField.join(', ')}.`,
            });
        } else if (errors?.phoneInvalid) {
            callbacks.setNotification({
                severity: 'error',
                message: `Order has an invalid phone number.`,
            });
        } else {
            if (errors?.failedPricing?.length) {
                let message = `Failed to calculate pricing for ${modified.order_number}.`;
                if (errors.failedPricing.includes('miles')) {
                    message = `${message} Cannot find route between pickup and dropoff locations.`;
                }
                if (errors.failedPricing.includes('delivery_type')) {
                    message = `${message} ${
                        modified.order_type === 'return' ? 'Return Pickup' : 'Delivery'
                    } type not set.`;
                }
                callbacks.setNotification({ severity: 'warning', message });
            }
            // Use existing state.updated instead of order

            const { warehouse_estimated_delivery_date, warehouse_estimated_ship_date, notes, ...rest } = modified;

            callbacks.handleFullOrderSave({
                order: {
                    ...rest,
                    notes: {
                        data: notes,
                        on_conflict: {
                            constraint: 'notes_pkey',
                            update_columns: ['note'],
                        },
                    },
                },
                items: itemsModified || [],
                removals: itemsRemoved || [],
                tags,
                tagsRemoved,
                documents: documents || [],
            });
            setOpenEditOrderModal(false);
        }
    };

    return (
        <>
            <Grid
                direction="row"
                container
                css={css`
                    align-items: center;
                    background: white;
                    padding: 12px 30px;
                    box-shadow: 0px 0px 0px 1px rgba(63, 63, 68, 0.05), 0px 1px 3px rgba(63, 63, 68, 0.15);
                    border-bottom: 1px solid #ececec;
                `}
            >
                <Grid
                    direction="column"
                    container
                    css={css`
                        flex: 1;
                        flex-basis: 0;
                    `}
                >
                    <Grid
                        direction="row"
                        container
                        css={css`
                            align-items: center;
                        `}
                    >
                        <OnwardBreadcrumbActive onClick={() => navigate(-1)}>Completed Orders</OnwardBreadcrumbActive>
                        <OnwardBreadcrumbSpacer />
                        <OnwardBreadcrumbInactive disabled>{state.updated.order_number}</OnwardBreadcrumbInactive>
                    </Grid>
                </Grid>
                {state.updated.oms && (
                    <Grid
                        direction="column"
                        container
                        css={css`
                            flex: 1;
                            flex-basis: 0;
                            align-items: flex-end;
                        `}
                    >
                        <PrimaryButton
                            css={css`
                                height: 40px;
                                align-self: flex-end;
                                justify-content: center;
                                text-align: center;
                            `}
                            onClick={callbacks.handleReadyToInvoice}
                            disabled={state.updated.ready_to_invoice || loading.invoice}
                        >
                            {state.updated.ready_to_invoice ? 'Approved' : 'Approve for Invoicing'}
                        </PrimaryButton>
                    </Grid>
                )}
            </Grid>

            <Container>
                <Grid
                    direction="row"
                    container
                    css={css`
                        margin-bottom: 35px;
                    `}
                >
                    <div
                        css={css`
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: 100%;
                        `}
                    >
                        <H1>
                            {state.updated.order_number} / {state.updated.po_number}
                        </H1>
                        <div
                            css={css`
                                display: flex;
                                align-items: center;
                            `}
                        >
                            {state.updated.oms && (
                                <IconButton
                                    onClick={() => setOpenEditOrderModal(true)}
                                    disabled={!state.editable || !state.updated.oms}
                                    css={css`
                                        border-radius: 50%;
                                        background-color: #59b863;
                                        &:hover {
                                            background-color: #59b863;
                                        }
                                        transform: scale(0.65);
                                    `}
                                >
                                    <CreateIcon style={{ color: 'white', backgroundColor: '#59b863' }} />
                                </IconButton>
                            )}
                            <IconButton
                                onClick={() => window.open(`/order/${state.updated.order_id}`, '_blank')}
                                disabled={!state.editable || !state.updated.oms}
                                css={css`
                                    border-radius: 10%;
                                    background-color: white;
                                    &:hover {
                                        background-color: lightgrey;
                                    }
                                    transform: scale(0.65);
                                `}
                            >
                                <OpenInNewIcon style={{ color: '59b863', backgroundColor: 'white' }} />
                            </IconButton>
                        </div>
                    </div>
                </Grid>

                <Grid
                    direction="row"
                    container
                    css={css`
                        margin-bottom: 20px;
                    `}
                >
                    <Accordion
                        css={css`
                            width: 100%;
                        `}
                    >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <H2>Comments ({state.updated?.invoice_notes?.length || 0})</H2>
                        </AccordionSummary>
                        <AccordionDetails
                            css={css`
                                display: flex;
                                flex-direction: column;
                                padding: 0 1rem 0rem 1rem;
                                margin-bottom: 0px;
                                justify-content: space-between;
                            `}
                        >
                            {(state.updated?.invoice_notes || [])?.map((comment, i) => (
                                <Grid
                                    container
                                    direction="row"
                                    key={i}
                                    style={{
                                        border: '1px solid #ddd',
                                        borderRadius: '5px',
                                        padding: '10px',
                                        marginBottom: '10px',
                                    }}
                                >
                                    <Grid
                                        container
                                        direction="column"
                                        css={css`
                                            width: 100%;
                                        `}
                                    >
                                        <Grid
                                            container
                                            direction="row"
                                            css={css`
                                                margin-bottom: 8px;
                                            `}
                                        >
                                            <Body1
                                                css={css`
                                                    font-size: 12px;
                                                    color: #4c4c4c;
                                                `}
                                            >
                                                {comment.created_by} - {new Date(comment.created_at).toLocaleString()}
                                            </Body1>
                                        </Grid>
                                        <Grid container direction="row">
                                            <Body1>{comment.comment}</Body1>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))}
                            <TextField
                                multiline
                                charLimit={200}
                                maxRows={2}
                                value={state.comment || ''}
                                css={css`
                                    width: 100%;
                                    height: 100px;
                                    justify-content: center;
                                `}
                                variant="outlined"
                                placeholder="Add a comment..."
                                onChange={(e) => {
                                    callbacks.setNewComment(e.target.value);
                                }}
                            />
                            <PrimaryButton
                                css={css`
                                    margin-bottom: 10px;
                                    height: 40px;
                                    align-self: flex-end;
                                    justify-content: center;
                                    text-align: center;
                                `}
                                onClick={() => {
                                    callbacks.saveComments();
                                }}
                            >
                                Submit
                            </PrimaryButton>
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                <Grid direction="row" container>
                    <Grid
                        direction="column"
                        container
                        css={css`
                            flex: 2;
                            flex-basis: 0;
                            margin-right: 10px;
                        `}
                    >
                        <Card>
                            <Header />
                            {chargeType.map(({ display, key }, idx) => {
                                return state?.breakdown?.[key] > 0 || chargeType == MKPL_CHARGES ? (
                                    <StaticCharge
                                        key={key}
                                        qbo_tags={qbo_tags}
                                        orderQboTag={state.orderQboTag}
                                        updateQboTag={callbacks.updateQboTag}
                                        data={[
                                            { key: 'type', value: display },
                                            {
                                                key: 'total',
                                                value: state?.breakdown?.[key]
                                                    ? dollarFormatter.format(state?.breakdown?.[key])
                                                    : chargeType === MKPL_CHARGES
                                                    ? dollarFormatter.format(state?.subtotal)
                                                    : '--',
                                            },
                                        ]}
                                    />
                                ) : null;
                            })}

                            {(state?.updated?.order_revenue_adjustments?.[state.perspective] || []).map(
                                (charge, idx) => {
                                    return (
                                        <Charge
                                            key={idx}
                                            accessorial={charge}
                                            accessorials={state.accessorials}
                                            rates={state.rates}
                                            isInternal={state.isInternal}
                                            editable={state.editable}
                                            qbo_tags={qbo_tags}
                                            callbacks={{
                                                onChange: (next) => {
                                                    callbacks.editCharge({ idx, charge: next });
                                                },
                                                onRemove: () => {
                                                    callbacks.removeCharge({ idx });
                                                },
                                            }}
                                        />
                                    );
                                }
                            )}

                            <Grid
                                container
                                direction="row"
                                css={css`
                                    margin-top: 20px;
                                `}
                            >
                                <PrimaryButton disabled={!state.editable} onClick={callbacks.addCharge}>
                                    Add Charge
                                </PrimaryButton>
                            </Grid>

                            <Grid
                                container
                                direction="row"
                                css={css`
                                    margin-top: 45px;
                                    margin-bottom: 8px;
                                    padding: 0px 25px;
                                `}
                            >
                                <Grid
                                    container
                                    direction="column"
                                    css={css`
                                        flex: 1;
                                        flex-basis: 0;
                                    `}
                                >
                                    <Body1>Subtotal</Body1>
                                </Grid>
                                <Grid
                                    container
                                    direction="column"
                                    css={css`
                                        flex: 1;
                                        flex-basis: 0;
                                        align-items: flex-end;
                                    `}
                                >
                                    <H3>{state?.subtotal ? dollarFormatter.format(state?.subtotal) : '--'}</H3>
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                direction="row"
                                css={css`
                                    margin-bottom: 20px;
                                    padding: 0px 25px;
                                    padding-bottom: 20px;

                                    border-bottom: 1px solid #4c4c4c;
                                `}
                            >
                                <Grid
                                    container
                                    direction="column"
                                    css={css`
                                        flex: 1;
                                        flex-basis: 0;
                                    `}
                                >
                                    <Body1>Adjustments</Body1>
                                </Grid>
                                <Grid
                                    container
                                    direction="column"
                                    css={css`
                                        flex: 1;
                                        flex-basis: 0;
                                        align-items: flex-end;
                                    `}
                                >
                                    <H3
                                        css={css`
                                            color: ${colors.greens.primary};
                                        `}
                                    >
                                        {state?.accessorialSubtotal
                                            ? dollarFormatter.format(state?.accessorialSubtotal)
                                            : '--'}
                                    </H3>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                css={css`
                                    margin-bottom: 44px;
                                `}
                            >
                                <Grid
                                    container
                                    direction="column"
                                    css={css`
                                        flex: 1;
                                        flex-basis: 0;
                                    `}
                                />
                                <Grid
                                    container
                                    direction="column"
                                    css={css`
                                        flex: 1;
                                        flex-basis: 0;
                                    `}
                                >
                                    <Grid
                                        container
                                        direction="row"
                                        css={css`
                                            padding: 0 25px;
                                        `}
                                    >
                                        <Grid
                                            container
                                            direction="column"
                                            css={css`
                                                flex: 1;
                                                flex-basis: 0;
                                                justify-content: center;
                                            `}
                                        >
                                            <Body1>Total</Body1>
                                        </Grid>
                                        <Grid
                                            container
                                            direction="column"
                                            css={css`
                                                flex: 1;
                                                flex-basis: 0;
                                                justify-content: center;
                                                align-items: flex-end;
                                            `}
                                        >
                                            <H3>{state?.total ? dollarFormatter.format(state?.total) : '--'}</H3>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                css={css`
                                    justify-content: flex-end;
                                `}
                            >
                                <PrimaryButton
                                    css={css`
                                        height: 40px;
                                        align-self: flex-end;
                                        justify-content: center;
                                        text-align: center;
                                    `}
                                    onClick={callbacks.saveOrder}
                                    disabled={loading.updatingOrder || isNaN(state.total) || !state.editable}
                                >
                                    Save
                                </PrimaryButton>
                            </Grid>
                        </Card>
                    </Grid>
                    <Grid
                        direction="column"
                        container
                        css={css`
                            flex: 1;
                            flex-basis: 0;
                            margin-left: 10px;
                            margin-bottom: 4rem;
                            width: 40%;
                        `}
                    >
                        <OrderInfoSection />
                    </Grid>
                </Grid>
            </Container>
            <EditOrderModal
                order={state.updated}
                open={openEditOrderModal}
                opt={{
                    readOnly: !!state.updated.carrier_invoice_id,
                    enableSingleOrderManifest: true,
                }}
                callbacks={{
                    onSave: onSaveOrder,
                    onClose: () => {
                        setOpenEditOrderModal(false);
                    },
                    onError: (error) => {
                        callbacks.setNotification({
                            severity: 'error',
                            message: error?.message || error || `We're sorry, something went wrong.`,
                        });
                    },
                }}
            />

            <Snackbar
                open={!!state.notification.message}
                {...state.notification}
                handleClose={callbacks.clearNotification}
            />
        </>
    );
};

const withContext = (Component) => (props) =>
    (
        <ContextProvider>
            <Component {...props} />
        </ContextProvider>
    );

export default compose(withContext)(Accessorials);
