import React, { useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { Grid } from '@material-ui/core';
import { colors } from '@/styles';
import { useSwappedAttributes } from '@/components/ShipmentForm/hooks';
import AddressAutocomplete from '@/components/ShipmentForm/ModifiedAddressAutocomplete';
import GoogleMap, { Marker } from '@/components/GoogleMap';
import {
    ErrorText,
    OnwardToggle,
    RadioLabelInfo,
    TabRow,
    TabSection,
    ToggleBlurb,
} from '@/components/ShipmentForm/blocks';
import { useClientUser, useOrderNotes } from '@/hooks';
import { OnwardTextField } from '../../InputFields';

const Address = ({ isDirty, order, hasError, callbacks, opt }) => {
    const { client_id, user_id } = useClientUser();
    const [viewMapOverride, setViewMap] = useState(false);

    const { is_custom, geocode_failed, address, city, state, street, zip, lat, long, unit } =
        useSwappedAttributes(order);
    const {
        address: pu_address,
        street: pu_street,
        city: pu_city,
        state: pu_state,
        zip: pu_zip,
    } = useSwappedAttributes(order, true);

    const isImport = window.location.pathname === '/import' || window.location.pathname.includes('job');
    const userType = (() => {
        if (!isImport && order?.carrier_id && client_id !== order?.shipper_id) {
            return 'Carrier';
        } else {
            return 'Shipper';
        }
    })();
    const orderNotes = useOrderNotes([order], userType);
    const currentNote = orderNotes?.delivery?.[0] || {};

    const modifyInitialNote = (e) => {
        if (currentNote?.note_id) {
            const currentNotes = [...order.notes];
            const currentNoteIdx = currentNotes.findIndex((n) => n.note_id === currentNote.note_id);
            currentNotes[currentNoteIdx] = {
                ...currentNote,
                note: e.target.value,
            };
            callbacks.modifyOrder({ notes: currentNotes });
        } else {
            callbacks.addNote({
                note: e.target.value,
                source_user_type: userType,
                source_user_id: user_id,
                is_acknowledgement: false,
                requires_acknowledgement: false,
                acknowledged_by_shipper: false,
                acknowledged_by_admin: false,
                private_to: null,
                type: 'Delivery',
            });
        }
    };

    const coords = useMemo(() => {
        if (order && lat && long) {
            return order[lat] && order[long] ? { lat: order[lat], lng: order[long] } : null;
        }

        return null;
    }, [order, lat, long]);

    const viewMap = useMemo(() => {
        return order[is_custom] || viewMapOverride;
    }, [viewMapOverride, order, is_custom]);

    const allowCustom = useMemo(() => {
        return !!order.order_id;
    }, [order]);

    let addressError = null;
    if (
        [address, street, city, state, zip, lat, long].some(
            (attr) => hasError[attr] && (opt.startDirty || isDirty[attr])
        ) ||
        order[geocode_failed]
    ) {
        addressError = (
            <Grid
                css={css`
                    color: ${colors.reds[1]};
                    margin-bottom: 4px;
                `}
                container
                direction="row"
            >
                <ErrorText>Address is invalid</ErrorText>
            </Grid>
        );
    } else if (
        ['distance', 'miles', 'duration_seconds'].some((attr) => hasError[attr] && (opt.startDirty || isDirty[attr]))
    ) {
        addressError = (
            <Grid
                css={css`
                    color: ${colors.reds[1]};
                    margin-bottom: 4px;
                `}
                container
                direction="row"
            >
                <ErrorText>Failed to find directions between pick up and dropoff address</ErrorText>
            </Grid>
        );
    }

    return (
        <>
            <TabSection>Address</TabSection>
            {allowCustom ? addressError : null}
            <TabRow>
                <Grid item xs={9}>
                    {order[is_custom] ? (
                        <OnwardTextField
                            variant="outlined"
                            color="primary"
                            label="Address"
                            value={order[street]}
                            error={hasError[street] && (opt.startDirty || isDirty[street])}
                            disabled={opt.disableAddressEditing}
                            onBlur={() => callbacks.makeDirty([street, ...(order.is_middle_mile ? [pu_street] : [])])}
                            onChange={(e) => {
                                callbacks.modifyOrder({
                                    [street]: e.target.value,
                                    [geocode_failed]: false,
                                    auto_assign_failed: false,
                                });
                                callbacks.makeDirty([
                                    address,
                                    street,
                                    ...(order.is_middle_mile ? [pu_address, pu_street] : []),
                                ]);
                            }}
                            fullWidth
                        />
                    ) : (
                        <AddressAutocomplete
                            disabled={opt.disableAddressEditing}
                            state={{
                                street: order[street],
                                city: order[city],
                                state: order[state],
                                zip: order[zip],
                            }}
                            handleAddressUpdate={async (value, split) => {
                                if (opt.disableGeocoding) {
                                    callbacks.modifyOrder({
                                        [geocode_failed]: false,
                                        [address]: value,
                                        [street]: split.street,
                                        [city]: split.city,
                                        [state]: split.state,
                                        [zip]: split.zip,
                                        auto_assign_failed: false,
                                    });
                                } else {
                                    const results = await callbacks.enrichOrder(address, value);
                                    if (results.errors.geocodeFailed) {
                                        callbacks.onError('Geocode failed. Please try again.');
                                    }
                                }

                                callbacks.makeDirty([
                                    address,
                                    street,
                                    city,
                                    state,
                                    zip,
                                    ...(order.is_middle_mile ? [pu_address, pu_street, pu_city, pu_state, pu_zip] : []),
                                ]);
                            }}
                            error={
                                [
                                    address,
                                    street,
                                    city,
                                    state,
                                    zip,
                                    lat,
                                    long,
                                    'distance',
                                    'miles',
                                    'duration_seconds',
                                ].some((attr) => hasError[attr] && (opt.startDirty || isDirty[attr])) ||
                                order[geocode_failed]
                            }
                        />
                    )}
                </Grid>
                <Grid item xs={3}>
                    <OnwardTextField
                        variant="outlined"
                        color="primary"
                        label="Unit/Suite #"
                        value={order[unit]}
                        onChange={(e) => callbacks.modifyOrder({ [unit]: e.target.value })}
                        fullWidth
                    />
                </Grid>
            </TabRow>
            <TabRow>
                <Grid item xs={4}>
                    <OnwardTextField
                        fullWidth
                        label="City"
                        variant="outlined"
                        color="primary"
                        value={order[city]}
                        error={hasError[city] && order[is_custom] && (opt.startDirty || isDirty[city])}
                        onBlur={() => callbacks.makeDirty([city])}
                        onChange={(e) =>
                            callbacks.modifyOrder({
                                [city]: e.target.value,
                            })
                        }
                        InputLabelProps={{
                            shrink: !!order[city],
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <OnwardTextField
                        fullWidth
                        variant="outlined"
                        color="primary"
                        label="State"
                        value={order[state]}
                        error={hasError[state] && order[is_custom] && (opt.startDirty || isDirty[state])}
                        onBlur={() => callbacks.makeDirty([state])}
                        onChange={(e) =>
                            callbacks.modifyOrder({
                                [state]: e.target.value,
                            })
                        }
                        InputLabelProps={{
                            shrink: !!order[state],
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <OnwardTextField
                        fullWidth
                        variant="outlined"
                        color="primary"
                        label="Zip"
                        value={order[zip]}
                        error={hasError[zip] && order[is_custom] && (opt.startDirty || isDirty[zip])}
                        onBlur={() => callbacks.makeDirty([zip])}
                        onChange={(e) =>
                            callbacks.modifyOrder({
                                [zip]: e.target.value,
                            })
                        }
                        InputLabelProps={{
                            shrink: !!order[zip],
                        }}
                    />
                </Grid>
            </TabRow>
            <TabRow>
                <Grid item xs={12}>
                    <OnwardTextField
                        type="text"
                        variant="outlined"
                        fullWidth
                        label="Comments"
                        multiline={true}
                        value={currentNote?.note || ''}
                        onChange={modifyInitialNote}
                    />
                </Grid>
            </TabRow>

            {allowCustom ? (
                <TabRow>
                    <Grid item>
                        <OnwardToggle
                            css={css`
                                margin: 0;
                            `}
                            disabled={opt.disableAddressEditing}
                            value={order[is_custom] || false}
                            onChange={(e) => {
                                callbacks.modifyOrder({
                                    [is_custom]: e.target.checked,
                                    [geocode_failed]: false,
                                });
                                callbacks.makeDirty([
                                    address,
                                    street,
                                    ...(order.is_middle_mile ? [pu_address, pu_street] : []),
                                ]);
                            }}
                        />
                    </Grid>
                    <Grid
                        direction="column"
                        container
                        css={css`
                            flex: 1;
                            flex-basis: 0;
                            margin-left: 12px;
                            justify-content: center;
                        `}
                    >
                        <Grid container item>
                            <RadioLabelInfo
                                css={css`
                                    color: #000;
                                    font-weight: 400;
                                `}
                            >
                                Having trouble with your address?&nbsp;
                                <a
                                    css={css`
                                        cursor: pointer;
                                    `}
                                    onClick={() => {
                                        if (opt.disableAddressEditing) return;
                                        callbacks.modifyOrder({
                                            [is_custom]: true,
                                            [geocode_failed]: false,
                                        });
                                        callbacks.makeDirty([
                                            address,
                                            street,
                                            ...(order.is_middle_mile ? [pu_address, pu_street] : []),
                                        ]);
                                    }}
                                >
                                    Place a pin on the map
                                </a>
                            </RadioLabelInfo>
                        </Grid>

                        {order[is_custom] ? (
                            <Grid
                                container
                                item
                                css={css`
                                    margin-top: 8px;
                                `}
                            >
                                <ToggleBlurb />
                            </Grid>
                        ) : null}
                    </Grid>
                </TabRow>
            ) : null}

            {viewMap && (
                <Grid
                    item
                    css={css`
                        min-height: 350px;
                        margin-bottom: 12px;
                        border-radius: 6px;
                        border: ${hasError[lat] || hasError[long] ? `1px solid #f44336;` : '1px solid transparent;'};
                    `}
                >
                    <GoogleMap
                        zoom={15}
                        onClick={(e) => {
                            if (opt.disableGeocoding) {
                                const x = e.latLng.lat();
                                const y = e.latLng.lng();

                                callbacks.modifyOrder({
                                    [lat]: x,
                                    [long]: y,
                                });
                            } else {
                                callbacks.geocodeLatLong(e);
                            }

                            callbacks.makeDirty([lat, long]);
                        }}
                        draggableCursor="pointer"
                        gestureHandling="greedy"
                        center={coords ? coords : undefined}
                    >
                        {coords && <Marker position={coords} />}
                    </GoogleMap>
                </Grid>
            )}
        </>
    );
};

export default Address;
