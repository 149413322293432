import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Grid, Checkbox, TextField, MenuItem } from '@material-ui/core';
import { typography, colors } from '@/styles';

export const Circle = styled.div`
    width: 55px;
    height: 55px;
    border-radius: 999px;
`;

export const Body1 = styled.span`
    ${typography.sansSerif}

    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
`;

export const Label = styled.span`
    ${typography.sansSerif}

    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
`;

const SubTitle = styled.span`
    ${typography.sansSerif}

    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
`;

const rowStyles = (props) => {
    return css`
        background: ${props.selected ? '#CFCFCF' : '#e5e5e5'};
        cursor: ${props.selected ? 'default' : 'pointer'};
    `;
};

const borderRed = (props) => {
    return css`
        border: ${props.error ? '2px solid #d23e3e' : '2px solid transparent'};
    `;
};

export const ErrorContainer = styled(({ children, ...props }) => (
    <Grid container direction="column" {...props}>
        {children}
    </Grid>
))`
    width: 100%;
    border-radius: 5px;

    ${borderRed}
`;

const OptionRow = styled(({ children, ...props }) => (
    <Grid container direction="row" {...props}>
        {children}
    </Grid>
))`
    ${rowStyles}
    padding: 16px 12px;

    &:not(:last-child) {
        border-bottom: 1px solid #c0c0c0;
    }

    &:first-of-type {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    &:last-of-type {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }
`;

export const Option = ({ isSelected, breakdown, order, subtext, label, drivers, price, logo, callbacks, ...rest }) => {
    return (
        <OptionRow selected={isSelected} className="option" {...rest}>
            <Grid
                container
                direction="column"
                css={css`
                    flex: 0;
                    flex-basis: 0;
                    justify-content: center;
                    margin-right: 8px;
                `}
                onClick={callbacks.onClick}
            >
                <Checkbox color="primary" checked={isSelected} disabled={isSelected} />
            </Grid>
            <Grid
                container
                direction="column"
                css={css`
                    flex: 0;
                    flex-basis: 0;
                    justify-content: center;
                    margin-right: 20px;
                `}
            >
                <Circle
                    css={css`
                        background: ${colors.white.primary};

                        display: flex;
                        justify-content: center;
                        align-items: center;
                    `}
                >
                    <img
                        css={css`
                            object-fit: cover;
                            display: flex;
                            height: 35px;
                        `}
                        src={logo}
                    />
                </Circle>
            </Grid>
            <Grid
                container
                direction="column"
                css={css`
                    flex: 1;
                    flex-basis: 0;
                    justify-content: center;
                `}
            >
                <Grid
                    container
                    direction="row"
                    css={css`
                        margin-bottom: 4px;
                    `}
                    onClick={callbacks.onClick}
                >
                    <Label
                        css={css`
                            color: #2b2b2b;
                        `}
                    >
                        {label}
                    </Label>
                </Grid>
                <Grid container direction="row" onClick={callbacks.onClick}>
                    <SubTitle
                        css={css`
                            color: #2b2b2b;
                        `}
                    >
                        {subtext}
                    </SubTitle>
                </Grid>
                {drivers.length > 0 ? (
                    <Grid
                        container
                        direction="row"
                        css={css`
                            margin-top: 16px;
                        `}
                    >
                        <Grid
                            container
                            direction="column"
                            css={css`
                                flex: 2;
                                flex-basis: 0;
                            `}
                        >
                            <TextField
                                variant="outlined"
                                select
                                fullWidth
                                size="small"
                                label="Preferred Driver"
                                value={order.preferred_driver_id || 'DEFAULT'}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => {
                                    const id = e.target.value;
                                    callbacks.onDriverSelect(id);
                                }}
                                css={css`
                                    width: 100%;
                                `}
                                error={order.preferred_driver_id && !breakdown._meta.preferred_driver_accepted}
                                helperText={
                                    order.preferred_driver_id && !breakdown._meta.preferred_driver_accepted ? (
                                        <span
                                            css={css`
                                                color: #f44336;
                                            `}
                                        >
                                            Invalid Driver
                                        </span>
                                    ) : null
                                }
                            >
                                <MenuItem key="DEFAULT" value="DEFAULT">
                                    <Body1>Marketplace</Body1>
                                </MenuItem>
                                {drivers.map(({ name, id }) => (
                                    <MenuItem key={id} value={id}>
                                        <Body1>{name}</Body1>
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid
                            container
                            direction="column"
                            css={css`
                                flex: 1;
                                flex-basis: 0;
                            `}
                        />
                    </Grid>
                ) : null}
            </Grid>
            <Grid
                container
                direction="column"
                css={css`
                    flex: 0;
                    flex-basis: 0;
                    justify-content: center;
                `}
                onClick={callbacks.onClick}
            >
                <Label
                    css={css`
                        color: #2b2b2b;
                        white-space: nowrap;
                    `}
                >
                    {`Price: ${price}`}
                </Label>
            </Grid>
        </OptionRow>
    );
};
