import { Grid } from '@material-ui/core';
import { ITEM_LABELS_BY_FREIGHT } from '@/components/ShipmentForm/constants/freightTypes';
import { ModalContext } from '..';
import { PrimaryButton } from '@/styles/blocks';
import { TabRow, TabTitle } from '../../../blocks';
import ItemDetails from './ItemDetails';
import React, { forwardRef, useContext, useState } from 'react';
import styled from '@emotion/styled';

const ItemsEditTab = forwardRef(({ itemHasError, isDirty, opt }, ref) => {
    const { startDirty } = opt;
    const { state: modalState, callbacks } = useContext(ModalContext);
    const { order } = modalState;
    const [collapsed, setCollapsed] = useState(false);

    return (
        <>
            <TabTitle ref={ref} collapsed={collapsed} callbacks={{ setCollapsed }}>
                Items
            </TabTitle>
            {collapsed ? null : (
                <>
                    {(order.itemsByOrderId || []).map((item, idx) => (
                        <ItemDetails
                            key={item.item_id}
                            order={order}
                            item={item}
                            index={idx}
                            callbacks={callbacks}
                            hasError={itemHasError[idx]}
                            isDirty={(isDirty.itemsByOrderId || [])?.[idx] || {}}
                            startDirty={startDirty}
                        />
                    ))}

                    <TabRow>
                        <Grid item>
                            <PrimaryButton onClick={() => callbacks.addItem()}>{`Add ${
                                ITEM_LABELS_BY_FREIGHT[order.freight_type] || 'Item'
                            }`}</PrimaryButton>
                        </Grid>
                    </TabRow>
                </>
            )}
        </>
    );
});

export default ItemsEditTab;
