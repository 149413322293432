import React from 'react';
import { Grid } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { OnwardCard } from './Card';
import { toNational } from '@/utilities/formatPhoneNumber';
import { CardTitle, CardItem } from './blocks';
import { css } from '@emotion/react';

export default function WillCallCard({ order }) {
    let content = <></>;
    switch (order.job_type) {
        case 'WILL_CALL':
            content = (
                <>
                    <CardItem className="my-1">Customer will pick up order</CardItem>
                    <CardItem className="my-1">
                        {order.dropoff_name}
                        {['complete', 'routedComplete'].includes(order.order_status) && (
                            <CheckCircleIcon style={{ color: '#59B863' }} className="float-right" />
                        )}
                    </CardItem>
                    <CardItem className="my-1">{toNational(order.dropoff_phone)}</CardItem>
                    <CardItem className="my-1">{order.dropoff_email || 'Email Not Provided'}</CardItem>
                    {order.notes &&
                        order.notes.length > 0 &&
                        order.notes.map((note, index) => (
                            <CardItem key={index} className="mt-1 mb-4">
                                {note.type} Comments: {note.note}
                            </CardItem>
                        ))}
                </>
            );
            break;
        case 'PICKUP_AND_WILL_CALL':
            content = (
                <>
                    <CardItem className="my-1">Shipper or middle mile carrier will pick up order</CardItem>
                </>
            );
            break;
    }
    return (
        <OnwardCard
            css={css`
                height: 100%;
            `}
            contentProps={{ style: { height: '100%' } }}
        >
            <Grid container style={{ height: '100%', flexWrap: 'nowrap' }} direction="column">
                <CardTitle>Will Call</CardTitle>
                {content}
            </Grid>
        </OnwardCard>
    );
}
