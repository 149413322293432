import React, { forwardRef, useContext, useState } from 'react';
import { ModalContext } from '..';
import Customer from './Customer';
import DeliveryDate from './DeliveryDate';
import DeliveryType from './DeliveryType';
import Address from './Address';
import { TabTitle } from '@/components/ShipmentForm/blocks';

const CustomerTab = forwardRef(({ opt }, ref) => {
    const { state: modalState, callbacks } = useContext(ModalContext);
    const { isDirty, order, hasError } = modalState;
    const [collapsed, setCollapsed] = useState(false);
    const isWillCall = order.job_type === 'WILL_CALL';

    return (
        <>
            <TabTitle ref={ref} collapsed={collapsed} callbacks={{ setCollapsed }}>
                Customer
            </TabTitle>
            {collapsed ? null : (
                <>
                    <Customer
                        isDirty={isDirty}
                        order={order}
                        hasError={hasError}
                        callbacks={callbacks}
                        opt={opt}
                        modalState={modalState}
                        ref={ref}
                    />
                    {!isWillCall && (
                        <>
                            <DeliveryDate
                                isDirty={isDirty}
                                order={order}
                                hasError={hasError}
                                callbacks={callbacks}
                                opt={opt}
                            />
                            <Address
                                isDirty={isDirty}
                                order={order}
                                hasError={hasError}
                                callbacks={callbacks}
                                opt={opt}
                            />
                            <DeliveryType
                                isDirty={isDirty}
                                order={order}
                                hasError={hasError}
                                callbacks={callbacks}
                                opt={opt}
                            />
                        </>
                    )}
                </>
            )}
        </>
    );
});

export default CustomerTab;
