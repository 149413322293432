import React from 'react';
import { css } from '@emotion/react';
import { BodyText, StickyCell } from '@/components/CarrierAccountingOrders/blocks';

export default function BodyCell({ cell, span = true, callbacks = {}, isChecked, className, tab, ...props }) {
    return (
        <StickyCell
            align="left"
            className={className}
            {...props}
            css={css`
                position: relative;
            `}
        >
            {span ? (
                <BodyText
                    css={css`
                        position: absolute;
                        top: 50%;
                        -webkit-transform: translateY(-50%);
                        transform: translateY(-50%);
                        ${cell?.column?.id === "est_received_date" && `width: -webkit-fill-available;`}
                        ${cell?.column?.id === "shipper" && tab?.value === 'OUTBOUND' && `width: -webkit-fill-available;`}
                    `}
                >
                    {cell.render('Cell', { callbacks, isChecked })}
                </BodyText>
            ) : (
                cell.render('Cell', { callbacks, isChecked })
            )}
        </StickyCell>
    );
}
