import { colors } from '@/styles';
import { CircularProgress, Grid, MenuItem, TextField } from '@material-ui/core';
import React, { useContext } from 'react';
import { compose } from 'recompose';
import { Context, withContext } from './store';
import { css } from '@emotion/react';
import { OnwardTab, OnwardTabContainer } from '../Tabs';
import { PrimaryButton } from '@/styles/blocks';
import { useClientUser } from '@/hooks';
import { PrimaryHeader } from '@/styles/typography';
import SearchInput from '../misc/SearchInput';
import { TABS } from './constants';
import Table from '../Table';
import CreateManifest from './modals/CreateManifest';
import Snackbar from '../Snackbar';
import { useNavigate } from 'react-router';
import { MANIFEST_STATUS_LABELS, MANIFEST_TYPE_LABELS } from '@/constants/manifestStatuses';

const Manifests = () => {
    const { state, callbacks, loading } = useContext(Context);
    const { user_id } = useClientUser();
    const navigate = useNavigate();

    return (
        <Grid
            container
            direction="column"
            css={css`
                height: 100%;
                flex-wrap: nowrap;
            `}
        >
            <Snackbar
                open={!!state.notification.message}
                {...state.notification}
                handleClose={callbacks.clearNotification}
            />
            <CreateManifest />
            <Grid
                container
                direction="row"
                css={css`
                    padding: 0rem 1rem;
                    border-bottom: 1px solid ${colors.greys.border};
                    background: ${colors.white.primary};
                    align-items: center;
                    justify-content: space-between;
                `}
            >
                <Grid container xs={3} />
                <Grid container xs={6} justifyContent="center">
                    <OnwardTabContainer
                        value={state.tab.value}
                        onChange={(_, tab) => {
                            callbacks.setTab(TABS[tab]);
                            callbacks.setFilters(TABS[tab]?.defaultFilters || {});
                        }}
                    >
                        {Object.values(TABS)
                            .filter(
                                (tab) => (tab.value !== 'EXTERNAL' && tab.value !== 'EXTERNALOUT') || state.hasExternal
                            )
                            .map((tab) => (
                                <OnwardTab key={`manifest-tab-${tab.value}`} label={tab.label} value={tab.value} />
                            ))}
                    </OnwardTabContainer>
                </Grid>
                <Grid container xs={3} justifyContent="flex-end">
                    {state.tab.value !== 'EXTERNAL' && state.tab.value !== 'EXTERNALOUT' && (
                        <PrimaryButton
                            onClick={() => {
                                callbacks.setNewManifest({
                                    warehouse_id: (
                                        (state.warehouses || []).find((location) => location.preferred_inbound) ||
                                        state.warehouses?.[0]
                                    )?.location_id,
                                    receiving_date: new Date().toISOString(),
                                    ...(state.tab.typeOptions?.length === 1
                                        ? {
                                              type: state.tab.typeOptions[0],
                                          }
                                        : {}),
                                });
                                callbacks.setShowCreateManifestModal(true);
                            }}
                        >
                            Create Manifest
                        </PrimaryButton>
                    )}
                </Grid>
            </Grid>
            <Grid
                container
                direction="row"
                css={css`
                    padding: 2rem 1rem 2rem 2rem;
                    align-items: center;
                    justify-content: space-between;
                `}
            >
                <Grid container xs={3}>
                    <PrimaryHeader>Manifests</PrimaryHeader>
                </Grid>
            </Grid>
            <Grid
                container
                direction="row"
                css={css`
                    padding: 1rem;
                    margin: 0rem 1rem 2rem 2rem;
                    align-items: center;
                    justify-content: space-between;
                    gap: 1rem;
                    background-color: white;
                    width: auto;
                `}
            >
                <Grid
                    item
                    css={css`
                        flex: 1 1 0;
                    `}
                >
                    <SearchInput
                        css={css`
                            width: 100%;
                        `}
                        placeholder="Search Manifests"
                        onChange={(search) => callbacks.setSearchManifests(search)}
                        size="medium"
                    />
                </Grid>
                <Grid
                    item
                    css={css`
                        flex: 1 1 0;
                    `}
                >
                    <TextField
                        select
                        variant="outlined"
                        label="Shipper"
                        value={state.filters.shipperSelect || ''}
                        onChange={(e) => {
                            const val = e.target.value !== '' ? e.target.value : null;
                            callbacks.setFilters((prev) => ({ ...prev, shipperSelect: val }));
                        }}
                        css={css`
                            width: 100%;
                            background-color: white;
                        `}
                    >
                        <MenuItem key={''} value={''}>
                            Select...
                        </MenuItem>
                        {state.shipperOptions.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid
                    item
                    css={css`
                        flex: 1 1 0;
                    `}
                >
                    <TextField
                        select
                        variant="outlined"
                        label="Status"
                        value={state.filters.status || 'ALL'}
                        onChange={(e) => {
                            const val = e.target.value !== 'ALL' ? e.target.value : null;
                            callbacks.setFilters((prev) => ({ ...prev, status: val }));
                        }}
                        css={css`
                            width: 100%;
                            background-color: white;
                        `}
                        SelectProps={{
                            renderValue: (value) => {
                                // For Active status, show a cleaner representation
                                if (value === 'ACTIVE') {
                                    return 'Active';
                                }
                                // For other statuses, show the label
                                return value === 'ALL' ? 'All' : MANIFEST_STATUS_LABELS[value]?.label || value;
                            },
                        }}
                    >
                        <MenuItem key={'ALL'} value={'ALL'}>
                            All
                        </MenuItem>
                        <MenuItem key={'ACTIVE'} value={'ACTIVE'}>
                            <span
                                css={css`
                                    display: flex;
                                    align-items: center;
                                    font-weight: 500;
                                `}
                            >
                                <span
                                    css={css`
                                        margin-right: 8px;
                                        font-size: 10px;
                                        color: ${colors.greys.secondary};
                                    `}
                                >
                                    ▼
                                </span>
                                Active
                            </span>
                        </MenuItem>
                        {state.tab.activeStatuses?.map((option) => (
                            <MenuItem
                                key={option}
                                value={option}
                                css={css`
                                    padding-left: 32px;
                                    font-size: 0.9em;
                                    color: ${colors.greys.secondary};
                                `}
                            >
                                {MANIFEST_STATUS_LABELS[option]?.label || option}
                            </MenuItem>
                        ))}
                        <MenuItem
                            disabled
                            css={css`
                                padding: 0;
                                margin: 4px 0;
                                border-bottom: 1px solid ${colors.greys.border};
                            `}
                        />
                        {state.tab.statusOptions
                            .filter((option) => !state.tab.activeStatuses?.includes(option))
                            .map((option) => (
                                <MenuItem key={option} value={option}>
                                    {MANIFEST_STATUS_LABELS[option]?.label || option}
                                </MenuItem>
                            ))}
                    </TextField>
                </Grid>
                <Grid
                    item
                    css={css`
                        flex: 1 1 0;
                    `}
                >
                    <TextField
                        select
                        variant="outlined"
                        label="Type"
                        value={state.filters.type || 'ALL'}
                        onChange={(e) => {
                            const val = e.target.value !== 'ALL' ? e.target.value : null;
                            callbacks.setFilters((prev) => ({ ...prev, type: val }));
                        }}
                        css={css`
                            width: 100%;
                            background-color: white;
                        `}
                    >
                        <MenuItem key={'ALL'} value={'ALL'}>
                            All
                        </MenuItem>
                        {state.tab.typeOptions.map((option) => (
                            <MenuItem key={option} value={option}>
                                {MANIFEST_TYPE_LABELS[option] || option}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
            </Grid>
            <Grid
                container
                css={css`
                    padding: 0rem 1rem 2rem 2rem;
                    flex-grow: 1;
                    justify-content: center;
                    align-items: flex-start;
                `}
            >
                {loading.queryLoading ? (
                    <Grid
                        item
                        css={css`
                            margin-top: 2rem;
                        `}
                    >
                        <CircularProgress color="primary" size="200px" />
                    </Grid>
                ) : (
                    <Table
                        data={
                            state.tab.value === 'EXTERNAL' || state.tab.value === 'EXTERNALOUT'
                                ? state.externalManifests
                                : state.manifests
                        }
                        columns={state.tab.columns}
                        callbacks={{
                            getRowId: (manifest) => manifest.manifest_id,
                            rowOnClick: (e, manifest) => {
                                const href = `/manifests/${manifest.manifest_id}`;
                                e.preventDefault();
                                if (e.ctrlKey) {
                                    window.open(href, '_blank');
                                } else {
                                    navigate(href);
                                }
                            },
                        }}
                        options={{
                            select: false,
                            sortAsync: false,
                        }}
                    />
                )}
            </Grid>
        </Grid>
    );
};

export default compose(withContext)(Manifests);
