import React, { useMemo } from 'react';
import Select from './Select';
import { convertToISO } from '../../utilities/convertToISO';
import { getTimezoneOffset } from 'date-fns-tz';
import { css } from '@emotion/react';
import { MenuItem, TextField } from '@material-ui/core';

const routeTimeZoneFormatter = (timeZone) =>
    new Intl.DateTimeFormat('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        timeZone,
        timeZoneName: 'short',
    });

const localFormatter = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
});

function StartTimeSelect({ timeZone, deliveryDate, interval, filter, ...props }) {
    const menuItems = useMemo(() => {
        const utcDate = new Date(convertToISO(deliveryDate));

        const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        const browserLocalDate = new Date(utcDate.getTime() - getTimezoneOffset(localTimeZone, utcDate));

        const items = [];

        const intervalList = [];
        for (let i = 0; i < 60; i += interval) {
            intervalList.push(i);
        }

        for (let hours = 0; hours < 24; hours++) {
            intervalList.forEach((minutes) => {
                browserLocalDate.setHours(hours);
                browserLocalDate.setMinutes(minutes);
                browserLocalDate.setSeconds(0);
                browserLocalDate.setMilliseconds(0);

                if (timeZone && localTimeZone !== timeZone) {
                    const routeLocalDate = new Date(
                        browserLocalDate.getTime() +
                            getTimezoneOffset(localTimeZone, utcDate) -
                            getTimezoneOffset(timeZone, utcDate)
                    );

                    if (!filter || filter({ local: browserLocalDate })) {
                        items.push({
                            value: routeLocalDate.getTime(),
                            label: `${localFormatter.format(routeLocalDate)} (${routeTimeZoneFormatter(timeZone).format(
                                routeLocalDate
                            )})`,
                        });
                    }
                } else {
                    if (!filter || filter({ local: browserLocalDate })) {
                        items.push({
                            value: browserLocalDate.getTime(),
                            label: `${localFormatter.format(browserLocalDate)}`,
                        });
                    }
                }
            });
        }

        return items;
    }, [timeZone, deliveryDate, interval, filter]);

    return (
        <TextField select variant="outlined" fullWidth {...props}>
            {menuItems.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                    {item.label}
                </MenuItem>
            ))}
        </TextField>
    );
}

export default StartTimeSelect;
