import { createContext, useMemo, useState, useEffect, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { useClientUser } from '@/hooks';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useExport } from '@/components/admin/AdminFinancials/payables/hooks';
import { SET_INVOICE_STATUS } from '@/components/admin/AdminFinancials/payables/graphql';
import { QUICKBOOKS_CSV_COLUMNS } from '@/components/CarrierAccountingInvoice/csvColumns';
import { useAccessorials } from '@/components/Account/Tariffs/utils';
import { GET_PRICING_OVERRIDES } from '@/graphql/queries/pricing_overrides';
import { captureException } from '@sentry/react';
import { useInvoiceColumns } from './columns';
import { CARRIER_INVOICES } from './graphql';
import { asBrowserDate, asUTCDate } from '@/utilities/convertToISO';
import { DETAILED_CSV_COLUMNS } from '../CarrierAccountingInvoice/detailedViewCsvColumns';
import { addDays, format } from 'date-fns';
import { INSERT_CSV_EXPORT_TEMPLATE } from '@/graphql/mutations/csv_export_templates';
import generateCSV from '@/utilities/createCSV';
import { FIXED_CHARGES } from '@/components/Accessorials/constants';
import { MKPL_CHARGES } from '@/components/Accessorials/constants';
import { calcOrderSubtotal } from '@/utilities/calcOrderSubtotal';

const SEARCHABLE = [
    'order_number',
    'po_number',
    'dropoff_name',
    'dropoff_state',
    'dropoff_city',
    'dropoff_zip',
    'pickup_state',
    'pickup_city',
    'pickup_zip',
];

export const Context = createContext();

export const ContextProvider = ({ children }) => {
    const navigate = useNavigate();
    const { user_id, shipping_partners, tags } = useClientUser();

    const [invoiceType, setInvoiceType] = useState('INTERNAL');
    const [status, setStatus] = useState('ALL');
    const [search, setSearch] = useState('');
    const [selectedMap, setSelected] = useState({});
    const [issuedDate, setIssuedDate] = useState('');
    const [dueDate, setDueDate] = useState('');
    const [selectedShippers, setSelectedShippers] = useState([]);
    const [openExportModal, setOpenExportModal] = useState(false);
    let [searchParams, setSearchParams] = useSearchParams();

    const orderFilters = useMemo(() => {
        const TODAY = new Date();

        return {
            completedFrom: format(
                searchParams.get('completedFrom')
                    ? new Date(parseInt(searchParams.get('completedFrom')))
                    : addDays(TODAY, -14),
                'yyyy-MM-dd'
            ),
            completedTo: format(
                searchParams.get('completedTo') ? new Date(parseInt(searchParams.get('completedTo'))) : TODAY,
                'yyyy-MM-dd'
            ),
            search: searchParams.get('search') || '',
            selectedShippers: searchParams.getAll('selectedShippers') || [],
        };
    }, [searchParams]);

    const setOrderFilters = useCallback(
        (callback) => {
            const next = callback(orderFilters);

            setSearchParams((prev) => {
                Object.entries(next).forEach(([key, value]) => {
                    prev.set(key, value);
                });

                return prev;
            });
        },
        [orderFilters]
    );

    const where = useMemo(() => {
        const _issuedDate = issuedDate ? asBrowserDate(issuedDate).toISOString() : null;
        const _dueDate = dueDate ? asBrowserDate(dueDate).toISOString() : null;
        let conditions = [{ client_id: { _eq: user_id } }, { type: { _eq: invoiceType } }];
        

        if (orderFilters.selectedShippers?.length > 0) {
            console.log(orderFilters.selectedShippers);
            const selectedIds = selectedShippers.map(shipper => shipper.id);
            conditions.push({ orders: { shipper_id: { _in: selectedIds } }});
            conditions.push({ orders: { shipper_id: { _in: orderFilters.selectedShippers } } });
        }
        if (orderFilters.completedFrom) {
            const cutoff = asUTCDate(orderFilters.completedFrom);
            conditions.push({ orders: { completion_time: { _gte: cutoff.toISOString() } } });
        }
        if (orderFilters.completedTo) {
            const cutoff = addDays(asUTCDate(orderFilters.completedTo), 1);
            conditions.push({ orders: { completion_time: { _lt: cutoff.toISOString() } } });
        }
        if (orderFilters.search) {
            conditions.push({
                _or: SEARCHABLE.map((field) => ({ orders: { [field]: { _ilike: `%${orderFilters.search}%` } }})),
            });
        }

        if (_issuedDate) {
            conditions.push({ created_at: { _gte: _issuedDate } });
            conditions.push({ created_at: { _lt: addDays(new Date(_issuedDate), 1).toISOString() } });
        }

        if (_dueDate) {
            conditions.push({ due_date: { _gte: _dueDate } });
            conditions.push({ due_date: { _lt: addDays(new Date(_dueDate), 1).toISOString() } });
        }

        conditions.push({ status: status === 'ALL' ? {} : { _eq: status } });

        if (search.length > 0) {
            const orConditions = [
                { orders: { order_number: { _ilike: `%${search}%` } } },
                { orders: { po_number: { _ilike: `%${search}%` } } },
            ];

            const parsedInt = parseInt(search, 10);
            if (!isNaN(parsedInt) && Number.isInteger(parsedInt)) {
                orConditions.push({ invoice_number: { _eq: parsedInt } });
            }

            conditions.push({
                _or: orConditions,
            });
        }

        return conditions;
    }, [user_id, status, search, invoiceType, issuedDate, dueDate, selectedShippers, orderFilters]);

    const [getInvoices, { data, loading, refetch }] = useLazyQuery(CARRIER_INVOICES, {
        onError: (err) => {
            console.error(err);
            captureException(err);
        },
    });

    const [setInvoiceStatus, { loading: invoiceStatusInflight }] = useMutation(SET_INVOICE_STATUS, {
        onError: (err) => {
            console.error(err);
            captureException(err);
        },
    });

    const [insertTemplate, { loading: insertTemplateLoading }] = useMutation(INSERT_CSV_EXPORT_TEMPLATE, {
        onError: (error) => {
            captureException(error);
            console.log(error.message);
        },
    });

    const handleShipperChange = (shipper) => {
        setSearchParams((prev) => {
            const last = prev.getAll('selectedShippers') || [];

            const clone = [...last];
            if (clone.includes(shipper)) {
                clone.splice(clone.indexOf(shipper), 1);
            } else {
                clone.push(shipper);
            }

            prev.delete('selectedShippers');
            clone.forEach((value) => {
                prev.append('selectedShippers', value);
            });

            return prev;
        });
    };

    const [fetchOverrides, { data: resp }] = useLazyQuery(GET_PRICING_OVERRIDES);

    useEffect(() => {
        if (user_id) {
            fetchOverrides({
                variables: {
                    shipper_ids: [],
                    carrier_ids: [user_id],
                    client_ids: [user_id],
                    partner_client_ids: [],
                },
            });
        }
    }, [user_id]);

    const getInvoicesDebounced = useMemo(
        () =>
            debounce((where) => {
                getInvoices({
                    variables: {
                        where: {
                            _and: where,
                        },
                    },
                });
            }, 500),
        []
    );

    const selected = useMemo(() => {
        return Object.keys(selectedMap).filter((attr) => selectedMap[attr]);
    }, [selectedMap]);

    useEffect(() => {
        getInvoicesDebounced(where);
    }, [where, getInvoicesDebounced]);

    const invoices = useMemo(() => {
        // Match shipper name to alias defined on user's account under Shipping Partners
        if (shipping_partners?.length) {
            const shippingPartnerNameMap = Object.fromEntries(
                shipping_partners.map((partner) => [partner.shipper_id, partner.shipper_alias])
            );
            const invoicesWithShipperAliases = (data?.carrier_invoices || []).map((i) => {
                if (!i?.partner_client_id) {
                    return i;
                }

                const shipperAlias = shippingPartnerNameMap?.[i?.partner_client_id];
                const { business_name, ...partnerClient } = i?.partner_client || {};
                return {
                    ...i,
                    partner_client: {
                        ...(partnerClient ? partnerClient : {}),
                        business_name: shipperAlias || business_name || '',
                    },
                };
            });
            return invoicesWithShipperAliases;
        } else {
            return data?.carrier_invoices || [];
        }
    }, [data, shipping_partners]);

    const invoicesMap = useMemo(() => {
        return Object.fromEntries(invoices.map((invoice) => [invoice.carrier_invoice_id, invoice]));
    }, [invoices]);

    const filtered = useMemo(() => {
        return invoices.filter((invoice) => {
            if (status === 'ALL') {
                return true;
            }

            return invoice.status === status;
        });
    }, [invoices, status]);

    const [type] = useMemo(() => {
        if (!resp) {
            return 'DEFAULT';
        }

        const { internal, carrier } = resp;
        const po = invoiceType === 'INTERNAL' ? internal : carrier;
        const type = po.length > 0 ? po[0]?.algo_type : 'DEFAULT';

        return [type];
    }, [resp, invoiceType]);

    const tabs = useMemo(() => {
        return [
            {
                label: 'All',
                value: 'ALL',
            },
            ...(invoiceType === 'INTERNAL'
                ? [
                      {
                          label: 'Unpaid',
                          value: 'UNPAID',
                      },
                  ]
                : []),
            ...(invoiceType === 'PAYABLE'
                ? [
                      {
                          label: 'Pending Onward Approval',
                          value: 'UNPAID',
                      },
                      {
                          label: 'Approved by Onward',
                          value: 'APPROVED',
                      },
                  ]
                : []),
            {
                label: 'Paid',
                value: 'PAID',
            },
        ];
    }, [invoiceType]);

    const accessorials = useAccessorials(type, tags);

    const { exportSelected: exportSelectedQuickbooks } = useExport({
        accessorials,
        invoices: selected.map((id) => invoicesMap[id]),
        columns: QUICKBOOKS_CSV_COLUMNS,
        breakdown: 'carrierBreakdown',
    });

    const { exportSelected: exportSelectedDetailed } = useExport({
        accessorials,
        invoices: selected.map((id) => invoicesMap[id]),
        columns: DETAILED_CSV_COLUMNS,
        breakdown: 'carrierBreakdown',
    });

    const handleCustomExportClick = () => {
        setOpenExportModal(true);
    }

    const handleCustomCsvExport = async (selectedColumns, templateName, exportType, includeAccessorials) => {
        if (templateName) {
            insertTemplate({
                variables: {
                    object: {
                        name: templateName,
                        user_id: user_id,
                        export_type: 'INVOICE',
                        template: {
                            ...selectedColumns,
                            Invoice: {
                                ...selectedColumns.Invoice,
                                ...(includeAccessorials && { Accessorials: true }),
                            },
                        },
                    },
                },
            });
        }

        const columns = DETAILED_CSV_COLUMNS.filter((col) => selectedColumns?.Invoice?.[col?.header]);
        const breakdown = 'carrierBreakdown';
        const invoices = selected.map((id) => invoicesMap[id]);

        const data = invoices.reduce((acc, invoice) => {
            const attr = breakdown === 'shipperBreakdown' ? 'shipper_orders' : 'orders';
            return [
                ...acc,
                ...(invoice?.[attr] || []).reduce((acc, order) => {
                    console.log('hit')
                    const accessorialsMap = Object.fromEntries(
                        accessorials.map((accessorial) => [accessorial.type, accessorial])
                    );

                    const type = !!order.oms ? 'internalBreakdown' : breakdown;
                    const chargeType = !!order.oms ? FIXED_CHARGES : MKPL_CHARGES;

                    const pickup_notes = [];
                    const delivery_notes = [];
                    const generic_notes = [];
                    const viewer = breakdown === 'shipperBreakdown' ? 'Shipper' : 'Carrier';
                    const notes = order?.notes || [];
                    notes.forEach((n) => {
                        if (n.private_to && n.private_to !== viewer) {
                            return;
                        }
                        if (n.type === 'Delivery') {
                            const parsedNote = n.note.replace(/(\r\n|\n|\r)/gm, " ");
                            delivery_notes.push(parsedNote);
                        } else if (n.type === 'Pickup') {
                            const parsedNote = n.note.replace(/(\r\n|\n|\r)/gm, " ");
                            pickup_notes.push(parsedNote);
                        } else {
                            const parsedNote = n.note.replace(/(\r\n|\n|\r)/gm, " ");
                            generic_notes.push(parsedNote);
                        }
                    });

                    return [
                        ...acc,
                        ...chargeType
                            .map(({ display, key }) => {
                                return {
                                    invoice,
                                    order,
                                    type: key,
                                    qbo_tag: order?.qbo_tag,
                                    qbo_class: order?.invoice_class,
                                    description: display,
                                    total: !!order.oms
                                        ? order?.price_breakdown?.[type]?.[key]
                                        : calcOrderSubtotal(order),
                                    delivery_notes,
                                    pickup_notes,
                                    generic_notes,
                                };
                            })
                            .filter((charge) => charge.total > 0),
                        ...(order?.price_breakdown?.[type]?.accessorials || []).map(({ type, quantity, rate }) => {
                            const meta = accessorialsMap[type] || accessorialsMap.default || 'DEFAULT';
                            return {
                                invoice,
                                order,
                                type,
                                accessorial: meta,
                                qbo_tag: order?.qbo_tag,
                                qbo_class: order?.invoice_class,
                                description: meta.label,
                                quantity,
                                rate,
                                total: quantity * rate,
                            };
                        }),
                    ];
                }, []),
            ];
        }, []);

        console.log(data);

        const TODAY = new Date();
        const blob = generateCSV(columns, data);
        const filename = `Invoices ${TODAY.getTime()}`;
        const autoclick = document.createElement('a');
        const payload = URL.createObjectURL(blob);
        autoclick.setAttribute('href', payload);
        autoclick.setAttribute('download', filename);
        autoclick.style.visibility = 'hidden';
        document.body.appendChild(autoclick);
        autoclick.click();
        document.body.removeChild(autoclick);
    }

    const callbacks = {
        setOrderFilters,
        setStatus,
        setInvoiceType,
        setSelected,
        setDueDate,
        setIssuedDate,
        handleShipperChange,
        handleCustomExportClick,
        setOpenExportModal,
        handleCustomCsvExport,
        setSearch: (e) => {
            return setSearch(e?.target?.value || '');
        },
        setPaid: () => {
            setInvoiceStatus({
                variables: {
                    ids: selected,
                    status: 'PAID',
                },
            });
        },
        paidId: (id) => {
            setInvoiceStatus({
                variables: {
                    ids: [id],
                    status: 'PAID',
                },
            });
        },
        refetch,
        exportSelectedQuickbooks,
        exportSelectedDetailed,
        gotoInvoice: (row) => {
            const invoice = row.original;
            return navigate(
                `/carrier/accounting/invoices/${invoice.carrier_invoice_id}`, 
                { state: { 
                    allInvoices: data?.allInvoiceIds || [],
                    returnTo: '/carrier/accounting/invoices',
                } }
            );
        },
        getId: (invoice) => {
            return invoice.carrier_invoice_id;
        },
    };

    const invoiceColumns = useInvoiceColumns({ shipping_partners, status, callbacks });

    const shippers = useMemo(() => {
        return shipping_partners?.map(({ shipper_id, shipper }) => ({
            label: shipper?.business_name,
            id: shipper_id,
        })) || []
    }, [shipping_partners]);

    return (
        <Context.Provider
            value={{
                state: {
                    invoices: filtered,
                    shippers,
                    status,
                    invoiceType,
                    selected,
                    totalCount: data?.carrier_invoices_aggregate?.aggregate?.count || 0,
                    loading,
                    invoiceColumns,
                    shipping_partners,
                    selectedShippers,
                    orderFilters,
                    filters: [
                        {
                            label: 'Internal',
                            value: 'INTERNAL',
                        },
                        {
                            label: 'Onward',
                            value: 'PAYABLE',
                        },
                    ],
                    tabs,
                    openExportModal,
                },
                loading: {
                    init: loading,
                },
                callbacks,
            }}
        >
            {children}
        </Context.Provider>
    );
};
