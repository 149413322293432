import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import {
    useClientUser,
    useItemQuantity,
    useTotalWeightSubscription,
    useTotalCubesSubscription,
    useOrderNotes,
} from '@/hooks';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { css } from '@emotion/react';
import { colors } from '@/styles';
import { startCase } from 'lodash';
import { hourMinuteFormatter } from '@/constants/formats';
import { recommendedEquipmentLabels } from '@/constants/recommendedEquipmentLabels';
import { LOCATION_TYPES } from '@/constants/locationTypes';
import { OnwardCard } from '../../OrderDetailsPage/Card';
import { CardTitle, CardItem, CardSubtitle, CardItemBold } from '../../OrderDetailsPage/blocks';
import { Select, MenuItem } from '@material-ui/core';
import { integerFormatter } from '@/constants/formats';
import { Context } from '../store';
import { PrimaryButton } from '@/styles/blocks';
import { format } from 'date-fns';
import { genAttributes, useWarehouseAggregate } from '@onward-delivery/core';
import { formatInTimeZone } from 'date-fns-tz';
import { asDateInTZ } from '@/utilities/convertToISO';
import { delWindowToFormattedDateRange, delWindowToFormattedDateSingle } from '@/utilities/delWindowToFormattedDate';
import { toNational } from '@/utilities/formatPhoneNumber';
import deliveryStrings from '@/constants/deliveryStrings';

export default function OrderSummaryCard() {
    const { state, callbacks } = useContext(Context);
    const { invoicingAccess } = state;
    const { handleSaveQBOClass } = callbacks;
    const order = state?.updated;
    const {
        full_address: pu_full_address,
        address: pu_address,
        unit: pu_unit,
        zip: pickup_zip,
    } = genAttributes(order, true);
    const {
        full_address: do_full_address,
        address: do_address,
        unit: do_unit,
        zip: dropoff_zip,
        location: do_location,
        location_info: do_location_info,
        location_type: do_location_type,
    } = genAttributes(order);
    const navigate = useNavigate();

    const { user_id, accountType, locations } = useClientUser();

    const [orderClass, setOrderClass] = useState(order.invoice_class);

    const qbo_classes = useMemo(() => {
        return [
            ...new Set(
                locations
                    .map((location) => location.qbo_class)
                    .filter((qbo_class) => qbo_class !== '' && qbo_class !== null)
            ),
        ];
    }, [locations]);

    const displayDeliveryTime = order?.carrier_id && user_id !== order?.carrier_id && !!order?.del_window_start;
    const displayNonSaasDeliveryTime =
        order?.carrier_id && user_id !== order?.carrier_id && !!order?.del_hour && !!order?.del_end;

    let timeRangeStart = '';
    let timeRangeEnd = '';
    if (displayDeliveryTime) {
        timeRangeStart = order.del_window_start;
        timeRangeEnd = order.del_window_end;
    } else if (displayNonSaasDeliveryTime) {
        const tz = zipcode_to_timezone.lookup(order.dropoff_zip) || 'America/New_York';

        timeRangeStart = new Date(new Date().toLocaleString('en-US', { timeZone: tz }));
        timeRangeEnd = new Date(new Date().toLocaleString('en-US', { timeZone: tz }));

        timeRangeStart.setHours(order.del_hour);
        timeRangeEnd.setHours(order.del_end);

        if (order.del_hour % 1 !== 0) {
            timeRangeStart.setMinutes(30);
        }
        if (order.del_end % 1 !== 0) {
            timeRangeEnd.setMinutes(30);
        }

        timeRangeStart = timeRangeStart.toISOString();
        timeRangeEnd = timeRangeEnd.toISOString();
    }

    const {
        ['2_man_team']: twoManTeam,
        ['3_man_team']: threeManTeam,
        lift_gate,
        ...otherEquipment
    } = useMemo(() => {
        return order.recommended_equipment
            ? Object.keys(order.recommended_equipment)
                  .filter((key) => order.recommended_equipment[key])
                  .reduce((acc, val) => ({ ...acc, [val]: recommendedEquipmentLabels[val] }), {})
            : {};
    }, [order]);

    const orderTypeText = useMemo(() => {
        const isInternal =
            order?.carrier_id === user_id ||
            (order?.shipper_id === user_id &&
                order.oms &&
                (order?.carrier_id === user_id || order?.carrier_id === null));

        if (accountType === 'carrier') {
            const isClaimed = order?.shipper_id !== order?.carrier_id && order?.carrier_id === user_id && !order.oms;
            if (isClaimed) {
                return <>Claimed Order / Delivery</>;
            } else if (!isClaimed && order.oms) {
                return <>Internal Order / Delivery</>;
            } else
                return (
                    <>
                        <span color="#59b863">Sent to Onward </span>/ Delivery
                    </>
                );
        } else {
            return (
                <>
                    {isInternal ? (
                        'Internal'
                    ) : (
                        <span
                            css={css`
                                color: ${colors.greens.primary};
                            `}
                        >
                            Onward Order
                        </span>
                    )}

                    {order?.order_type && ` / ${startCase(order.order_type)}`}
                </>
            );
        }
    }, [order]);

    const formatTimestamp = useCallback(
        (datestr) => {
            if (!datestr || !order || !pickup_zip) return 'N/A';
            const tz =
                zipcode_to_timezone.lookup(order[pickup_zip]) || Intl.DateTimeFormat().resolvedOptions().timeZone;
            return formatInTimeZone(asDateInTZ(datestr, tz), tz, 'EEE, MMM d, yyyy');
        },
        [order, pickup_zip]
    );

    const timeframe = useMemo(() => {
        if (!order) return '-';
        return order.del_window_start
            ? delWindowToFormattedDateRange(order.del_window_start, order.del_window_end, order[dropoff_zip])
            : '-';
    }, [order, dropoff_zip]);

    const duration = order?.duration_seconds && hourMinuteFormatter.format(order?.duration_seconds / 60);

    const [pickupRoute, dropoffRoute] = useMemo(() => {
        const sortedRoutes = [...(order?.routes || [])].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        return [
            sortedRoutes.find((mapping) => mapping.type === 'PICKUP')?.route,
            sortedRoutes.find((mapping) => mapping.type !== 'PICKUP')?.route,
        ];
    }, [order]);

    const pickupLocationName = useMemo(() => {
        if (!order?.order_shipper?.locations) return '';
        const pickupLocation = order.order_shipper.locations.find(
            (location) => location.business_address === order.pickup_street_address
        );
        return pickupLocation?.location_name || '';
    });

    const totalWeight = useTotalWeightSubscription([order]);
    const totalItems = useItemQuantity([order]);
    const totalVolume = useTotalCubesSubscription([order]);
    const orderNotes = useOrderNotes([order], accountType);
    const warehouse = useWarehouseAggregate(order?.wh_events || []);

    const daysInStorage = useMemo(() => {
        if (!warehouse?.received_date) return 'N/A';
        if (!order?.completion_time) return 'N/A';

        const start = new Date(warehouse.received_date).getTime();
        const end = new Date(order.completion_time).getTime();
        return Math.round((end - start) / 1000 / 60 / 60 / 24);
    }, [order, warehouse])

    const CardRow = ({ children, ...rest }) => {
        return (
            <Grid
                container
                item
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                style={{ marginTop: '0.25rem', marginBottom: '0.25rem' }}
                {...rest}
            >
                {children}
            </Grid>
        );
    };

    const HalfGridItem = ({ children, props }) => {
        return (
            <Grid item style={{ maxWidth: '50%', textAlign: 'right' }} {...props}>
                {children}
            </Grid>
        );
    };

    const handleRouteClick = (routeId) => {
        if (routeId) {
            navigate(`/${accountType}/routedetails/${routeId}`, {
                state: { source: 'OrderSummary' },
            });
        }
    };
    const RouteNumber = ({ route }) => {
        if (!route) return '-';
        const isClickable = route.carrier_id === user_id;

        return isClickable ? (
            <span
                onClick={(e) => {
                    e.stopPropagation();
                    handleRouteClick(route.route_id);
                }}
                css={css`
                    cursor: pointer;
                    color: ${colors.greens.primary};
                    text-decoration: underline;
                    &:hover {
                        color: ${colors.blues.dark};
                    }
                `}
            >
                {route.route_number}
            </span>
        ) : (
            <span>{route.route_number}</span>
        );
    };

    return (
        <>
            <OnwardCard>
                <Grid container justifyContent="space-between" direction="row">
                    <Grid item>
                        <CardRow style={{ marginBottom: 0 }}>
                            <Grid item>
                                <CardTitle>
                                    {order.pickup_city}, {order.pickup_state} - {order.dropoff_city},{' '}
                                    {order.dropoff_state}
                                </CardTitle>
                            </Grid>
                        </CardRow>
                        <CardRow style={{ marginTop: 0, marginBottom: '1.75rem' }}>
                            <Grid item>
                                <CardSubtitle>{orderTypeText}</CardSubtitle>
                            </Grid>
                        </CardRow>
                    </Grid>
                    <Grid item>
                        <PrimaryButton onClick={() => window.open(`/order/${state.updated.order_id}`, '_blank')}>View More</PrimaryButton>
                    </Grid>
                    

                </Grid>

                <CardRow>
                    <Grid item>
                        <CardItem>PO / Ref #</CardItem>
                    </Grid>
                    <HalfGridItem>
                        <CardItemBold>
                            {order?.po_number || order?.reference_id
                                ? `${order?.po_number || '--'} / ${order?.reference_id || '--'}`
                                : 'N/A'}
                        </CardItemBold>
                    </HalfGridItem>
                </CardRow>

                {/* Created */}
                <CardRow>
                    <Grid item>
                        <CardItem>Created</CardItem>
                    </Grid>
                    <HalfGridItem>
                        <CardItemBold>
                            {order.created_at ? format(new Date(order.created_at), 'EEE, MMM d, yyyy') : 'N/A'}
                        </CardItemBold>
                    </HalfGridItem>
                </CardRow>

                {/* Available */}
                <CardRow>
                    <Grid item>
                        <CardItem>Available</CardItem>
                    </Grid>
                    <HalfGridItem>
                        <CardItemBold>
                            {order?.first_available ? format(new Date(order.first_available, 'MM/dd/yyyy')) : '--'}
                        </CardItemBold>
                    </HalfGridItem>
                </CardRow>

                {/* Scheduled Delivery Date */}
                <CardRow>
                    <Grid item>
                        <CardItem>Scheduled Delivery Date</CardItem>
                    </Grid>
                    <HalfGridItem>
                        <CardItemBold>{order.delivery_date_formatted || 'TBD'}</CardItemBold>
                    </HalfGridItem>
                </CardRow>

                {/* Preferred Delivery Date */}
                <CardRow>
                    <Grid item>
                        <CardItem>Preferred Delivery Date</CardItem>
                    </Grid>
                    <HalfGridItem>
                        <CardItemBold>{order.preferred_delivery_date_formatted || 'N/A'}</CardItemBold>
                    </HalfGridItem>
                </CardRow>

                {/* Preferred Delivery Date */}
                <CardRow>
                    <Grid item>
                        <CardItem>Pickup Time</CardItem>
                    </Grid>
                    <HalfGridItem>
                        <CardItemBold>
                            {order?.ltl_pickup_completed
                                ? formatTimestamp(order?.ltl_pickup_completed) +
                                  ' ' +
                                  delWindowToFormattedDateSingle(order?.ltl_pickup_completed, order[pickup_zip])
                                : 'N/A'}
                        </CardItemBold>
                    </HalfGridItem>
                </CardRow>

                {/* Delivery Speed */}
                {/* <CardRow>
                <Grid item>
                    <CardItem>Delivery Speed</CardItem>
                </Grid>
                <HalfGridItem>
                    <CardItemBold>
                        TBD
                    </CardItemBold>
                </HalfGridItem>
                </CardRow> */}

                {/* Delivery Day Speed */}
                {/* <CardRow>
                <Grid item>
                    <CardItem>Delivery Day Speed</CardItem>
                </Grid>
                <HalfGridItem>
                    <CardItemBold>
                        TBD
                    </CardItemBold>
                </HalfGridItem>
                </CardRow> */}

                {/* Arrival Time */}
                <CardRow>
                    <Grid item>
                        <CardItem>Arrival Time</CardItem>
                    </Grid>
                    <HalfGridItem>
                        <CardItemBold>
                            {order?.completion_time
                                ? delWindowToFormattedDateSingle(order?.ltl_dropoff_arrival, order[dropoff_zip])
                                : 'N/A'}
                        </CardItemBold>
                    </HalfGridItem>
                </CardRow>

                {/* Completed Time */}
                <CardRow>
                    <Grid item>
                        <CardItem>Completed Time</CardItem>
                    </Grid>
                    <HalfGridItem>
                        <CardItemBold>
                            {order?.completion_time
                                ? delWindowToFormattedDateSingle(order?.completion_time, order[dropoff_zip])
                                : 'N/A'}
                        </CardItemBold>
                    </HalfGridItem>
                </CardRow>

                {/* Delivery Timeframe */}
                <CardRow>
                    <Grid item>
                        <CardItem>Delivery Timeframe</CardItem>
                    </Grid>
                    <HalfGridItem>
                        <CardItemBold>{timeframe}</CardItemBold>
                    </HalfGridItem>
                </CardRow>

                {/* Estimated Distance */}
                <CardRow>
                    <Grid item>
                        <CardItem>Estimated Distance</CardItem>
                    </Grid>
                    <HalfGridItem>
                        <CardItemBold>{order.distance ? `${order.distance}` : 'N/A'}</CardItemBold>
                    </HalfGridItem>
                </CardRow>

                {/* Estimated Trip Time */}
                <CardRow>
                    <Grid item>
                        <CardItem>Estimated Trip Time</CardItem>
                    </Grid>
                    <HalfGridItem>
                        <CardItemBold>{duration || 'N/A'}</CardItemBold>
                    </HalfGridItem>
                </CardRow>

                {/* Estimated Service Time */}
                {/* <CardRow>
                    <Grid item>
                        <CardItem>Estimated Service Time</CardItem>
                    </Grid>
                    <HalfGridItem>
                        <CardItemBold>
                            TBD
                        </CardItemBold>
                    </HalfGridItem>
                    </CardRow> */}

                {/* Actual Service Time */}
                {/* <CardRow>
                    <Grid item>
                        <CardItem>Actual Service Time</CardItem>
                    </Grid>
                    <HalfGridItem>
                        <CardItemBold>
                            TBD
                        </CardItemBold>
                    </HalfGridItem>
                    </CardRow> */}

                {/* Route */}
                <CardRow>
                    <Grid item>
                        <CardItem>Route</CardItem>
                    </Grid>
                    <HalfGridItem>
                        <CardItemBold>
                            {pickupRoute ? <RouteNumber route={pickupRoute} /> : null}
                            {pickupRoute && dropoffRoute ? ' / ' : null}
                            <RouteNumber route={dropoffRoute} />
                        </CardItemBold>
                    </HalfGridItem>
                </CardRow>

                {/* Tags */}
                <CardRow>
                    <Grid item>
                        <CardItem>Tags</CardItem>
                    </Grid>
                    <HalfGridItem>
                        <CardItemBold>
                            {order?.tags?.length
                                ? order.tags.map((tag_mapping) => tag_mapping.tag.tag).join(', ')
                                : 'N/A'}
                        </CardItemBold>
                    </HalfGridItem>
                </CardRow>

                {/* Service Level */}
                <CardRow>
                    <Grid item>
                        <CardItem>Service Level</CardItem>
                    </Grid>
                    <HalfGridItem>
                        <CardItemBold>
                            {order?.service_level?.service_level || LOCATION_TYPES[order.dropoff_location_type] || '--'}
                        </CardItemBold>
                    </HalfGridItem>
                </CardRow>

                {/* Equipment */}
                <CardRow>
                    <Grid item>
                        <CardItem>Equipment</CardItem>
                    </Grid>
                    <HalfGridItem>
                        <CardItemBold>{Object.values(otherEquipment).join(', ')}</CardItemBold>
                    </HalfGridItem>
                </CardRow>

                {/* Palletized dimensions */}
                {!!order?.palletize_returns && (
                    <CardRow>
                        <Grid item>
                            <CardItem>Palletized dimensions</CardItem>
                        </Grid>
                        <HalfGridItem>
                            <CardItemBold>
                                {['L', 'W', 'H'].every((dimension) => order?.palletized_dimensions?.[dimension]) ? (
                                    <CardItemBold>{`${['L', 'W', 'H']
                                        .map((key) => `${order.palletized_dimensions?.[key]}${key}`)
                                        .join(', ')}`}</CardItemBold>
                                ) : (
                                    ''
                                )}
                            </CardItemBold>
                        </HalfGridItem>
                    </CardRow>
                )}

                {/* Attached Documents */}
                <CardRow>
                    <Grid item>
                        <CardItem>Attached Documents</CardItem>
                    </Grid>
                    <HalfGridItem
                        css={css`
                            display: flex;
                            flex-direction: column;
                        `}
                    >
                        {order?.documents?.length ? (
                            order.documents.map((doc_mapping) => (
                                <a href={doc_mapping.document.link} target="_blank" key={`doc-${doc_mapping.mapping_id}`}>
                                    <CardItemBold
                                        css={css`
                                            cursor: pointer;
                                            color: ${colors.greens.primary};
                                            text-decoration: underline;
                                            &:hover {
                                                color: ${colors.blues.dark};
                                            }
                                        `}
                                    >
                                        {doc_mapping.document.name}
                                    </CardItemBold>
                                </a>
                            ))
                        ) : (
                            <CardItemBold>None</CardItemBold>
                        )}
                    </HalfGridItem>
                </CardRow>

                <CardRow>
                    <CardTitle css={css`margin-top: 30px`}>
                        Warehouse
                    </CardTitle>
                </CardRow>
                <CardRow>
                    <Grid item>
                        <CardItem>Received</CardItem>
                    </Grid>
                    <HalfGridItem>
                        <CardItemBold>{warehouse?.received_date ? format(new Date(warehouse.received_date), 'EEE, MMM d, yyyy') : 'N/A'}</CardItemBold>
                    </HalfGridItem>
                </CardRow>
                <CardRow>
                    <Grid item>
                        <CardItem>Days in Storage</CardItem>
                    </Grid>
                    <HalfGridItem>
                        <CardItemBold>{daysInStorage}</CardItemBold>
                    </HalfGridItem>
                </CardRow>

                <CardRow>
                    <CardTitle
                        css={css`
                            margin-top: 30px;
                        `}
                    >
                        Pickup
                    </CardTitle>
                </CardRow>
                {pickupLocationName && (
                    <CardRow>
                        <Grid item>
                            <CardItem>{pickupLocationName}</CardItem>
                        </Grid>
                    </CardRow>
                )}
                <CardRow>
                    <Grid item>
                        <CardItem>{toNational(order.pickup_phone)}</CardItem>
                    </Grid>
                </CardRow>
                <CardRow>
                    <Grid item>
                        <CardItem>{order.pickup_email || 'Email Not Provided'}</CardItem>
                    </Grid>
                </CardRow>
                <CardRow>
                    <Grid item>
                        <CardItem>
                            {order[pu_full_address] ||
                                `${order[pu_address]}${order[pu_unit] ? `, Unit ${order[pu_unit]}` : ''}`}
                        </CardItem>
                    </Grid>
                </CardRow>
                <CardRow>
                    <Grid item>
                        <CardItem>
                            First Available:{' '}
                            {order?.first_available_date ? formatTimestamp(order.first_available_date) : 'TBD'}
                        </CardItem>
                    </Grid>
                </CardRow>
                {!!orderNotes?.pickup?.length && (
                    <CardRow>
                        <Grid item>
                            <CardItem>Comments: {orderNotes.pickup.map((n) => n.note).join(' / ')}</CardItem>
                        </Grid>
                    </CardRow>
                )}

                <CardRow>
                    <CardTitle
                        css={css`
                            margin-top: 30px;
                        `}
                    >
                        Dropoff
                    </CardTitle>
                </CardRow>
                <CardRow>
                    <Grid item>
                        <CardItem>{order.dropoff_name}</CardItem>
                    </Grid>
                </CardRow>
                <CardRow>
                    <Grid item>
                        <CardItem>{toNational(order.dropoff_phone)}</CardItem>
                    </Grid>
                </CardRow>
                <CardRow>
                    <Grid item>
                        <CardItem>{order.dropoff_email || 'Email Not Provided'}</CardItem>
                    </Grid>
                </CardRow>
                <CardRow>
                    <Grid item>
                        <CardItem>
                            {order[do_full_address] ||
                                `${order[do_address]}${order[do_unit] ? `, Unit ${order[do_unit]}` : ''}`}
                        </CardItem>
                    </Grid>
                </CardRow>
                <CardRow>
                    <Grid item>
                        <CardItem>
                            {order[do_location]}
                            {order[do_location_info] ? ', ' + order[do_location_info] : ''}
                            {order[do_location_type]
                                ? ', ' + deliveryStrings.dropOffLocationType[order[do_location_type]]
                                : ''}
                        </CardItem>
                    </Grid>
                </CardRow>
                {!!orderNotes?.delivery?.length && (
                    <CardRow>
                        <Grid item>
                            <CardItem>Comments: {orderNotes.delivery.map((n) => n.note).join(' / ')}</CardItem>
                        </Grid>
                    </CardRow>
                )}
            </OnwardCard>
        </>
    );
}
