import React, { useState, useMemo, useContext, forwardRef } from 'react';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { addHours, format } from 'date-fns';
import { css } from '@emotion/react';
import { useQuery } from '@apollo/client';
import { Grid, MenuItem } from '@material-ui/core';
import GoogleMap, { Marker } from '@/components/GoogleMap';
import AddressAutocomplete from '@/components/ShipmentForm/ModifiedAddressAutocomplete';
import { colors } from '@/styles';
import { asBrowserDate, asDateInTZ } from '@/utilities/convertToISO';

import {
    addressSplit,
    sanitizeEnum,
    VALID_LOCATION_TYPES,
    VALID_DROPOFF_TYPES as VALID_PICKUP_TYPES,
} from '../../utilities/processOrders';
import StartTimeSelect from '../../../misc/StartTimeSelect';
import { QUERY_LOCATIONS_BY_CLIENT_ID } from '../../graphql/queries';
import { useSwappedAttributes } from '../../hooks';
import {
    ErrorText,
    TextField,
    OnwardToggle,
    RadioLabelInfo,
    ToggleBlurb,
    TabSection,
    TabTitle,
    TabRow,
} from '../../blocks';
import { ModalContext } from './';

import {
    SingleDatePicker,
    SecondaryContactBtn,
    StoreSelect,
    NewAddressCheckbox,
    LocationType,
    PickupType,
    StoreDetails,
    OnwardTextField,
    OnwardPhoneInput,
} from '../InputFields';
import { useClientUser, useOrderNotes } from '@/hooks';

const ContactFields = ({ order, attrs, hasError, isDirty, callbacks, opt }) => {
    const [name, phone, email] = attrs;

    return (
        <TabRow>
            <Grid item xs={4}>
                <OnwardTextField
                    fullWidth
                    label="Name"
                    variant="outlined"
                    value={order[name]}
                    onBlur={() => callbacks.makeDirty([name])}
                    onChange={(e) => callbacks.modifyOrder({ [name]: e.target.value })}
                    error={hasError[name] && (opt.startDirty || isDirty[name])}
                />
            </Grid>
            <Grid item xs={4}>
                <OnwardPhoneInput
                    fullWidth
                    label="Phone"
                    variant="outlined"
                    value={order[phone]}
                    onBlur={() => callbacks.makeDirty([phone])}
                    onChange={(e, value) => callbacks.modifyOrder({ [phone]: value })}
                    error={hasError[phone] && (opt.startDirty || isDirty[phone])}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    fullWidth
                    label="Email"
                    variant="outlined"
                    value={order[email]}
                    onBlur={() => callbacks.makeDirty([email])}
                    onChange={(e) => callbacks.modifyOrder({ [email]: e.target.value })}
                />
            </Grid>
        </TabRow>
    );
};

const PickupTab = forwardRef(({ opt }, ref) => {
    const { disableAddressEditing, disableGeocoding, startDirty, isInternal } = opt;
    const { state: modalState, callbacks } = useContext(ModalContext);
    const { order, isDirty, hasError, errors } = modalState;
    const { user_id, shipping_partners, client_id } = useClientUser();
    const [collapsed, setCollapsed] = useState(false);
    const [viewMapOverride, setViewMap] = useState(false);
    const [hasSecondaryContactOverride, setHasSecondaryContact] = useState(null);
    const [hasNewAddressOverride, setHasNewAddress] = useState(null);
    const [storeOverride, setStoreOverride] = useState(null);

    const clientId = useMemo(() => {
        if (order.carrier_id && (order.wh_events?.find((e) => e.action === 'START:RECEIVING') || order.oms)) {
            return order.carrier_id;
        }
        return order.shipper_id || client_id;
    }, [order]);

    const { data: locationsData } = useQuery(QUERY_LOCATIONS_BY_CLIENT_ID, {
        variables: {
            client_id: clientId,
        },
        onError: (e) => {
            callbacks.onError(e);
        },
    });

    const {
        is_custom,
        geocode_failed,
        address,
        city,
        state,
        street,
        zip,
        lat,
        long,
        comments,
        unit,
        location,
        location_type,
    } = useSwappedAttributes(order, true);

    const locations = useMemo(() => {
        return locationsData?.locations || [];
    }, [locationsData]);

    const viewMap = useMemo(() => {
        return order[is_custom] || viewMapOverride;
    }, [viewMapOverride, order, is_custom]);

    const hasSecondaryContact = useMemo(() => {
        return hasSecondaryContactOverride !== null
            ? hasSecondaryContactOverride
            : order?.secondary_pickup_contact_name?.length > 0;
    }, [order, hasSecondaryContactOverride]);

    const store = useMemo(() => {
        if (!storeOverride && locations.length) {
            const match = locations.findIndex(
                (location) =>
                    location.address === order[address] ||
                    (location.location_name && location.location_name === order.chosen_store_name)
            );
            return match > -1 ? match : null;
        }
        return storeOverride;
    }, [storeOverride, order, locations]);

    const hasNewAddress = useMemo(() => {
        let hasNewAddress = false;
        if (hasNewAddressOverride !== null) {
            hasNewAddress = hasNewAddressOverride;
        } else {
            hasNewAddress =
                (locations?.length || 0) === 0 ||
                ((order[address]?.length || 0) > 0 && store === null) ||
                hasError[location] ||
                hasError[location_type] ||
                order[is_custom];
        }

        return hasNewAddress;
    }, [hasNewAddressOverride, order, store, hasError, location, location_type, is_custom, locations]);

    const coords = useMemo(() => {
        if (order && lat && long) {
            return order[lat] && order[long] ? { lat: order[lat], lng: order[long] } : null;
        }

        return null;
    }, [order, lat, long]);

    const allowCustom = useMemo(() => {
        return order[lat] || order[long] || order[geocode_failed];
    }, [order, lat, long, geocode_failed]);

    const orderTZ = useMemo(() => {
        return order[zip] ? zipcode_to_timezone.lookup(order[zip]) : Intl.DateTimeFormat().resolvedOptions().timeZone;
    }, [order, zip]);

    const handleStoreSelect = async (e) => {
        setStoreOverride(e.target.value);
        let _store = locations[e.target.value];

        const [address1, address2] = addressSplit(_store.business_address);
        if (disableGeocoding) {
            callbacks.modifyOrder({
                [is_custom]: false,
                [geocode_failed]: false,
                [unit]: address2,
                [address]: [address1, _store.business_city, _store.business_state, _store.business_zip].join(', '),
                [street]: address1,
                [city]: _store.business_city,
                [state]: _store.business_state,
                [zip]: _store.business_zip,
            });
        } else {
            const results = await callbacks.enrichOrder(
                address,
                [
                    address1,
                    ...(_store.business_city ? [_store.business_city] : []),
                    ...(_store.business_state ? [_store.business_state] : []),
                    ...(_store.business_zip ? [_store.business_zip] : []),
                ].join(', '),
                { pickup: true }
            );
            if (results.errors.geocodeFailed) {
                callbacks.onError(new Error('Geocode failed. Please try again.'));
            }

            callbacks.modifyOrder({
                [is_custom]: false,
                [unit]: address2,
                [location]: sanitizeEnum(_store.location_type, VALID_PICKUP_TYPES) || 'Business',
                [location_type]: sanitizeEnum(_store.location_info, VALID_LOCATION_TYPES) || 'rollUpDoor',
            });
        }

        callbacks.makeDirty([is_custom, unit, address, street, city, state, zip]);
    };

    let addressError = null;
    if (order.auto_assign_failed) {
        addressError = (
            <Grid
                css={css`
                    color: ${colors.reds[1]};
                    margin-bottom: 4px;
                `}
                container
                direction="row"
            >
                <ErrorText>Address provided could not be auto assigned to carrier</ErrorText>
            </Grid>
        );
    } else if (
        allowCustom &&
        hasNewAddress &&
        ([address, street, city, state, zip, lat, long].some(
            (attr) => hasError[attr] && (startDirty || isDirty[attr])
        ) ||
            order[geocode_failed])
    ) {
        addressError = (
            <Grid
                css={css`
                    color: ${colors.reds[1]};
                    margin-bottom: 4px;
                `}
                container
                direction="row"
            >
                <ErrorText>Address is invalid</ErrorText>
            </Grid>
        );
    } else if (
        allowCustom &&
        hasNewAddress &&
        ['distance', 'miles', 'duration_seconds'].some((attr) => hasError[attr] && (startDirty || isDirty[attr]))
    ) {
        addressError = (
            <Grid
                css={css`
                    color: ${colors.reds[1]};
                    margin-bottom: 4px;
                `}
                container
                direction="row"
            >
                <ErrorText>Failed to find directions between pick up and dropoff address</ErrorText>
            </Grid>
        );
    }

    const isImport = window.location.pathname === '/import' || window.location.pathname.includes('job');
    const userType = (() => {
        if (!isImport && order?.carrier_id && client_id !== order?.shipper_id) {
            return 'Carrier';
        } else {
            return 'Shipper';
        }
    })();
    const orderNotes = useOrderNotes([order], userType);
    const currentNote = orderNotes?.pickup?.[0] || {};

    const modifyInitialNote = (e) => {
        if (currentNote?.note_id) {
            const currentNotes = [...order.notes];
            const currentNoteIdx = currentNotes.findIndex((n) => n.note_id === currentNote.note_id);
            currentNotes[currentNoteIdx] = {
                ...currentNote,
                note: e.target.value,
            };
            callbacks.modifyOrder({ notes: currentNotes });
        } else {
            callbacks.addNote({
                note: e.target.value,
                source_user_type: userType,
                source_user_id: user_id,
                is_acknowledgement: false,
                requires_acknowledgement: false,
                acknowledged_by_shipper: false,
                acknowledged_by_admin: false,
                private_to: null,
                type: 'Pickup',
            });
        }
    };

    return (
        <>
            <TabTitle ref={ref} collapsed={collapsed} callbacks={{ setCollapsed }}>
                Pickup
            </TabTitle>
            {collapsed ? null : (
                <>
                    <TabSection>Contact Info</TabSection>
                    <ContactFields
                        order={order}
                        isDirty={isDirty}
                        attrs={['pickup_name', 'pickup_phone', 'pickup_email']}
                        hasError={hasError}
                        callbacks={callbacks}
                        opt={opt}
                    />

                    <TabRow>
                        <Grid item>
                            <SecondaryContactBtn
                                hasSecondaryContact={hasSecondaryContact}
                                onClick={() => {
                                    setHasSecondaryContact(!hasSecondaryContact);
                                    callbacks.modifyOrder({
                                        secondary_pickup_contact_name: null,
                                        secondary_pickup_contact_phone: null,
                                        secondary_pickup_contact_email: null,
                                    });
                                }}
                            />
                        </Grid>
                    </TabRow>

                    {hasSecondaryContact && (
                        <ContactFields
                            order={order}
                            isDirty={isDirty}
                            attrs={[
                                'secondary_pickup_contact_name',
                                'secondary_pickup_contact_phone',
                                'secondary_pickup_contact_email',
                            ]}
                            hasError={hasError}
                            callbacks={callbacks}
                            opt={opt}
                        />
                    )}

                    <TabSection>Pickup Date</TabSection>

                    <TabRow>
                        {!opt.enablePickupDateEditing ? (
                            <Grid item xs={6}>
                                <SingleDatePicker
                                    label="First Available"
                                    tooltipText="This is the earliest date that these orders can be scheduled for delivery. Will be defaulted to today if left blank."
                                    value={
                                        order.first_available_date
                                            ? format(new Date(order.first_available_date), 'yyyy-MM-dd')
                                            : null
                                    }
                                    onChange={(e) =>
                                        callbacks.modifyOrder({
                                            first_available_date: asBrowserDate(e.target.value).toISOString(),
                                        })
                                    }
                                    error={
                                        hasError.first_available_date && (startDirty || isDirty.first_available_date)
                                    }
                                />
                            </Grid>
                        ) : (
                            <>
                                <Grid item xs={4}>
                                    <TextField
                                        select
                                        type="text"
                                        variant="outlined"
                                        fullWidth
                                        label="Pickup"
                                        value={order.is_same_day}
                                        onChange={(e) => {
                                            const utc = new Date(new Date().setUTCHours(0, 0, 0, 0)).toISOString();
                                            const hour = new Date(
                                                addHours(new Date().setMinutes(0, 0, 0), 1)
                                            ).toISOString();
                                            const today = asDateInTZ(utc, orderTZ).toISOString();

                                            callbacks.modifyOrder({
                                                is_same_day: e.target.value,
                                                ...(e.target.value
                                                    ? {
                                                          delivery_date: today,
                                                          pickup_date: today,
                                                          firstAvailableDate: today,
                                                          pickup_window_end: hour,
                                                      }
                                                    : {
                                                          delivery_date: null,
                                                          pickup_date: null,
                                                          firstAvailableDate: null,
                                                          pickup_window_end: null,
                                                      }),
                                            });
                                        }}
                                    >
                                        <MenuItem key="yes" value={true}>
                                            Now
                                        </MenuItem>
                                        <MenuItem key="no" value={false}>
                                            Later
                                        </MenuItem>
                                    </TextField>
                                </Grid>
                                {!order.is_same_day ? (
                                    <>
                                        <Grid item xs={4}>
                                            <SingleDatePicker
                                                label="First Available"
                                                tooltipText="This is the earliest date that these orders can be scheduled for delivery. Will be defaulted to today if left blank."
                                                value={
                                                    order.first_available_date
                                                        ? format(
                                                              asBrowserDate(order.first_available_date),
                                                              'yyyy-MM-dd'
                                                          )
                                                        : null
                                                }
                                                onChange={(e) => {
                                                    let dateStr = null;
                                                    let hour = null;

                                                    if (e.target.value) {
                                                        const utc = new Date(
                                                            new Date(e.target.value).setUTCHours(0, 0, 0, 0)
                                                        ).toISOString();
                                                        dateStr = asDateInTZ(utc, orderTZ).toISOString();

                                                        hour = addHours(
                                                            new Date(new Date(dateStr).setUTCMinutes(0, 0, 0)),
                                                            8
                                                        ).toISOString();
                                                    }

                                                    callbacks.modifyOrder({
                                                        first_available_date: dateStr,
                                                        delivery_date: dateStr,
                                                        pickup_window_end: hour ? hour : null,
                                                    });
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <StartTimeSelect
                                                onChange={(e) => {
                                                    callbacks.modifyOrder({
                                                        pickup_window_end: new Date(e.target.value).toISOString(),
                                                    });
                                                }}
                                                value={
                                                    order.pickup_window_end
                                                        ? new Date(order.pickup_window_end).getTime()
                                                        : 0
                                                }
                                                timeZone={orderTZ}
                                                deliveryDate={
                                                    order.first_available_date
                                                        ? order.first_available_date
                                                        : new Date().toISOString()
                                                }
                                                disabled={!order.first_available_date}
                                                interval={30}
                                                label="Pickup Time"
                                                filter={({ local }) => {
                                                    const hours = local.getHours();
                                                    const minutes = local.getMinutes();
                                                    return (
                                                        hours >= 6 && (hours < 20 || (hours === 20 && minutes === 0))
                                                    );
                                                }}
                                                InputLabelProps={{
                                                    shrink: !!order.pickup_window_end,
                                                }}
                                            />
                                        </Grid>
                                    </>
                                ) : null}
                            </>
                        )}
                    </TabRow>

                    <TabSection>Address</TabSection>

                    <TabRow>
                        <Grid item xs={9}>
                            <StoreSelect
                                value={store}
                                onChange={handleStoreSelect}
                                error={hasError[address] && !hasNewAddress && (startDirty || isDirty[address])}
                                storeList={locations || []}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <NewAddressCheckbox
                                hasNewAddress={hasNewAddress}
                                disabled={locations?.length === 0}
                                onChange={() => {
                                    const next = !hasNewAddress;
                                    if (!next) {
                                        callbacks.modifyOrder({ [is_custom]: false });
                                    }
                                    setHasNewAddress(next);
                                }}
                            />
                        </Grid>
                    </TabRow>

                    {addressError ? addressError : null}

                    {!hasNewAddress && store !== null ? (
                        <>
                            <Grid
                                container
                                direction="column"
                                css={css`
                                    margin-bottom: 12px;
                                `}
                            >
                                <StoreDetails
                                    storeName={locations?.[store]?.location_name}
                                    fullAddress={order[address]}
                                    locationType={order[location]}
                                    pickupType={order[location_type]}
                                />
                            </Grid>
                            <TabRow>
                                <Grid item xs={12}>
                                    <TextField
                                        type="text"
                                        variant="outlined"
                                        fullWidth
                                        label="Comments"
                                        multiline={true}
                                        value={currentNote?.note || ''}
                                        onChange={modifyInitialNote}
                                    />
                                </Grid>
                            </TabRow>
                        </>
                    ) : (
                        <>
                            <TabRow>
                                <Grid item xs={9}>
                                    {order[is_custom] ? (
                                        <OnwardTextField
                                            variant="outlined"
                                            color="primary"
                                            label="Address"
                                            value={order[street]}
                                            error={hasError[street] && (startDirty || isDirty[street])}
                                            disabled={disableAddressEditing}
                                            onBlur={() => callbacks.makeDirty([street])}
                                            onChange={(e) => {
                                                callbacks.modifyOrder({
                                                    [street]: e.target.value,
                                                    [geocode_failed]: false,
                                                    auto_assign_failed: false,
                                                });
                                                callbacks.makeDirty([address, street]);
                                            }}
                                            fullWidth
                                        />
                                    ) : (
                                        <AddressAutocomplete
                                            disabled={disableAddressEditing}
                                            state={{
                                                street: order[street],
                                                city: order[city],
                                                state: order[state],
                                                zip: order[zip],
                                            }}
                                            handleAddressUpdate={async (value, split) => {
                                                if (disableGeocoding) {
                                                    callbacks.modifyOrder({
                                                        [geocode_failed]: false,
                                                        [address]: value,
                                                        [street]: split.street,
                                                        [city]: split.city,
                                                        [state]: split.state,
                                                        [zip]: split.zip,
                                                        auto_assign_failed: false,
                                                    });
                                                } else {
                                                    const results = await callbacks.enrichOrder(address, value, {
                                                        pickup: true,
                                                    });
                                                    if (results.errors.geocodeFailed) {
                                                        callbacks.onError(
                                                            new Error('Geocode failed. Please try again.')
                                                        );
                                                    }
                                                }

                                                callbacks.makeDirty([address, street, city, state, zip]);
                                            }}
                                            error={
                                                (hasNewAddress &&
                                                    [
                                                        address,
                                                        street,
                                                        city,
                                                        state,
                                                        zip,
                                                        lat,
                                                        long,
                                                        'distance',
                                                        'miles',
                                                        'duration_seconds',
                                                    ].some(
                                                        (attr) => hasError[attr] && (startDirty || isDirty[attr])
                                                    )) ||
                                                order[geocode_failed] ||
                                                order.auto_assign_failed
                                            }
                                        />
                                    )}
                                </Grid>
                                <Grid item xs={3}>
                                    <OnwardTextField
                                        variant="outlined"
                                        color="primary"
                                        label="Unit/Suite #"
                                        value={order[unit]}
                                        onChange={(e) => callbacks.modifyOrder({ [unit]: e.target.value })}
                                        fullWidth
                                    />
                                </Grid>
                            </TabRow>
                            <TabRow>
                                <Grid item xs={4}>
                                    <OnwardTextField
                                        fullWidth
                                        label="City"
                                        variant="outlined"
                                        color="primary"
                                        value={order[city]}
                                        error={hasError[city] && order[is_custom] && (startDirty || isDirty[city])}
                                        onBlur={() => callbacks.makeDirty([city])}
                                        onChange={(e) => callbacks.modifyOrder({ [city]: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <OnwardTextField
                                        fullWidth
                                        variant="outlined"
                                        color="primary"
                                        label="State"
                                        error={hasError[state] && order[is_custom] && (startDirty || isDirty[state])}
                                        value={order[state]}
                                        onBlur={() => callbacks.makeDirty([state])}
                                        onChange={(e) => callbacks.modifyOrder({ [state]: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <OnwardTextField
                                        fullWidth
                                        variant="outlined"
                                        color="primary"
                                        label="Zip"
                                        value={order[zip]}
                                        error={hasError[zip] && order[is_custom] && (startDirty || isDirty[state])}
                                        onBlur={() => callbacks.makeDirty([zip])}
                                        onChange={(e) => callbacks.modifyOrder({ [zip]: e.target.value })}
                                    />
                                </Grid>
                            </TabRow>
                            <TabRow>
                                <Grid item xs={12}>
                                    <TextField
                                        type="text"
                                        variant="outlined"
                                        fullWidth
                                        label="Comments"
                                        multiline={true}
                                        value={currentNote?.note || ''}
                                        onChange={modifyInitialNote}
                                    />
                                </Grid>
                            </TabRow>
                            {allowCustom ? (
                                <TabRow>
                                    <Grid item>
                                        <OnwardToggle
                                            css={css`
                                                margin: 0;
                                            `}
                                            disabled={disableAddressEditing}
                                            value={order[is_custom] || false}
                                            onChange={(e) => {
                                                callbacks.modifyOrder({
                                                    [is_custom]: e.target.checked,
                                                    [geocode_failed]: false,
                                                });
                                                callbacks.makeDirty([address, street]);
                                            }}
                                        />
                                    </Grid>
                                    <Grid
                                        direction="column"
                                        container
                                        css={css`
                                            flex: 1;
                                            flex-basis: 0;
                                            margin-left: 12px;
                                            justify-content: center;
                                        `}
                                    >
                                        <Grid container item>
                                            <RadioLabelInfo
                                                css={css`
                                                    color: #000;
                                                    font-weight: 400;
                                                `}
                                            >
                                                Having trouble with your address?&nbsp;
                                                <a
                                                    css={css`
                                                        cursor: pointer;
                                                    `}
                                                    onClick={() => {
                                                        callbacks.modifyOrder({
                                                            [is_custom]: true,
                                                            [geocode_failed]: false,
                                                        });
                                                        callbacks.makeDirty([address, street]);
                                                    }}
                                                >
                                                    Place a pin on the map
                                                </a>
                                            </RadioLabelInfo>
                                        </Grid>

                                        {order[is_custom] ? (
                                            <Grid
                                                container
                                                item
                                                css={css`
                                                    margin-top: 8px;
                                                `}
                                            >
                                                <ToggleBlurb />
                                            </Grid>
                                        ) : null}
                                    </Grid>
                                </TabRow>
                            ) : null}
                        </>
                    )}

                    {viewMap && (
                        <Grid
                            item
                            css={css`
                                min-height: 350px;
                                margin-top: 24px;
                                border-radius: 6px;
                                border: ${hasError[lat] || hasError[long]
                                    ? `1px solid #f44336;`
                                    : '1px solid transparent;'};
                            `}
                        >
                            <GoogleMap
                                zoom={15}
                                onClick={(e) => {
                                    if (disableGeocoding) {
                                        const x = e.latLng.lat();
                                        const y = e.latLng.lng();

                                        callbacks.modifyOrder({
                                            [lat]: x,
                                            [long]: y,
                                        });
                                    } else {
                                        callbacks.geocodeLatLong(e);
                                    }

                                    callbacks.makeDirty([lat, long]);
                                }}
                                draggableCursor="pointer"
                                gestureHandling="greedy"
                                center={coords ? coords : undefined}
                            >
                                {coords && <Marker position={coords} />}
                            </GoogleMap>
                        </Grid>
                    )}

                    <TabSection>Pickup Type</TabSection>
                    <TabRow>
                        <Grid item xs={6}>
                            <LocationType
                                value={order[location]}
                                onChange={(e) => {
                                    callbacks.modifyOrder({ [location]: e.target.value });
                                    callbacks.makeDirty([location]);
                                }}
                                error={hasError[location] && (startDirty || isDirty[location])}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <PickupType
                                disabled={!order[location] || order[location].length === 0}
                                value={order[location_type]}
                                locationType={order[location]}
                                onChange={(e) => {
                                    callbacks.modifyOrder({ [location_type]: e.target.value });
                                    callbacks.makeDirty([location_type]);
                                }}
                                error={hasError[location_type] && (startDirty || isDirty[location_type])}
                            />
                        </Grid>
                    </TabRow>
                </>
            )}
        </>
    );
});

export default PickupTab;
