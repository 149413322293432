import { useCallback } from 'react';
import generateCSV from '@/utilities/createCSV';
import { FIXED_CHARGES } from '@/components/Accessorials/constants';
import { MKPL_CHARGES } from '@/components/Accessorials/constants';
import { calcOrderSubtotal } from '@/utilities/calcOrderSubtotal';
export const genCallback = ({ accessorials, invoices, breakdown, columns }) => {
    return () => {
        const data = invoices.reduce((acc, invoice) => {
            const attr = breakdown === 'shipperBreakdown' ? 'shipper_orders' : 'orders';
            return [
                ...acc,
                ...(invoice?.[attr] || []).reduce((acc, order) => {
                    const accessorialsMap = Object.fromEntries(
                        accessorials.map((accessorial) => [accessorial.type, accessorial])
                    );

                    const type = !!order.oms ? 'internalBreakdown' : breakdown;
                    const chargeType = !!order.oms ? FIXED_CHARGES : MKPL_CHARGES;

                    const pickup_notes = [];
                    const delivery_notes = [];
                    const generic_notes = [];
                    const viewer = breakdown === 'shipperBreakdown' ? 'Shipper' : 'Carrier';
                    const notes = order?.notes || [];
                    notes.forEach((n) => {
                        if (n.private_to && n.private_to !== viewer) {
                            return;
                        }
                        if (n.type === 'Delivery') {
                            const parsedNote = n.note.replace(/(\r\n|\n|\r)/gm, " ");
                            delivery_notes.push(parsedNote);
                        } else if (n.type === 'Pickup') {
                            const parsedNote = n.note.replace(/(\r\n|\n|\r)/gm, " ");
                            pickup_notes.push(parsedNote);
                        } else {
                            const parsedNote = n.note.replace(/(\r\n|\n|\r)/gm, " ");
                            generic_notes.push(parsedNote);
                        }
                    });

                    return [
                        ...acc,
                        ...chargeType
                            .map(({ display, key }) => {
                                return {
                                    invoice,
                                    order,
                                    type: key,
                                    qbo_tag: order?.qbo_tag,
                                    qbo_class: order?.invoice_class,
                                    description: display,
                                    total: !!order.oms
                                        ? order?.price_breakdown?.[type]?.[key]
                                        : calcOrderSubtotal(order),
                                    delivery_notes,
                                    pickup_notes,
                                    generic_notes,
                                };
                            })
                            .filter((charge) => charge.total > 0),
                        ...(order?.price_breakdown?.[type]?.accessorials || []).map(({ type, quantity, rate }) => {
                            const meta = accessorialsMap[type] || accessorialsMap.default || 'DEFAULT';
                            return {
                                invoice,
                                order,
                                type,
                                accessorial: meta,
                                qbo_tag: order?.qbo_tag,
                                qbo_class: order?.invoice_class,
                                description: meta.label,
                                quantity,
                                rate,
                                total: quantity * rate,
                            };
                        }),
                    ];
                }, []),
            ];
        }, []);

        const TODAY = new Date();
        const blob = generateCSV(columns, data);
        const filename = `Invoices ${TODAY.getTime()}`;
        const autoclick = document.createElement('a');
        const payload = URL.createObjectURL(blob);
        autoclick.setAttribute('href', payload);
        autoclick.setAttribute('download', filename);
        autoclick.style.visibility = 'hidden';
        document.body.appendChild(autoclick);
        autoclick.click();
        document.body.removeChild(autoclick);
    };
};

export const useExport = ({ accessorials, invoices, columns, breakdown }) => {
    const exportSelected = useCallback(genCallback({ accessorials, invoices, columns, breakdown }), [
        accessorials,
        invoices,
        columns,
        breakdown,
    ]);

    return { exportSelected };
};
