import { PDFDocument } from 'pdf-lib';

/**
 * Merge all of an order's POD or BOL documents into a single PDF
 *
 * @param {object} order    Order to process
 * @param {string} set     'pod' or 'bol_file' to indicate which file set to merge
 * @returns                 A PDFDocument object with all of {order}'s files of given type merged into a single document
 */
export default async function getOrdersFilesAsPDF(order, set, corruptFiles) {
    let files;
    if (typeof order[set] === 'string') {
        files = [order[set]];
    } else {
        files = order[set];
    }

    if (!files || files.length === 0) {
        return;
    }

    const fileTypeFetchPromises = [];
    const fileResponseBlobPromises = [];
    for (const file of files) {
        fileTypeFetchPromises.push(fetch(file?.url ? file.url : file));
    }

    const fileFetchResults = await Promise.all(fileTypeFetchPromises);

    for (const fileFetchResult of fileFetchResults) {
        fileResponseBlobPromises.push(fileFetchResult.blob());
    }

    const fileBlobs = await Promise.all(fileResponseBlobPromises);

    const fileTypeMap = {
        'application/pdf': 'pdf',
        'image/jpeg': 'jpg',
        'image/png': 'png',
    };

    const filesWithTypes = files.map((file, index) => {
        const returnObj = { file: file?.url ? file.url : file };

        const type = fileBlobs[index].type;

        if (fileTypeMap[type]) {
            returnObj.type = fileTypeMap[type];
        } else {
            if (type === 'application/json') {
                corruptFiles.push({
                    file,
                    orderNumber: order.order_number,
                    set,
                });
            }
            returnObj.type = type;
        }

        return returnObj;
    });

    const pdfDoc = await PDFDocument.create();
    let donorPdfBytes;

    for (let i = 0; i < filesWithTypes.length; i++) {
        const type = filesWithTypes[i].type;
        if (type === 'pdf') {
            try {
                donorPdfBytes = await fetch(filesWithTypes[i].file).then((res) => res.arrayBuffer());
            } catch (error) {
                console.error(error);
                continue;
            }

            const donorPdfDoc = await PDFDocument.load(donorPdfBytes);
            const docLength = donorPdfDoc.getPageCount();
            for (let k = 0; k < docLength; k++) {
                const [donorPage] = await pdfDoc.copyPages(donorPdfDoc, [k]);
                pdfDoc.addPage(donorPage);
            }
        } else if (type !== 'application/json') {
            const imageBytes = await fetch(filesWithTypes[i].file).then((res) => res.arrayBuffer());
            let image;
            if (type === 'png') {
                image = await pdfDoc.embedPng(imageBytes);
            } else if (type === 'jpg') {
                image = await pdfDoc.embedJpg(imageBytes);
            } else {
                continue; // not an acceptable image file type, skip
            }

            const page = pdfDoc.addPage();

            // If image width is greater than default page width, scale image down to match
            let newDims = { width: image.width, height: image.height };
            if (image.width > page.getWidth()) {
                newDims = image.scale(page.getWidth() / image.width);
            }

            page.drawImage(image, {
                x: 0,
                y: page.getHeight() - newDims.height,
                width: newDims.width,
                height: newDims.height,
            });
        }
    }

    return pdfDoc;
}
