import React, { useContext, useMemo } from 'react';
import { iconcss, LinkContainer, LinkLabel, SublinkIcon } from './blocks';
import { ChevronLeft, Menu } from '@material-ui/icons';
import { NavDivider, white } from './blocks';
import { css } from '@emotion/react';
import onwardLogo from '../../assets/onwardlogotransparent.webp';
import Header from './Header';
import { UserContext } from '../App';
import { LINKS } from './links';
import NavLink from './NavLink';
import { useLocation, useNavigate } from 'react-router';
import { Drawer, ListItemIcon, ListItemText, IconButton } from '@material-ui/core';
import { BoxArrowLeft } from 'react-bootstrap-icons';
import { useClientUser } from '@/hooks';
import CustomerHeader from './CustomerHeader';
import { onwardNavy } from '@/styles/colors';
import * as ROUTES from '@/constants/routes';

const Navigation = () => {
    const navigate = useNavigate();
    const { user, clientId, setClientId, sidebarCollapsed, setSidebarCollapsed, customHeader, customColor } =
        useContext(UserContext);
    const { accountType, userType, teammateRoles, circles, isImposter, white_label_color, white_label_img } =
        useClientUser();

    const links = useMemo(() => {
        let links = [];

        if (user?.roles?.['ONWARD_ADMIN'] && !clientId) {
            links = LINKS.ADMIN;
        } else if (user?.roles?.['ADMIN'] && !clientId) {
            links = LINKS.CORPORATE_ADMIN;
        } else if (accountType === 'shipper') {
            if (userType !== 'teammate' || ['admin', 'operations', 'viewer'].some((role) => teammateRoles?.[role])) {
                links = LINKS.SHIPPER;
            } else if (teammateRoles?.driver) {
                links = LINKS.SHIPPER_DRIVER_TEAMMATE;
            }
        } else if (accountType === 'carrier') {
            if (userType !== 'teammate' || ['admin', 'operations', 'viewer'].some((role) => teammateRoles?.[role])) {
                links = LINKS.CARRIER;
            } else if (teammateRoles?.driver) {
                links = LINKS.CARRIER_DRIVER_TEAMMATE;
            }
        }

        const processedLinks = links.map((link) => {
            let locked = true;
            if (!link.feature) locked = false;
            if (Array.isArray(link.feature) && link?.feature?.some((feat) => circles?.[feat])) {
                locked = false;
            } else if (circles?.[link.feature]) {
                locked = false;
            }
            if (link?.restrictionFeature && link?.restrictionFeature?.some((feat) => circles?.[feat])) {
                locked = true;
            }
            return { ...link, locked };
        });

        return processedLinks.sort((a, b) => {
            if (a.locked === b.locked) return 0;
            return a.locked ? 1 : -1;
        });
    }, [user, accountType, userType, teammateRoles, clientId]);

    const resetAdmin = () => {
        setClientId(null);
        window?.sessionStorage?.removeItem('client_id');
        if (user?.roles?.['ONWARD_ADMIN']) {
            navigate(ROUTES.ADMIN_USERS);
        } else if (user?.roles?.['ADMIN']) {
            navigate(ROUTES.CORPORATE_USERS);
        }
    };

    if (window.self !== window.top) {
        return <></>;
    }

    return user ? (
        <>
            <Header />
            <Drawer
                anchor="left"
                variant="permanent"
                css={css`
                    .MuiPaper-root {
                        background-color: ${white_label_color || onwardNavy};
                    }
                `}
            >
                {/* Nav Control */}
                {sidebarCollapsed ? (
                    <IconButton onClick={() => setSidebarCollapsed(false)}>
                        <Menu css={white} />
                    </IconButton>
                ) : (
                    <div
                        css={css`
                            display: flex;
                            justify-content: center;
                            margin-left: 45px;
                        `}
                    >
                        {white_label_img ? (
                            <img
                                src={white_label_img}
                                css={css`
                                    width: 151px;
                                    height: 50px;
                                `}
                            />
                        ) : (
                            <>
                                <img
                                    css={css`
                                        width: 130px;
                                        height: 50px;
                                        object-fit: cover;
                                    `}
                                    src={onwardLogo}
                                />
                                <div
                                    css={css`
                                        margin-top: 15px;
                                    `}
                                >
                                    <LinkLabel
                                        css={css`
                                            color: white;
                                            font-size: 10px;
                                            font-weight: 700;
                                            font-family: 'Copperplate', sans-serif !important;
                                        `}
                                    >
                                        <i>v3.0</i>
                                    </LinkLabel>
                                </div>
                            </>
                        )}
                        <IconButton
                            css={css`
                                width: 50px;
                            `}
                            onClick={() => setSidebarCollapsed(true)}
                        >
                            <ChevronLeft css={white} />
                        </IconButton>
                    </div>
                )}

                {/* Nav Links */}
                {links.map((link) => (
                    <NavLink link={link} key={`nav-${link.label}`} />
                ))}

                {LINKS.FOOTER.map((link) => (
                    <NavLink link={link} key={`nav-${link.label}`} />
                ))}

                {isImposter && (
                    <LinkContainer onClick={resetAdmin}>
                        <ListItemIcon style={{ minWidth: '44px', marginLeft: '10px' }}>
                            <BoxArrowLeft style={{ fontSize: '24px', color: 'red', marginRight: '0' }} />
                        </ListItemIcon>
                        {!sidebarCollapsed && (
                            <ListItemText style={{ fontWeight: '700' }} className="text-danger font-weight-extra-bold">
                                <span style={{ fontWeight: 700 }}>BACK TO ADMIN</span>
                            </ListItemText>
                        )}
                    </LinkContainer>
                )}
            </Drawer>
        </>
    ) : (
        <CustomerHeader customHeader={customHeader} customColor={customColor} />
    );
};

export default Navigation;
