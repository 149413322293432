import React, { useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { Grid, MenuItem, TextField } from '@material-ui/core';
import {
    ResponsiveSidebarDialog,
    StickyModalActions,
    ModalContent,
    PrimaryButton,
    SecondaryButton,
    OnwardSwitch,
} from '@/styles/blocks';
import { Body1, Header1 } from '../Crossdocking/blocks';
import PhoneNumberInput from '@/components/PhoneNumberInput';
import { toE164, toNational } from '@/utilities/formatPhoneNumber';

const AssignModal = ({ event, order, callbacks, isReceived, isCrossdocked, drivers }) => {
    const [updates, setUpdates] = useState({});

    const updated = useMemo(() => {
        return {
            ...(event || {}),
            driver_id: event?.driver_id || order?.driver_id,
            sendText: true,
            tempDriver: !!event && !event?.driver_id,
            ...updates,
        };
    }, [updates, event]);

    const submit = () => {
        callbacks.onSubmit({
            event: {
                ...(event.event_id ? { event_id: event.event_id } : {}),
                order_id: order.order_id,
                action: event.action,
                driver: {
                    data: {
                        entity_id: order.order_id,
                        key: updated.driver_id || updated.driver_phone || updated.driver_name,
                        entity_type: 'ORDER',
                        name: updated.driver_name,
                        phone: updated.driver_phone,
                    },
                    on_conflict: {
                        constraint: 'drivers_order_key_uniq_idx',
                        update_columns: ['name', 'phone'],
                    },
                },
            },
            order: {
                order_id: order.order_id,
            },
            sendText: updated.sendText,
        });
    };

    return (
        <ResponsiveSidebarDialog open={!!event} onClose={callbacks.onClose}>
            <ModalContent
                css={css`
                    width: 100%;
                `}
            >
                <Grid
                    direction="row"
                    container
                    css={css`
                        margin-bottom: 12px;
                    `}
                >
                    <Body1>Assign a driver to this leg of the order</Body1>
                </Grid>
                <Grid
                    direction="row"
                    container
                    css={css`
                        margin-bottom: 12px;
                    `}
                >
                    <OnwardSwitch
                        label={<Header1>New Driver</Header1>}
                        checked={updated.tempDriver}
                        onChange={() => setUpdates((prev) => ({ ...prev, tempDriver: !prev.tempDriver }))}
                    />{' '}
                </Grid>

                <Grid
                    direction="row"
                    container
                    css={css`
                        margin-bottom: 12px;
                    `}
                >
                    {updated.tempDriver ? (
                        <>
                            <TextField
                                variant="outlined"
                                label="Driver Name"
                                InputLabelProps={{
                                    shrink: !!updated?.driver_name,
                                }}
                                value={updated?.driver_name || ''}
                                onChange={(e) => {
                                    setUpdates((prev) => ({
                                        ...prev,
                                        driver_id: null,
                                        driver_name: e.target.value,
                                    }));
                                }}
                                css={css`
                                    width: 240px;
                                `}
                            />
                            <TextField
                                variant="outlined"
                                label="Driver Phone"
                                InputProps={{
                                    inputComponent: PhoneNumberInput,
                                }}
                                InputLabelProps={{
                                    shrink: !!updated?.driver_phone,
                                }}
                                value={toNational(updated?.driver_phone || '')}
                                onChange={(e) => {
                                    setUpdates((prev) => ({
                                        ...prev,
                                        driver_id: null,
                                        driver_phone: toE164(e.target.value),
                                    }));
                                }}
                                css={css`
                                    margin-left: 12px;
                                    width: 160px;
                                `}
                            />
                        </>
                    ) : (
                        <TextField
                            select
                            variant="outlined"
                            label="Driver"
                            value={updated?.driver_id || ''}
                            onChange={(e) => {
                                const option = e.target.value;

                                setUpdates((prev) => ({
                                    ...prev,
                                    driver_id: option.teammate_id,
                                    driver_name: option.username,
                                    driver_phone: option.phone,
                                }));
                            }}
                            css={css`
                                width: 240px;
                            `}
                        >
                            {drivers.map((option) => (
                                <MenuItem key={option.teammate_id} value={option}>
                                    {option.username}
                                </MenuItem>
                            ))}
                        </TextField>
                    )}
                </Grid>
            </ModalContent>
            <StickyModalActions>
                <Grid
                    container
                    direction="column"
                    css={css`
                        flex: 0;
                    `}
                >
                    <SecondaryButton onClick={callbacks.onClose}>Cancel</SecondaryButton>
                </Grid>
                <Grid
                    container
                    direction="column"
                    css={css`
                        flex: 0;
                    `}
                >
                    <PrimaryButton onClick={submit}>Save</PrimaryButton>
                </Grid>
            </StickyModalActions>
        </ResponsiveSidebarDialog>
    );
};

export default AssignModal;
