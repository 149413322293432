import React, { useContext, useMemo, useState } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import { css } from '@emotion/react';
import { PrimaryButton } from '@/styles/blocks';
import { Context } from './store';
import { ACCOUNTING_STAGES } from './constants';
import ExportCsvTemplateModal from '../ExportCsvTemplateModal';
import { ORDER_EXPORT_COLUMNS } from '../ExportCsvTemplateModal/constants';
import { INSERT_CSV_EXPORT_TEMPLATE } from '@/graphql/mutations/csv_export_templates';
import { GET_PRICING_OVERRIDES } from '@/graphql/queries/pricing_overrides';
import { genAccessorials } from '../Account/Tariffs/utils';
import csvDownload from 'json-to-csv-export';
import { useLazyQuery, useMutation } from '@apollo/client';
import { captureException } from '@sentry/react';
import { useClientUser } from '@/hooks';
import { calcOrderOwner, calcOrderPricing } from '@/utilities/calcOrderPricing';

const styled = withStyles({
    modal: {
        position: 'absolute',
        top: 'calc(50% -  95px)',
        left: 'calc(50% - 227px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '0px',
        width: '502px',
    },
});

function Footer({ state, callbacks }) {
    const { selectedOrders, filters, subTab } = state;
    const { setStage, handleReadyToInvoice } = callbacks;
    const { user_id, circles } = useClientUser();

    const [openExportModal, setOpenExportModal] = useState();

    const isValid = useMemo(() => {
        return selectedOrders.length > 0 && filters?.completedFrom && filters?.completedTo;
    }, [selectedOrders, filters]);

    const [isValidCreateSingle, isValidCreateMultiple] = useMemo(() => {
        const shipperObject = {};
        const shipperCount = (selectedOrders || []).reduce((count, o) => {
            if (!shipperObject[o.shipper_id]) {
                shipperObject[o.shipper_id] = true;
                return count + 1;
            } else {
                return count;
            }
        }, 0);
        return [
            selectedOrders.length > 0 && filters?.completedFrom && filters?.completedTo && shipperCount === 1,
            selectedOrders.length > 0 && filters?.completedFrom && filters?.completedTo && shipperCount > 1,
        ]
    }, [selectedOrders, filters]);

    const handleReadyClick = (selectedOrders) => {
        const orderIds = selectedOrders.map((order) => order.order_id);
        handleReadyToInvoice(orderIds);
    };

    const exportOrderRevenue = (order) => {
        return calcOrderPricing(order, user_id, circles);
    };

    const exportOrderOwner = (order) => {
        return calcOrderOwner(order, user_id);
    };

    const csvExportColumns = {
        Order: ORDER_EXPORT_COLUMNS,
    };

    const [insertTemplate, { loading: insertTemplateLoading }] = useMutation(INSERT_CSV_EXPORT_TEMPLATE, {
        onError: (error) => {
            captureException(error);
            console.log(error.message);
        },
    });

    const [fetchOverrides, { loading: exportInflight }] = useLazyQuery(GET_PRICING_OVERRIDES);

    const csvRowData = useMemo(() => {
        const orderData = selectedOrders.reduce((acc, selected) => {
            const order = selected;
            if (!order) return acc;
            let revenue = exportOrderRevenue(order);
            let owner = exportOrderOwner(order);
            let routeLabel =
                order.routes && order.routes.length > 0
                    ? order.routes
                          .map((routeMapping) => routeMapping.route.route_alias || routeMapping.route.route_number)
                          .join(', ')
                    : '';

            const rows = order.itemsByOrderId.map((item) => {
                return { order, item, revenue, owner, routeLabel };
            });
            return [...acc, ...rows];
        }, []);
        return { orderData: orderData };
    }, [selectedOrders]);

    return (
        <>
            {subTab === 1 && (
                <Grid
                    container
                    css={css`
                        padding: 1rem;
                        background-color: white;
                        justify-content: center;
                        align-items: center;
                        box-shadow: 0px -4px 4px 0px #37373752;
                    `}
                >
                    <Grid
                        item
                        css={css`
                            margin-right: 1rem;
                        `}
                    >
                        <PrimaryButton disabled={!isValidCreateSingle} onClick={() => setStage(ACCOUNTING_STAGES.CREATE_INVOICE)}>
                            Create Invoice
                        </PrimaryButton>
                    </Grid>
                    <Grid
                        item
                        css={css`
                            margin-left: 1rem;
                        `}
                    >
                        <PrimaryButton
                            disabled={!isValidCreateMultiple}
                            onClick={() => setStage(ACCOUNTING_STAGES.CREATE_MULTI_INVOICES)}
                        >
                            Create Invoices by Shipper
                        </PrimaryButton>
                    </Grid>
                    <Grid
                        item
                        css={css`
                            margin-left: 1rem;
                        `}
                    >
                        <PrimaryButton disabled={!isValid} onClick={() => setOpenExportModal(true)}>
                            Export Order Info
                        </PrimaryButton>
                    </Grid>
                </Grid>
            )}
            {subTab === 0 && (
                <Grid
                    container
                    css={css`
                        padding: 1rem;
                        background-color: white;
                        justify-content: center;
                        align-items: center;
                    `}
                >
                    <Grid
                        item
                        css={css`
                            margin-right: 1rem;
                        `}
                    >
                        <PrimaryButton
                            disabled={!isValid}
                            onClick={() => {
                                handleReadyClick(selectedOrders);
                            }}
                        >
                            Approve for Invoicing
                        </PrimaryButton>
                    </Grid>
                    <Grid
                        item
                        css={css`
                            margin-left: 1rem;
                        `}
                    >
                        <PrimaryButton disabled={!isValid} onClick={() => setOpenExportModal(true)}>
                            Export Order Info
                        </PrimaryButton>
                    </Grid>
                </Grid>
            )}
            {(subTab === 2 || subTab === 3) && (
                <Grid
                    container
                    css={css`
                        padding: 1rem;
                        background-color: white;
                        justify-content: center;
                        align-items: center;
                    `}
                >
                    <Grid
                        item
                        css={css`
                            margin-left: 1rem;
                        `}
                    >
                        <PrimaryButton disabled={!isValid} onClick={() => setOpenExportModal(true)}>
                            Export Order Info
                        </PrimaryButton>
                    </Grid>
                </Grid>
            )}

            <ExportCsvTemplateModal
                open={openExportModal}
                setOpen={setOpenExportModal}
                exportType={'ORDER'}
                csvExportColumns={csvExportColumns}
                callbacks={{
                    onExport: async (selectedColumns, templateName, exportType, includeAccessorials) => {
                        if (templateName) {
                            insertTemplate({
                                variables: {
                                    object: {
                                        name: templateName,
                                        user_id: user_id,
                                        export_type: exportType,
                                        template: {
                                            ...selectedColumns,
                                            Order: {
                                                ...selectedColumns.Order,
                                                ...(includeAccessorials && { Accessorials: true }),
                                            },
                                        },
                                    },
                                },
                            });
                        }

                        if (includeAccessorials) {
                            let potentialAccessorials;
                            await fetchOverrides({
                                variables: {
                                    shipper_ids: csvRowData.orderData.map((row) => row.order.shipper_id),
                                    carrier_ids: csvRowData.orderData.map((row) => row.order.carrier_id),
                                    client_ids: [user_id],
                                    partner_client_ids: [],
                                    retailer_ids: [],
                                },
                            }).then(({ data }) => {
                                const {
                                    carrier_defaults = [],
                                    carrier = [],
                                    internal = [],
                                    shipper_defaults = [],
                                    shipper = [],
                                } = data || {};

                                let types = [
                                    ...carrier_defaults,
                                    ...carrier,
                                    ...shipper,
                                    ...shipper_defaults,
                                    ...internal,
                                ]
                                    .map((override) => override.algo_type)
                                    .filter((type) => type);

                                if (types.length === 0) {
                                    types = ['DEFAULT'];
                                }

                                potentialAccessorials = types.reduce((acc, type) => {
                                    return [...acc, ...genAccessorials(type)];
                                }, []);
                            });

                            const getBreakdown = (order) => {
                                return order.oms
                                    ? order?.price_breakdown?.internalBreakdown
                                    : user_id === order.shipper_id
                                    ? order?.price_breakdown?.shipperBreakdown
                                    : order?.price_breakdown?.carrierBreakdown;
                            };

                            let maxAccessorialCount = csvRowData.orderData.reduce((acc, row) => {
                                const priceBreakdown = getBreakdown(row.order);

                                const accessorialCount = priceBreakdown?.accessorials?.length || 0;
                                return Math.max(acc, accessorialCount);
                            }, 1);

                            const accCols = [
                                {
                                    header: 'Base Rate',
                                    value: ({ order }) => {
                                        const priceBreakdown = getBreakdown(order);
                                        return priceBreakdown?.base_charge || '-';
                                    },
                                },
                                ...Array(maxAccessorialCount)
                                    .fill(0)
                                    .flatMap((_, idx) => [
                                        {
                                            header: `Accessorial ${idx + 1} Type`,
                                            value: ({ order }) => {
                                                const priceBreakdown = getBreakdown(order);
                                                const accessorialType = priceBreakdown?.accessorials[idx]?.type;
                                                if (!accessorialType) return '-';
                                                const accessorial = potentialAccessorials.find(
                                                    (a) => a.type === accessorialType
                                                );
                                                return accessorial?.label || accessorialType;
                                            },
                                        },
                                        {
                                            header: `Accessorial ${idx + 1} Subtotal`,
                                            value: ({ order }) => {
                                                const priceBreakdown = getBreakdown(order);
                                                return priceBreakdown?.accessorials[idx]?.quantity
                                                    ? `$${Number(priceBreakdown?.accessorials[idx]?.quantity).toFixed(
                                                          2
                                                      )}`
                                                    : '-';
                                            },
                                        },
                                    ]),
                            ];

                            csvExportColumns['Order'] = [...csvExportColumns['Order'], ...accCols];
                        }

                        // Filter out duplicate orders if they don't want item info
                        let filteredOrderData = csvRowData.orderData;
                        if (!selectedColumns.Order.Item) {
                            const uniqueOrders = new Set();
                            filteredOrderData = csvRowData.orderData.filter(({ order }) => {
                                if (uniqueOrders.has(order.order_id)) {
                                    return false;
                                }
                                uniqueOrders.add(order.order_id);
                                return true;
                            });
                        }

                        Object.keys(csvExportColumns).forEach((colType) => {
                            const finalExportCols = csvExportColumns[colType].filter(
                                (col) =>
                                    col.header === 'Base Rate' ||
                                    col.header.toLowerCase().includes('accessorial') ||
                                    selectedColumns[colType][col.header]
                            );
                            if (finalExportCols.length > 0) {
                                csvDownload({
                                    headers: finalExportCols.map((col) => col.header),
                                    data: filteredOrderData.map((row) => finalExportCols.map((col) => col.value(row))),
                                    filename: `onward-${colType}Export-${new Date().toISOString()}.csv`,
                                    delimiter: ',',
                                });
                            }
                        });
                    },
                }}
            />
            {/* {subTab === 2 && null} */}
        </>
    );
}

export default styled(Footer);
