import { gql } from '@apollo/client';

export const DRIVER_FIELDS = gql`
    fragment DriverFields on drivers {
        driver_id
        username: name
        entity_id
        key
        name
        email
        phone
        created_at
    }
`;
