import { Grid } from '@material-ui/core';
import React from 'react';
import { css } from '@emotion/react';
import { SURVEY_TYPES } from '../Account/Surveys/constants';
import { CardItem, CardTitle } from './blocks';
import { OnwardCard } from './Card';
import { Rating } from '@material-ui/lab';

const SurveyCard = ({ surveyType, responses, order }) => {
    const getResponse = (response, question) => {
        switch (question.response_type) {
            case 'string':
                return response.response_string || '';
            case 'boolean':
                return response.response_boolean ? 'Yes' : 'No';
            case 'number':
                return <Rating readOnly size="large" precision={0.5} value={response.response_number || 0} />;
        }
        return null;
    };

    let extras = null;
    if (surveyType === 'PREDELIVERY') {
        extras = (
            <>
                <CardItem
                    css={css`
                        margin-left: 1rem;
                        margin-top: 1rem;
                    `}
                >
                    Instructions: {order?.customer_instructions || ''}
                </CardItem>
                <CardItem
                    css={css`
                        margin-left: 1rem;
                        margin-top: 1rem;
                    `}
                >
                    Gate Code: {order?.gate_code || ''}
                </CardItem>
            </>
        );
    }

    if (!responses?.length) return null;

    return (
        <OnwardCard>
            <Grid
                container
                direction="column"
                css={css`
                    flex-wrap: nowrap;
                `}
            >
                <CardTitle>{SURVEY_TYPES[surveyType]} Survey</CardTitle>
                {responses.map((response, idx) => (
                    <CardItem
                        css={css`
                            margin-left: 1rem;
                            ${idx > 0 ? 'margin-top: 1rem;' : ''}
                        `}
                    >
                        {idx + 1}. {response.survey_question.question}
                        <br />
                        {getResponse(response, response.survey_question)}
                    </CardItem>
                ))}
                {extras}
            </Grid>
        </OnwardCard>
    );
};

export default SurveyCard;
