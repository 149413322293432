import React, { useContext, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { ModalContext } from '..';
import { useClientUser } from '@/hooks';
import { Grid, MenuItem, TextField } from '@material-ui/core';
import { Row, SecondaryButton } from '@/styles/blocks';
import { PriceInput } from '../../InputFields';
import { FIXED_CHARGES } from '@/components/Accessorials/constants';
import StaticCharge from '@/components/Accessorials/StaticCharge';
import Header from '@/components/Accessorials/Header';
import { PrimaryButton } from '@/styles/blocks';
import Charge from '@/components/Accessorials/Charge';
import { useAccessorials } from '@/components/Account/Tariffs/utils';
import { GET_PRICING_OVERRIDE_BY_ID } from '@/graphql/queries/pricing_overrides';
import { useLazyQuery } from '@apollo/client';
import { H3, Body1, H2, H1 } from '@/components/Accessorials/blocks';
import { colors } from '@/styles';
import { isFinite } from 'lodash';
import { TabSection } from '@/components/ShipmentForm/blocks';

const dollarFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const OrderDetailsPricing = ({ perspective }) => {
    const { state: modalState, callbacks } = useContext(ModalContext);
    const { order } = modalState;
    const { tags, qbo_tags } = useClientUser();

    const [baseOverride, breakdown, adjustments, override_id, title] = useMemo(() => {
        switch (perspective) {
            case 'shipper':
                return [
                    order.admin_shipper_rate_override ?? order.listing?.final_accepted_shipper_rate,
                    order.price_breakdown?.shipperBreakdown,
                    order.order_revenue_adjustments?.shipper,
                    order.shipper_rate_override_id,
                    'Shipper Rate Breakdown',
                ];
            case 'carrier':
                return [
                    order.admin_carrier_rate_override ?? order.listing?.final_accepted_carrier_rate,
                    order.price_breakdown?.carrierBreakdown,
                    order.order_revenue_adjustments?.carrier,
                    order.carrier_rate_override_id,
                    'Carrier Rate Breakdown',
                ];

            case 'internal':
                return [
                    order.order_revenue_override,
                    order.price_breakdown?.internalBreakdown,
                    order.order_revenue_adjustments?.internal,
                    order.onward_rate_override_id,
                    'Rate Breakdown',
                ];
        }
        return [null, null, null, null, 'Rate Breakdown'];
    }, [perspective, order]);

    const accessorials = useMemo(() => {
        return adjustments || breakdown?.accessorials || [];
    }, [breakdown, adjustments]);

    const subtotal = useMemo(() => {
        return isFinite(baseOverride) ? baseOverride : breakdown?.base_charge || 0;
    }, [baseOverride, breakdown]);

    const accessorialsSum = useMemo(() => {
        return accessorials.reduce((acc, charge) => {
            return acc + charge.quantity * charge.rate;
        }, 0);
    }, [accessorials]);

    const total = useMemo(() => {
        return subtotal + accessorialsSum;
    }, [subtotal, accessorialsSum]);

    const [fetchOverridesById, { data: resp }] = useLazyQuery(GET_PRICING_OVERRIDE_BY_ID);

    const rates = useMemo(() => {
        if (!order || !resp) return [];
        const override = resp?.pricing_overrides_by_pk;
        const overrideRates = override?.tariff?.rates || [];
        return overrideRates;
    }, [order, resp]);

    useEffect(() => {
        if (override_id) {
            fetchOverridesById({
                variables: {
                    pricing_override_id: override_id,
                },
            });
        }
    }, [override_id]);

    const overrideType = useMemo(() => {
        return breakdown?._meta?.type || 'DEFAULT';
    }, [breakdown]);

    const allAccessorials = useAccessorials(overrideType, perspective === 'internal' ? tags : []);

    const accessorialOptions = useMemo(() => {
        return allAccessorials
            .filter((accessorial) => !override_id || rates.some((rate) => rate.type === accessorial.type))
            .sort((a, b) => a.label.localeCompare(b.label));
    }, [allAccessorials, rates, override_id]);

    return (
        <>
            {perspective !== 'internal' ? <TabSection>{title}</TabSection> : null}
            <Header />

            {FIXED_CHARGES.map(({ display, key }, idx) => {
                let chargeValue = breakdown?.[key] ? dollarFormatter.format(breakdown?.[key]) : '--';
                if (key === 'base_charge' && isFinite(baseOverride)) {
                    chargeValue = dollarFormatter.format(baseOverride);
                }
                return breakdown?.[key] > 0 ? (
                    <StaticCharge
                        key={key}
                        qbo_tags={qbo_tags}
                        orderQboTag={order.qbo_tag}
                        updateQboTag={(e) =>
                            callbacks.modifyOrder({
                                qbo_tag: e,
                            })
                        }
                        data={[
                            { key: 'type', value: display },
                            {
                                key: 'total',
                                value: chargeValue,
                            },
                        ]}
                    />
                ) : null;
            })}

            {accessorials.map((charge, idx) => {
                return (
                    <Charge
                        key={idx}
                        accessorial={charge}
                        accessorials={accessorialOptions}
                        rates={rates}
                        isInternal={perspective === 'internal'}
                        // editable={state.editable}
                        editable={true}
                        qbo_tags={qbo_tags}
                        callbacks={{
                            onChange: (next) => {
                                let updated = [...accessorials];
                                updated[idx] = next;

                                callbacks.modifyOrder({
                                    order_revenue_adjustments: {
                                        ...(order.order_revenue_adjustments || {}),
                                        [perspective]: updated,
                                    },
                                });
                            },
                            onRemove: () => {
                                let updated = [...accessorials].splice(idx, 1);

                                callbacks.modifyOrder({
                                    order_revenue_adjustments: {
                                        ...(order.order_revenue_adjustments || {}),
                                        [perspective]: updated,
                                    },
                                });
                            },
                        }}
                    />
                );
            })}

            <Grid
                container
                direction="row"
                css={css`
                    margin-top: 20px;
                    margin-bottom: 20px;
                `}
            >
                <PrimaryButton
                    onClick={() => {
                        const DEFAULT_CHARGE = {
                            type: 'Custom',
                            rate: 0,
                            quantity: 1,
                            total: 0,
                        };

                        let updated = [...accessorials, DEFAULT_CHARGE];

                        callbacks.modifyOrder({
                            order_revenue_adjustments: {
                                ...(order.order_revenue_adjustments || {}),
                                [perspective]: updated,
                            },
                        });
                    }}
                >
                    Add Charge
                </PrimaryButton>
            </Grid>

            <Grid
                container
                direction="row"
                css={css`
                    margin-bottom: 8px;
                    padding: 0px 25px;
                `}
            >
                <Grid
                    container
                    direction="column"
                    css={css`
                        flex: 1;
                        flex-basis: 0;
                    `}
                >
                    <Body1>Base Rate</Body1>
                </Grid>
                <Grid
                    container
                    direction="column"
                    css={css`
                        flex: 1;
                        flex-basis: 0;
                        align-items: flex-end;
                    `}
                >
                    <H3>{dollarFormatter.format(subtotal)}</H3>
                </Grid>
            </Grid>

            <Grid
                container
                direction="row"
                css={css`
                    margin-bottom: 20px;
                    padding: 0px 25px;
                    padding-bottom: 20px;

                    border-bottom: 1px solid #4c4c4c;
                `}
            >
                <Grid
                    container
                    direction="column"
                    css={css`
                        flex: 1;
                        flex-basis: 0;
                    `}
                >
                    <Body1>Accessorials</Body1>
                </Grid>
                <Grid
                    container
                    direction="column"
                    css={css`
                        flex: 1;
                        flex-basis: 0;
                        align-items: flex-end;
                    `}
                >
                    <H3
                        css={css`
                            color: ${colors.greens.primary};
                        `}
                    >
                        {dollarFormatter.format(accessorialsSum)}
                    </H3>
                </Grid>
            </Grid>
            <Grid
                container
                direction="row"
                css={css`
                    margin-bottom: 44px;
                `}
            >
                <Grid
                    container
                    direction="column"
                    css={css`
                        flex: 1;
                        flex-basis: 0;
                    `}
                />
                <Grid
                    container
                    direction="column"
                    css={css`
                        flex: 1;
                        flex-basis: 0;
                    `}
                >
                    <Grid
                        container
                        direction="row"
                        css={css`
                            padding: 0 25px;
                        `}
                    >
                        <Grid
                            container
                            direction="column"
                            css={css`
                                flex: 1;
                                flex-basis: 0;
                                justify-content: center;
                            `}
                        >
                            <Body1>Total</Body1>
                        </Grid>
                        <Grid
                            container
                            direction="column"
                            css={css`
                                flex: 1;
                                flex-basis: 0;
                                justify-content: center;
                                align-items: flex-end;
                            `}
                        >
                            <H3>{dollarFormatter.format(total)}</H3>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default OrderDetailsPricing;
