import React, { useState, useEffect, createContext, useMemo } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import {
    ApolloClient,
    ApolloLink,
    ApolloProvider,
    concat,
    HttpLink,
    split,
    useLazyQuery,
    useMutation,
} from '@apollo/client';
import cache from '@/graphql';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { useQuery } from '@apollo/client';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import api from '@/utilities/api';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import ReactGA from 'react-ga4';

import * as ROUTES from '../constants/routes';
import '../styles/App.css';

import SigninComponent from './Auth/Auth';

import AuthContactPage from './AuthContact';

import MyOrders from './MyOrders';
import AuthResetPasswordRequest from './AuthResetPasswordRequest';
import AuthResetPassword from './AuthResetPassword';
import AdminCommunications from './admin/AdminCommunications';
import AdminFTLBoard from './admin/AdminFTLBoard';
import AdminEditFTLRoute from './admin/AdminEditFTLRoute';
import AdminTariffs from './admin/AdminTariffs';
import AdminExceptions from './admin/AdminExceptions';
import AdminNotes from './admin/AdminNotes';
import AdminIntegrations from './admin/AdminIntegrations';

import {
    ADMIN_CLIENTS_BY_ID,
    CLIENT_BY_ID,
    QUERY_BY_FIREBASE_ID,
    TEAMMATE_CLIENT_BY_ID,
} from '../graphql/queries/users';
import { UPDATE_GEOLOCATION } from '@/graphql/mutations/users';
import AdminUsers from './admin/AdminUsers';
import Navigation from './Navigation';
import NavResponsiveContainer from './Navigation/NavResponsiveContainer';
import { useMediaQuery } from 'react-responsive';
import { CONFIG } from '@/config';
import Account from './Account';
import Tariff from './Account/Tariffs';
import ShipmentForm from './ShipmentForm';
import JobDetails from './ShipmentForm/JobDetails';
import Loader from './Loader';
import DispatchPlan from './DispatchPlan';
import DispatchUnassigned from './DispatchUnassigned';
import Tracking from './Tracking';
import AdminShipperDetails from './admin/AdminShipperDetails';
import AdminCarrierDetails from './admin/AdminCarrierDetails';
import CustomerPredelivery from './CustomerPredelivery';
import CustomerTracking from './CustomerTrack';
import CustomerRating from './CustomerRating';
import ExceptionManager from './ExceptionManager';
import CustomerRoot from './Customer';
import ShipperSignUp from './ShipperSignUp';
import CarrierSignUp from './CarrierSignUp';
import Subregions from './Subregions';
import TeammateSignUp from './TeammateSignUp';

import CarrierFTLBoard from './CarrierFTLBoard';
import MyRoutes from './MyRoutes';
import OrderDetailsPage from './OrderDetailsPage';
import ListingDetailsPage from './ListingDetailsPage';
import * as Sentry from '@sentry/react';
import { ErrorFallback } from './ErrorBoundary';
import SaaSRouteDetails from './RouteDetails';
import LogRocket from 'logrocket';
import AdminAccountReset from './admin/AdminAccountReset';
import LoadBoardLTL from './LoadBoardLTL';
import InstantQuote from './InstantQuote';
import AdminOrders from './admin/AdminOrders';
import Resources from './Resources';
import Accounting from './Accounting/Invoices';
import AccountingInvoice from './Accounting/Invoice';
import ResourceNotifications from './ResourceNotifications';
import AdminFinancialsReceivables from './admin/AdminFinancials/receivables';
import AdminFinancialsPayables from './admin/AdminFinancials/payables';
import PrivacyPage from './Privacy';
import CancelPage from './Cancel';
import AdminMatchingTool from './admin/AdminMatchingTool';
import AuctionedOrders from './AuctionedOrders';
import CustomerConfirmTimeframe from './CustomerConfirmTimeframe';
import CorporateSignup from './CorporateSignup';
import CorporateUsers from './CorporateUsers';
import OrderDetailsPageDriver from './OrderDetailsPageDriver';
import CarrierAccountingOrders from './CarrierAccountingOrders';
import Accessorials from './Accessorials';
import ShipperAccountingInvoice from './ShipperAccountingInvoice';
import CarrierAccountingInvoices from './CarrierAccountingInvoices';
import CarrierAccountingInvoice from './CarrierAccountingInvoice';
import TrackOrder from './TrackOrder';
import Manifests from './Manifests';
import Manifest from './Manifest';
import WarehouseSetup from './WarehouseSetup';
import InventoryManagement from './InventoryManagement';
import DriverPayments from './DriverPayments';
import DriverPayment from './DriverPayment';

export const UserContext = createContext();

const theme = createTheme({
    typography: {
        fontFamily: ['Montserrat', 'Roboto', 'Arial', 'sans-serif'],
        button: {
            fontWeight: '700',
            textTransform: 'capitalize',
        },
    },
    palette: {
        primary: {
            main: '#59b863',
        },
    },
});

const UserWrapper = ({ firebaseUser, children, isAuthenticated }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [customHeader, setCustomHeader] = useState(null);
    const [customColor, setCustomColor] = useState(null);

    const isSmall = useMediaQuery({ query: '(max-width: 1280px)' });
    const [sidebarCollapsed, setSidebarCollapsed] = useState(isSmall);

    useEffect(() => {
        setSidebarCollapsed(isSmall);
    }, [isSmall]);

    const { loading, data } = useQuery(QUERY_BY_FIREBASE_ID, {
        variables: { legacy_user_id: firebaseUser?.uid },
        skip: !firebaseUser || firebaseUser?.uid === 'anonymous-user',
        onError: (error) => {
            console.error(error);
            Sentry.captureException(error);
        },
        onCompleted: (result) => {
            const hasuraUser = result?.users?.[0];
            if (hasuraUser) {
                LogRocket.identify(firebaseUser?.uid, {
                    email: hasuraUser?.email,
                    name: hasuraUser?.username,
                    user_id: hasuraUser?.user_id,
                });
                Sentry.setUser({
                    id: hasuraUser.user_id,
                    username: hasuraUser.username,
                    email: hasuraUser.email,
                });
            }
        },
    });

    const [updateGeolocation] = useMutation(UPDATE_GEOLOCATION, {
        onError: (e) => {
            console.error(e);
            Sentry.captureException(e);
        },
    });

    const [clientId, setClientId] = useState(window?.sessionStorage?.getItem('client_id'));
    const [getClient, { loading: clientLoading, data: clientData }] = useLazyQuery(CLIENT_BY_ID);
    const [getTeammateClient, { loading: teammateLoading, data: teammateData }] = useLazyQuery(TEAMMATE_CLIENT_BY_ID);
    const [getAdminClients, { loading: adminLoading, data: adminData }] = useLazyQuery(ADMIN_CLIENTS_BY_ID);

    const [client, teammate] = useMemo(() => {
        let client, teammate;
        const user = data?.users?.[0];

        if (teammateData?.teammate) {
            const { userByTeamLeaderId, ...rest } = teammateData.teammate;
            client = userByTeamLeaderId?.client;
            teammate = rest;
        } else {
            client = clientData?.client;
            teammate = null;
        }

        let useClient = true;
        if ((user?.roles?.['ADMIN'] || user?.roles?.['ONWARD_ADMIN']) && !clientId) useClient = false;
        const useTeammate = user?.roles?.['TEAMMATE'];

        return [useClient ? client : null, useTeammate ? teammate : null];
    }, [data, clientId, clientData, teammateData]);

    const admin_clients = useMemo(() => {
        const user = data?.users?.[0];
        let admin_clients = (adminData?.admin_client_mappings || []).map((mapping) => mapping.client);

        return user?.roles?.['ADMIN'] ? admin_clients : null;
    }, [data, adminData]);

    useEffect(() => {
        if (
            !isAuthenticated &&
            ![
                /^\/customer/,
                /^\/rate/,
                /^\/signin/,
                /^\/(shipper|carrier|teammate|corporate)\/signup/,
                /^\/pw-forget/,
                /^\/auth\/password-reset/,
                /^\/instant-quote/,
                /^\/privacy/,
                /^\/cancel/,
                /^\/order\/([0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12})\/driver/,
            ].some((unAuthPath) => unAuthPath.test(location.pathname))
        ) {
            console.log('redirect');
            navigate('/signin');
        }
    }, [isAuthenticated]);

    // Super user client login
    useEffect(() => {
        const user = data?.users?.[0];
        if ((user?.roles?.['ADMIN'] || user?.roles?.['ONWARD_ADMIN']) && clientId) {
            getClient({ variables: { client_id: clientId } });
        }
    }, [data, clientId]);

    // Regular user client/teammate/admin login
    useEffect(() => {
        const user = data?.users?.[0];
        if (user?.roles?.['SHIPPER'] || user?.roles?.['CARRIER']) {
            getClient({ variables: { client_id: user.user_id } });
        } else if (user?.roles?.['TEAMMATE']) {
            getTeammateClient({ variables: { teammate_id: user.user_id } });
        } else if (user?.roles?.['ADMIN']) {
            getAdminClients({ variables: { admin_id: user.user_id } });
        }
    }, [data]);

    // Update geolocation on users table at sign-in
    useEffect(() => {
        (async () => {
            if (isAuthenticated && data?.users?.[0]) {
                try {
                    let currentDate = Date.now();
                    let expirationDate = data?.users?.[0]?.geolocation_expiration || new Date(0);
                    if (currentDate > expirationDate) {
                        // Expires after 10 days
                        const oneDay = 24 * 60 * 60 * 1000;
                        const newExpirationDate = new Date(currentDate + oneDay * 10);

                        const pos = await new Promise((resolve, reject) => {
                            navigator.geolocation.getCurrentPosition(resolve, reject);
                        });

                        updateGeolocation({
                            variables: {
                                user_id: data?.users?.[0]?.user_id,
                                geolocation: {
                                    lat: pos.coords.latitude,
                                    lng: pos.coords.longitude,
                                },
                                geolocation_expiration: newExpirationDate.toISOString(),
                            },
                        });
                    }
                } catch (e) {
                    // User has denied geolocation access.
                    console.error(e);
                }
            }
        })();
    }, [isAuthenticated, data]);

    const loadingClient = clientLoading || (clientId && !client);
    if (loading || loadingClient || teammateLoading || adminLoading) {
        return <Loader />;
    }

    return (
        <UserContext.Provider
            value={{
                customHeader,
                setCustomHeader,
                customColor,
                setCustomColor,
                sidebarCollapsed,
                setSidebarCollapsed,
                clientId,
                setClientId,
                firebaseUser,
                user: data?.users?.[0],
                client,
                teammate,
                admin_clients,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

const AuthWrapper = ({ children, history }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    // const [accessToken, setAccessToken] = useState(null);
    const [firebaseUser, setFirebaseUser] = useState(null);

    useEffect(() => {
        const unregisterAuthObserver = onAuthStateChanged(getAuth(), async (user) => {
            console.debug('Auth State Changed');
            if (user) {
                // User is signed in.
                console.debug('User is signed in');
                if (process.env.REACT_APP_ENVIRONMENT === 'development') console.debug(user);
                if (user.uid !== 'anonymous-user') {
                    LogRocket.identify(user.uid);
                    Sentry.setUser({ id: user.uid });
                }
                setIsAuthenticated(true);
                setIsLoading(false);
                setFirebaseUser(user);
                api.setToken(user);
                window?.localStorage?.setItem('onward.already-signed-in', 1);
            } else {
                // User is signed out.
                console.debug('User is signed out');
                setIsAuthenticated(false);
                setIsLoading(false);
                setFirebaseUser(null);
                window?.localStorage?.removeItem('onward.already-signed-in');
            }
        });

        return () => {
            unregisterAuthObserver();
        };
    }, []);

    const wsLink = new GraphQLWsLink(
        createClient({
            url: CONFIG.WS_HASURA_URL,
            connectionParams: async () => {
                let auth = { 'X-Hasura-Admin-Secret': 'myadminsecretkey' };
                if (CONFIG.ENV !== 'development') {
                    const accessToken = await firebaseUser.getIdToken();
                    auth = { authorization: `Bearer ${accessToken}` };
                }

                return {
                    headers: {
                        ...auth,
                    },
                };
            },
        })
    );
    const httpLink = new HttpLink({
        uri: CONFIG.HASURA_URL,
        fetchOptions: {
            method: 'POST',
        },
    });

    // The split function takes three parameters:
    //
    // * A function that's called for each operation to execute
    // * The Link to use for an operation if the function returns a "truthy" value
    // * The Link to use for an operation if the function returns a "falsy" value
    const splitLink = split(
        ({ query }) => {
            const definition = getMainDefinition(query);
            return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
        },
        wsLink,
        httpLink
    );

    const authMiddleware = new ApolloLink(async (operation, forward) => {
        const definition = getMainDefinition(operation.query);
        if (definition.kind === 'OperationDefinition' && definition.operation !== 'subscription') {
            let auth = { 'X-Hasura-Admin-Secret': 'myadminsecretkey' };
            if (CONFIG.ENV !== 'development') {
                const accessToken = await firebaseUser.getIdToken();
                auth = { authorization: `Bearer ${accessToken}` };
            }

            operation.setContext(({ headers = {} }) => ({
                headers: {
                    ...headers,
                    ...auth,
                },
            }));
        }

        return forward(operation);
    });

    const client = new ApolloClient({
        link: concat(authMiddleware, splitLink),
        cache,
        connectToDevTools: CONFIG.ENV !== 'production',
    });

    if (isLoading) {
        return <Loader />;
    }

    return (
        <ApolloProvider client={client}>
            <UserWrapper firebaseUser={firebaseUser} children={children} isAuthenticated={isAuthenticated} />
        </ApolloProvider>
    );
};

const App = () => {
    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
    }, []);

    return (
        <Router>
            <AuthWrapper>
                <ThemeProvider theme={theme}>
                    <Navigation />
                    <NavResponsiveContainer>
                        <Sentry.ErrorBoundary fallback={ErrorFallback}>
                            <Routes>
                                {/* Account & SignIn */}
                                <Route exact path={ROUTES.SIGNIN} element={<SigninComponent />} />
                                <Route exact path={ROUTES.ACCOUNT} element={<Account />} />
                                <Route exact path={ROUTES.ACCOUNT_TARIFFS} element={<Tariff />} />
                                <Route exact path={ROUTES.ACCOUNTING_V2_INVOICE} element={<AccountingInvoice />} />
                                <Route exact path={ROUTES.SUBREGION} element={<Subregions />} />
                                <Route exact path={ROUTES.RESOURCES} element={<Resources />} />
                                <Route exact path={ROUTES.NOTIFICATIONS} element={<ResourceNotifications />} />
                                <Route exact path={ROUTES.SHIPPER_SIGN_UP} element={<ShipperSignUp />} />
                                <Route exact path={ROUTES.CARRIER_SIGN_UP} element={<CarrierSignUp />} />
                                <Route exact path={ROUTES.TEAMMATE_SIGN_UP} element={<TeammateSignUp />} />
                                <Route exact path={ROUTES.CORPORATE_SIGNUP} element={<CorporateSignup />} />
                                <Route exact path={ROUTES.PASSWORD_FORGET} element={<AuthResetPasswordRequest />} />
                                <Route exact path={ROUTES.PASSWORD_RESET_PAGE} element={<AuthResetPassword />} />

                                {/* Admin Routes */}
                                <Route exact path={ROUTES.ADMIN_USERS} element={<AdminUsers />} />
                                <Route exact path={ROUTES.ADMIN_SHIPPER} element={<AdminShipperDetails />} />
                                <Route exact path={ROUTES.ADMIN_CARRIER} element={<AdminCarrierDetails />} />
                                <Route exact path={ROUTES.ADMIN_ORDERS} element={<AdminOrders />} />
                                <Route exact path={ROUTES.ADMIN_COMMUNICATIONS} element={<AdminCommunications />} />
                                <Route exact path={ROUTES.ADMIN_FTL} element={<AdminFTLBoard />} />
                                <Route exact path={ROUTES.ADMIN_TRACKING_FTL} element={<Tracking />} />
                                <Route exact path={ROUTES.ADMIN_TRACKING_LTL} element={<Tracking />} />
                                <Route exact path={ROUTES.ADMIN_EDITFTLROUTE} element={<AdminEditFTLRoute />} />
                                <Route exact path={ROUTES.ADMIN_ACCOUNT_RESET} element={<AdminAccountReset />} />
                                <Route
                                    exact
                                    path={ROUTES.ADMIN_FINANCIALS_RECEIVABLES}
                                    element={<AdminFinancialsReceivables />}
                                />
                                <Route
                                    exact
                                    path={ROUTES.ADMIN_FINANCIALS_PAYABLES}
                                    element={<AdminFinancialsPayables />}
                                />
                                <Route exact path={ROUTES.ADMIN_MATCHING_TOOL} element={<AdminMatchingTool />} />
                                <Route exact path={ROUTES.ADMIN_TARIFFS} element={<AdminTariffs />} />
                                <Route exact path={ROUTES.ADMIN_EXCEPTIONS} element={<AdminExceptions />} />
                                <Route exact path={ROUTES.ADMIN_NOTES} element={<AdminNotes />} />
                                <Route exact path={ROUTES.ADMIN_INTEGRATIONS} element={<AdminIntegrations />} />

                                {/* User Routes */}
                                <Route exact path={ROUTES.WAREHOUSES} element={<WarehouseSetup />} />
                                <Route exact path={ROUTES.MANIFESTS} element={<Manifests />} />
                                <Route exact path={ROUTES.INVENTORY} element={<InventoryManagement />} />
                                <Route exact path={ROUTES.MANIFEST} element={<Manifest />} />
                                <Route exact path={ROUTES.DISPATCH_PLAN} element={<DispatchPlan />} />
                                <Route
                                    exact
                                    path={ROUTES.CARRIER_DISPATCH_UNASSIGNED}
                                    element={<DispatchUnassigned />}
                                />
                                <Route
                                    exact
                                    path={ROUTES.SHIPPER_DISPATCH_UNASSIGNED}
                                    element={<DispatchUnassigned />}
                                />
                                <Route exact path={ROUTES.SHIPPER_INVOICE} element={<ShipperAccountingInvoice />} />
                                <Route exact path={ROUTES.CARRIER_ACCOUNTING_V2} element={<Accounting />} />
                                <Route
                                    exact
                                    path={ROUTES.CARRIER_ACCOUNTING_ORDERS}
                                    element={<CarrierAccountingOrders />}
                                />
                                <Route exact path={ROUTES.CARRIER_INVOICES} element={<CarrierAccountingInvoices />} />
                                <Route path={ROUTES.CARRIER_INVOICE} element={<CarrierAccountingInvoice />} />
                                <Route exact path={ROUTES.ACCESSORIALS} element={<Accessorials />} />
                                <Route exact path={ROUTES.CARRIER_LOADBOARD_LTL} element={<LoadBoardLTL />} />
                                <Route exact path={ROUTES.SHIPPER_TRACKING} element={<Tracking />} />
                                <Route exact path={ROUTES.CARRIER_TRACKING} element={<Tracking />} />
                                <Route exact path={ROUTES.CARRIER_LOADBOARD_FTL} element={<CarrierFTLBoard />} />
                                <Route exact path={ROUTES.SHIPPER_ROUTEDETAILS} element={<SaaSRouteDetails />} />
                                <Route exact path={ROUTES.ADMIN_ROUTEDETAILS} element={<SaaSRouteDetails />} />
                                <Route exact path={ROUTES.CARRIER_ROUTEDETAILS} element={<SaaSRouteDetails />} />
                                <Route exact path={ROUTES.CARRIER_OMS_MYROUTES} element={<MyRoutes />} />
                                <Route exact path={ROUTES.SHIPPER_OMS_MYROUTES} element={<MyRoutes />} />
                                <Route exact path={ROUTES.IMPORT_ORDERS} element={<ShipmentForm />} />
                                <Route exact path={ROUTES.JOB_DETAILS} element={<JobDetails />} />
                                <Route exact path={ROUTES.EXCEPTIONS} element={<ExceptionManager />} />
                                <Route path={ROUTES.ORDER_DETAILS_PAGE_DRIVER} element={<OrderDetailsPageDriver />} />
                                <Route path={ROUTES.ORDER_DETAILS_PAGE} element={<OrderDetailsPage />} />
                                <Route path={ROUTES.LISTING_DETAILS_PAGE} element={<ListingDetailsPage />} />
                                <Route exact path={ROUTES.SHIPPER_OMS_MYORDERS} element={<MyOrders />} />
                                <Route exact path={ROUTES.CARRIER_OMS_MYORDERS} element={<MyOrders />} />
                                <Route
                                    exact
                                    path={ROUTES.AUCTIONED_ORDERS_BIDS}
                                    element={<AuctionedOrders viewType="bids" />}
                                />
                                <Route
                                    exact
                                    path={ROUTES.AUCTIONED_ORDERS_LISTINGS}
                                    element={<AuctionedOrders viewType="listings" />}
                                />
                                <Route exact path={ROUTES.CORPORATE_USERS} element={<CorporateUsers />} />
                                <Route exact path={ROUTES.DRIVER_PAYMENTS} element={<DriverPayments />} />
                                <Route path={ROUTES.DRIVER_PAYMENT} element={<DriverPayment />} />

                                {/* Customer Routes */}
                                <Route
                                    exact
                                    path={ROUTES.CUSTOMER_CONFIRM_TIMEFRAME_PAGE}
                                    element={<CustomerConfirmTimeframe />}
                                />
                                <Route
                                    exact
                                    path={ROUTES.CUSTOMER_PREDELIVERY_SURVEY_PAGE}
                                    element={<CustomerPredelivery />}
                                />
                                <Route exact path={ROUTES.CUSTOMER_TRACK_PAGE} element={<CustomerTracking />} />
                                <Route exact path={ROUTES.CUSTOMER_TRACK_BY_PO} element={<CustomerTracking />} />
                                <Route exact path={ROUTES.RATE} element={<CustomerRating />} />
                                <Route exact path={ROUTES.CUSTOMER} element={<CustomerRoot />} />

                                {/* Misc Routes */}
                                <Route exact path={ROUTES.AUTH_CONTACT} element={<AuthContactPage />} />
                                <Route exact path={ROUTES.INSTANT_QUOTE} element={<InstantQuote />} />
                                <Route exact path={ROUTES.CANCEL} element={<CancelPage />} />
                                <Route exact path={ROUTES.PRIVACY} element={<PrivacyPage />} />
                                <Route exact path={ROUTES.TRACK_ORDER} element={<TrackOrder />} />

                                {/* Catch all redirect to signin */}
                                <Route path="*" element={<Navigate replace to="/signin" />} />
                            </Routes>
                        </Sentry.ErrorBoundary>
                    </NavResponsiveContainer>
                </ThemeProvider>
            </AuthWrapper>
        </Router>
    );
};

export default App;
