import { useCallback, useContext, useMemo, useState } from 'react';
import FTLStopHelpers from '@/utilities/FTLStopHelpers';
import { PlanningContext } from './context';
import { useClientUser } from '@/hooks/useClientUser';
import { addDays } from 'date-fns';
import { asDateInTZ, asUTCDate, convertToISO } from '@/utilities/convertToISO';

import { useApolloClient, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { QUERY_PLANNING_DATA, LOADBOARD_QUERY, NETWORK_ROUTE_QUERY, GET_ROUTE_ALIAS } from './graphql/queries';
import { UPDATE_ROUTE_STOPS, UPDATE_ROUTE } from './graphql/mutations';
import { UPDATE_CLIENT_BY_PK } from '@/graphql/mutations/clients';
import * as Sentry from '@sentry/react';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { formatInTimeZone, getTimezoneOffset } from 'date-fns-tz';
import { post } from '@/utilities/onwardClient';
import { CLAIM_NOW, ORDER_RECOMMENDATIONS, PLACE_BID } from '@/constants/apiRoutes';
import { calcItemVolume } from '@/utilities/calculateItemMetrics';
import useAction from '@/utilities/useQuery';
import { captureException } from '@sentry/react';
import { differenceInMinutes } from 'date-fns';
import { PLAN_PAGE_NETWORK_ROUTES } from '@/constants/featureFlags';
import { isEmpty } from 'lodash';

export const useLazyRecommendations = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const { test_acc } = useClientUser();

    const {
        state: { routes },
    } = useContext(PlanningContext);

    const getOrderRecommendations = useCallback(async () => {
        setLoading(true);
        try {
            const result = await post(ORDER_RECOMMENDATIONS, {
                routes: routes.map((route) => {
                    const ordersByKey = Object.fromEntries(
                        route.orders.map((mapping) => [mapping.order_id, mapping.order])
                    );

                    return {
                        route_id: route.route_id,
                        capacity: 1200,
                        delivery_date: route.scheduled_delivery,
                        stops: route.stopsByRouteId.map((stop) => {
                            const cubes = calcItemVolume(
                                (stop.orders || []).map((order_id) => ordersByKey[order_id]).filter((order) => order)
                            );
                            return {
                                ...stop,
                                cubes,
                            };
                        }),
                    };
                }),
                options: {
                    max_detour_miles: 150,
                    max_detour_minutes: 60,
                    test_orders: test_acc,
                },
            });

            if (result?.data?.recommendations) {
                const allRecs = result.data.recommendations.reduce((acc, rec) => {
                    const recs = { ...acc };
                    rec.recommendations.forEach((orderRec) => {
                        const existingRec = recs[orderRec.order.order_id];
                        if (!existingRec || orderRec.score > existingRec.score) {
                            recs[orderRec.order.order_id] = orderRec;
                        }
                    });
                    return recs;
                }, {});

                setData(
                    Object.values(allRecs)
                        .sort((a, b) => b.score - a.score)
                        .slice(0, 3)
                );
            }
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }, [routes]);

    return { getOrderRecommendations, data, loading };
};

export const useNetworkRoutesQuery = () => {
    const { deliveryDate, setError, showNetworkRoutes } = useContext(PlanningContext);
    const { user_id, test_acc, circles } = useClientUser();

    const start = asUTCDate(deliveryDate);
    const end = addDays(start, 1);

    // TODO - add load board query
    const {
        loading,
        error,
        data,
        refetch: networkRouteRefetch,
    } = useQuery(NETWORK_ROUTE_QUERY, {
        skip: !circles?.[PLAN_PAGE_NETWORK_ROUTES] || !showNetworkRoutes,
        variables: {
            from_date: start,
            to_date: end,
            user_id,
            test_acc,
        },
        fetchPolicy: 'cache-and-network',
        onError: (error) => {
            setError(error, `Error getting network routes for delivery date ${deliveryDate}`);
        },
    });
    const networkRoutes = useMemo(() => {
        if (data?.routes) {
            return data?.routes;
        }
        return [];
    }, [data]);

    return { loading, error, networkRoutes, networkRouteRefetch };
};

export const useCustomerStops = (route) => {
    return (route?.stopsByRouteId || []).filter((stop) => FTLStopHelpers.isCustomerStop(stop, route))?.length || 0;
};

export const useCallbacks = () => {
    const { cache } = useApolloClient();
    const {
        setError,
        setNotification,
        setModalOpen,
        setBid,
        setSelectedLoads,
        state: { selectedLoadBoardLoads },
        callbacks: { refetch, loadBoardRefetch },
    } = useContext(PlanningContext);
    const { user_id } = useClientUser();
    const [setRouteAlias] = useMutation(UPDATE_ROUTE);
    const [updateStops] = useMutation(UPDATE_ROUTE_STOPS);
    const [updateShapes] = useMutation(UPDATE_CLIENT_BY_PK);

    const saveShapes = (shapesObj) => {
        updateShapes({
            variables: {
                client_id: user_id,
                update: {
                    map_shapes: shapesObj,
                },
            },
            onCompleted: () => {
                setNotification({
                    severity: 'success',
                    message: 'Manufacturer shape changes saved',
                });
            },
            onError: (e) => {
                Sentry.captureException(e);
                setError('Error Saving Shapes');
            },
        });
    };

    const pushRouteToTracking = (route, sendingTo) => {
        let newStatus;
        let newCarrierId;
        switch (sendingTo) {
            case 'internal':
                newStatus = 'active';
                newCarrierId = route.shipper_id;
                break;
            case 'marketplace':
                newStatus = 'pending';
                newCarrierId = null;
                break;
            case 'partner':
                newStatus = 'active';
                newCarrierId = route.carrier_id;
                break;
        }

        const inProgress = route.stopsByRouteId.some((stop) => stop.stop_completion_time);
        if (inProgress) {
            newStatus = 'inProgress';
        }
        const allComplete = route.stopsByRouteId.filter((stop) => !stop.start && !stop.end && stop.type !== "LUNCH").every((stop) => stop.stop_completion_time);
        if (allComplete) {
            newStatus = 'complete'
        }

        updateStops({
            variables: {
                route_id: route.route_id,
                route_update: {
                    status: newStatus,
                    pending_confirmations: false,
                    sending_to: sendingTo,
                    oms: sendingTo === 'internal',
                    carrier_id: newCarrierId,
                    need_to_submit: false,
                },
            },
            onError: (error) => {
                setError(error, `Error Pushing route to tracking. Please try again.`);
            },
            onCompleted: () => {
                setNotification({
                    severity: 'success',
                    message:
                        'Route has successfully been pushed to Tracking. Unlock the route to make further changes.',
                });
            },
        });
    };

    const updateRouteAlias = async (route, routeAlias) => {
        const route_alias = routeAlias.trim();
        if (route?.route_alias === route_alias || routeAlias === `Route ${route.route_number}`) return;

        setRouteAlias({
            variables: {
                route_id: route.route_id,
                update: { route_alias },
            },
            onError: (error) => {
                setError(error, `Error setting route alias. Please try again.`);
            },
            onCompleted: () => {
                const aliasSetMessage = `Route alias successfully set to ${route_alias}`;
                const aliasRemovedMessage = 'Route alias successfully removed';

                setNotification({
                    severity: 'success',
                    message: routeAlias ? aliasSetMessage : aliasRemovedMessage,
                });
            },
        });
    };

    const [claimNow] = useAction(async (body) => post(CLAIM_NOW, body), {
        onComplete: ({ data }) => {
            if (data?.success?.length === selectedLoadBoardLoads.length) {
                setNotification({
                    severity: 'success',
                    message: `Successfully claimed orders! ${data.success
                        .map((order) => order.order_number)
                        .join(', ')}`,
                });
            } else if (data?.success?.length > 0) {
                setNotification({
                    severity: 'warning',
                    message: `Only claimed ${data.success
                        .map((order) => order.order_number)
                        .join(', ')}. Some other orders have already been claimed or have since been cancelled.`,
                });
            } else {
                setNotification({
                    severity: 'error',
                    message: data?.error || 'Error claiming orders. Please try again.',
                });
            }
            setSelectedLoads({});
            setModalOpen(null);
            setBid(null);
            loadBoardRefetch();
            refetch();
        },
        onError: (err) => {
            console.error(err);
            captureException(err);
            setNotification({
                severity: 'error',
                message: 'Error claiming orders. Please try again.',
            });
            setModalOpen(null);
            setBid(null);
            loadBoardRefetch();
        },
    });

    const [placeBid] = useAction(async (body) => post(PLACE_BID, body), {
        onComplete: ({ data }) => {
            if (data?.success) {
                setNotification({
                    severity: 'success',
                    message: 'Bid successfully placed!',
                });
                cache.evict({ id: 'ROOT_QUERY', fieldName: 'listings' });
            } else {
                setNotification({
                    severity: 'error',
                    message: data?.error || 'Error placing Bid. Please try again.',
                });
            }
            setModalOpen(null);
            setBid(null);
        },
        onError: (err) => {
            console.error(err);
            captureException(err);
            setNotification({
                severity: 'error',
                message: 'Error placing bid. Please try again.',
            });
            setModalOpen(null);
            setBid(null);
        },
    });

    return {
        pushRouteToTracking,
        updateRouteAlias,
        saveShapes,
        claimNow,
        placeBid,
    };
};

export const useAfterHours = (route) => {
    return useMemo(() => {
        const localtz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const zip = route?.orders?.[0]?.order?.dropoff_zip;
        const tz = zipcode_to_timezone.lookup(zip) || localtz;

        const localDate = new Date();

        const sod = new Date(localDate);
        sod.setHours(8);
        sod.setMinutes(0);
        sod.setSeconds(0);
        sod.setMilliseconds(0);
        const sodRouteLocal = new Date(
            sod.getTime() + getTimezoneOffset(localtz, localDate) - getTimezoneOffset(tz, localDate)
        );

        const eod = new Date(localDate);
        eod.setHours(17);
        eod.setMinutes(0);
        eod.setSeconds(0);
        eod.setMilliseconds(0);
        const eodRouteLocal = new Date(
            eod.getTime() + getTimezoneOffset(localtz, localDate) - getTimezoneOffset(tz, localDate)
        );

        // console.log(`${sodRouteLocal.toISOString()} < ${localDate.toISOString()} > ${eodRouteLocal.toISOString()}`);
        // console.log(`${sodRouteLocal} < ${localDate} > ${eodRouteLocal}`);

        return localDate < sodRouteLocal || localDate > eodRouteLocal;
    }, [route]);
};

export const useRouteTimeLimits = (
    route,
    preferences_route_time_limit,
    preferences_route_eod_limit,
    preferences_route_eod_limit_next_day
) => {
    return useMemo(() => {
        if (!route || !route?.stopsByRouteId?.length || (!preferences_route_time_limit && !preferences_route_eod_limit))
            return false;

        const routeStart = route.start_time;
        const lastStop = route.stopsByRouteId.reduce(
            (currentLastStop, stop) => {
                if (stop.ordering >= currentLastStop?.ordering) {
                    return stop;
                }
                return currentLastStop;
            },
            { ordering: -1 }
        );

        let routeEnd;
        // If there are no orders associated with the route (final destination stops for example), then use the stop_start_time
        // as the route's end time. Otherwise use the stop_end_time as the route's end time.
        if (lastStop?.orders?.length) {
            routeEnd = lastStop.stop_end_time;
        } else {
            routeEnd = lastStop.stop_start_time;
        }

        if (preferences_route_time_limit) {
            const totalTimeLimit = preferences_route_time_limit.hours * 60 + preferences_route_time_limit.minutes;
            const routeDurationMinutes = differenceInMinutes(new Date(routeEnd), new Date(routeStart));
            if (routeDurationMinutes > totalTimeLimit) {
                return true;
            }
        }

        // EOD is timezone agnostic.
        if (preferences_route_eod_limit) {
            const routeEndDate = new Date(routeEnd);
            let eodDate = new Date(routeEnd);
            eodDate.setHours(preferences_route_eod_limit.hours);
            eodDate.setMinutes(preferences_route_eod_limit.minutes);
            eodDate.setSeconds(0);
            eodDate.setMilliseconds(0);
            if (preferences_route_eod_limit_next_day) {
                eodDate = addDays(eodDate, 1);
            }

            if (eodDate < routeEndDate) {
                return true;
            }
        }

        return false;
    }, [route, preferences_route_time_limit, preferences_route_eod_limit, preferences_route_eod_limit_next_day]);
};

export const useRouteStartTime = (route) => {
    return useMemo(() => {
        if (!route) {
            return null;
        }

        if (route.start_time) {
            return new Date(route.start_time);
        }

        const zip = route?.orders?.[0]?.order?.dropoff_zip;
        const routeTz = zipcode_to_timezone.lookup(zip) || Intl.DateTimeFormat().resolvedOptions().timeZone;

        const routeDate = asDateInTZ(route.scheduled_delivery, routeTz);
        routeDate.setHours(7, 0, 0, 0);
        return routeDate;
    }, [route]);
};

export const useInvalidEventStates = (route) => {
    //Determine if any crossdocked dropoff orders have not had their pickup leg completed
    const [getRouteAlias] = useLazyQuery(GET_ROUTE_ALIAS)
    return useMemo(() => {
        let badState = {};
        if (route && Array.isArray(route.orders) && route.orders.length > 0) {
            const dropoffCDOrders = route.orders
                .filter((mapping) => mapping?.type === 'DROPOFF')
                .map((mapping) => mapping?.order);

            dropoffCDOrders.forEach(async (order) => {
                if (['CD_PENDING'].includes(order.event_state)) {
                    let pickupLegLocation;

                    const pickupScheduledEvent = order.wh_events.findLast((event) => event.action.includes('ADD_PU'));
                    const zip = order.pickup_zip;
                    const tz = zipcode_to_timezone.lookup(zip) || 'America/New_York';

                    if (pickupScheduledEvent) {
                        let route_alias;
                        if (pickupScheduledEvent?.route_id) {
                            let { data } = await getRouteAlias({
                                variables: {
                                    route_id: pickupScheduledEvent?.route_id,
                                }
                            })
                            route_alias = data?.routes_by_pk?.route_alias;
                        }
                        const dateString = formatInTimeZone(
                            asDateInTZ(pickupScheduledEvent.est_pickup_date, tz),
                            tz,
                            'MM/dd/yyyy'
                        );
                        const routeNumber =
                            pickupScheduledEvent.notes && pickupScheduledEvent.route_id
                                ? pickupScheduledEvent.notes.match(/route (\d+)/i)[1]
                                : 'not assigned';
                        pickupLegLocation = `Plan Page Date: ${dateString},  Route: ${route_alias || routeNumber}.`;
                    } else if (order.pickup_date) {
                        const dateString = formatInTimeZone(asDateInTZ(order.pickup_date, tz), tz, 'MM/dd/yyyy');
                        pickupLegLocation = `Plan Page Date: ${dateString},  Route: not yet assigned.`;
                    } else {
                        pickupLegLocation = `Dispatch Unscheduled page.`;
                    }

                    badState[order.order_number] = pickupLegLocation;
                }
            });
        }
        return isEmpty(badState) ? null : badState;
    }, [route]);
};
