import React, { useMemo } from 'react';
import { Grid, MenuItem, TextField } from '@material-ui/core';
import { css } from '@emotion/react';
import { Body1 } from './blocks';
import { COLUMNS } from './constants';

const StaticCharge = ({ data, qbo_tags, orderQboTag, updateQboTag }) => {
    const processed = useMemo(() => {
        return Object.fromEntries(
            data.map(({ value, key }) => {
                return [key, value];
            })
        );
    }, [data]);

    return (
        <Grid
            container
            direction="row"
            css={css`
                border-bottom: 1px solid lightgrey;
                padding: 12px 5px;
                flex-wrap: nowrap;
            `}
        >
            {COLUMNS.map(({ key, width, align }, idx) => {
                return (
                    <Grid
                        key={key}
                        container
                        direction="column"
                        css={css`
                            flex: ${width ? width : 1};
                            flex-basis: 2;
                            max-width: ${width === 2 ? `calc(33.3% - 12px)` : 'calc(16.6% - 24px)'};
                            margin: 0 12px;
                            align-items: ${align === 'left' ? 'flex-start' : align === 'right' ? 'flex-end' : 'center'};
                        `}
                    >
                        {key === 'qbo_tag' ? (
                            <TextField
                                select
                                variant="outlined"
                                label="Tag"
                                size="small"
                                value={orderQboTag || ''}
                                onChange={(e) => updateQboTag(e.target.value)}
                                fullWidth
                            >
                                <MenuItem value="">
                                    <Body1>-</Body1>
                                </MenuItem>
                                {qbo_tags?.map((tag, i) => (
                                    <MenuItem key={i} value={tag}>
                                        <Body1>{tag}</Body1>
                                    </MenuItem>
                                ))}
                            </TextField>
                        ) : (
                            <Body1>{processed[key] || '--'}</Body1>
                        )}
                    </Grid>
                );
            })}
        </Grid>
    );
};

export default StaticCharge;
