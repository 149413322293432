/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react';
import { Grid } from '@material-ui/core';
import { css } from '@emotion/react';
import {
    OnwardExceptionCard,
    ExceptionHeader,
    ExceptionReasonText,
    ExceptionNotesText,
    ExceptionDetailsText,
} from './blocks';
import WarningIcon from '@mui/icons-material/Warning';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { colors } from '@/styles';
import { PrimaryButton } from '@/styles/blocks';
import dateFnsFormat from 'date-fns/format';
import Image from 'react-bootstrap/Image';
import { delWindowToFormattedDateSingle } from '@/utilities/delWindowToFormattedDate';

export default function ExceptionCard({ exception, order, exceptionItems, callbacks, ...props }) {
    const [isCollapsed, setIsCollapsed] = useState(exception?.status === 'RESOLVED');

    const reportedAtMap = {
        DROPOFF: 'Drop Off',
        PICKUP: 'Pickup',
        DISPATCH: 'Dispatch',
    };

    const exceptionTypeMap = {
        itemsTooDamaged: 'Items Too Damaged',
        itemDamaged: 'Item Damaged',
        customerNotAvailable: 'Customer Not Available',
        orderNotAvailable: 'Order Not Available',
        customerRefusal: 'Customer Refusal',
        unableToLocateOrder: 'Unable to Locate Order',
        deliveryDidntArrive: "Delivery didn't arrive or was cancelled",
        unableToLocateCustomer: 'Unable to Locate Customer',
        orderDidntFit: "Order Didn't Fit",
        'weather/safety': 'Weather/Safety',
        shortage: 'Shortage',
    };

    const hasCallbacks = useMemo(() => {
        return (
            callbacks &&
            typeof callbacks.setExceptionParams === 'function' &&
            typeof callbacks.setResolveExceptionParams === 'function'
        );
    }, [callbacks]);

    const createdAt = useMemo(() => {
        if (!exception.created_at) return '';

        const createdAtDate = dateFnsFormat(new Date(exception?.created_at), 'MMM d, yyyy');
        const createdAtTime = delWindowToFormattedDateSingle(exception?.created_at, order.dropoff_zip);

        return `${createdAtDate} @ ${createdAtTime}`;
    }, [exception]);

    const handleResolveClick = () => {
        callbacks.setResolveExceptionParams({
            exception: exception,
            items: exceptionItems || [],
            order: order,
        });
        // Check if setOrderId exists before calling it
        if (callbacks.setOrderId && typeof callbacks.setOrderId === 'function') {
            callbacks.setOrderId(order.order_id);
        }
    };

    return (
        <OnwardExceptionCard {...props}>
            <Grid container spacing={1}>
                <Grid item sm={12} container alignItems="center" justifyContent="space-between">
                    <Grid item container alignItems="center" xs>
                        <WarningIcon
                            css={css`
                                color: ${colors.oranges.primary};
                                margin-right: 10px;
                            `}
                        />
                        <ExceptionHeader>
                            {`${exceptionTypeMap[exception?.type] || 'Exception'} (${
                                exceptionItems?.length ? 'Item' : 'Order'
                            })${exception?.status === 'RESOLVED' ? ' - Resolved' : ''}`}
                        </ExceptionHeader>
                    </Grid>
                    <Grid item>
                        {isCollapsed ? (
                            <ExpandMoreIcon
                                onClick={() => setIsCollapsed(false)}
                                css={css`
                                    cursor: pointer;
                                    margin-right: 10px;
                                `}
                            />
                        ) : (
                            <ExpandLessIcon
                                onClick={() => setIsCollapsed(true)}
                                css={css`
                                    cursor: pointer;
                                    margin-right: 10px;
                                `}
                            />
                        )}
                    </Grid>
                </Grid>

                {!isCollapsed && (
                    <>
                        {exception?.reason && (
                            <Grid item sm={12}>
                                <ExceptionReasonText>{exception.reason}</ExceptionReasonText>
                            </Grid>
                        )}

                        {exceptionItems &&
                            exceptionItems.map((exceptionItem) => (
                                <Grid item sm={12}>
                                    <ExceptionNotesText>{`Item: ${
                                        exceptionItem.description || 'Description Not Provided'
                                    }${exceptionItem.sku ? ' - ' + exceptionItem.sku : ''}`}</ExceptionNotesText>
                                </Grid>
                            ))}

                        {exception?.notes && (
                            <Grid item sm={12}>
                                <ExceptionNotesText>{exception.notes}</ExceptionNotesText>
                            </Grid>
                        )}

                        {exception?.reported_at && (
                            <Grid item sm={12}>
                                <ExceptionDetailsText>
                                    Reported at {reportedAtMap[exception.reported_at]}
                                </ExceptionDetailsText>
                            </Grid>
                        )}

                        <Grid item sm={12}>
                            <ExceptionDetailsText>{createdAt}</ExceptionDetailsText>
                        </Grid>

                        {exception?.images && (
                            <Grid item container spacing={1} sm={12}>
                                {exception.images.map((image) => (
                                    <Grid item key={image}>
                                        <a
                                            href={image}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            css={css`
                                                position: relative;
                                            `}
                                        >
                                            <Image
                                                className="doc-thumbnail"
                                                css={css`
                                                    width: 116px;
                                                    height: 116px;
                                                `}
                                                src={image}
                                            />
                                            <OpenInNewIcon
                                                css={css`
                                                    position: absolute;
                                                    padding: 2.5px;
                                                    right: 0;
                                                    background-color: rgba(100, 100, 100, 0.5);
                                                    color: white;
                                                    border-radius: 100%;
                                                    height: 15px;
                                                    width: 15px;
                                                    margin-right: 3px;
                                                    margin-top: 3px;
                                                `}
                                            />
                                        </a>
                                    </Grid>
                                ))}
                            </Grid>
                        )}

                        {hasCallbacks && (
                            <Grid
                                direction="row"
                                container
                                css={css`
                                    margin-top: 24px;
                                `}
                            >
                                {exception.status === 'RESOLVED' ? (
                                    <Grid
                                        direction="column"
                                        container
                                        css={css`
                                            flex: 0;
                                            flex-basis: 0;
                                        `}
                                    >
                                        <PrimaryButton onClick={handleResolveClick}>View</PrimaryButton>
                                    </Grid>
                                ) : (
                                    <>
                                        <Grid
                                            direction="column"
                                            container
                                            css={css`
                                                flex: 0;
                                                flex-basis: 0;
                                                margin-right: 16px;
                                            `}
                                        >
                                            <PrimaryButton
                                                onClick={() =>
                                                    callbacks.setExceptionParams({
                                                        type: exception.reported_at,
                                                        prev: exception,
                                                        order,
                                                    })
                                                }
                                            >
                                                Edit
                                            </PrimaryButton>
                                        </Grid>
                                        <Grid
                                            direction="column"
                                            container
                                            css={css`
                                                flex: 0;
                                                flex-basis: 0;
                                            `}
                                        >
                                            <PrimaryButton onClick={handleResolveClick}>Resolve</PrimaryButton>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        )}
                    </>
                )}
            </Grid>
        </OnwardExceptionCard>
    );
}
