import React, { useMemo } from 'react';
import { css } from '@emotion/react';
import { colors } from '@/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

const dateShort = new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
});

export const useTableColumns = (tab) => {
    return useMemo(() => {
        return [
            {
                Header: 'Create Manifest',
                id: 'create-manifest-table',
                columns: [
                    {
                        Header: 'Order',
                        id: 'order_number',
                        width: 100,
                        accessor: (order) => order.order_number,
                    },
                    {
                        Header: 'PO',
                        id: 'po_number',
                        width: 100,
                        accessor: (order) => order.po_number,
                    },

                    ...(tab.value === 'INBOUND'
                        ? [
                              {
                                  Header: 'Shipper',
                                  id: 'shipper',
                                  width: 150,
                                  accessor: (order) => order?.order_shipper?.business_name || '',
                              },
                              {
                                  Header: 'Est. Received Date',
                                  id: 'est_received_date',
                                  width: 200,
                                  accessor: (order) => {
                                      const receive =
                                          (order?.wh_events || []).find(
                                              (event) => event.action === 'START:RECEIVING'
                                          ) || {};
                                      return receive?.est_received_date ? new Date(receive.est_received_date) : 0;
                                  },
                                  Cell: ({ value, callbacks, row }) => {
                                      let timeValue;
                                      if (value === 0) {
                                          timeValue = '-';
                                      } else {
                                          timeValue = dateShort.format(value);
                                      }

                                      return (
                                          <div
                                              css={css`
                                                  display: flex;
                                                  justify-content: space-between;
                                                  flex-direction: row;
                                                  width: 100%;
                                              `}
                                          >
                                              <span>{timeValue}</span>
                                              {row?.canExpand && (
                                                  <span
                                                      onClick={() => callbacks.toggleRow()}
                                                      css={css`
                                                          cursor: pointer;
                                                          margin-right: 20px;
                                                      `}
                                                  >
                                                      {row.isExpanded ? (
                                                          <KeyboardArrowDownIcon />
                                                      ) : (
                                                          <KeyboardArrowLeftIcon />
                                                      )}
                                                  </span>
                                              )}
                                          </div>
                                      );
                                  },
                              },
                          ]
                        : [
                              {
                                  Header: 'Shipper',
                                  id: 'shipper',
                                  width: 150,
                                  accessor: (order) => order?.order_shipper?.business_name || '',
                                  Cell: ({ value, callbacks, row }) => {
                                      return (
                                          <div
                                              css={css`
                                                  display: flex;
                                                  justify-content: space-between;
                                                  flex-direction: row;
                                                  width: 100%;
                                              `}
                                          >
                                              <span>{value}</span>
                                              {row?.canExpand && (
                                                  <span
                                                      onClick={() => callbacks.toggleRow()}
                                                      css={css`
                                                          cursor: pointer;
                                                          margin-right: 20px;
                                                      `}
                                                  >
                                                      {row.isExpanded ? (
                                                          <KeyboardArrowDownIcon />
                                                      ) : (
                                                          <KeyboardArrowLeftIcon />
                                                      )}
                                                  </span>
                                              )}
                                          </div>
                                      );
                                  },
                              },
                          ]),
                ],
            },
        ];
    }, [tab]);
};
