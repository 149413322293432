import { gql } from '@apollo/client';

export const DRIVER_TRACKING_FIELDS = gql`
    fragment DriverTrackingFields on driver_tracking {
        driver_id
        time
        lat
        lng: long
    }
`;
