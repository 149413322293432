import React, { forwardRef, useContext, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { ModalContext } from '..';
import { useClientUser } from '@/hooks';
import { Grid, MenuItem, TextField } from '@material-ui/core';
import { Row, SecondaryButton, SectionSubtitle2 } from '@/styles/blocks';
import { OnwardPriceInput, OnwardTextField } from '../../InputFields';
import UploadFile from '../UploadFile';
import { AIRLINE_OPTIONS } from '../../../constants';
import AdditionalDocuments from '../AdditionalDocuments';
import OrderDetailsPricing from './OrderDetailsPricing';
import { isFinite } from 'lodash';
import { TabRow, TabSection, TabTitle } from '@/components/ShipmentForm/blocks';

const OrderDetailsTab = forwardRef(({ opt }, ref) => {
    const { state: modalState, callbacks } = useContext(ModalContext);
    const { isDirty, order, hasError } = modalState;
    const { shipping_partners, user_id } = useClientUser();
    const [collapsed, setCollapsed] = useState(false);

    const showRevenueOption = useMemo(() => {
        if (order) {
            return order.wh_events?.some((event) => event.action.includes('ADD_CD'));
        }
        return false;
    }, [order]);

    let options;
    if (order.freight_type === 'pallet') {
        options = (
            <>
                <TabRow>
                    <Grid item xs={4}>
                        <TextField
                            select
                            fullWidth
                            variant="outlined"
                            label="Airline"
                            value={order.airline}
                            onChange={(e) => callbacks.modifyOrder({ airline: e.target.value })}
                        >
                            {AIRLINE_OPTIONS.map((airline) => (
                                <MenuItem key={airline} value={airline}>
                                    {airline}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid css={css`flex: 1; flex-basis;`}></Grid>
                </TabRow>
            </>
        );
    }

    return (
        <>
            <TabTitle
                ref={ref}
                collapsed={collapsed}
                callbacks={{ setCollapsed }}
                css={css`
                    align-items: center;
                    justify-content: space-between;
                    ${collapsed ? '' : 'margin-bottom: 20px;'}
                `}
            >
                Order Details
            </TabTitle>
            {collapsed ? null : (
                <>
                    <TabRow>
                        <Grid item xs={4}>
                            <OnwardTextField
                                fullWidth
                                variant="outlined"
                                label="PO #"
                                value={order.po_number}
                                onBlur={() => callbacks.makeDirty(['po_number'])}
                                onChange={(e) => callbacks.modifyOrder({ po_number: e.target.value })}
                                error={hasError.po_number && (opt.startDirty || isDirty.po_number)}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <OnwardTextField
                                fullWidth
                                variant="outlined"
                                label="Ref #"
                                value={order.reference_id}
                                onBlur={() => callbacks.makeDirty(['reference_id'])}
                                onChange={(e) => callbacks.modifyOrder({ reference_id: e.target.value })}
                                error={hasError.reference_id && (opt.startDirty || isDirty.reference_id)}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <OnwardTextField
                                fullWidth
                                variant="outlined"
                                label="Manufacturer"
                                value={order.manufacturer}
                                onBlur={() => callbacks.makeDirty(['manufacturer'])}
                                onChange={(e) => callbacks.modifyOrder({ manufacturer: e.target.value })}
                                error={hasError.manufacturer && (opt.startDirty || isDirty.manufacturer)}
                            />
                        </Grid>
                    </TabRow>

                    {order.oms ? (
                        <>
                            <TabRow>
                                <Grid item xs={12}>
                                    <TextField
                                        select
                                        variant="outlined"
                                        fullWidth
                                        label="Shipping Partner"
                                        value={order.shipper_id || ''}
                                        onChange={(e) => {
                                            const shipper_id = e.target.value;
                                            callbacks.modifyOrder({
                                                shipper_id,
                                                carrier_id: shipper_id !== user_id ? user_id : null,
                                            });
                                        }}
                                    >
                                        <MenuItem value={user_id}>None</MenuItem>
                                        {shipping_partners.map((shipper) => (
                                            <MenuItem key={shipper.shipper_id} value={shipper.shipper_id}>
                                                {shipper.shipper.business_name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            </TabRow>

                            <TabSection>Rates</TabSection>

                            <TabRow>
                                <Grid item xs={showRevenueOption ? 3 : 5}>
                                    <OnwardPriceInput
                                        disabled
                                        fullWidth
                                        variant="outlined"
                                        label="Total Rate"
                                        value={order.order_revenue}
                                    />
                                </Grid>
                                <Grid item xs={showRevenueOption ? 3 : 5}>
                                    <OnwardPriceInput
                                        fullWidth
                                        variant="outlined"
                                        label="Base Rate Override"
                                        value={order.order_revenue_override}
                                        onChange={(_, rate) =>
                                            callbacks.modifyOrder({
                                                order_revenue_override: rate,
                                                order_revenue_adjustments: {
                                                    ...(order.order_revenue_adjustments || {}),
                                                    internal: order.order_revenue_adjustments?.internal || [],
                                                },
                                            })
                                        }
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <SecondaryButton
                                        fullWidth
                                        onClick={() =>
                                            callbacks.modifyOrder({
                                                order_revenue_override: null,
                                                order_revenue_adjustments: {
                                                    ...(order.order_revenue_adjustments || {}),
                                                    internal: null,
                                                },
                                            })
                                        }
                                    >
                                        Reset
                                    </SecondaryButton>
                                </Grid>
                                {showRevenueOption && (
                                    <Grid item xs={4}>
                                        <TextField
                                            variant="outlined"
                                            select
                                            label="Order revenue applied to"
                                            name="warehouse_id"
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            value={order.show_revenue_on}
                                            onChange={(e) => callbacks.modifyOrder({ show_revenue_on: e.target.value })}
                                        >
                                            <MenuItem key={'DROPOFF'} value={'DROPOFF'}>
                                                Delivery
                                            </MenuItem>
                                            <MenuItem key={'PICKUP'} value={'PICKUP'}>
                                                Pickup
                                            </MenuItem>
                                        </TextField>
                                    </Grid>
                                )}
                            </TabRow>

                            <OrderDetailsPricing perspective="internal" />
                        </>
                    ) : null}

                    {options}

                    <TabSection>Documents</TabSection>
                    <TabRow>
                        <Grid item xs={4}>
                            <UploadFile order={order} type={'waybill'} />
                        </Grid>
                        <Grid item xs={4}>
                            <UploadFile order={order} type={'pickup_notification'} />
                        </Grid>
                        <Grid item xs={4}>
                            <UploadFile order={order} type={'bol'} />
                        </Grid>
                    </TabRow>

                    <AdditionalDocuments callbacks={callbacks} />
                </>
            )}
        </>
    );
});

export default OrderDetailsTab;
