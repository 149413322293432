import React from 'react';
import { css } from '@emotion/react';
import { Grid, IconButton, TextField, InputAdornment, MenuItem } from '@material-ui/core';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DeleteForeverOutlined from '@material-ui/icons/DeleteForeverOutlined';
import { PrimaryButton } from '@/styles/blocks';

import { Card, H1, Body1 } from './blocks';

import FuelModal from './FuelModal';

const dollarFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const FuelRates = ({ className, rates, errors, callbacks, qbo_tags = [] }) => {
    const [fuelModalOpen, setFuelModalOpen] = React.useState(false);

    const handleOpenFuelModal = () => {
        setFuelModalOpen(true);
    };

    const handleCloseFuelModal = () => {
        setFuelModalOpen(false);
    };

    const handleFuelModalSubmit = ({ startRate, endRate, percentInterval, rateInterval, startingPercent }) => {
        // Calculate number of intervals needed
        const numIntervals = endRate === startRate ? 1 : Math.max(1, Math.ceil((endRate - startRate) / rateInterval));

        // Generate fuel rates
        const newRates = [];
        for (let i = 0; i < numIntervals; i++) {
            const min = Number((startRate + i * rateInterval).toFixed(2));
            const max = i === numIntervals - 1 ? 'infinity' : Number((startRate + (i + 1) * rateInterval).toFixed(2));
            newRates.push({
                min,
                max,
                rate: (startingPercent + i * percentInterval) / 100, // Start from startingPercent and add interval each time
                bucket: i,
            });
        }

        // Update rates through callback
        callbacks.setAllFuelRates(newRates);
    };
    return (
        <Card className={className}>
            <Grid
                container
                direction="row"
                css={css`
                    margin-bottom: 24px;
                    flex: 0;
                    flex-basis: 0;
                    align-items: center;
                `}
            >
                <H1>Fuel Surcharge</H1>
                <TextField
                    select
                    size="small"
                    label="QBO Product/Service"
                    value={rates?.['FUEL_PRICE_MODIFIER']?.[0]?.qbo_tag || ''}
                    onChange={(e) => {
                        const next = e.target.value;
                        callbacks.updateQBO({
                            qbo: next,
                            types: ['FUEL_PRICE_MODIFIER'],
                        });
                    }}
                    css={css`
                        width: 300px;
                        margin-left: 1rem;
                    `}
                >
                    {(qbo_tags || []).map((qbo) => (
                        <MenuItem key={qbo} value={qbo}>
                            {qbo}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            {(rates?.['FUEL_PRICE_MODIFIER'] || []).map(({ min, max, rate }, idx) => {
                return (
                    <Grid
                        key={`rate-${idx}`}
                        container
                        direction="row"
                        css={css`
                            margin-bottom: 16px;
                            flex: 0;
                            flex-basis: 0;
                        `}
                    >
                        <Grid
                            container
                            item
                            direction="column"
                            css={css`
                                flex: 0;
                                justify-content: center;
                                margin-right: 12px;
                            `}
                        >
                            <Body1>{dollarFormatter.format(min)}</Body1>
                        </Grid>
                        <Grid
                            container
                            item
                            direction="column"
                            css={css`
                                flex: 0;
                                justify-content: center;
                                margin-right: 12px;
                            `}
                        >
                            <ArrowForwardIcon />
                        </Grid>
                        <Grid
                            container
                            item
                            direction="column"
                            xs={3}
                            css={css`
                                justify-content: center;
                                margin-right: 12px;
                            `}
                        >
                            {typeof max === 'string' && max.toLowerCase() === 'infinity' ? (
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    disabled
                                    value={max}
                                    error={!max || max <= min}
                                />
                            ) : (
                                <CurrencyTextField
                                    variant="outlined"
                                    size="small"
                                    value={max || 0}
                                    error={!max || max <= min}
                                    onChange={(_, val) => {
                                        callbacks.setFuelRate({
                                            bucket: idx,
                                            max: val,
                                        });
                                    }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">&</InputAdornment>,
                                    }}
                                />
                            )}
                        </Grid>
                        <Grid
                            container
                            item
                            direction="column"
                            css={css`
                                flex: 0;
                                justify-content: center;
                                margin-right: 12px;
                            `}
                        >
                            =
                        </Grid>
                        <Grid
                            container
                            item
                            direction="column"
                            xs={3}
                            css={css`
                                justify-content: center;
                                margin-right: 12px;
                            `}
                        >
                            <CurrencyTextField
                                variant="outlined"
                                size="small"
                                value={rate >= 0 ? rate * 100 : 0}
                                error={!(rate >= 0)}
                                onChange={(_, val) => {
                                    callbacks.setFuelRate({
                                        bucket: idx,
                                        rate: val / 100,
                                    });
                                }}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                }}
                            />
                        </Grid>
                        {rates?.['FUEL_PRICE_MODIFIER']?.length - 1 === idx ? (
                            <Grid
                                container
                                item
                                direction="column"
                                css={css`
                                    flex: 0;
                                    justify-content: center;
                                `}
                            >
                                <IconButton
                                    onClick={() => {
                                        callbacks.deleteFuelRate({ bucket: idx });
                                    }}
                                    css={css`
                                        padding: 4px;
                                    `}
                                >
                                    <DeleteForeverOutlined color="error" />
                                </IconButton>
                            </Grid>
                        ) : null}
                    </Grid>
                );
            })}
            <Grid
                container
                direction="row"
                css={css`
                    margin-bottom: 16px;
                    flex: 0;
                    flex-basis: 0;
                `}
            >
                <PrimaryButton
                    onClick={callbacks.addFuelRate}
                    css={css`
                        margin-right: 16px;
                    `}
                >
                    Add Row
                </PrimaryButton>
                <PrimaryButton onClick={handleOpenFuelModal}>Add Calculated FSC</PrimaryButton>
            </Grid>
            <FuelModal open={fuelModalOpen} onClose={handleCloseFuelModal} onSubmit={handleFuelModalSubmit} />
        </Card>
    );
};

export default FuelRates;
