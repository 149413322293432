import { format } from 'date-fns';
import { css } from '@emotion/react';
import { colors } from '@/styles';
import { sansSerif } from '@/styles/typography';
import { useMemo } from 'react';

export const useColumns = (compact = false) => {
    return useMemo(() => {
        return [
            {
                Header: 'Activity Log',
                id: 'data-columns',
                columns: [
                    {
                        Header: 'Date',
                        id: 'timestamp',
                        width: 150,
                        disableSortBy: true,
                        accessor: (log) => log.timestamp,
                        Cell: ({ value }) => {
                            return (
                                <span
                                    css={css`
                                        ${sansSerif}
                                    `}
                                >
                                    {value ? format(new Date(value), 'MMM d, yyyy h:mm a') : '-'}
                                </span>
                            );
                        },
                    },
                    {
                        Header: 'Description',
                        id: 'log_message',
                        disableSortBy: true,
                        width: compact ? 500 : 700,
                        accessor: (log) => [log.log_message, log.log_detail],
                        Cell: ({ value }) => {
                            return (
                                <span
                                    css={css`
                                        ${sansSerif}
                                    `}
                                >
                                    {value[0]}
                                    {value[1] ? (
                                        <span
                                            css={css`
                                                font-style: italic;
                                                color: ${colors.greys.secondary};
                                            `}
                                        >
                                            {' '}
                                            ({value[1]})
                                        </span>
                                    ) : null}
                                </span>
                            );
                        },
                    },
                    {
                        Header: 'User',
                        id: 'user.username',
                        width: 150,
                        disableSortBy: true,
                        accessor: (log) => log.user?.username,
                        Cell: ({ value }) => {
                            return (
                                <span
                                    css={css`
                                        ${sansSerif}
                                    `}
                                >
                                    {value || ''}
                                </span>
                            );
                        },
                    },
                ],
            },
        ];
    }, [compact]);
};
