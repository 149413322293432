import React, { useState, useMemo } from 'react';
const { setDay, setWeek, startOfWeek, endOfWeek } = require('date-fns');
import { startCase } from 'lodash';
import { colors } from '@/styles';
import { Grid } from '@material-ui/core';
import { css } from '@emotion/react';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Popover from '@material-ui/core/Popover';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { CheckCircleOutline, HelpOutline, HighlightOffOutlined, SyncOutlined } from '@material-ui/icons';
import { ITEM_TYPES, FREIGHT_TYPES } from '@/components/ShipmentForm/constants/freightTypes';
import { asBrowserDate } from '@/utilities/convertToISO';
import { BodyText } from '@/components/CarrierAccountingOrders/blocks';
import { calcOrderSubtotal } from '@/utilities/calcOrderSubtotal';
import { calcOrderInvoiceTotals } from '@/utilities/calcOrderInvoiceTotals';

const dateNumeric = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
});

const dollarFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export const useColumns = ({ callbacks }) => {
    return useMemo(() => {
        return [
            {
                Header: 'Data',
                id: 'data-columns',
                columns: [
                    {
                        Header: 'Invoice',
                        id: 'invoice_number',
                        accessor: (invoice) => invoice.invoice_number || '--',
                    },
                    {
                        Header: 'Carrier',
                        id: 'business_name',
                        span: false,
                        accessor: (invoice) => invoice.client?.business_name,
                        Cell: ({ value }) => {
                            return (
                                <div
                                    css={css`
                                        overflow: hidden;
                                        height: 100%;
                                    `}
                                >
                                    <BodyText
                                        css={css`
                                            white-space: normal;
                                        `}
                                    >
                                        {value}
                                    </BodyText>
                                </div>
                            );
                        },
                    },
                    {
                        Header: 'Week',
                        id: 'week_number',
                        accessor: (invoice) => invoice.week_number,
                    },
                    {
                        Header: 'Pay Period',
                        id: 'period',
                        span: false,
                        accessor: (invoice) => {
                            let created = new Date(new Date().setHours(0, 0, 0, 0));
                            if (invoice.created_at) {
                                created = new Date(new Date(invoice.created_at).setHours(0, 0, 0, 0));
                            }
                            const week = setWeek(created, invoice.week_number, { weekStartsOn: 1 });
                            const sow = startOfWeek(week, { weekStartsOn: 1 });
                            const eow = endOfWeek(week, { weekStartsOn: 1 });

                            return `${dateNumeric.format(sow)} - ${dateNumeric.format(eow)}`;
                        },
                        Cell: ({ value }) => {
                            return (
                                <div
                                    css={css`
                                        overflow: hidden;
                                        height: 100%;
                                    `}
                                >
                                    <BodyText
                                        css={css`
                                            white-space: normal;
                                        `}
                                    >
                                        {value}
                                    </BodyText>
                                </div>
                            );
                        },
                    },
                    {
                        Header: 'Edits Due',
                        id: 'edits_due',
                        span: false,
                        accessor: (invoice) => {
                            let created = new Date(new Date().setHours(0, 0, 0, 0));
                            if (invoice.created_at) {
                                created = new Date(new Date(invoice.created_at).setHours(0, 0, 0, 0));
                            }
                            const due = setWeek(created, invoice.week_number + 1, { weekStartsOn: 1 });
                            const thurs = setDay(due, 4);

                            if (!invoice.is_pending) {
                                return `${dateNumeric.format(new Date(thurs))} (submitted)`;
                            }

                            return dateNumeric.format(thurs);
                        },
                        Cell: ({ value }) => {
                            return (
                                <div
                                    css={css`
                                        overflow: hidden;
                                        height: 100%;
                                    `}
                                >
                                    <BodyText
                                        css={css`
                                            white-space: normal;
                                        `}
                                    >
                                        {value}
                                    </BodyText>
                                </div>
                            );
                        },
                    },
                    {
                        Header: 'Due',
                        id: 'due_date',
                        width: 150,
                        disableSortBy: false,
                        Cell: ({ row }) => {
                            const invoice = row.original;
                            return invoice.due_date ? dateNumeric.format(asBrowserDate(invoice.due_date)) : '--';
                        },
                    },
                    {
                        Header: 'POD',
                        id: 'pod',
                        width: 110,
                        Cell: ({ row }) => {
                            const invoice = row.original;
                            const isMissing = !invoice.orders.every((order) => {
                                const hasPhoto = order.delivery_photo?.length >= 1 || order.pod?.length >= 1;
                                const hasSignature = order.customer_signature?.length >= 1;
                                return hasSignature || hasPhoto;
                            });

                            return !isMissing ? (
                                <>
                                    <DoneIcon
                                        css={css`
                                            color: ${colors.greens.primary};
                                        `}
                                    />
                                </>
                            ) : (
                                <CloseIcon
                                    css={css`
                                        color: ${colors.reds.primary};
                                        background: none;
                                    `}
                                />
                            );
                        },
                    },
                    {
                        Header: 'Status',
                        id: 'status',
                        width: 150,
                        span: false,
                        disableSortBy: false,
                        Cell: ({ row }) => {
                            const invoice = row.original;
                            const label = startCase(invoice.status.toLowerCase());
                            let Icon = HelpOutline;
                            let color = 'black';
                            switch (invoice.status) {
                                case 'PAID':
                                    color = colors.greens.primary;
                                    Icon = CheckCircleOutline;
                                    break;
                                case 'APPROVED':
                                    color = colors.greens.primary;
                                    Icon = CheckCircleOutline;
                                    break;
                                case 'UNPAID':
                                case 'CANCELLED':
                                    color = colors.reds.cancelled;
                                    Icon = HighlightOffOutlined;
                                    break;
                                case 'PROCESSING':
                                    color = colors.oranges.primary;
                                    Icon = SyncOutlined;
                                    break;
                                default:
                                    color = 'black';
                                    Icon = HelpOutline;
                                    break;
                            }

                            return (
                                <Grid
                                    container
                                    direction="row"
                                    css={css`
                                        color: ${color};
                                        height: 100%;
                                        align-items: center;
                                        flex-wrap: nowrap;
                                    `}
                                >
                                    <Grid
                                        container
                                        direction="column"
                                        css={css`
                                            flex: 0;
                                            flex-basis: 0;
                                            margin-right: 4px;
                                        `}
                                    >
                                        <Icon />
                                    </Grid>
                                    <Grid
                                        container
                                        direction="column"
                                        css={css`
                                            flex: 0;
                                            flex-basis: 0;
                                        `}
                                    >
                                        <BodyText>{label}</BodyText>
                                    </Grid>
                                </Grid>
                            );
                        },
                    },
                    {
                        Header: 'Subtotal',
                        id: 'subtotal',
                        width: 125,
                        canSort: false,
                        Cell: ({ row }) => {
                            const invoice = row.original;
                            const amount = (invoice.orders || []).reduce((acc, order) => {
                                const subTotalAdj = calcOrderSubtotal(order);
                                return acc + subTotalAdj;
                            }, 0);
                            return dollarFormatter.format(amount);
                        },
                    },
                    {
                        Header: 'Accessorials',
                        id: 'accessorials',
                        width: 125,
                        canSort: false,
                        Cell: ({ row }) => {
                            const invoice = row.original;
                            const amount = (invoice.orders || []).reduce((acc, order) => {
                                const breakdown = 'carrierBreakdown';

                                const total = (order?.price_breakdown?.[breakdown]?.accessorials || []).reduce(
                                    (acc, { quantity, rate }) => {
                                        return acc + quantity * rate;
                                    },
                                    0
                                );

                                return acc + total;
                            }, 0);

                            return dollarFormatter.format(amount);
                        },
                    },
                    {
                        Header: 'Total Due',
                        id: 'revenue',
                        width: 125,
                        canSort: false,
                        Cell: ({ row }) => {
                            const invoice = row.original;
                            const amount = (invoice.orders || []).reduce((acc, order) => {
                                const total = calcOrderInvoiceTotals(order);
                                return acc + total;
                            }, 0);
                            return dollarFormatter.format(amount);
                        },
                    },
                ],
            },
            {
                sticky: 'right',
                Header: 'Sticky',
                id: 'sticky-group',
                columns: [
                    {
                        Header: '',
                        id: 'action-1',
                        width: 45,
                        Cell: ({ row }) => {
                            const invoice = row.original;
                            const [anchorEl, setAnchorEl] = React.useState(null);

                            const handleClick = (event) => {
                                event.stopPropagation();
                                event.preventDefault();
                                setAnchorEl(event.currentTarget);
                            };

                            const handleClose = (event) => {
                                event?.stopPropagation();
                                event?.preventDefault();
                                setAnchorEl(null);
                            };

                            return (
                                <div
                                    css={css`
                                        color: #59b863;
                                        font-weight: 600;
                                        display: flex-start;
                                        justify-content: flex-start;
                                        align-items: center;
                                    `}
                                >
                                    <IconButton
                                        disabled={invoice.status === 'PAID'}
                                        onClick={handleClick}
                                        css={css`
                                            font-weight: 600;
                                            padding: 4px;
                                            justify-content: center;
                                        `}
                                    >
                                        <MoreVertIcon />
                                    </IconButton>
                                    <Popover
                                        open={Boolean(anchorEl)}
                                        anchorEl={anchorEl}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                        style={{ padding: '10px', borderRadius: '20px' }}
                                    >
                                        <Button
                                            style={{ padding: '10px' }}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                callbacks.payId(invoice.carrier_invoice_id);
                                                handleClose();
                                            }}
                                        >
                                            Mark as Paid
                                        </Button>
                                    </Popover>
                                </div>
                            );
                        },
                        span: false,
                    },
                ],
            },
        ];
    }, [callbacks]);
};

export const QUICKBOOKS_CSV_COLUMNS = [
    {
        header: 'Invoice No.',
        getValue: ({ invoice, order }) =>
            invoice.invoice_number ? invoice.invoice_number + '-' + order.order_number : '',
    },
    {
        header: 'Shipper',
        getValue: ({ order }) => order?.shipper_id,
    },
    {
        header: 'Carrier',
        getValue: ({ order }) => order?.carrier_id,
    },
    {
        // No place for them to enter this in our app
        header: 'Terms',
        getValue: () => '',
    },
    {
        header: 'Invoice Date',
        getValue: ({ invoice }) => {
            if (!invoice.created_at) {
                return undefined;
            }
            const date = new Date(invoice?.created_at);
            return isNaN(date) ? undefined : date;
        },
        format: (value) => `"${dateNumeric.format(value)}"`,
    },
    {
        header: 'Due Date',
        getValue: ({ invoice }) => {
            if (!invoice.due_date) {
                return undefined;
            }
            const date = new Date(invoice?.due_date);
            return isNaN(date) ? undefined : date;
        },
        format: (value) => `"${dateNumeric.format(value)}"`,
    },
    {
        // No place for them to enter this in our app
        header: 'Location',
        getValue: () => '',
    },
    {
        // No place for them to enter this in our app
        header: 'Location Of Sale',
        getValue: () => '',
    },
    {
        // No place for them to enter this in our app
        header: 'Shipping To',
        getValue: () => '',
    },
    {
        // No place for them to enter this in our app
        header: 'Shipping Via',
        getValue: () => '',
    },
    {
        // No place for them to enter this in our app
        header: 'Shipping Date',
        getValue: () => '',
    },
    {
        // No place for them to enter this in our app
        header: 'Tracking No.',
        getValue: () => '',
    },
    {
        // No place for them to enter this in our app
        header: 'Purchase Order',
        getValue: () => '',
    },
    {
        // No place for them to enter this in our app
        header: 'Account Number',
        getValue: () => '',
    },
    {
        // No place for them to enter this in our app
        header: 'Cubic Feet',
        getValue: () => '',
    },
    // No place for them to enter this in our app
    {
        header: 'Product/Service',
        getValue: ({ row }) => {
            return row?.qbo_tag || '';
        },
    },
    {
        // No place for them to enter this in our app
        header: 'Service Date',
        getValue: () => '',
    },
    {
        // No place for them to enter this in our app
        header: 'SKU',
        getValue: () => '',
    },
    {
        header: 'Description',
        getValue: ({ order, description }) => {
            let descriptors;
            if (order.itemsByOrderId) {
                descriptors = order.itemsByOrderId.map((item) => {
                    return `${
                        item.item_type
                            ? ITEM_TYPES[item.item_type]?.find((el) => el.value === item.item_type_details)?.label ||
                              FREIGHT_TYPES.find((el) => el.value === item.item_type)?.label ||
                              'Item'
                            : 'Item'
                    }: ${item.description ? `${item.description} ` : ''}${item.quantity} Count`;
                });
            } else {
                descriptors = [];
            }

            let orderNumber = order.oms ? order?.po_number : order.order_number;

            let text = orderNumber + ' : ' + descriptors.join(', ');
            text += description;
            return `"${text}"`;
        },
    },
    {
        header: 'Qty',
        getValue: ({ quantity }) => {
            return quantity;
        },
    },
    {
        // Rate = amount / qty
        header: 'Rate',
        getValue: ({ rate }) => {
            return dollarFormatter.format(rate || 0);
        },
    },
    {
        header: 'Amount',
        getValue: ({ total }) => {
            return dollarFormatter.format(total || 0);
        },
    },
    {
        // No place for them to enter this in our app
        header: 'Class',
        getValue: ({ qbo_class }) => {
            return qbo_class;
        },
    },
    {
        // No place for them to enter this in our app
        header: 'Tax',
        getValue: () => '',
    },
    {
        // No place for them to enter this in our app
        header: 'Memo',
        getValue: ({ order }) => {
            return order.oms ? order?.po_number : order.order_number;
        },
    },
    {
        // No place for them to enter this in our app
        header: 'Message on Invoice',
        getValue: () => '',
    },
    {
        // No place for them toggle this in our app.
        header: 'Send Later',
        getValue: () => '',
    },
    {
        // Leave this. We put subtotal under 'amount' column
        header: 'Subtotal',
        getValue: () => '',
    },
    {
        // No place for them to enter this in our app
        header: 'Taxable Subtotal',
        getValue: () => '',
    },
    {
        // No place for them to enter this in our app
        header: 'Sales Tax Amount',
        getValue: () => '',
    },
    {
        // No place for them to enter this in our app
        header: 'Shipping Amt',
        getValue: () => '',
    },
    {
        // No place for them to enter this in our app
        header: 'Tax on Shipping',
        getValue: () => '',
    },
    {
        // Leave this for quickbooks to auto-fill
        header: 'Total',
        getValue: () => '',
    },
    {
        // No place for them to enter this in our app
        header: 'Attachments',
        getValue: () => '',
    },
];
