import React, { useContext, useMemo } from 'react';
import { UserContext } from '@/components/App';
import {
    DEFAULT_DELIVERY_TIMEFRAME,
    DEFAULT_SERVICE_TIME,
    DEFAULT_SERVICE_TIME_C,
    DEFAULT_SERVICE_TIME_FDS,
    DEFAULT_SERVICE_TIME_INCREMENT,
    DEFAULT_SERVICE_TIME_ROC,
    DEFAULT_SERVICE_TIME_WG,
    DEFAULT_TIMEFRAME_BUFFER,
} from '@/constants/deliveryTimeframes';
import { teammateRoles } from '@/components/Account/Teammates';

export const useClientUser = () => {
    const { user, clientId, client, teammate } = useContext(UserContext);

    return useMemo(() => {
        return {
            isOnwardAdmin: !!user?.roles?.['ONWARD_ADMIN'] && !client?.client_id,
            isImposter: !!user?.roles?.['ONWARD_ADMIN'] && client?.client_id,
            roles: client?.user?.roles || user?.roles,
            circles: client?.user?.circles || user?.circles,
            ...(client?.user?.roles?.['SHIPPER']
                ? { accountType: 'shipper', userType: 'shipper' }
                : { accountType: 'carrier', userType: 'carrier' }),
            user_id: client?.client_id,
            client_id: client?.client_id,
            username: client?.username,
            email: client?.email,
            approved: !!client?.approved,
            test_acc: !!client?.test_acc,
            businessName: client?.business_name,
            businessPhone: client?.business_phone,
            businessState: client?.business_state || client?.billing_state,
            businessZip: client?.business_zip || client?.billing_zip,
            cc_valid: client?.cc_valid,
            payment_type: client?.payment_type,
            stripe_user_id: client?.stripe_user_id,
            stripe_payment_method: client?.stripe_payment_method,
            teammates: client?.user?.teammatesByLeaderId || [],
            drivers: (client?.user?.teammatesByLeaderId || []).filter((tm) => tm.roles?.driver),
            auto_assigned_carrier: client?.auto_assigned_carrier,
            trucks: client?.trucks || [],
            locations: (client?.locations || []).filter(l => !l.is_customer_location),
            customer_locations: (client?.locations || []).filter(l => l.is_customer_location),
            shipping_partners: client?.carrier_partnerships || [],
            partner_carriers: client?.shipper_partnerships || [],
            shipper_integrations: client?.shipper_integrations || [],
            dispatch_integration_type: client?.dispatch_integration_type,
            dispatch_integration_meta: client?.dispatch_integration_meta,
            service_levels: client?.service_levels || [],
            tags: client?.tags || [],
            customer_messages: client?.customer_messages || {},
            survey_questions: client?.survey_questions || [],
            settings: client?.settings || {},
            savedShapes: client?.map_shapes,
            default_end_location: client?.locations?.find((l) => l.default_end_location),
            commingled_internal_mandatory: client?.commingled_internal_mandatory,
            qbo_tags: client?.qbo_tags || [],
            documents: client?.documents || [],
            preferences_service_time: client?.preferences_service_time || DEFAULT_SERVICE_TIME,
            preferences_use_service_default_as_minimum: client?.preferences_use_service_default_as_minimum || false,
            preferences_service_time_incr: client?.preferences_service_time_incr || DEFAULT_SERVICE_TIME_INCREMENT,
            preferences_service_time_curbside: client?.preferences_service_time_curbside || DEFAULT_SERVICE_TIME_C,
            preferences_service_time_first_dry_space:
                client?.preferences_service_time_first_dry_space || DEFAULT_SERVICE_TIME_FDS,
            preferences_service_time_room_of_choice:
                client?.preferences_service_time_room_of_choice || DEFAULT_SERVICE_TIME_ROC,
            preferences_service_time_white_glove:
                client?.preferences_service_time_white_glove || DEFAULT_SERVICE_TIME_WG,
            preferences_delivery_timeframe: client?.preferences_delivery_timeframe || DEFAULT_DELIVERY_TIMEFRAME,
            preferences_timeframe_buffer: client?.preferences_timeframe_buffer || DEFAULT_TIMEFRAME_BUFFER,
            preferences_timeframe_buffer_apply: client?.preferences_timeframe_buffer_apply || false,
            preferences_timeframe_round: client?.preferences_timeframe_round || false,
            preferences_route_time_limit: client?.preferences_route_time_limit || 0,
            preferences_route_eod_limit: client?.preferences_route_eod_limit || 0,
            preferences_route_eod_limit_next_day: client?.preferences_route_eod_limit_next_day || false,
            preferences_start_estimate: client?.preferences_start_estimate || false,
            preferences_end_estimate: client?.preferences_end_estimate || false,
            preferences_warehouse_return_estimate: client?.preferences_warehouse_return_estimate || false,
            preferences_next_day_returns: client?.preferences_next_day_returns || false,
            preferences_bypass_optimize: client?.preferences_bypass_optimize || false,
            invoiceAccess: true,
            ...(teammate?.teammate_id
                ? {
                      userType: 'teammate',
                      teammateRoles: teammate.roles?.admin
                          ? Object.fromEntries(Object.keys(teammateRoles).map((role) => [role, true]))
                          : teammate.roles,
                      invoiceAccess: teammate?.roles?.admin || teammate?.roles?.invoicing,
                  }
                : {}),
            white_label_img: client?.white_label_img || null,
            white_label_color: client?.white_label_color || null,
        };
    }, [user, clientId, client, teammate]);
};
