import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Container, Row, Col, Card } from 'reactstrap';
import { Button } from 'react-bootstrap';
import * as Sentry from '@sentry/react';
import PhoneNumberInput from '../PhoneNumberInput';
import * as ROLES from '@/constants/roles';
import * as ROUTES from '@/constants/routes';
import { useLocation, useNavigate } from 'react-router';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { postUnauth } from '@/utilities/onwardClient';
import { CREATE_TEAMMATE, VERIFY_TOKEN } from '@/constants/apiRoutes';
import { captureException } from '@sentry/react';
import { Alert, AlertTitle } from '@material-ui/lab';

const defaultInputValues = {
    username: '',
    email: '',
    phone: '',
    password: '',
    confirmationPassword: '',
};

function TeammateSignUp(props) {
    const [errors, setErrors] = useState([]);
    const [inputValues, setInputValues] = useState(defaultInputValues);
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState(null);
    const [claims, setClaims] = useState(null);
    const [success, setSuccess] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const token = urlParams.get('invite_token');
        if (!token) {
            navigate('/signin');
        }
        (async () => {
            postUnauth(`${VERIFY_TOKEN}/customer`, { token })
                .then((result) => {
                    setToken(token);
                    setClaims(result?.data?.verified);
                })
                .catch((err) => {
                    setErrors(['Error - invalid token, please ask your account owner to send a new invite link']);
                    console.error(err);
                    captureException(err);
                });
        })();
    }, []);

    const onChange = (e) => {
        let { name, value } = e.target;

        if (name === 'email') {
            value = value.toLowerCase();
        }

        setInputValues({ ...inputValues, [name]: value });
    };

    const validate = () => {
        const { username, email, password, confirmationPassword, phone } = inputValues;

        const _errors = [];

        if (!username) {
            _errors.push('Name is required');
        }

        if (!email) {
            _errors.push('Email is required.');
        }

        if (password !== confirmationPassword) {
            _errors.push('Password and password confirmation do not match.');
        }

        if (claims?.teamLeaderType === 'shipper' && claims?.teammateRoles.driver) {
            if (phone.includes('_') || !phone) {
                _errors.push('Driver teammates require a phone number to sign up.');
            }
        }

        setErrors(_errors);

        return !_errors.length;
    };

    const createTeammate = async () => {
        const { username, email, phone, password } = inputValues;
        try {
            // const { user: firebaseUser } = await createUserWithEmailAndPassword(getAuth(), email, password);
            return postUnauth(
                CREATE_TEAMMATE,
                { username, email, phone, password },
                { headers: { Authorization: `Bearer ${token}` } }
            )
                .then(() => {
                    setSuccess(true);
                })
                .catch(({ data }) => {
                    setErrors([`Error creating teammate account`]);
                });
        } catch (err) {
            console.error(err);
            captureException(err);
        }
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const isValidForm = validate();

        if (!isValidForm) {
            setLoading(false);
            return;
        }

        try {
            await createTeammate();
        } catch (err) {
            console.log(err);
            setErrors([err.message]);
            Sentry.captureException(err);
        }

        setLoading(false);
    };

    return (
        <div>
            <Container className="mt-5">
                <Row>
                    <Col className="d-flex justify-content-center">
                        <Card className="shadow border-0 signupform mt-5">
                            {success ? (
                                <Alert severity="success">
                                    <AlertTitle>Success</AlertTitle>
                                    Account Created! - click <a href="/signin">here</a> to sign in
                                </Alert>
                            ) : (
                                <Form style={{ margin: '5rem' }}>
                                    <Container className="d-flex justify-content-center mb-3">
                                        <h4>Create your Onward Teammate Account</h4>
                                    </Container>

                                    <Form.Control
                                        name="username"
                                        type="text"
                                        placeholder="Full Name"
                                        onChange={onChange}
                                        value={inputValues.username}
                                        style={{ marginBottom: '10px' }}
                                    />
                                    <Form.Control
                                        name="email"
                                        type="input"
                                        placeholder="Email Address"
                                        onChange={onChange}
                                        value={inputValues.email}
                                        style={{ marginBottom: '10px' }}
                                    />

                                    <PhoneNumberInput
                                        value={inputValues.phone}
                                        name="phone"
                                        onChange={onChange}
                                        placeholder="Phone Number"
                                        className="form-control"
                                        style={{ marginBottom: '10px' }}
                                    />
                                    <Form.Control
                                        name="password"
                                        type="password"
                                        placeholder="Password"
                                        onChange={onChange}
                                        value={inputValues.password}
                                        style={{ marginBottom: '10px' }}
                                    />
                                    <Form.Control
                                        name="confirmationPassword"
                                        type="password"
                                        placeholder="Confirm Password"
                                        onChange={onChange}
                                        value={inputValues.confirmationPassword}
                                        style={{ marginBottom: '10px' }}
                                    />

                                    <Container
                                        style={{ marginBottom: '10px' }}
                                        className="d-flex justify-content-center"
                                    >
                                        <Button
                                            variant="primary"
                                            type="submit"
                                            onClick={(e) => onSubmit(e)}
                                            disabled={
                                                loading ||
                                                !inputValues.username ||
                                                !inputValues.email ||
                                                !inputValues.password ||
                                                !inputValues.confirmationPassword
                                            }
                                        >
                                            Sign Up
                                        </Button>
                                    </Container>

                                    {!!errors.length && errors.map((error) => <p style={{ color: 'red' }}>{error}</p>)}
                                </Form>
                            )}
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default TeammateSignUp;
