import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import {
    useClientUser,
    useItemQuantity,
    useTotalWeightSubscription,
    useTotalCubesSubscription,
    useOrderNotes,
} from '@/hooks';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { css } from '@emotion/react';
import { colors } from '@/styles';
import { startCase } from 'lodash';
import { hourMinuteFormatter } from '@/constants/formats';
import { recommendedEquipmentLabels } from '@/constants/recommendedEquipmentLabels';
import { OnwardCard } from '../../OrderDetailsPage/Card';
import { CardTitle, CardItem, CardSubtitle, CardItemBold } from '../../OrderDetailsPage/blocks';
import { Context } from '../store';
import { genAttributes, useWarehouseAggregate } from '@onward-delivery/core';
import { formatInTimeZone } from 'date-fns-tz';
import { asDateInTZ } from '@/utilities/convertToISO';
import { delWindowToFormattedDateRange, delWindowToFormattedDateSingle } from '@/utilities/delWindowToFormattedDate';
import Image from 'react-bootstrap/Image';

export default function ItemsAndDocs() {
    const { state, callbacks } = useContext(Context);
    const order = state?.updated;
    const { zip: pickup_zip } = genAttributes(order, true);
    const { zip: dropoff_zip } = genAttributes(order);

    const { user_id, accountType, locations } = useClientUser();

    const displayDeliveryTime = order?.carrier_id && user_id !== order?.carrier_id && !!order?.del_window_start;
    const displayNonSaasDeliveryTime =
        order?.carrier_id && user_id !== order?.carrier_id && !!order?.del_hour && !!order?.del_end;

    let timeRangeStart = '';
    let timeRangeEnd = '';
    if (displayDeliveryTime) {
        timeRangeStart = order.del_window_start;
        timeRangeEnd = order.del_window_end;
    } else if (displayNonSaasDeliveryTime) {
        const tz = zipcode_to_timezone.lookup(order.dropoff_zip) || 'America/New_York';

        timeRangeStart = new Date(new Date().toLocaleString('en-US', { timeZone: tz }));
        timeRangeEnd = new Date(new Date().toLocaleString('en-US', { timeZone: tz }));

        timeRangeStart.setHours(order.del_hour);
        timeRangeEnd.setHours(order.del_end);

        if (order.del_hour % 1 !== 0) {
            timeRangeStart.setMinutes(30);
        }
        if (order.del_end % 1 !== 0) {
            timeRangeEnd.setMinutes(30);
        }

        timeRangeStart = timeRangeStart.toISOString();
        timeRangeEnd = timeRangeEnd.toISOString();
    }

    const {
        ['2_man_team']: twoManTeam,
        ['3_man_team']: threeManTeam,
        lift_gate,
        ...otherEquipment
    } = useMemo(() => {
        return order.recommended_equipment
            ? Object.keys(order.recommended_equipment)
                  .filter((key) => order.recommended_equipment[key])
                  .reduce((acc, val) => ({ ...acc, [val]: recommendedEquipmentLabels[val] }), {})
            : {};
    }, [order]);

    const CardRow = ({ children, ...rest }) => {
        return (
            <Grid
                container
                item
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                style={{ marginTop: '0.25rem', marginBottom: '0.25rem' }}
                {...rest}
            >
                {children}
            </Grid>
        );
    };

    return (
        <>
            <OnwardCard>
                <CardRow>
                    <Grid
                        item
                        css={css`
                            margin-bottom: 1.75rem;
                        `}
                    >
                        <CardTitle>Items ({order?.itemsByOrderId?.length || '-'})</CardTitle>
                    </Grid>
                </CardRow>

                {(order?.itemsByOrderId || []).map((i) => (
                    <React.Fragment key={`item-${i.item_id}`}>
                        <CardRow>
                            <Grid item>
                                <CardItem>{i.description || 'Description Not Provided'}</CardItem>
                            </Grid>
                        </CardRow>
                        <CardRow>
                            <Grid item>
                                <CardItem>Quantity: {i.quantity}</CardItem>
                            </Grid>
                        </CardRow>
                        <CardRow>
                            <Grid item>
                                <CardItem>Total Cubes: {i?.total_cubes?.toFixed(1) || ''}</CardItem>
                            </Grid>
                        </CardRow>
                        <CardRow>
                            <Grid item>
                                <CardItem>
                                    Dimensions:{' '}
                                    {i.length ? `${i.length}L ${i.width}W ${i.height}H` : `Dimensions Not Provided`}
                                </CardItem>
                            </Grid>
                        </CardRow>
                        <CardRow>
                            <Grid item>
                                <CardItem>Weight per item: {i.weight ? `${i.weight}lbs` : ''}</CardItem>
                            </Grid>
                        </CardRow>
                    </React.Fragment>
                ))}

                <CardRow>
                    <Grid
                        item
                        css={css`
                            margin-top: 1.75rem;
                        `}
                    >
                        <CardTitle>Haulaway</CardTitle>
                    </Grid>
                </CardRow>
                {!!order?.haulaway_items?.length ? (
                    order.haulaway_items.map((i, idx) => (
                        <React.Fragment key={`haulaway-${idx}`}>
                            <CardRow>
                                <Grid item>
                                    <CardItem>{i.description || 'Description Not Provided'}</CardItem>
                                </Grid>
                            </CardRow>
                        </React.Fragment>
                    ))
                ) : (
                    <CardRow>
                        <Grid item>
                            <CardItem>None</CardItem>
                        </Grid>
                    </CardRow>
                )}

                <CardRow>
                    <Grid
                        item
                        css={css`
                            margin-top: 1.75rem;
                        `}
                    >
                        <CardTitle>Warehouse</CardTitle>
                    </Grid>
                </CardRow>
                {!!order?.warehouse_file?.length ? (
                    order.warehouse_file.map((fileLink, idx) => (
                        <Grid item className="d-flex position-relative" key={`warehouse-${idx}`}>
                            <a href={fileLink} target="_blank">
                                <Image
                                    style={{
                                        height: '100px',
                                        width: '100px',
                                    }}
                                    src={fileLink}
                                />
                            </a>
                        </Grid>
                    ))
                ) : (
                    <CardRow>
                        <Grid item>
                            <CardItem>No uploads</CardItem>
                        </Grid>
                    </CardRow>
                )}

                <CardRow>
                    <Grid
                        item
                        css={css`
                            margin-top: 1.75rem;
                        `}
                    >
                        <CardTitle>BOL</CardTitle>
                    </Grid>
                </CardRow>
                {!!order?.bol_file?.length ? (
                    order.bol_file.map((fileLink, idx) => (
                        <Grid item className="d-flex position-relative" key={`bol-${idx}`}>
                            <a href={fileLink} target="_blank">
                                <Image
                                    style={{
                                        height: '100px',
                                        width: '100px',
                                    }}
                                    src={fileLink}
                                />
                            </a>
                        </Grid>
                    ))
                ) : (
                    <CardRow>
                        <Grid item>
                            <CardItem>No uploads</CardItem>
                        </Grid>
                    </CardRow>
                )}

                <CardRow>
                    <Grid
                        item
                        css={css`
                            margin-top: 1.75rem;
                        `}
                    >
                        <CardTitle>Waybill</CardTitle>
                    </Grid>
                </CardRow>
                {!!order?.waybill?.length ? (
                    order.waybill.map((fileLink, idx) => (
                        <Grid item className="d-flex position-relative" key={`waybill-${idx}`}>
                            <a href={fileLink} target="_blank">
                                <Image
                                    style={{
                                        height: '100px',
                                        width: '100px',
                                    }}
                                    src={fileLink}
                                />
                            </a>
                        </Grid>
                    ))
                ) : (
                    <CardRow>
                        <Grid item>
                            <CardItem>No uploads</CardItem>
                        </Grid>
                    </CardRow>
                )}

                <CardRow>
                    <Grid
                        item
                        css={css`
                            margin-top: 1.75rem;
                        `}
                    >
                        <CardTitle>Pickup Notification</CardTitle>
                    </Grid>
                </CardRow>
                {!!order?.pickup_notification?.length ? (
                    order.pickup_notification.map((fileLink, idx) => (
                        <Grid item className="d-flex position-relative" key={`pickup_notification-${idx}`}>
                            <a href={fileLink} target="_blank">
                                <Image
                                    style={{
                                        height: '100px',
                                        width: '100px',
                                    }}
                                    src={fileLink}
                                />
                            </a>
                        </Grid>
                    ))
                ) : (
                    <CardRow>
                        <Grid item>
                            <CardItem>No uploads</CardItem>
                        </Grid>
                    </CardRow>
                )}

                <CardRow>
                    <Grid
                        item
                        css={css`
                            margin-top: 1.75rem;
                        `}
                    >
                        <CardTitle>POD</CardTitle>
                    </Grid>
                </CardRow>
                {!!order?.pod?.length ? (
                    <CardRow
                        css={css`
                            justify-content: flex-start;
                            gap: 5px;
                        `}
                    >
                        {order.pod.map((pod, idx) => (
                            <Grid item className="d-flex position-relative" key={`pod-${idx}`}>
                                <a href={pod.url} target="_blank">
                                    <Image
                                        style={{
                                            height: '100px',
                                            width: '100px',
                                        }}
                                        src={pod.url}
                                    />
                                </a>
                            </Grid>
                        ))}
                    </CardRow>
                ) : (
                    <CardRow>
                        <Grid item>
                            <CardItem>No uploads</CardItem>
                        </Grid>
                    </CardRow>
                )}

                <CardRow>
                    <Grid
                        item
                        css={css`
                            margin-top: 1.75rem;
                        `}
                    >
                        <CardTitle>Final Delivery Photos</CardTitle>
                    </Grid>
                </CardRow>
                {!!order?.delivery_photo?.length ? (
                    order.delivery_photo.map((deliveryPhoto, idx) => (
                        <Grid item className="d-flex position-relative" key={`del_photo-${idx}`}>
                            <a href={deliveryPhoto.url} target="_blank">
                                <Image
                                    style={{
                                        height: '100px',
                                        width: '100px',
                                    }}
                                    src={deliveryPhoto.url}
                                />
                            </a>
                        </Grid>
                    ))
                ) : (
                    <CardRow>
                        <Grid item>
                            <CardItem>No uploads</CardItem>
                        </Grid>
                    </CardRow>
                )}

                <CardRow>
                    <Grid
                        item
                        css={css`
                            margin-top: 1.75rem;
                        `}
                    >
                        <CardTitle>Customer Signature</CardTitle>
                    </Grid>
                </CardRow>
                {order?.customer_signature || order?.customer_signature_meta?.name ? (
                    <>
                        {order?.customer_signature && (
                            <CardRow>
                                <Grid item container>
                                    <a href={order.customer_signature} target="_blank">
                                        <Image src={order.customer_signature} />
                                    </a>
                                </Grid>
                            </CardRow>
                        )}
                        {order?.customer_signature_meta?.name && (
                            <>
                                <CardRow>
                                    <Grid item>Signature manually entered for:</Grid>
                                </CardRow>
                                <CardRow>
                                    <CardItemBold>{order?.customer_signature_meta?.name}</CardItemBold>
                                </CardRow>
                            </>
                        )}
                    </>
                ) : (
                    <CardRow>
                        <Grid item>
                            <CardItem>No signature</CardItem>
                        </Grid>
                    </CardRow>
                )}
            </OnwardCard>
        </>
    );
}
