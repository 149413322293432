import React, { useState, useMemo, useContext, useEffect } from 'react';
import { Grid, Button, FormControlLabel, MenuItem, Tooltip, Typography } from '@material-ui/core';
import { css } from '@emotion/react';
import { useQuery } from '@apollo/client';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { format, isToday, addHours } from 'date-fns';

import {
    SecondaryButton,
    PrimaryButton,
    ResponsiveSidebarDialog,
    SectionTitle,
    ModalContent,
    StickyModalActions,
    Row,
    GreyBody,
    OnwardSwitch,
} from '@/styles/blocks';
import handleGeocode from '@/utilities/handleGeocode';
import { asBrowserDate, asDateInTZ } from '@/utilities/convertToISO';
import geocodeByLatLng from '@/utilities/geocodeByLatLng';
import { toE164, toNational } from '@/utilities/formatPhoneNumber';
import { useClientUser } from '@/hooks/useClientUser';
import StartTimeSelect from '@/components/misc/StartTimeSelect';
import { UserContext } from '@/components/App';
import GoogleMap, { Marker } from '@/components/GoogleMap';
import { validatePhoneNumber } from '@/utilities/validatePhoneNumber';

import { Context } from '../store';
import { OnwardRadio, TextField, OnwardToggle, RadioLabelInfo, ToggleBlurb, Body2, TableBody, Body1 } from '../blocks';
import { MODALS, SHIPMENT_FORM_TYPES } from '../constants';
import { FREIGHT_TYPES } from '../constants/freightTypes';
import { QUERY_CLIENTS_BY_USER } from '../graphql/queries';
import {
    addressSplit,
    sanitizeEnum,
    VALID_LOCATION_TYPES,
    VALID_DROPOFF_TYPES as VALID_PICKUP_TYPES,
} from '../utilities/processOrders';

import {
    Name,
    Phone,
    Email,
    SecondaryContactBtn,
    StoreSelect,
    SingleDatePicker,
    Comments,
    NewAddressCheckbox,
    Address,
    AddressLine2,
    City,
    State,
    Zip,
    LocationType,
    PickupType,
    PICKUP_INFO_FIELDS,
    StoreDetails,
} from './InputFields';
import { Info } from '@material-ui/icons';
import { MM_INTEGRATION_TYPES } from '@/constants/integrationTypes';

const ContactFields = ({ pickupObj, isSecondary, hasError, updatePickup, setDirty, isDirty }) => {
    const name = isSecondary ? PICKUP_INFO_FIELDS.SECONDARY_NAME : PICKUP_INFO_FIELDS.NAME;
    const phone = isSecondary ? PICKUP_INFO_FIELDS.SECONDARY_PHONE : PICKUP_INFO_FIELDS.PHONE;
    const email = isSecondary ? PICKUP_INFO_FIELDS.SECONDARY_EMAIL : PICKUP_INFO_FIELDS.EMAIL;

    return (
        <Grid
            container
            justifyContent="space-between"
            css={css`
                margin-bottom: 20px;
            `}
        >
            <Grid item xs={12} sm={6} md={4}>
                <Name
                    value={pickupObj[name]}
                    onChange={(e) => updatePickup({ [name]: e.target.value })}
                    onBlur={() => {
                        setDirty(name);
                    }}
                    error={hasError[name] && isDirty[name]}
                    isSecondary={isSecondary}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <Phone
                    value={toNational(pickupObj[phone])}
                    onChange={(e) => updatePickup({ [phone]: toE164(e.target.value) })}
                    onBlur={() => {
                        setDirty(phone);
                    }}
                    error={hasError[phone] && isDirty[phone]}
                    isSecondary={isSecondary}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Email
                    value={pickupObj[email]}
                    onChange={(e) => updatePickup({ [email]: e.target.value })}
                    onBlur={() => {
                        setDirty(email);
                    }}
                    isSecondary={isSecondary}
                />
            </Grid>
        </Grid>
    );
};

const PickupInfoModal = ({ callbacks: { onClose } }) => {
    const { state, callbacks } = useContext(Context);
    const open = state.current === MODALS.PICKUP_INFO;
    const {
        user_id,
        warehouseAddress,
        warehouseLocationInfo,
        warehouseLocationType,
        accountType,
        circles,
        shipping_partners,
        shipper_integrations,
        settings,
    } = useClientUser();
    const { user } = useContext(UserContext);

    const [CLIENT_DEFAULTS, setDefaults] = useState({
        isCustom: false,
        is_same_day: false,
    });
    const [hasSecondaryContactOverride, setHasSecondaryContact] = useState(null);
    const [hasNewAddressOverride, setHasNewAddress] = useState(null);
    const [storeOverride, setStore] = useState(null);
    const [isDirty, setIsDirty] = useState({});
    const [viewMapOverride, setViewMap] = useState(false);

    const setDirty = (field) => setIsDirty((prev) => ({ ...prev, [field]: true }));

    const { loading: loadingClient, data } = useQuery(QUERY_CLIENTS_BY_USER, {
        variables: { client_id: user_id },
    });

    const reset = () => {
        setHasSecondaryContact(null);
        setHasNewAddress(null);
        setStore(null);
        setViewMap(false);
    };

    const updatePickup = (updates) => {
        callbacks.updatePickupInfo(updates);
    };

    const client = useMemo(() => {
        return data?.result?.length > 0 ? data.result[0] : null;
    }, [data]);

    useEffect(() => {
        const calcDefaults = async (address, additional) => {
            const geoResults = await handleGeocode({ address });

            return setDefaults((prev) => ({
                ...prev,
                [PICKUP_INFO_FIELDS.LAT]: geoResults.lat,
                [PICKUP_INFO_FIELDS.LONG]: geoResults.lng,
                pickupaddress: geoResults.address,
                pickupfulladdress: geoResults.address,
                [PICKUP_INFO_FIELDS.STREET]: geoResults.street,
                [PICKUP_INFO_FIELDS.STATE]: geoResults.state,
                [PICKUP_INFO_FIELDS.CITY]: geoResults.city,
                [PICKUP_INFO_FIELDS.ZIP]: geoResults.zip,
                ...additional,
            }));
        };

        // Find the preferred inbound location if it exists
        let locationIndex = -1;

        if (client?.locations?.length > 0) {
            if (client.locations.length === 1) {
                // If there's only one location, use it
                locationIndex = 0;
            } else {
                // Try to find a preferred inbound location
                const preferredIndex = client.locations.findIndex((location) => location.preferred_inbound);

                // If a preferred location was found, use it; otherwise default to the first location
                locationIndex = preferredIndex !== -1 ? preferredIndex : 0;
            }
        }

        if (warehouseAddress) {
            calcDefaults(warehouseAddress, {
                [PICKUP_INFO_FIELDS.LOCATION_TYPE]:
                    sanitizeEnum(warehouseLocationType, VALID_PICKUP_TYPES) || 'Business',
                [PICKUP_INFO_FIELDS.PICKUP_TYPE]:
                    sanitizeEnum(warehouseLocationInfo, VALID_LOCATION_TYPES) || 'rollUpDoor',
            });
        } else if (client?.locations?.length && locationIndex >= 0) {
            setStore(locationIndex);
            const defaultStore = client.locations[locationIndex];
            const defaultAddress = defaultStore.address
                ? defaultStore.address
                : `${defaultStore.business_address}, ${defaultStore.business_city}, ${defaultStore.business_state} ${defaultStore.business_zip}`;

            calcDefaults(defaultAddress, {
                [PICKUP_INFO_FIELDS.LOCATION_TYPE]:
                    sanitizeEnum(defaultStore.location_type, VALID_PICKUP_TYPES) || 'Business',
                [PICKUP_INFO_FIELDS.PICKUP_TYPE]:
                    sanitizeEnum(defaultStore.location_info, VALID_LOCATION_TYPES) || 'rollUpDoor',
                [PICKUP_INFO_FIELDS.ADDRESS_LINE_2]: defaultStore?.business_unit,
                [PICKUP_INFO_FIELDS.NAME]: defaultStore.contact_name || client?.business_name || '',
                [PICKUP_INFO_FIELDS.PHONE]: defaultStore.contact_phone
                    ? toE164(defaultStore.contact_phone)
                    : client?.business_phone
                    ? toE164(client.business_phone)
                    : '',
                [PICKUP_INFO_FIELDS.EMAIL]: defaultStore.contact_email || client?.email || '',
            });
        }

        if (client && locationIndex === -1) {
            setDefaults((prev) => ({
                ...prev,
                [PICKUP_INFO_FIELDS.NAME]: client?.business_name || '',
                [PICKUP_INFO_FIELDS.PHONE]: client?.business_phone ? toE164(client.business_phone) : '',
                [PICKUP_INFO_FIELDS.EMAIL]: client?.email || '',
                [PICKUP_INFO_FIELDS.SECONDARY_NAME]: client?.secondary_username || null,
                [PICKUP_INFO_FIELDS.SECONDARY_PHONE]: client?.secondary_phone ? toE164(client.secondary_phone) : null,
                [PICKUP_INFO_FIELDS.SECONDARY_EMAIL]: client?.secondary_email || null,
            }));
        }
    }, [user, client]);

    const [hasNewAddress, hasSecondaryContact, store, combined] = useMemo(() => {
        const hasDefault = CLIENT_DEFAULTS?.pickupaddress?.length > 0;
        const hasSecondary = CLIENT_DEFAULTS?.[PICKUP_INFO_FIELDS.SECONDARY_NAME]?.length > 0;

        let store = storeOverride;
        if (state.pickupInfo.pickupaddress && client?.locations?.length > 0) {
            const match = client.locations.findIndex(
                (location) =>
                    location.business_address === state.pickupInfo.pickupstreetaddress &&
                    location.business_zip === state.pickupInfo.pickupzip
            );
            store = match > -1 ? match : null;
        }

        let hasNewAddress = false;
        if (hasNewAddressOverride !== null) {
            hasNewAddress = hasNewAddressOverride;
        } else {
            hasNewAddress =
                (client?.locations?.length || 0) === 0 ||
                ((hasDefault || state.pickupInfo.pickupaddress) && store === null) ||
                state.pickupInfo.isCustom;
        }

        return [
            hasNewAddress,
            hasSecondaryContactOverride !== null ? hasSecondaryContactOverride : hasSecondary,
            store,
            {
                ...CLIENT_DEFAULTS,
                ...state.pickupInfo,
            },
        ];
    }, [state.pickupInfo, CLIENT_DEFAULTS, hasNewAddressOverride, hasSecondaryContactOverride]);

    useEffect(() => {
        const calc = (fields) => fields.filter((val) => val && val.length > 0).join(', ');

        if (combined && combined.isCustom) {
            const address = calc([
                combined[PICKUP_INFO_FIELDS.STREET],
                combined[PICKUP_INFO_FIELDS.CITY],
                combined[PICKUP_INFO_FIELDS.STATE],
                combined[PICKUP_INFO_FIELDS.ZIP],
            ]);
            const fullAddress = calc([
                combined[PICKUP_INFO_FIELDS.STREET],
                combined[PICKUP_INFO_FIELDS.ADDRESS_LINE_2],
                combined[PICKUP_INFO_FIELDS.CITY],
                combined[PICKUP_INFO_FIELDS.STATE],
                combined[PICKUP_INFO_FIELDS.ZIP],
            ]);

            updatePickup({
                pickupaddress: address,
                pickupfulladdress: fullAddress,
            });
        }
    }, [
        combined.isCustom,
        combined[PICKUP_INFO_FIELDS.STREET],
        combined[PICKUP_INFO_FIELDS.CITY],
        combined[PICKUP_INFO_FIELDS.STATE],
        combined[PICKUP_INFO_FIELDS.ZIP],
        combined[PICKUP_INFO_FIELDS.ADDRESS_LINE_2],
    ]);

    const requiredFields = useMemo(() => {
        if (state.groupedPickup) {
            return [];
        }

        const required = [PICKUP_INFO_FIELDS.NAME, PICKUP_INFO_FIELDS.PHONE];

        if (hasSecondaryContact) {
            required.push(PICKUP_INFO_FIELDS.SECONDARY_NAME, PICKUP_INFO_FIELDS.SECONDARY_PHONE);
        }

        if (hasNewAddress && !state?.pickupInfo?.is_middle_mile) {
            required.push(
                PICKUP_INFO_FIELDS.STREET,
                PICKUP_INFO_FIELDS.LOCATION_TYPE,
                PICKUP_INFO_FIELDS.PICKUP_TYPE,
                PICKUP_INFO_FIELDS.STATE,
                PICKUP_INFO_FIELDS.CITY
            );
        } else {
            required.push(PICKUP_INFO_FIELDS.STORE);
        }

        if (settings?.import?.requireShippingPartner) {
            required.push(PICKUP_INFO_FIELDS.SHIPPER_ID);
        }

        return required;
    }, [hasSecondaryContact, hasNewAddress, state?.pickupInfo, state?.groupedPickup, settings]);

    const viewMap = useMemo(() => {
        return combined.isCustom || viewMapOverride;
    }, [viewMapOverride, combined]);

    const orderTz = useMemo(() => {
        if (combined) {
            return combined[PICKUP_INFO_FIELDS.ZIP]
                ? zipcode_to_timezone.lookup(combined[PICKUP_INFO_FIELDS.ZIP])
                : Intl.DateTimeFormat().resolvedOptions().timeZone;
        }
    }, [combined]);

    const hasError = useMemo(() => {
        return Object.fromEntries(
            requiredFields
                .filter((field) => {
                    const isEmpty = !combined[field] || combined[field].length === 0;
                    switch (field) {
                        case PICKUP_INFO_FIELDS.FULL_ADDRESS:
                            return hasNewAddress && isEmpty && !state?.pickupInfo?.is_middle_mile;
                        case PICKUP_INFO_FIELDS.STORE:
                            return !hasNewAddress && store === null && !state?.pickupInfo?.is_middle_mile;
                        case PICKUP_INFO_FIELDS.PHONE:
                            return isEmpty || !validatePhoneNumber(combined[PICKUP_INFO_FIELDS.PHONE]);
                        default:
                            return isEmpty;
                    }
                })
                .map((field) => [field, true])
        );
    }, [combined, requiredFields, hasNewAddress, store]);

    const isValid = useMemo(() => {
        return !Object.values(hasError).some((isInvalid) => isInvalid);
    }, [hasError]);

    const latLng = useMemo(() => {
        if (combined) {
            return combined[PICKUP_INFO_FIELDS.LAT] && combined[PICKUP_INFO_FIELDS.LONG]
                ? { lat: combined[PICKUP_INFO_FIELDS.LAT], lng: combined[PICKUP_INFO_FIELDS.LONG] }
                : null;
        }

        return null;
    }, [combined]);

    const handleStoreSelect = async (e) => {
        setStore(e.target.value);

        let _store = client.locations[e.target.value];
        const [address1, address2] = addressSplit(_store.business_address);

        updatePickup({
            [PICKUP_INFO_FIELDS.IS_CUSTOM]: false,
            pickupfulladdress: _store.address,
            [PICKUP_INFO_FIELDS.STREET]: address1,
            [PICKUP_INFO_FIELDS.CITY]: _store.business_city,
            [PICKUP_INFO_FIELDS.STATE]: _store.business_state,
            [PICKUP_INFO_FIELDS.ZIP]: _store.business_zip,
            [PICKUP_INFO_FIELDS.COMMENTS]: _store.shipper_pu_comments || '',
            [PICKUP_INFO_FIELDS.FULL_ADDRESS]: _store.address,
            [PICKUP_INFO_FIELDS.LOCATION_TYPE]: sanitizeEnum(_store.location_type, VALID_PICKUP_TYPES) || 'Business',
            [PICKUP_INFO_FIELDS.PICKUP_TYPE]: sanitizeEnum(_store.location_info, VALID_LOCATION_TYPES) || 'rollUpDoor',
            [PICKUP_INFO_FIELDS.ADDRESS_LINE_2]: address2,
            [PICKUP_INFO_FIELDS.NAME]: _store?.contact_name || client?.business_name || '',
            [PICKUP_INFO_FIELDS.PHONE]: _store?.contact_phone
                ? toE164(_store.contact_phone)
                : client?.business_phone
                ? toE164(client.business_phone)
                : '',
            [PICKUP_INFO_FIELDS.EMAIL]: _store?.contact_email || client?.email || '',
        });
    };

    const displayStoreDetails = !hasNewAddress && store !== null;

    return (
        <ResponsiveSidebarDialog
            open={open}
            onClose={() => {
                reset();
                onClose();
            }}
        >
            <ModalContent width={620}>
                <Grid
                    css={css`
                        margin-bottom: 20px;
                    `}
                >
                    <SectionTitle>Freight Type</SectionTitle>
                </Grid>

                <Grid
                    container
                    direction="column"
                    css={css`
                        margin-bottom: 36px;
                    `}
                >
                    <TextField
                        select
                        fullWidth
                        variant="outlined"
                        label="Freight Type"
                        value={state.freightType}
                        onChange={(e) => callbacks.setFreightType(e.target.value)}
                    >
                        {FREIGHT_TYPES.map(
                            (freight) =>
                                !freight.hidden && (
                                    <MenuItem key={`freight-select-${freight.value}`} value={freight.value}>
                                        {freight.label}
                                        {freight.info && (
                                            <Typography
                                                variant="caption"
                                                css={css`
                                                    margin-left: 8px;
                                                    color: #666;
                                                `}
                                            >
                                                {freight.info}
                                            </Typography>
                                        )}
                                    </MenuItem>
                                )
                        )}
                    </TextField>
                </Grid>

                {!state.multiPickup && state.formType !== SHIPMENT_FORM_TYPES.PDF ? (
                    <>
                        <Grid
                            css={css`
                                margin-bottom: 24px;
                            `}
                        >
                            <SectionTitle>Pickup / Warehouse Information</SectionTitle>
                        </Grid>

                        {!state?.pickupInfo?.is_middle_mile && (
                            <>
                                <ContactFields
                                    pickupObj={combined}
                                    hasError={hasError}
                                    updatePickup={updatePickup}
                                    isDirty={isDirty}
                                    setDirty={setDirty}
                                />
                                {hasSecondaryContact ? (
                                    <ContactFields
                                        pickupObj={combined}
                                        hasError={hasError}
                                        isSecondary={true}
                                        updatePickup={updatePickup}
                                        isDirty={isDirty}
                                        setDirty={setDirty}
                                    />
                                ) : null}

                                <Grid
                                    css={css`
                                        margin-bottom: 24px;
                                    `}
                                >
                                    <SecondaryContactBtn
                                        hasSecondaryContact={hasSecondaryContact}
                                        onClick={() => {
                                            setHasSecondaryContact(!hasSecondaryContact);
                                            updatePickup({
                                                [PICKUP_INFO_FIELDS.SECONDARY_NAME]: null,
                                                [PICKUP_INFO_FIELDS.SECONDARY_PHONE]: null,
                                                [PICKUP_INFO_FIELDS.SECONDARY_EMAIL]: null,
                                            });
                                        }}
                                        state={combined}
                                        error={[
                                            PICKUP_INFO_FIELDS.STREET,
                                            PICKUP_INFO_FIELDS.STATE,
                                            PICKUP_INFO_FIELDS.CITY,
                                        ].some((i) => hasError[i] && isDirty[i])}
                                    />
                                </Grid>

                                <Grid
                                    css={css`
                                        margin-bottom: 20px;
                                        display: flex;
                                        align-items: center;
                                        gap: 16px;
                                    `}
                                    container
                                    direction="row"
                                >
                                    <Grid item xs={8}>
                                        <StoreSelect
                                            value={store}
                                            onChange={handleStoreSelect}
                                            onBlur={() => {
                                                setDirty(PICKUP_INFO_FIELDS.STORE);
                                            }}
                                            error={
                                                hasError[PICKUP_INFO_FIELDS.STORE] && isDirty[PICKUP_INFO_FIELDS.STORE]
                                            }
                                            storeList={client?.locations || []}
                                        />
                                    </Grid>
                                    {client?.locations?.length > 0 && (
                                        <Grid item xs={3}>
                                            <Button
                                                variant="outlined"
                                                fullWidth
                                                onClick={() => setHasNewAddress(!hasNewAddress)}
                                                css={css`
                                                    text-transform: none;
                                                    ${hasNewAddress &&
                                                    `background-color: #e3f2fd; border-color: #2196f3; color: #2196f3;`}
                                                `}
                                            >
                                                {hasNewAddress ? <>New Address ✓</> : 'New Address'}
                                            </Button>
                                        </Grid>
                                    )}
                                </Grid>
                                {hasNewAddress && (
                                    <Grid
                                        container
                                        direction="column"
                                        css={css`
                                            margin-bottom: 24px;
                                        `}
                                    >
                                        <Grid
                                            container
                                            spacing={2}
                                            css={css`
                                                margin-bottom: 20px;
                                            `}
                                        >
                                            <Grid item xs={9}>
                                                <Address
                                                    handleAddressUpdate={(value, split) => {
                                                        updatePickup({
                                                            pickupaddress: value,
                                                            pickupfulladdress: value,
                                                            [PICKUP_INFO_FIELDS.STREET]: split.street,
                                                            [PICKUP_INFO_FIELDS.CITY]: split.city,
                                                            [PICKUP_INFO_FIELDS.STATE]: split.state,
                                                            [PICKUP_INFO_FIELDS.ZIP]: split.zip,
                                                        });
                                                    }}
                                                    onBlur={() => {
                                                        setDirty(PICKUP_INFO_FIELDS.STREET);
                                                        setDirty(PICKUP_INFO_FIELDS.STATE);
                                                        setDirty(PICKUP_INFO_FIELDS.CITY);
                                                    }}
                                                    state={combined}
                                                    error={[
                                                        PICKUP_INFO_FIELDS.STREET,
                                                        PICKUP_INFO_FIELDS.STATE,
                                                        PICKUP_INFO_FIELDS.CITY,
                                                    ].some((i) => hasError[i] && isDirty[i])}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <AddressLine2
                                                    value={combined[PICKUP_INFO_FIELDS.ADDRESS_LINE_2]}
                                                    onChange={(e) =>
                                                        updatePickup({
                                                            [PICKUP_INFO_FIELDS.ADDRESS_LINE_2]: e.target.value,
                                                        })
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            container
                                            spacing={2}
                                            css={css`
                                                margin-bottom: 24px;
                                            `}
                                        >
                                            <Grid item xs={12} sm={6} md={4}>
                                                <City
                                                    error={
                                                        hasError[PICKUP_INFO_FIELDS.CITY] &&
                                                        combined[PICKUP_INFO_FIELDS.IS_CUSTOM] &&
                                                        isDirty[PICKUP_INFO_FIELDS.CITY]
                                                    }
                                                    value={combined[PICKUP_INFO_FIELDS.CITY]}
                                                    onBlur={() => {
                                                        setDirty(PICKUP_INFO_FIELDS.CITY);
                                                    }}
                                                    onChange={(e) =>
                                                        updatePickup({ [PICKUP_INFO_FIELDS.CITY]: e.target.value })
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <State
                                                    error={
                                                        hasError[PICKUP_INFO_FIELDS.STATE] &&
                                                        combined[PICKUP_INFO_FIELDS.IS_CUSTOM] &&
                                                        isDirty[PICKUP_INFO_FIELDS.STATE]
                                                    }
                                                    value={combined[PICKUP_INFO_FIELDS.STATE]}
                                                    onBlur={() => {
                                                        setDirty(PICKUP_INFO_FIELDS.STATE);
                                                    }}
                                                    onChange={(e) =>
                                                        updatePickup({ [PICKUP_INFO_FIELDS.STATE]: e.target.value })
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <Zip
                                                    error={
                                                        hasError[PICKUP_INFO_FIELDS.ZIP] &&
                                                        combined[PICKUP_INFO_FIELDS.IS_CUSTOM] &&
                                                        isDirty[PICKUP_INFO_FIELDS.ZIP]
                                                    }
                                                    value={combined[PICKUP_INFO_FIELDS.ZIP]}
                                                    onBlur={() => {
                                                        setDirty(PICKUP_INFO_FIELDS.ZIP);
                                                    }}
                                                    onChange={(e) =>
                                                        updatePickup({ [PICKUP_INFO_FIELDS.ZIP]: e.target.value })
                                                    }
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            container
                                            direction="column"
                                            css={css`
                                                margin-bottom: 24px;
                                            `}
                                        >
                                            {!combined.isCustom ? (
                                                <Grid item container>
                                                    <Button
                                                        css={css`
                                                            color: #fff;
                                                        `}
                                                        onClick={() => setViewMap((prevState) => !prevState)}
                                                        variant="contained"
                                                        color="primary"
                                                    >
                                                        {viewMap ? 'Hide Map' : 'Find Address on Map'}
                                                    </Button>
                                                </Grid>
                                            ) : null}

                                            {viewMap && (
                                                <Grid
                                                    item
                                                    css={css`
                                                        height: 350px;
                                                        margin-top: 24px;
                                                        border-radius: 6px;
                                                        border: ${hasError[PICKUP_INFO_FIELDS.LAT] ||
                                                        hasError[PICKUP_INFO_FIELDS.LONG]
                                                            ? `1px solid #f44336;`
                                                            : '1px solid transparent;'};
                                                    `}
                                                >
                                                    <GoogleMap
                                                        zoom={15}
                                                        onClick={(e) => {
                                                            const x = e.latLng.lat();
                                                            const y = e.latLng.lng();

                                                            updatePickup({
                                                                [PICKUP_INFO_FIELDS.LAT]: x,
                                                                [PICKUP_INFO_FIELDS.LONG]: y,
                                                            });
                                                        }}
                                                        draggableCursor="pointer"
                                                        gestureHandling="greedy"
                                                        center={latLng ? latLng : undefined}
                                                    >
                                                        {latLng && <Marker position={latLng} />}
                                                    </GoogleMap>
                                                </Grid>
                                            )}
                                        </Grid>

                                        <Grid item container xs={12} spacing={2}>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <LocationType
                                                    value={combined[PICKUP_INFO_FIELDS.LOCATION_TYPE]}
                                                    onChange={(e) =>
                                                        updatePickup({
                                                            [PICKUP_INFO_FIELDS.LOCATION_TYPE]: e.target.value,
                                                        })
                                                    }
                                                    onBlur={() => {
                                                        setDirty(PICKUP_INFO_FIELDS.LOCATION_TYPE);
                                                    }}
                                                    error={
                                                        hasError[PICKUP_INFO_FIELDS.LOCATION_TYPE] &&
                                                        isDirty[PICKUP_INFO_FIELDS.LOCATION_TYPE]
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <PickupType
                                                    disabled={
                                                        !combined[PICKUP_INFO_FIELDS.LOCATION_TYPE] ||
                                                        combined[PICKUP_INFO_FIELDS.LOCATION_TYPE].length === 0
                                                    }
                                                    value={combined[PICKUP_INFO_FIELDS.PICKUP_TYPE]}
                                                    locationType={combined[PICKUP_INFO_FIELDS.LOCATION_TYPE]}
                                                    onChange={(e) =>
                                                        updatePickup({
                                                            [PICKUP_INFO_FIELDS.PICKUP_TYPE]: e.target.value,
                                                        })
                                                    }
                                                    onBlur={() => {
                                                        setDirty(PICKUP_INFO_FIELDS.PICKUP_TYPE);
                                                    }}
                                                    error={
                                                        hasError[PICKUP_INFO_FIELDS.PICKUP_TYPE] &&
                                                        isDirty[PICKUP_INFO_FIELDS.PICKUP_TYPE]
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                                <Grid
                                    item
                                    xs={12}
                                    css={css`
                                        margin-bottom: 24px;
                                    `}
                                >
                                    <Comments
                                        value={combined[PICKUP_INFO_FIELDS.COMMENTS]}
                                        onChange={(e) =>
                                            updatePickup({ [PICKUP_INFO_FIELDS.COMMENTS]: e.target.value })
                                        }
                                    />
                                </Grid>
                                <Grid
                                    container
                                    alignItems="center"
                                    css={css`
                                        margin-bottom: 24px;
                                        display: flex;
                                        align-items: center;
                                    `}
                                >
                                    <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                                        <SectionTitle style={{ margin: 0 }}>Availability</SectionTitle>
                                        <Tooltip
                                            title="This is the earliest date that these orders can be scheduled for delivery. Will be defaulted to today if left blank."
                                            placement="top"
                                        >
                                            <Info style={{ marginLeft: 8, color: '#59B863', display: 'flex' }} />
                                        </Tooltip>
                                    </Grid>
                                </Grid>

                                <Grid
                                    container
                                    direction="row"
                                    css={css`
                                        margin-bottom: 24px;
                                        flex-wrap: nowrap;
                                    `}
                                >
                                    <Grid
                                        container
                                        direction="column"
                                        css={css`
                                            flex: 0.8;
                                            flex-basis: 0;
                                            margin-right: 16px;
                                        `}
                                    >
                                        <SingleDatePicker
                                            label="Available Date"
                                            value={
                                                combined.firstAvailableDate
                                                    ? format(asBrowserDate(combined.firstAvailableDate), 'yyyy-MM-dd')
                                                    : null
                                            }
                                            onChange={(e) => {
                                                let dateStr = null;
                                                let hour = null;

                                                if (e.target.value) {
                                                    const utc = new Date(
                                                        new Date(e.target.value).setUTCHours(0, 0, 0, 0)
                                                    ).toISOString();
                                                    dateStr = asDateInTZ(utc, orderTz).toISOString();

                                                    hour = addHours(
                                                        new Date(new Date(dateStr).setUTCMinutes(0, 0, 0)),
                                                        8
                                                    ).toISOString();
                                                }

                                                updatePickup({
                                                    firstAvailableDate: dateStr,
                                                    delivery_date: dateStr,
                                                    pickup_window_end: hour ? hour : null,
                                                });
                                            }}
                                            disableDate={combined.is_same_day}
                                        />
                                    </Grid>
                                    <Grid
                                        container
                                        direction="column"
                                        css={css`
                                            flex: 0.6;
                                            flex-basis: 0;
                                            margin-right: 16px;
                                            min-width: 120px;
                                        `}
                                    >
                                        <TextField
                                            select
                                            type="text"
                                            variant="outlined"
                                            fullWidth
                                            label="Pickup"
                                            value={combined.is_same_day}
                                            onChange={(e) => {
                                                const utc = new Date(new Date().setUTCHours(0, 0, 0, 0)).toISOString();
                                                const hour = new Date(
                                                    addHours(new Date().setMinutes(0, 0, 0), 1)
                                                ).toISOString();
                                                const today = asDateInTZ(utc, orderTz).toISOString();

                                                updatePickup({
                                                    is_same_day: e.target.value,
                                                    ...(e.target.value
                                                        ? {
                                                              delivery_date: today,
                                                              pickup_date: today,
                                                              firstAvailableDate: today,
                                                              pickup_window_end: hour,
                                                          }
                                                        : {
                                                              delivery_date: null,
                                                              pickup_date: null,
                                                              firstAvailableDate: null,
                                                              pickup_window_end: null,
                                                          }),
                                                });
                                            }}
                                        >
                                            <MenuItem key="yes" value={true}>
                                                Now
                                            </MenuItem>
                                            <MenuItem key="no" value={false}>
                                                Later
                                            </MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Grid
                                        container
                                        direction="column"
                                        css={css`
                                            flex: 1;
                                            flex-basis: 0;
                                        `}
                                    >
                                        <StartTimeSelect
                                            onChange={(e) => {
                                                updatePickup({
                                                    pickup_window_end: new Date(e.target.value).toISOString(),
                                                });
                                            }}
                                            value={
                                                combined.pickup_window_end
                                                    ? new Date(combined.pickup_window_end).getTime()
                                                    : 0
                                            }
                                            timeZone={orderTz}
                                            deliveryDate={
                                                combined.firstAvailableDate
                                                    ? combined.firstAvailableDate
                                                    : new Date().toISOString()
                                            }
                                            disabled={!combined.firstAvailableDate || combined.is_same_day}
                                            interval={30}
                                            label="Pickup Time"
                                            filter={({ local }) => {
                                                const hours = local.getHours();
                                                const minutes = local.getMinutes();
                                                return hours >= 6 && (hours < 20 || (hours === 20 && minutes === 0));
                                            }}
                                            InputLabelProps={{
                                                shrink: !!combined.pickup_window_end,
                                            }}
                                            style={{ width: '100%' }}
                                            css={css`
                                                display: flex;
                                                justify-content: flex-start;
                                                color: #212529;

                                                .MuiFormControl-root {
                                                    width: 100%;
                                                }

                                                .MuiSelect-root {
                                                    padding: 18.5px 14px;
                                                    padding-right: 32px;
                                                    font-size: 16px;
                                                }

                                                .MuiInputBase-root fieldset {
                                                    top: -5px;
                                                }
                                            `}
                                        />
                                    </Grid>
                                </Grid>

                                {/* {displayStoreDetails ? (
                                    <Grid
                                        item
                                        xs={12}
                                        css={css`
                                            margin-bottom: 24px;
                                        `}
                                    >
                                        <StoreDetails
                                            storeName={client?.locations?.[store].location_name}
                                            fullAddress={combined[PICKUP_INFO_FIELDS.FULL_ADDRESS]}
                                            locationType={combined[PICKUP_INFO_FIELDS.LOCATION_TYPE]}
                                            pickupType={combined[PICKUP_INFO_FIELDS.PICKUP_TYPE]}
                                        />
                                    </Grid>
                                ) : null} */}
                                <Grid
                                    css={css`
                                        margin-bottom: 24px;
                                    `}
                                >
                                    <SectionTitle>Additional Details</SectionTitle>
                                </Grid>

                                {shipping_partners.length > 0 ? (
                                    <Grid item className="mt-3" container alignItems="center" xs={6}>
                                        <Grid item xs={11}>
                                            <TextField
                                                select
                                                variant="outlined"
                                                fullWidth
                                                label="Shipper"
                                                value={state.pickupInfo.shipper_id || ''}
                                                onChange={(e) => {
                                                    const shipper_id = e.target.value;
                                                    updatePickup({
                                                        shipper_id,
                                                        carrier_id: user_id !== shipper_id ? user_id : null,
                                                    });
                                                }}
                                                onBlur={() => setDirty(PICKUP_INFO_FIELDS.SHIPPER_ID)}
                                                error={hasError[PICKUP_INFO_FIELDS.SHIPPER_ID]}
                                            >
                                                {shipping_partners.map((shipper) => (
                                                    <MenuItem key={shipper.shipper_id} value={shipper.shipper_id}>
                                                        {shipper.shipper.business_name}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={1} container justifyContent="center">
                                            <Tooltip
                                                style={{ color: '#59B863' }}
                                                title={
                                                    'Here you can choose a partnered shipper for all orders in this current import. Changing this value will overwrite any already existing partner shipper selections you have made on individual orders.'
                                                }
                                                placement="top"
                                            >
                                                <Info />
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                ) : null}
                            </>
                        )}
                        {shipper_integrations?.some((integration) =>
                            Object.keys(MM_INTEGRATION_TYPES).includes(integration.type)
                        ) ? (
                            <Grid
                                container
                                css={css`
                                    margin-bottom: 20px;
                                    margin-top: 20px;
                                `}
                            >
                                <FormControlLabel
                                    css={css`
                                        margin: 0;
                                        align-items: flex-start;
                                    `}
                                    control={
                                        <OnwardToggle
                                            value={!!state?.pickupInfo?.is_middle_mile_origin}
                                            onChange={(e) =>
                                                updatePickup({
                                                    is_middle_mile_origin: !state?.pickupInfo?.is_middle_mile_origin,
                                                })
                                            }
                                        />
                                    }
                                    label={
                                        <Grid
                                            item
                                            css={css`
                                                margin-left: 20px;
                                            `}
                                        >
                                            <Body2
                                                css={css`
                                                    color: black;
                                                `}
                                            >
                                                Pickup is origin
                                            </Body2>
                                            <br />
                                            <Body2>
                                                A middle mile carrier will pickup from the origin address selected below
                                                and deliver to the final mile carrier location
                                            </Body2>
                                        </Grid>
                                    }
                                />
                            </Grid>
                        ) : circles?.['ltl-marketplace'] ? (
                            <Grid
                                container
                                css={css`
                                    margin-bottom: 20px;
                                    margin-top: 20px;
                                `}
                            >
                                <FormControlLabel
                                    css={css`
                                        margin: 0;
                                        align-items: flex-start;
                                    `}
                                    control={
                                        <OnwardToggle
                                            value={!!state?.pickupInfo?.is_middle_mile}
                                            onChange={(e) => {
                                                updatePickup({
                                                    is_middle_mile: !state?.pickupInfo?.is_middle_mile,
                                                });
                                                if (!state?.pickupInfo?.is_middle_mile) {
                                                    setHasSecondaryContact(false);
                                                }
                                            }}
                                        />
                                    }
                                    label={
                                        <Grid
                                            item
                                            css={css`
                                                margin-left: 20px;
                                            `}
                                        >
                                            <Body2
                                                css={css`
                                                    color: black;
                                                `}
                                            >
                                                Carrier's warehouse to receive claimed inbound and return orders
                                            </Body2>
                                            <br />
                                            <Body2>
                                                Inbound: Order will be directed by your team to deliver to the carrier's
                                                facility, at which point they can deliver to consignee.
                                            </Body2>
                                            <br />
                                            <Body2>
                                                Return: Order will be brought back to carrier's warehouse before taken
                                                back to shipper warehouse.
                                            </Body2>
                                        </Grid>
                                    }
                                />
                            </Grid>
                        ) : null}

                        <Grid
                            container
                            css={css`
                                margin-bottom: 20px;
                                margin-top: 20px;
                            `}
                        >
                            <FormControlLabel
                                css={css`
                                    margin: 0;
                                    align-items: flex-start;
                                `}
                                control={
                                    <OnwardToggle
                                        value={['WILL_CALL', 'PICKUP_AND_WILL_CALL'].includes(
                                            state?.pickupInfo?.job_type
                                        )}
                                        onChange={(e, checked) => {
                                            updatePickup({
                                                job_type: checked ? 'WILL_CALL' : 'SHIPMENT',
                                            });
                                        }}
                                    />
                                }
                                label={
                                    <Grid
                                        item
                                        css={css`
                                            margin-left: 20px;
                                        `}
                                    >
                                        <Body2
                                            css={css`
                                                color: black;
                                            `}
                                        >
                                            Will call order
                                        </Body2>
                                        <br />
                                        <Body2>
                                            Order will not have a delivery. The customer will pick up the order from the
                                            warehouse.
                                        </Body2>
                                    </Grid>
                                }
                            />
                        </Grid>

                        {['WILL_CALL', 'PICKUP_AND_WILL_CALL'].includes(state?.pickupInfo?.job_type) && (
                            <Grid
                                container
                                css={css`
                                    margin-bottom: 20px;
                                    margin-top: 20px;
                                `}
                            >
                                <FormControlLabel
                                    css={css`
                                        margin: 0;
                                        align-items: flex-start;
                                    `}
                                    control={
                                        <OnwardToggle
                                            value={state?.pickupInfo?.job_type === 'PICKUP_AND_WILL_CALL'}
                                            onChange={(e, checked) => {
                                                updatePickup({
                                                    job_type: checked ? 'PICKUP_AND_WILL_CALL' : 'WILL_CALL',
                                                });
                                            }}
                                        />
                                    }
                                    label={
                                        <Grid
                                            item
                                            css={css`
                                                margin-left: 20px;
                                            `}
                                        >
                                            <Body2
                                                css={css`
                                                    color: black;
                                                `}
                                            >
                                                Return order
                                            </Body2>
                                            <br />
                                            <Body2>
                                                Order will be picked up from the customer. The shipper or middle mile
                                                carrier will pick up the order from the warehouse.
                                            </Body2>
                                        </Grid>
                                    }
                                />
                            </Grid>
                        )}
                    </>
                ) : null}
            </ModalContent>
            <StickyModalActions border="true">
                <SecondaryButton
                    onClick={() => {
                        reset();
                        callbacks.revert();
                    }}
                >
                    {state.isFirstStep ? 'Cancel' : 'Back'}
                </SecondaryButton>
                <PrimaryButton
                    onClick={() => {
                        const updates = {};

                        if (!combined.firstAvailableDate) {
                            let today = new Date(new Date().setHours(0, 0, 0, 0));
                            updates.firstAvailableDate = today.toISOString();
                        }

                        const hasAddressLine2 = !!combined[PICKUP_INFO_FIELDS.ADDRESS_LINE_2];
                        updates[PICKUP_INFO_FIELDS.RESIDENCE_TYPE] = hasAddressLine2 ? 'Apartment' : '';

                        reset();
                        callbacks.updatePickupInfo({ ...combined, ...updates });
                        callbacks.advance();
                    }}
                    disabled={!isValid}
                >
                    Next
                </PrimaryButton>
            </StickyModalActions>
        </ResponsiveSidebarDialog>
    );
};

export default PickupInfoModal;
