import { gql } from '@apollo/client';
import { DRIVER_FIELDS } from '../fragments/drivers';
import { DRIVER_TRACKING_FIELDS } from '../fragments/driver_tracking';

export const GET_DRIVER_COORDINATES = gql`
    ${DRIVER_FIELDS}
    ${DRIVER_TRACKING_FIELDS}

    subscription GetDriverCoordinates($entity_id: uuid!) {
        results: drivers(where: { entity_id: { _eq: $entity_id } }, order_by: { created_at: desc }) {
            ...DriverFields

            coordinates(order_by: { time: desc }, limit: 2) {
                ...DriverTrackingFields
            }
        }
    }
`;
