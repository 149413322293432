import { Grid, Tooltip } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import { css } from '@emotion/react';
import { colors } from '@/styles';
import { PALLET_STATUSES } from '@/constants/manifestStatuses';
import { OnwardTooltip, PrimaryButton } from '@/styles/blocks';
import { Body1, Body2 } from '../blocks';
import HistoryIcon from '@mui/icons-material/History';
import { Info } from '@material-ui/icons';

const ConsolidateSection = ({ manifest, items, loading, callbacks }) => {
    const pallets = useMemo(() => {
        return Object.values(
            items.reduce((acc, item) => {
                if (item.pallet_id) {
                    return {
                        ...acc,
                        [item.pallet_id]: {
                            ...item.pallet,
                            items: [...(acc[item.pallet_id]?.items || []), item],
                        },
                    };
                }
                return acc;
            }, {})
        );
    }, [items]);

    const [isConsolidated, canConsolidate, canPreConsolidate] = useMemo(() => {
        return [
            pallets.every((pallet) => pallet.type === 'CONSOLIDATED'),
            manifest.type === 'INBOUND' &&
                pallets.length > 1 &&
                pallets.every((pallet) =>
                    [PALLET_STATUSES.RECEIVED, PALLET_STATUSES.STORED].includes(pallet.warehouse_status)
                ),
            manifest.type === 'INBOUND' && pallets.length === 0,
        ];
    }, [pallets]);

    if (!isConsolidated && !canConsolidate && !canPreConsolidate) {
        return null;
    }

    const canBeStaged = (pallet) => {
        return [PALLET_STATUSES.STORED, PALLET_STATUSES.PICKED].includes(pallet.warehouse_status);
    };

    return (
        <Grid
            container
            direction="column"
            css={css`
                padding: 1rem;
                flex-wrap: nowrap;
                border-top: 1px solid ${colors.greys.border};
            `}
        >
            {!isConsolidated ? (
                <Grid item>
                    <PrimaryButton onClick={() => callbacks.consolidatePalletsInit(pallets)}>Consolidate</PrimaryButton>
                </Grid>
            ) : canPreConsolidate ? (
                items.length > 1 ? (
                    <Grid item>
                        <PrimaryButton onClick={() => callbacks.preconsolidateItems(items, manifest)}>
                            Use Single Label
                        </PrimaryButton>
                        <Tooltip
                            style={{ color: '#59B863', marginLeft: '5px' }}
                            title={
                                'Click to consolidate items together onto a single order-level pallet. You can then use this single pallet label for scanning across all warehouse management actions.'
                            }
                            placement="top"
                        >
                            <Info />
                        </Tooltip>
                    </Grid>
                ) : null
            ) : (
                <Grid
                    container
                    direction="column"
                    css={css`
                        flex-wrap: nowrap;
                    `}
                >
                    <Body1
                        css={css`
                            text-decoration: underline;
                            color: ${colors.greens.primary};
                            ${canConsolidate ? 'cursor: pointer;' : ''}
                        `}
                        onClick={() => canConsolidate && callbacks.consolidatePalletsInit(pallets)}
                    >
                        Consolidated to {pallets.length} pallet{pallets.length > 1 && 's'}
                    </Body1>
                    {pallets.map((pallet) => (
                        <Grid
                            container
                            direction="column"
                            css={css`
                                flex-wrap: nowrap;
                            `}
                            key={pallet.pallet_id}
                        >
                            <Grid
                                container
                                css={css`
                                    margin-top: 0.5rem;
                                    cursor: pointer;
                                    align-items: center;
                                `}
                                onClick={() => callbacks.openPalletLog(pallet)}
                            >
                                <Body2
                                    css={css`
                                        margin-right: 1rem;
                                    `}
                                >
                                    Pallet {pallet.pallet_number}
                                </Body2>
                                {pallet.warehouse_location ? (
                                    <Body1
                                        css={css`
                                            margin-right: 0.25rem;
                                        `}
                                    >
                                        {pallet.warehouse_location}
                                    </Body1>
                                ) : null}
                                <HistoryIcon
                                    css={css`
                                        color: ${colors.greens.primary};
                                    `}
                                />
                            </Grid>
                            {manifest.type === 'INBOUND' &&
                            [PALLET_STATUSES.RECEIVED, PALLET_STATUSES.STORED].includes(pallet.warehouse_status) ? (
                                <Grid container>
                                    <PrimaryButton onClick={() => callbacks.storePalletsInit([pallet])}>
                                        {pallet.warehouse_location ? 'Relocate' : 'Store'}
                                    </PrimaryButton>
                                </Grid>
                            ) : null}
                            {manifest.type === 'OUTBOUND' ? (
                                <Grid container>
                                    <OnwardTooltip
                                        title={
                                            canBeStaged(pallet)
                                                ? ''
                                                : 'All items must be received and stored before being picked or staged'
                                        }
                                        placement="top"
                                    >
                                        <span>
                                            <PrimaryButton
                                                disabled={!canBeStaged(pallet)}
                                                onClick={() => callbacks.stagePalletsInit([pallet])}
                                            >
                                                Pick & Stage
                                            </PrimaryButton>
                                        </span>
                                    </OnwardTooltip>
                                </Grid>
                            ) : null}
                        </Grid>
                    ))}
                </Grid>
            )}
            {/* {!isConsolidated && canConsolidate ? <Grid item>

            </Grid> : <}
            <Grid item>
                {isConsolidated ? (
                    <Body1
                        css={css`
                            text-decoration: underline;
                            color: ${colors.greens.primary};
                            ${canConsolidate ? 'cursor: pointer;' : ''}
                        `}
                        onClick={() => canConsolidate && callbacks.consolidatePalletsInit(pallets)}
                    >
                        Consolidated to {pallets.length} pallet{pallets.length > 1 && 's'}
                    </Body1>
                ) : (
                    <Body1>Items can be consolidated into pallets</Body1>
                )}
            </Grid>
            {pallets
                .filter((pallet) => !!pallet.warehouse_location)
                .map((pallet) => (
                    <Grid container direction="column" css={css`flex-wrap: nowrap;`}>
                        <Grid
                            item
                            css={css`
                                cursor: pointer;
                                margin-top: 0.5rem;
                            `}
                            onClick={() => callbacks.openPalletLog(pallet)}
                        >
                            <Body2>
                                Pallet {pallet.pallet_number}: {pallet.warehouse_location}
                            </Body2>
                            <HistoryIcon
                                css={css`
                                    margin-left: 0.25rem;
                                    color: ${colors.greens.primary};
                                `}
                            />
                        </Grid>
                    </Grid>
                ))}
            {actions ? (
                <Grid
                    container
                    css={css`
                        align-items: center;
                        margin-top: 0.5rem;
                    `}
                ></Grid>
            ) : null}
            <Grid
                container
                css={css`
                    justify-content: flex-end;
                    align-items: flex-end;
                `}
            >
                {!isConsolidated && canConsolidate ? (
                    <PrimaryButton onClick={() => callbacks.consolidatePalletsInit(pallets)}>Consolidate</PrimaryButton>
                ) : null}
                {isConsolidated && canStore ? (
                    <PrimaryButton onClick={() => callbacks.storePalletsInit(pallets)}>
                        {stored ? 'Relocate' : 'Store'}
                    </PrimaryButton>
                ) : null}
            </Grid> */}
        </Grid>
    );
};

export default ConsolidateSection;
