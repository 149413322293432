import { CircularProgress, Grid } from '@material-ui/core';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { css } from '@emotion/react';
import { GridCard, PrimaryHeaderLabel } from '../CarrierAccountingOrders/blocks';
import React, { useMemo, useEffect } from 'react';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { FIXED_CHARGES } from '@/components/Accessorials/constants';
import { MKPL_CHARGES } from '@/components/Accessorials/constants';
import generateCSV from '@/utilities/createCSV';
import { useAccessorials } from '@/components/Account/Tariffs/utils';
import { CARRIER_INVOICE, MARK_AS_PAID } from './graphql';
import { captureException } from '@sentry/react';
import { PrimaryButton, SecondaryButton } from '@/styles/blocks';
import { startCase } from 'lodash';
import { colors } from '@/styles';
import { GET_PRICING_OVERRIDES } from '@/graphql/queries/pricing_overrides';
import InvoiceOrdersTable from '../CarrierAccountingInvoices/InvoiceOrdersTable';
import { format } from 'date-fns';
import { QUICKBOOKS_CSV_COLUMNS } from './csvColumns';
import { calcOrderSubtotal } from '@/utilities/calcOrderSubtotal';
import { useClientUser } from '@/hooks';
import StickyFooter from '@/components/StickyFooter';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { OnwardBreadcrumbActive, OnwardBreadcrumbInactive, OnwardBreadcrumbSpacer } from '../Breadcrumbs';
import { useExport } from '@/components/admin/AdminFinancials/payables/hooks';

const LineItem = ({ label, value, lg = false, color = 'black' }) => {
    return (
        <Grid
            item
            css={css`
                width: 50%;
                min-width: 400px;
                display: flex;
                justify-content: space-between;
                align-items: center;
            `}
        >
            <span
                css={css`
                    ${lg &&
                    `
                    font-size: 18px;
                    font-weight: 700;
                `}
                `}
            >
                {label}
            </span>
            <span
                css={css`
                    font-size: 16px;
                    font-weight: 700;
                    ${lg &&
                    `
                        font-size: 22px;
                    `}
                    color: ${color};
                `}
            >
                {value || value === 0 ? `$${value.toFixed(2)}` : '--'}
            </span>
        </Grid>
    );
};

const CarrierAccountingInvoice = () => {
    const { invoice_id } = useParams();
    const { tags } = useClientUser();
    const location = useLocation();
    const navigate = useNavigate();
    const allInvoices = location?.state?.allInvoices || [];
    const returnTo = location?.state?.returnTo;

    const invoiceIdx = useMemo(() => {
        return allInvoices.findIndex((i) => i.carrier_invoice_id === invoice_id);
    }, [allInvoices, invoice_id]);

    const goToNextInvoice = (direction) => {
        const newInvoice = allInvoices[invoiceIdx + direction];
        return navigate(`/carrier/accounting/invoices/${newInvoice.carrier_invoice_id}`, {
            state: {
                allInvoices: allInvoices || [],
                returnTo: returnTo,
            },
        });
    };

    const { data, loading } = useQuery(CARRIER_INVOICE, {
        variables: { invoice_id },
        skip: !invoice_id,
        onError: (err) => {
            console.error(err);
            captureException(err);
        },
    });

    const [fetchOverrides, { data: resp }] = useLazyQuery(GET_PRICING_OVERRIDES);

    const [markAsPaid, { loading: updateLoading }] = useMutation(MARK_AS_PAID, {
        variables: { invoice_id },
        onError: (err) => {
            console.error(err);
            captureException(err);
        },
    });

    const invoice = useMemo(() => {
        return data?.carrier_invoices_by_pk || {};
    }, [data]);

    useEffect(() => {
        if (invoice?.client_id) {
            fetchOverrides({
                variables: {
                    shipper_ids: [],
                    carrier_ids: [invoice.client_id],
                    client_ids: [invoice.client_id],
                    partner_client_ids: invoice.partner_client_id ? [invoice.partner_client_id] : [],
                },
            });
        }
    }, [invoice]);

    const [type, types] = useMemo(() => {
        if (!resp) {
            return ['DEFAULT', {}];
        }

        const { internal, carrier } = resp;
        const po = invoice.type === 'INTERNAL' ? internal : carrier;
        const type = po.length > 0 ? po[0]?.algo_type : 'DEFAULT';

        return [type, Object.fromEntries((invoice?.orders || []).map((order) => [order.order_id, type]))];
    }, [resp, invoice]);

    const statusContent = useMemo(() => {
        let fragment = `
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 1rem;
            width: 100px;
            height: 100%;
            font-size: 16px;
            font-weight: 700;
            border: 3px solid;
            border-radius: 3px;
        `;
        switch (invoice.status) {
            case 'PAID':
                fragment = `
                    ${fragment}
                    border-color: ${colors.greens.primary};
                    color: ${colors.greens.primary};
                `;
                break;
            case 'APPROVED':
            case 'UNPAID':
            case 'CANCELLED':
                fragment = `
                    ${fragment}
                    border-color: ${colors.reds.cancelled};
                    color: ${colors.reds.cancelled};
                `;
                break;
            case 'PROCESSING':
                fragment = `
                    ${fragment}
                    border-color: ${colors.oranges.primary};
                    color: ${colors.oranges.primary};
                `;
                break;
            default:
                break;
        }

        return (
            <div
                css={css`
                    ${fragment}
                `}
            >
                <span>{startCase(invoice.status?.toLowerCase() || '--')}</span>
            </div>
        );
    }, [invoice]);

    const [subtotal, adjustments] = useMemo(() => {
        return (invoice?.orders || []).reduce(
            ([subtotalAcc, adjustmentsAcc], order) => {
                const breakdown = order.oms ? 'internalBreakdown' : 'carrierBreakdown';
                const subtotal = FIXED_CHARGES.map(({ key: attr }) => {
                    return order?.price_breakdown?.[breakdown]?.[attr] || 0;
                }).reduce((acc, val) => {
                    return acc + val;
                }, 0);

                const accessorials = (order?.price_breakdown?.[breakdown]?.accessorials || []).reduce(
                    (acc, { quantity, rate }) => {
                        return acc + quantity * rate;
                    },
                    0
                );

                return [subtotalAcc + subtotal, adjustmentsAcc + accessorials];
            },
            [0, 0]
        );
    }, [invoice]);

    const accessorials = useAccessorials(type, invoice.type === 'INTERNAL' ? tags : []);

    const { exportSelected } = useExport({
        accessorials,
        invoices: [invoice], // Pass the full invoice object
        columns: QUICKBOOKS_CSV_COLUMNS,
        breakdown: 'carrierBreakdown',
    });
    const handleExport = () => {
        if (exportSelected) {
            exportSelected();
        }
    };

    const handleCsvExport = () => {
        const data = invoice.orders.reduce((acc, order) => {
            const breakdown = order.oms ? 'internalBreakdown' : 'carrierBreakdown';
            const accessorialsMap = Object.fromEntries(
                accessorials.map((accessorial) => [accessorial.type, accessorial])
            );

            const chargeType = !!order.oms ? FIXED_CHARGES : MKPL_CHARGES;

            return [
                ...acc,
                ...chargeType
                    .map(({ display, key }) => {
                        return {
                            invoice,
                            order,
                            type: key,
                            qbo_tag: order?.qbo_tag,
                            qbo_class: order?.invoice_class,
                            description: display,
                            total: !!order.oms ? order?.price_breakdown?.[type]?.[key] : calcOrderSubtotal(order),
                        };
                    })
                    .filter((charge) => charge.total > 0),
                ...(order?.price_breakdown?.[breakdown]?.accessorials || []).map(
                    ({ type, quantity, rate, qbo_tag }) => {
                        const meta = accessorialsMap[type] || accessorialsMap.default || 'DEFAULT';
                        return {
                            invoice,
                            order,
                            type,
                            accessorial: meta,
                            qbo_tag: qbo_tag || order?.qbo_tag,
                            qbo_class: order?.invoice_class,
                            description: meta.label,
                            quantity,
                            rate,
                            total: quantity * rate,
                        };
                    }
                ),
            ];
        }, []);

        const blob = generateCSV(QUICKBOOKS_CSV_COLUMNS, data);
        const filename = `Invoice ${invoice.invoice_number}`;
        const autoclick = document.createElement('a');
        const payload = URL.createObjectURL(blob);
        autoclick.setAttribute('href', payload);
        autoclick.setAttribute('download', filename);
        autoclick.style.visibility = 'hidden';
        document.body.appendChild(autoclick);
        autoclick.click();
        document.body.removeChild(autoclick);
    };

    return (
        <Grid container direction="column" wrap="nowrap" className="h-100">
            {loading ? (
                <Grid
                    item
                    css={css`
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    `}
                >
                    <CircularProgress color="primary" size={200} />
                </Grid>
            ) : (
                <>
                    <Grid
                        direction="row"
                        container
                        css={css`
                            align-items: center;
                            background: white;
                            padding: 12px 30px;
                            box-shadow: 0px 0px 0px 1px rgba(63, 63, 68, 0.05), 0px 1px 3px rgba(63, 63, 68, 0.15);
                            border-bottom: 1px solid #ececec;
                        `}
                    >
                        <Grid
                            direction="column"
                            container
                            css={css`
                                flex: 1;
                                flex-basis: 0;
                            `}
                        >
                            <Grid
                                direction="row"
                                container
                                css={css`
                                    align-items: center;
                                `}
                            >
                                <OnwardBreadcrumbInactive
                                    disabled={!returnTo}
                                    onClick={() => {
                                        return navigate(returnTo);
                                    }}
                                >
                                    Invoices
                                </OnwardBreadcrumbInactive>
                                <OnwardBreadcrumbSpacer />
                                <OnwardBreadcrumbActive disabled>{invoice?.invoice_number}</OnwardBreadcrumbActive>
                            </Grid>
                        </Grid>
                        <Grid
                            direction="column"
                            container
                            css={css`
                                flex: 0;
                                flex-basis: 0;
                            `}
                        >
                            <Grid
                                item
                                css={css`
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    height: 100%;
                                `}
                            >
                                <SecondaryButton
                                    css={css`
                                        margin-right: 0.5rem;
                                        margin-left: 0.5rem;
                                    `}
                                    onClick={handleExport}
                                >
                                    Export
                                </SecondaryButton>

                                <PrimaryButton
                                    disabled={updateLoading || invoice.status === 'PAID'}
                                    onClick={() => markAsPaid()}
                                    css={css`
                                        margin-right: 0.5rem;
                                        margin-left: 0.5rem;
                                    `}
                                >
                                    Mark as Paid
                                </PrimaryButton>
                                <div
                                    css={css`
                                        margin-right: 0.5rem;
                                    `}
                                >
                                    {statusContent}
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        css={css`
                            padding: 2rem 2rem 1rem 2rem;
                        `}
                    >
                        <Grid
                            item
                            css={css`
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                            `}
                        >
                            <PrimaryHeaderLabel>Invoice {invoice.invoice_number}</PrimaryHeaderLabel>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        css={css`
                            padding: 1rem 2rem;
                        `}
                    >
                        <GridCard
                            css={css`
                                display: flex;
                                flex-wrap: nowrap;
                                flex-direction: column;
                                align-items: flex-start;
                            `}
                        >
                            <span>
                                Issued:{' '}
                                {invoice.created_at ? format(new Date(invoice.created_at), 'MMM d, yyyy') : '--'}
                            </span>
                            <span>
                                Due: {invoice.due_date ? format(new Date(invoice.due_date), 'MMM d, yyyy') : '--'}
                            </span>
                            <span>Shipper: {invoice.partner_client?.business_name || ''}</span>
                            <span>Description: {invoice.description || ''}</span>
                        </GridCard>
                    </Grid>
                    <Grid
                        item
                        css={css`
                            padding: 1rem 2rem;
                            flex-grow: 1;
                        `}
                    >
                        <InvoiceOrdersTable
                            orders={invoice.orders || []}
                            types={types}
                            breakdown={invoice.type === 'INTERNAL' ? 'internalBreakdown' : 'carrierBreakdown'}
                            tags={invoice.type === 'INTERNAL' ? tags : []}
                        />
                    </Grid>
                    <Grid
                        item
                        css={css`
                            padding: 1rem 2rem;
                        `}
                    >
                        <GridCard
                            css={css`
                                display: flex;
                                flex-wrap: nowrap;
                                flex-direction: column;
                                align-items: flex-end;
                            `}
                        >
                            <LineItem label="Subtotal" value={subtotal} />
                            <LineItem label="Adjustments" value={adjustments} color={colors.greens.primary} />
                            <hr
                                css={css`
                                    width: 50%;
                                    min-width: 400px;
                                    border-width: 2px;
                                `}
                            />
                            <LineItem label="Total" value={subtotal + adjustments} lg />
                        </GridCard>
                    </Grid>
                </>
            )}
            {invoiceIdx >= 0 && (
                <StickyFooter
                    styles={{
                        margin: 0,
                        boxShadow: '0 -2px 5px rgba(0, 0, 0, 0.25)',
                    }}
                >
                    <Grid container direction="row" justifyContent="center">
                        <PrimaryButton disabled={invoiceIdx === 0} onClick={() => goToNextInvoice(-1)}>
                            <Grid item>
                                <span>
                                    <ChevronLeftIcon />
                                </span>
                                <span>Previous Invoice</span>
                            </Grid>
                        </PrimaryButton>

                        <PrimaryButton
                            disabled={invoiceIdx === allInvoices.length - 1}
                            onClick={() => goToNextInvoice(1)}
                            css={css`
                                margin-left: 25px;
                            `}
                        >
                            <Grid item>
                                <span>Next Invoice</span>
                                <span>
                                    <ChevronRightIcon />
                                </span>
                            </Grid>
                        </PrimaryButton>
                    </Grid>
                </StickyFooter>
            )}
        </Grid>
    );
};

export default CarrierAccountingInvoice;
