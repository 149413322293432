import withAdminRights from '@/components/Auth/withAdminRights';
import { Button, Card, FormControl, InputAdornment, TextField } from '@material-ui/core';
import { useState } from 'react';
import compose from 'recompose/compose';
import { css } from '@emotion/react';
import { PageTitle } from '@/styles/blocks';
import { colors } from '@/styles';
import { Alert, AlertTitle } from '@material-ui/lab';
import { post } from '@/utilities/onwardClient';
import { RESET_ACCOUNT } from '@/constants/apiRoutes';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const AdminAccountReset = () => {
    const [authId, setAuthId] = useState(null);
    const [clientId, setClientId] = useState(null);
    const [email, setEmail] = useState(null);
    const [passwordOne, setPasswordOne] = useState(null);
    const [passwordTwo, setPasswordTwo] = useState(null);
    const [passwordHidden, setPasswordHidden] = useState(true);

    const [error, setError] = useState(null);
    const [formErrors, setFormErrors] = useState([]);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    const submit = () => {
        let errors = [];
        if (!authId) {
            errors.push({
                formError: 'authId',
                errorMessage: 'Auth ID is required',
            });
        }
        if (!clientId) {
            errors.push({
                formError: 'clientId',
                errorMessage: 'Client ID is required',
            });
        }
        if (!email) {
            errors.push({
                formError: 'email',
                errorMessage: 'New email is required',
            });
        }
        if (!passwordOne || !passwordTwo) {
            errors.push({
                formError: 'password',
                errorMessage: 'Password is required',
            });
        }
        if (passwordOne !== passwordTwo) {
            errors.push({
                formError: 'password',
                errorMessage: 'Both passwords must match',
            });
        }

        if (errors.length > 0) {
            setFormErrors(errors.map((err) => err.formError));
            setError(errors.map((err) => err.errorMessage).join('\n'));
            return;
        }

        setFormErrors([]);
        setError(null);
        setLoading(true);

        post(RESET_ACCOUNT, {
            authId,
            clientId,
            email,
            password: passwordOne,
        })
            .then(() => {
                setSuccess(true);
            })
            .catch(({ response }) => {
                setError(response?.data?.error || 'Something went wrong. Please try again.');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Card
            css={css`
                height: 100%;
                padding: 2rem;
                display: flex;
                flex-direction: column;
            `}
        >
            <PageTitle>Reset Account Login Information</PageTitle>
            <FormControl
                css={css`
                    width: fit-content;
                    min-width: 400px;
                `}
            >
                {error && (
                    <Alert
                        severity="error"
                        css={css`
                            margin-bottom: 1rem;
                        `}
                    >
                        <AlertTitle>Error</AlertTitle>
                        {error}
                    </Alert>
                )}
                {success && (
                    <Alert
                        severity="success"
                        css={css`
                            margin-bottom: 1rem;
                        `}
                    >
                        <AlertTitle>Success</AlertTitle>
                        Successfully reset account. Please inform user of new account info and ask them to reset their
                        password.
                    </Alert>
                )}
                <TextField
                    id="reset-auth-id"
                    label="Auth ID"
                    variant="outlined"
                    required
                    error={formErrors.includes('authId')}
                    css={css`
                        margin-bottom: 1rem;
                    `}
                    value={authId || ''}
                    onChange={(e) => setAuthId(e.target.value?.trim())}
                />
                <TextField
                    id="reset-client-id"
                    label="Client ID"
                    variant="outlined"
                    required
                    error={formErrors.includes('clientId')}
                    css={css`
                        margin-bottom: 1rem;
                    `}
                    value={clientId || ''}
                    onChange={(e) => setClientId(e.target.value?.trim())}
                />
                <TextField
                    id="reset-email"
                    label="New Email"
                    variant="outlined"
                    required
                    error={formErrors.includes('email')}
                    css={css`
                        margin-bottom: 1rem;
                    `}
                    value={email || ''}
                    onChange={(e) => setEmail(e.target.value?.toLowerCase()?.trim())}
                />
                <TextField
                    id="reset-pw-1"
                    label="New Password"
                    type={passwordHidden ? 'password' : 'text'}
                    variant="outlined"
                    required
                    error={formErrors.includes('password')}
                    css={css`
                        margin-bottom: 1rem;
                    `}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end" onClick={() => setPasswordHidden((prev) => !prev)}>
                                {passwordHidden ? <VisibilityOff /> : <Visibility />}
                            </InputAdornment>
                        ),
                    }}
                    value={passwordOne || ''}
                    onChange={(e) => setPasswordOne(e.target.value?.trim())}
                />
                <TextField
                    id="reset-pw-2"
                    label="Confirm Password"
                    type={passwordHidden ? 'password' : 'text'}
                    variant="outlined"
                    required
                    error={formErrors.includes('password')}
                    css={css`
                        margin-bottom: 1rem;
                    `}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end" onClick={() => setPasswordHidden((prev) => !prev)}>
                                {passwordHidden ? <VisibilityOff /> : <Visibility />}
                            </InputAdornment>
                        ),
                    }}
                    value={passwordTwo || ''}
                    onChange={(e) => setPasswordTwo(e.target.value?.trim())}
                />
                <Button
                    css={css`
                        background-color: ${colors.greens[0]};
                        color: white;
                    `}
                    onClick={submit}
                    disabled={loading}
                >
                    Reset Account
                </Button>
            </FormControl>
        </Card>
    );
};

export default compose(withAdminRights)(AdminAccountReset);
