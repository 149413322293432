export const COLUMNS = [
    {
        key: 'type',
        label: 'Type',
        align: 'left',
        width: 2,
    },
    {
        label: 'Rate',
        key: 'rate',
        align: 'center',
    },
    {
        label: 'Quantity',
        key: 'quantity',
        align: 'center',
        width: 1,
    },
    {
        label: 'Product/Service',
        key: 'qbo_tag',
    },
    {
        label: 'Total',
        key: 'total',
        align: 'right',
        width: 1,
    },
];

export const FIXED_CHARGES = [
    { display: 'Base Charge', key: 'base_charge' },
    { display: 'Mileage Charge', key: 'mileage_charge' },
    { display: 'ZIP Charge', key: 'location_charge' },
    { display: 'CC Txn Charge', key: 'cc_transaction_charge' },
];

export const MKPL_CHARGES = [{ display: 'Order Revenue', key: 'carrier_rate' }];
