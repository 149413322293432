import { Tab, Tabs } from '@material-ui/core';
import styled from '@emotion/styled';
import { colors, typography } from '@/styles';
import React from 'react';
import { css } from '@emotion/react';

export const OnwardTabContainer = styled((props) => (
    <Tabs
        {...props}
        variant="scrollable"
        scrollButtons="auto"
        classes={{ indicator: 'MuiTabs-indicator' }}
        textColor="primary"
        indicatorColor="primary"
    />
))`
    & .MuiTabs-indicator {
        height: 4px;
    }
`;

export const OnwardTab = styled((props) => <Tab {...props} />)`
    ${typography.sansSerif}

    text-transform: none;
    font-weight: 700;
    color: ${(props) => (props.error ? '#f44336' : '#2b2b2b')};
    min-height: unset;
    padding: 21px 12px;
`;

export const OnwardCardTabContainer = styled((props) => (
    <Tabs {...props} classes={{ indicator: 'MuiTabs-indicator' }} />
))`
    min-height: auto;
    margin-bottom: -1px;
    & .MuiTabs-flexContainer {
        height: 100%;
    }
    & .MuiTabs-indicator {
        height: 0px;
    }
    & .Mui-selected {
        background-color: white;
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
        border-top: 1px solid ${colors.greys.border};
        border-left: 1px solid ${colors.greys.border};
        border-right: 1px solid ${colors.greys.border};
    }
    & .MuiTab-root {
        min-width: auto;
        padding: 0.5rem 1rem;
    }
`;

export const OnwardCardTab = styled((props) => <Tab {...props} />)`
    ${typography.sansSerif}

    text-transform: none;
    font-weight: 700;
    color: ${(props) => (props.error ? '#f44336' : '#2b2b2b')};
    min-height: unset;
    padding: 21px 12px;
`;

export const VerticalTabs = styled((props) => (
    <Tabs
        {...props}
        orientation="vertical"
        variant="scrollable"
        scrollButtons="auto"
        classes={{ indicator: 'MuiTabs-indicator' }}
        textColor="primary"
        indicatorColor="primary"
    />
))`
    min-width: fit-content;
    height: 100%;
    overflow-y: auto;
    border-right: 1px solid rgba(76, 76, 76, 0.5);
`;

export const VerticalTab = styled(({ icon, label, ...props }) => (
    <Tab
        {...props}
        label={
            <span
                css={css`
                    text-align: start;
                    width: 100%;
                `}
            >
                <span
                    css={css`
                        margin-right: 12px;
                    `}
                >
                    {icon}
                </span>
                {label}
            </span>
        }
    />
))`
    ${typography.sansSerif}
    text-transform: none;
    font-weight: 700;
    padding: 12px;
    min-width: ${(props) => (props.collapsed ? '50px' : '160px')};
    color: ${(props) => (props.error ? '#f44336' : '#2b2b2b')};
`;
