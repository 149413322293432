import React, { useContext, useMemo, useState } from 'react';
import { Context } from '../store';
import { OnwardTab, OnwardTabContainer } from '@/components/Tabs';
import { css } from '@emotion/react';

import OrderSummaryCard from './OrderSummaryCard';
import { Box } from '@material-ui/core';
import SurveyInfo from './SurveyInfo';
import ItemsAndDocs from './ItemsAndDocs';
import Notes from './Notes';


const ORDER_INFO_TABS = {
    DETAILS: 'Details',
    ITEMS_AND_DOCS: 'Items/Docs',
    NOTES: 'Notes',
    SURVEY: 'Survey',
};


export default function OrderInfoSection() {
    const {state, callbacks} = useContext(Context);

    const [tab, setTab] = useState('DETAILS');

    return (
        <>
        <Box display="flex" justifyContent="center" css={css`
                width: -webkit-fill-available;
            `}>
            <OnwardTabContainer
                value={tab}
                onChange={(e, newVal) => setTab(newVal)}
                textColor="primary"
                indicatorColor="primary"
                css={css`
                        background-color: white;
                        border-bottom: 1px solid #A6A6A6;
                    `}
                variant={'scrollable'}
                scrollButtons={'auto'}
            >
                {Object.entries(ORDER_INFO_TABS).map(([value, label]) => (
                    <OnwardTab key={value} value={value} label={label} />
                ))}
            </OnwardTabContainer>
        </Box>


            {ORDER_INFO_TABS[tab] === ORDER_INFO_TABS.DETAILS && (
                <OrderSummaryCard />
            )}
            {ORDER_INFO_TABS[tab] === ORDER_INFO_TABS.ITEMS_AND_DOCS && (
                <ItemsAndDocs />
            )}
            {ORDER_INFO_TABS[tab] === ORDER_INFO_TABS.NOTES && (
                <Notes />
            )}
            {ORDER_INFO_TABS[tab] === ORDER_INFO_TABS.SURVEY && (
                <SurveyInfo />
            )}
        </>
    );
}
