import React, { useContext, useMemo } from 'react';

import { css } from '@emotion/react';
import { Grid } from '@material-ui/core';
import { Body1, Body2 } from './blocks';
import { PrimaryButton } from '@/styles/blocks';

import { WarehousingContext } from './context';

const WarehouseList = () => {
    const { locations, setSelectedWarehouse, selectedWarehouse, view, setMode, setView } =
        useContext(WarehousingContext);

    const locationsSorted = useMemo(() => {
        return [...locations]
            .filter((location) => location?.enable_for_wms !== false)
            .sort((a, b) => {
                return (a?.location_name || 'z').localeCompare(b?.location_name || 'z');
            });
    }, [locations]);

    const getBinCount = (l) => {
        const racks = l?.warehouse_racks || [];
        return racks.reduce((accum, r) => {
            return accum + r.rows * r.columns * r.rackDepth;
        }, 0);
    };

    return (
        <>
            <Grid
                css={css`
                    width: 100%;
                    text-align: center;
                    background-color: #e2e2e2;
                    padding-top: 0.75rem;
                    padding-bottom: 0.75rem;
                `}
            >
                <span
                    css={css`
                        font-size: 16px;
                        font-weight: 700;
                    `}
                >
                    Warehouses
                </span>
            </Grid>
            <Grid
                css={css`
                    overflow-y: scroll;
                `}
            >
                {locationsSorted?.map((l, idx) => (
                    <Grid
                        key={`location-container-1-${l.location_id}`}
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        css={css`
                            align-content: center;
                            padding: 1rem 1.5rem 1rem 1rem;
                            border-bottom: 1px solid #e2e2e2;
                            border-top: 1px solid #e2e2e2;
                            background-color: ${selectedWarehouse?.location_id === l.location_id ? '#ececec' : 'white'};
                        `}
                    >
                        <Grid
                            item
                            css={css`
                                width: 100%;
                            `}
                        >
                            <Body1>{l.location_name || `Warehouse ${idx + 1}`}</Body1>
                        </Grid>
                        <Grid item>
                            {' '}
                            <Body2>Bins: {getBinCount(l)}</Body2>
                        </Grid>
                        <Grid
                            item
                            container
                            direction="row"
                            css={css`
                                flex-wrap: nowrap;
                                justify-content: space-around;
                                margin-top: 10px;
                            `}
                        >
                            <Grid item>
                                <PrimaryButton
                                    css={css`
                                        min-width: 100px;
                                        ${selectedWarehouse?.location_id === l?.location_id &&
                                        view === 'view' &&
                                        `
                                                background-color: #3a7a41;
                                                box-shadow: none;
                                            `}
                                    `}
                                    // disabled={!(getBinCount(l) > 0)}
                                    onClick={() => {
                                        setSelectedWarehouse(l);
                                        setMode('3d');
                                        setView('view');
                                    }}
                                >
                                    View
                                </PrimaryButton>
                            </Grid>
                            <Grid item>
                                <PrimaryButton
                                    css={css`
                                        min-width: 100px;
                                        ${selectedWarehouse?.location_id === l?.location_id &&
                                        view === 'edit' &&
                                        `
                                                background-color: #3a7a41;
                                                box-shadow: none;
                                            `}
                                    `}
                                    onClick={() => {
                                        setSelectedWarehouse(l);
                                        setMode('2d');
                                        setView('edit');
                                    }}
                                >
                                    Edit
                                </PrimaryButton>
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

export default WarehouseList;
