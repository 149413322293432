import { useClientUser } from '@/hooks';
import React, { useContext, useState } from 'react';
import { compose } from 'recompose';
import { useNavigate } from 'react-router-dom';
import { css } from '@emotion/react';
import { TextField, Grid, InputAdornment, LinearProgress, FormControl, InputLabel, Select, MenuItem, ListItemText } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import { Search } from '@material-ui/icons';
import { ACCOUNTING_V2 } from '@/constants/featureFlags';
import * as ROUTES from '@/constants/routes';
import { OnwardTab, OnwardTabContainer } from '@/components/Tabs';
import Table from './Table';
import Footer from './Footer';
import { H1 } from './blocks';
import { Context, ContextProvider } from './store';
import { FilterHeader } from '../DispatchUnassigned/blocks';
import { asBrowserDate } from '@/utilities/convertToISO';
import { CustomCheckbox } from '../CarrierAccountingOrders/blocks';
import ExportCsvTemplateModal from '../ExportCsvTemplateModal';
import { DETAILED_CSV_COLUMNS } from '../CarrierAccountingInvoice/detailedViewCsvColumns';


export const Invoices = ({ context, title, Footer }) => {
    const { state, loading, callbacks } = useContext(context);
    const { orderFilters, shippers } = state;
    const navigate = useNavigate();
    const { circles, roles } = useClientUser();
    const enabled = !!circles?.[ACCOUNTING_V2] || roles['ONWARD_ADMIN'];

    const csvExportColumns = {
        Invoice: DETAILED_CSV_COLUMNS,
    };

    return (
        <>
        <Grid
            direction="column"
            container
            css={css`
                width: 100%;
                height: 100%;
            `}
        >
            {state?.filters?.length > 0 ? (
                <Grid
                    direction="row"
                    item
                    css={css`
                        display: flex;
                        justify-content: center;
                        background-color: white;
                    `}
                >
                    <OnwardTabContainer value={0} textColor="primary" indicatorColor="primary" centered>
                        <OnwardTab
                            label={
                                !enabled ? (
                                    <Grid
                                        direction="row"
                                        container
                                        css={css`
                                            justify-content: center;
                                        `}
                                    >
                                        <Grid
                                            direction="column"
                                            container
                                            css={css`
                                                flex-basis: 0;
                                            `}
                                        >
                                            <LockIcon
                                                css={css`
                                                    font-size: 20px;
                                                    justify-content: center;

                                                    margin-right: 8px;
                                                `}
                                            />
                                        </Grid>
                                        <Grid
                                            direction="column"
                                            container
                                            css={css`
                                                flex-basis: 0;
                                            `}
                                        >
                                            Internal
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <>Internal</>
                                )
                            }
                            disabled={!enabled}
                        />
                        <OnwardTab
                            label="Onward"
                            onClick={() => {
                                navigate(ROUTES.CARRIER_ACCOUNTING_V2);
                            }}
                        />
                    </OnwardTabContainer>
                </Grid>
            ) : null}
            <Grid
                container
                item
                direction="row"
                css={css`
                    padding-bottom: 0;
                `}
            >
                <Grid
                    container
                    item
                    direction="row"
                    css={css`
                        padding: 40px;
                        padding-bottom: 0;
                        margin-bottom: 12px;
                    `}
                >
                    <H1>{title}</H1>
                </Grid>
                <Grid
                    container
                    item
                    direction="row"
                    justifyContent="flex-start"
                    css={css`
                        padding: 0 40px;
                        margin-bottom: 30px;
                        align-items: flex-end;
                    `}
                >
                    <Grid
                        direction="column"
                        container
                        item
                        css={css`
                            flex: 1;
                            flex-basis: 0;
                            align-items: flex-end;
                            margin-right: 8px;
                        `}
                    >
                        <Grid
                            direction="row"
                            item
                            css={css`
                                align-self: flex-start;
                                margin-bottom: 4px;
                            `}
                        >
                            <FilterHeader>Completion Date</FilterHeader>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            css={css`
                                flex-wrap: nowrap;
                            `}
                        >
                            <Grid
                                item
                                direction="column"
                                css={css`
                                    flex: 1;
                                    flex-basis: 0;
                                    margin-right: 8px;
                                `}
                            >
                                <TextField
                                    type="date"
                                    variant="outlined"
                                    size="medium"
                                    InputLabelProps={{ shrink: true }}
                                    name="completedTo"
                                    label="Start Date"
                                    value={orderFilters.completedFrom}
                                    onChange={(e) =>
                                        callbacks.setOrderFilters((prev) => ({
                                            completedFrom: asBrowserDate(e.target.value).getTime(),
                                        }))
                                    }
                                    css={css`
                                        background-color: white;
                                        width: 100%;
                                        padding-right: 2px;
                                        margin-top: 0.2rem;
                                    `}
                                />
                            </Grid>

                            <Grid
                                item
                                direction="column"
                                css={css`
                                    flex: 1;
                                    flex-basis: 0;
                                `}
                            >
                                <TextField
                                    type="date"
                                    variant="outlined"
                                    size="medium"
                                    InputLabelProps={{ shrink: true }}
                                    name="completedTo"
                                    label="End Date"
                                    value={orderFilters.completedTo}
                                    onChange={(e) =>
                                        callbacks.setOrderFilters(() => ({
                                            completedTo: asBrowserDate(e.target.value).getTime(),
                                        }))
                                    }
                                    css={css`
                                        background-color: white;
                                        width: 100%;
                                        padding-left: 2px;
                                        margin-top: 0.2rem;
                                    `}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        direction="column"
                        item
                        css={css`
                            flex: 1;
                            flex-basis: 0;
                            margin-right: 8px;
                        `}
                    >
                        <FormControl
                            css={css`
                                width: 100%;
                                background-color: white;
                            `}
                            variant="outlined"
                        >
                            <InputLabel
                                shrink
                                id="partner-shipper"
                                css={css`
                                    background: #fff;
                                `}
                            >
                                Shipper
                            </InputLabel>
                            <Select
                                fullWidth
                                labelId="partner-shipper"
                                id="select-partner-shipper"
                                multiple
                                value={orderFilters?.selectedShippers || []}
                                renderValue={(selectedShippersLocal) =>
                                    selectedShippersLocal.length > 0
                                        ? shippers
                                              .reduce((acc, shipper) => {
                                                  if (selectedShippersLocal.includes(shipper.key)) {
                                                      acc.push(shipper.label);
                                                  }
                                                  return acc;
                                              }, [])
                                              .join(', ')
                                        : ''
                                }
                            >
                                {shippers?.map((shipper, i) => (
                                    <MenuItem key={shipper.id} value={shipper.id}>
                                        <CustomCheckbox
                                            checked={
                                                orderFilters?.selectedShippers.length > 0
                                                    ? orderFilters?.selectedShippers.indexOf(shipper.id) > -1
                                                    : false
                                            }
                                            onClick={() => {
                                                callbacks.handleShipperChange(shipper.id);
                                            }}
                                        />
                                        <ListItemText
                                            primary={shipper.label}
                                            onClick={() => {
                                                callbacks.handleShipperChange(shipper.id);
                                            }}
                                        />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid
                        direction="column"
                        item
                        css={css`
                            flex: 1;
                            flex-basis: 0;
                        `}
                    >
                        <TextField
                            value={state.search}
                            placeholder="Search Orders"
                            onChange={callbacks.setSearch}
                            variant="outlined"
                            color="primary"
                            size="medium"
                            css={css`
                                width: 100%;
                            `}
                            InputProps={{
                                style: { backgroundColor: 'white' },
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>

            {loading.init && (
                <Grid
                    item
                    css={css`
                        margin: 0 2rem;
                    `}
                >
                    <LinearProgress
                        color="primary"
                        css={css`
                            width: 100%;
                        `}
                    />
                </Grid>
            )}

            <Grid
                direction="row"
                item
                css={css`
                    display: flex;
                    justify-content: center;
                    background-color: white;
                    margin: 0 40px;
                    justify-content: flex-start;
                `}
            >
                <OnwardTabContainer
                    value={state.status}
                    onChange={(e, t) => {
                        callbacks.setStatus(t);
                        callbacks.setSelected({});
                    }}
                    textColor="primary"
                    indicatorColor="primary"
                    centered
                >
                    {state.tabs.map((tab) => (
                        <OnwardTab key={tab.value} label={tab.label} value={tab.value} style={{ outline: 0 }} />
                    ))}
                </OnwardTabContainer>
            </Grid>
            <Grid
                item
                css={css`
                    overflow: hidden;
                    flex-grow: 1;
                    padding: 0 40px;
                `}
            >
                <Table state={state} callbacks={callbacks} />
            </Grid>

            <Footer state={state} callbacks={callbacks} />
        </Grid>
        <ExportCsvTemplateModal 
            open={state.openExportModal}
            setOpen={callbacks.setOpenExportModal}
            exportType={'INVOICE'}
            csvExportColumns={csvExportColumns}
            callbacks={{
                onExport: callbacks.handleCustomCsvExport,
            }}
        />
        </>
    );
};

const withContext = (Component) => (props) =>
    (
        <ContextProvider>
            <Component {...props} context={Context} title="Invoices" Footer={Footer} />
        </ContextProvider>
    );

export default compose(withContext)(Invoices);
