import React, { useMemo } from 'react';
import { InputAdornment, Grid, MenuItem, TextField } from '@material-ui/core';
import { css } from '@emotion/react';
import { Body1 } from './blocks';

const Charge = ({ accessorial, accessorials, rates, editable, isInternal, callbacks, qbo_tags }) => {
    const meta = useMemo(() => {
        return (
            accessorials.find((rate) => rate.type === accessorial.type || accessorial.type?.endsWith(rate.type)) || {}
        );
    }, [accessorial, accessorials]);

    const mod = meta?.isPercentage ? 100 : 1;

    return (
        <Grid
            container
            direction="row"
            css={css`
                border-bottom: 1px solid lightgrey;
                padding: 12px 5px;
                flex-wrap: nowrap;
            `}
        >
            <Grid
                container
                direction="column"
                css={css`
                    flex: 2;
                    flex-basis: 2;
                    max-width: calc(33.3% - 12px);
                    margin-right: 12px;
                `}
            >
                <TextField
                    select
                    variant="outlined"
                    label="Type"
                    disabled={!editable}
                    size="small"
                    value={accessorial.type || null}
                    onChange={(e) => {
                        const type = e.target.value;
                        if (type === 'remove') {
                            callbacks.onRemove();
                        } else {
                            const rate = rates?.[type]?.[0];
                            callbacks.onChange({
                                ...accessorial,
                                rate: rate?.rate || 0,
                                quantity: rate?.min || 1,
                                type,
                            });
                        }
                    }}
                    fullWidth
                >
                    <MenuItem key={'custom'} value={'Custom'}>
                        <Body1>Custom</Body1>
                    </MenuItem>
                    {accessorials.map(({ label, type }) => (
                        <MenuItem key={type} value={type}>
                            <Body1>{label}</Body1>
                        </MenuItem>
                    ))}
                    <MenuItem
                        key={'remove'}
                        value={'remove'}
                        css={css`
                            color: red;
                        `}
                    >
                        <Body1>Remove</Body1>
                    </MenuItem>
                </TextField>
            </Grid>
            <Grid
                container
                direction="column"
                css={css`
                    max-width: calc(16.6% - 24px);
                    margin: 0 12px;
                `}
            >
                <TextField
                    fullWidth
                    variant="outlined"
                    type="number"
                    size="small"
                    disabled={(!isInternal && accessorial.type !== 'Custom') || !editable}
                    value={accessorial.rate * mod}
                    onChange={(e) => {
                        let next = parseFloat(e.target.value) / mod;
                        if (isNaN(next)) {
                            next = '';
                        }

                        callbacks.onChange({
                            ...accessorial,
                            rate: next,
                            total: next * accessorial.quantity,
                        });
                    }}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">{meta.adornment || '$'}</InputAdornment>,
                        inputProps: {
                            style: { textAlign: 'center', paddingRight: '16px' },
                        },
                    }}
                    helperText={meta.decorator}
                    css={css`
                        & .MuiFormHelperText-contained {
                            white-space: nowrap;
                        }
                    `}
                />
            </Grid>
            <Grid
                container
                direction="column"
                css={css`
                    max-width: calc(16.6% - 24px);
                    margin: 0 12px;
                `}
            >
                <TextField
                    fullWidth
                    variant="outlined"
                    type="number"
                    size="small"
                    disabled={!editable}
                    value={accessorial.quantity || null}
                    onChange={(e) => {
                        const qty = e.target.value;
                        callbacks.onChange({
                            ...accessorial,
                            quantity: qty,
                            total: accessorial.rate * qty,
                        });
                    }}
                    InputProps={{
                        inputProps: {
                            style: { textAlign: 'center' },
                        },
                    }}
                />
            </Grid>
            <Grid
                container
                direction="column"
                css={css`
                    max-width: calc(16.6% - 24px);
                    margin: 0 12px;
                `}
            >
                <TextField
                    fullWidth
                    select
                    variant="outlined"
                    size="small"
                    label="Tag"
                    disabled={!editable}
                    value={accessorial.qbo_tag || null}
                    onChange={(e) => {
                        const tag = e.target.value;
                        callbacks.onChange({
                            ...accessorial,
                            qbo_tag: tag,
                        });
                    }}
                >
                    <MenuItem key={'remove'} value={'-'}>
                        <Body1>-</Body1>
                    </MenuItem>
                    {!!accessorial?.qbo_tag &&
                    !qbo_tags?.includes(accessorial?.qbo_tag) &&
                    accessorial.qbo_tag !== '-' ? (
                        <MenuItem key={accessorial?.qbo_tag} value={accessorial?.qbo_tag} disabled>
                            <Body1>
                                {accessorial?.qbo_tag} (<i>deleted</i>)
                            </Body1>
                        </MenuItem>
                    ) : null}
                    {!!qbo_tags &&
                        Object.values(qbo_tags)?.map((tag, i) => (
                            <MenuItem key={i} value={tag}>
                                <Body1>{tag}</Body1>
                            </MenuItem>
                        ))}
                </TextField>
            </Grid>
            <Grid
                container
                direction="column"
                css={css`
                    max-width: calc(16.6% - 24px);
                    margin-left: 12px;
                    justify-content: center;
                    align-items: flex-end;
                `}
            >
                <Body1>
                    {accessorial.total
                        ? new Intl.NumberFormat('en-US', {
                              style: 'currency',
                              currency: 'USD',
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                          }).format(accessorial.total)
                        : '--'}
                </Body1>
            </Grid>
        </Grid>
    );
};

export default Charge;
